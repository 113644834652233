
import React, { useEffect, useState, useRef } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./styles.css";
import ClientFileTable from '../../../client-files';
import Reports from '../../../reports';
import appconfig from '../../../appConfig'
import { useMsal, useAccount } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { tokenRequest } from '../../../auth/authConfig';
import { useAuthorization } from "../../../hooks/useAuthorization";

function ClientFileList() {
  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const [list, setList] = useState([]);

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  const apiServerUrl = appconfig.API_SERVER_URL;

  useEffect(() => {
    
    const FetchData = (token: string) => {
      const headers = new Headers();
      const bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      const options = {
        method: "GET",
        headers: headers
      };

        const uri = apiServerUrl + 'ClientFileService/GetClientFiles?onlyLastTwoYears=false';

      fetch(uri, options)
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res.json();
        })
        .then(data => {
          setList(data);
        });
    };
    

    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then((response) => {
        if (response) {
          FetchData(response.accessToken);
        }
      });
    }

  }, [account, instance]);
  if (canRead("crm-conflict-dashboard", null)) {
  return (<>
    <div className='home'>
      <Tabs
        defaultActiveKey="client-files"
        transition={false}
        id="client-files-tab"
        className="mb-3"
      >
        <Tab eventKey="client-files" title="Client Files">
          <ClientFileTable clientFiles={list} />
        </Tab>
        <Tab eventKey="reports" title="Reports">
          <Reports />
        </Tab>
      </Tabs>
    </div>
    </>
  );
} else {
  return (
      <div className="clientFile">
          <div className="clientDetails">
              <div className="sectionTitle">Client Details</div><br />
              <div>You are not authorized to read this record.</div>
          </div>
      </div>
  );
}
}

export default ClientFileList;