import "./client-financial-info.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import appconfig from '../appConfig'
import { tokenRequest } from "../auth/authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import {Grid , MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import {TextButton} from "../components/Button";
import StyledModal from '../components/Modal';

const ClientFinancialInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const [clientFinancialInformationId, setClientFinancialInformationId] = useState(0);
    const [financiallyEligible, setFinanciallyEligible] = useState('');
    const [familySize, setFamilySize] = useState('');
    const [familySizeError, setFamilySizeError] = useState(false);
    const [last12MonthsIncome, setLast12MonthsIncome] = useState('');
    const [last12MonthsIncomeError, setLast12MonthsIncomeError] = useState(false);
    const [last30DaysIncome, setLast30DaysIncome] = useState('');
    const [last30DaysIncomeError, setLast30DaysIncomeError] = useState(false);
    const [totalEquityValue, setTotalEquityValue] = useState('');
    const [totalEquityValueError, setTotalEquityValueError] = useState(false);
    const [lastAssessmentDate, setLastAssessmentDate] = useState("");
    const [showDate, setShowDate] = useState("");

    useImperativeHandle(ref, () => ({
        getClientFinancialInfo() {
            return {
                "clientFinancialInformationId": clientFinancialInformationId,
                "financiallyEligible": financiallyEligible !=  ''  ?  Boolean(financiallyEligible) : null,
                "familySize": familySize != ''? familySize: null,
                "last12MonthsIncome": last12MonthsIncome!=''? Number(last12MonthsIncome): null,
                "last30DaysIncome": last30DaysIncome!=''? Number(last30DaysIncome): null,
                "totalEquityValue":  totalEquityValue!=''? Number(totalEquityValue): null,
                "lastAssessmentDate": (null == lastAssessmentDate || "" == lastAssessmentDate) ? null : new Date(lastAssessmentDate)
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ClientFileService/GetClientFinancialInformation?clientFileId=' + props.clientFileId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                    setMainInformation(data);
                  
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        };


        if (!props.clientFile && props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                  tokenRequest
                ).then((response) => {
                  if (response) {
                    FetchData(response.accessToken);
                  }
                });
              }
        }
        else if (props.clientFile) {
            setMainInformation(props.clientFile.clientFinancialInformation);
        };
    }, [account, instance]);

    function setMainInformation(data) {
        setClientFinancialInformationId(data.clientFinancialInformationId);
        setFinanciallyEligible(data?.financiallyEligible != null ? Number(data.financiallyEligible) : '');
        setFamilySize(data.familySize);
        setLast12MonthsIncome(data?.last12MonthsIncome != null ? data.last12MonthsIncome.toLocaleString("en-US") : "");
        setLast30DaysIncome(data?.last30DaysIncome != null ? data.last30DaysIncome.toLocaleString("en-US") : "");
        setTotalEquityValue(data?.totalEquityValue != null ? data.totalEquityValue.toLocaleString("en-US") : "");
        setLastAssessmentDate((null == data.lastAssessmentDate || "" == data.lastAssessmentDate) ? null : Date.parse(data.lastAssessmentDate));
        setShowDate((null == data.lastAssessmentDate || "" == data.lastAssessmentDate) ? null : new Date(data.lastAssessmentDate).toLocaleDateString());
    }

    const onLastAssessmentDateChanged = (date) => {
        setShowDate(date);
        setLastAssessmentDate(Date.parse(date));
      };

    const [openModal, setOpenModal] = useState(false);
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
      };
    return (
        <Grid container style={{padding: "10px 20px 0px 20px",marginBottom:props.isCRMOnly ? "1px": null,backgroundColor: props.isCRMOnly ? "#ececec" : null , border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius:"8px"}}>
        <Grid item xs={6} md={6} style={{ textAlign: "left", font: "normal normal bold 17px/22px Franklin Gothic Medium",
          letterSpacing: "0px", color:" #000000", opacity: "1", marginBottom:"10px"}}> {props.fromViewDeatils ? "" : "Client Financial Information"}</Grid> 
           {!props.isResolveCrmConflicts && (<Grid item xs={6} md={6}  style={{ textAlign: "right"}}><TextButton label="Financial Eligibility Guidelines" size="small" handleOnClick={handleModalOpen} /></Grid>)}
            <StyledModal open={openModal} title="Financial Eligibility" handleOnClose={handleModalClose} />
          
          <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "20px 0px 10px 0px"}}>
            <SelectorTextField label=" Financially Eligible " placeholder=" Financially Eligible "
                disabled={props.isCRMOnly? true : null}
                select
                    value={financiallyEligible}  >
                    <MenuItem key='' value=""></MenuItem>
                    <MenuItem key="1" value={1}>Yes</MenuItem>
                    <MenuItem key="0" value={0}>No</MenuItem>
            </SelectorTextField>
            </Grid>
            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "20px 0px 10px 0px"}}>
            <TextField label="Family Size" placeholder="Family Size"
                disabled={props.isCRMOnly ? true : null}
                value={familySize} />
            </Grid>
            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "20px 0px 10px 0px"}}>
            <TextField label="Last 12 Months Income ($)" placeholder="Last 12 Months Income"
                disabled={props.isCRMOnly ? true : null}
                value={last12MonthsIncome} />
            </Grid>
            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "10px 0px 10px 0px"}}>
                <TextField label="Last 30 Days Income ($)" placeholder="Last 30 Days Income"
                disabled={props.isCRMOnly ? true : null}
                value={last30DaysIncome} />
            </Grid>
            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "10px 0px 10px 0px"}}>
            <TextField label="Total Equity Value ($)" placeholder="Total Equity Value"
                disabled={props.isCRMOnly ? true : null}
                value={totalEquityValue} />
            </Grid>
            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{margin: "10px 0px 10px 0px"}}>
            <CustomDatePicker label="Last Assessment Date" placeholder="Last Assessment Date" disableFuture={true}
            disabled={props.isCRMOnly? true : null}
            value={showDate} 
            handleOnChange={onLastAssessmentDateChanged}/>
                </Grid>
            </Grid>
    );
})

export default ClientFinancialInfo;