import { useEffect } from "react";
import { GetClientFileById } from "../services/client-file.service";
import {differenceInYears} from 'date-fns';

export function useClientGatewayAccessValidation() {

    useEffect(() => {
    }, []);

    const hasAccess = async (token, clientId) => {
      return await GetClientFileById(parseInt(clientId), token).then(async data => {            
            if(data.clientFileCrmId && data.clientFinancialInformation.lastAssessmentDate)
            {
                var today = new Date();
                var lastAssmentDate = new Date(data.clientFinancialInformation.lastAssessmentDate)
                const numberOfYears = differenceInYears (today, lastAssmentDate);
                if(numberOfYears <=2 )
                {
                    return true;
                }
                else
                {
                    return false;
                }
                
            }
        });
       
    }
    return [hasAccess];
}