import styled from "styled-components";

export const StyledLoader = styled.div`
  position: relative;
  left: -9999px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${({ theme }) => {
    return theme.colors.darkgrey;
  }}
  color : ${({ theme }) => {
    return theme.colors.darkgrey;
  }}
  box-shadow: ${({ theme }) => {
    return `9999px 0 0 -5px ${theme.colors.darkgrey};`;
  }}
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.5s;

  &:before {
    box-shadow: ${({ theme }) => {
      return `9974px 0 0 -20px ${theme.colors.darkgrey};`;
    }}
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${({ theme }) => {
      return theme.colors.darkgrey;
    }}
    color : ${({ theme }) => {
      return theme.colors.darkgrey;
    }}
  }

  &:after {
    box-shadow: ${({ theme }) => {
      return `10024px 0 0 -5px ${theme.colors.darkgrey};`;
    }}
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 1s;
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${({ theme }) => {
      return theme.colors.darkgrey;
    }}
    color : ${({ theme }) => {
      return theme.colors.darkgrey;
    }}
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: ${({ theme }) => {
        return `9974px 0 0 -5px ${theme.colors.darkgrey};`;
      }} 
    }
    30% {
      box-shadow: ${({ theme }) => {
        return `9974px 0 0 2px ${theme.colors.darkgrey};`;
      }} 
    }
    60%,
    100% {
      box-shadow: ${({ theme }) => {
        return `9974px 0 0 -5px ${theme.colors.darkgrey};`;
      }} 
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: ${({ theme }) => {
        return `9999px 0 0 -5px ${theme.colors.darkgrey};`;
      }}
    }
    30% {
      box-shadow: ${({ theme }) => {
        return `9999px 0 0 2px  ${theme.colors.darkgrey};`;
      }}
    }
    60%,
    100% {
      box-shadow: ${({ theme }) => {
        return `9999px 0 0 -5px ${theme.colors.darkgrey};`;
      }}
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: ${({ theme }) => {
        return `10024px 0 0 -5px ${theme.colors.darkgrey};`;
      }}
    }
    30% {
      box-shadow: ${({ theme }) => {
        return `10024px 0 0 2px ${theme.colors.darkgrey};`;
      }}
    }
    60%,
    100% {
      box-shadow: ${({ theme }) => {
        return `10024px 0 0 -5px ${theme.colors.darkgrey};`;
      }}
    }
  }
`;

export const Container = styled.div`
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  width: fit-content;
  height: fit-content;
`;
