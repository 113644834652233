import appconfig from '../appConfig'

const azAppClientId = appconfig.AZ_APP_CLIENT_ID;
const azApiClientId = appconfig.AZ_API_CLIENT_ID

export const msalConfig = {
  auth: {
    clientId: azAppClientId,
    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: "https://login.microsoftonline.com/32e161fc-98b4-481b-a0c2-2b74a6117b85", 
    redirectUri: "/",
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: "localStorage",
    // Set this to "true" if you are having issues on IE11 or Edge 
    storeAuthStateInCookie: false, 
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};
// Add scopes here for Access token to be used at Microsoft identity platform endpoints.
export const tokenRequest = {
  scopes: [`api://${azApiClientId}/WebApi.Scopes`]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};