import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/reviewdashboard/crm";

const CLIENTFILES="/clientfiles";
const CLIENTFILESFILTER="/clientfiles/filter";
const CLIENTFILESRESOLVE="/clientfiles/resolve";
const COUNTS="/counts";

const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const GETFAILED = 'Error Occurred.';



export async function RetrieveConflictedClientFiles(token, filterDetails, index, take) {

    const PAGINATION = `?index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILES+PAGINATION;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(filterDetails)
        
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}

export async function GetConflictDashboardCounts(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+COUNTS;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {            
            var data =await response.json();             
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function FilterClientAndCRMFiles(token, searchBy) {    

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILESFILTER;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(searchBy)
        
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
           // alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}

export async function ResolveCrmConflictedClientFile(token, crmId, clientId, linkOption) {

const queryParam = `?crmId=${crmId}&id=${clientId}&isUnlink=${linkOption}`;
    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILESRESOLVE+queryParam;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,  
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED);
     console.log(error); });

    return result;
}