import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/reviewdashboard/dcp";

const CLIENTFILES="/clientfiles";
const CLIENTFILESFILTER="/clientfiles/filter";
const CLIENTFILESRESOLVE="/clientfiles/resolve";
const CLIENTFILESMARKUNCONFLICTED="/clientfiles/markunconflicted";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const GETFAILED = 'Error Occurred.';



export async function RetrieveConflictedClientFiles(token, index, take) {

    const PAGINATION = `?index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILES+PAGINATION;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers     
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}

export async function GetMatchingClientFiles(token, id) {    

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const params = `?clientFileId=${id}`;
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILESFILTER+params;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers  
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
           // alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}

export async function ResolveConflictedClientFile(token, fromClientFileId, toClientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const params = `?fromClientFileId=${fromClientFileId}&toClientFileId=${toClientFileId}`;
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILESRESOLVE+params;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers       
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED);
     console.log(error); });

    return result;
}

export async function ClearConflictedDcpClientFile(token, fromClientFileId) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const params = `?fromClientFileId=${fromClientFileId}`;
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+CLIENTFILESMARKUNCONFLICTED+params;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers       
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED);
     console.log(error); });

    return result;
}