import "./client-basic-info.css";
import React, { useEffect, useState, useReducer, useRef, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import appconfig from '../appConfig'
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import CustomDatePicker from "../components/DatePicker";
import { Grid,MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import SelectorTextField from "../components/SelectorTextField";

const ClientBasicInfo = forwardRef((props, ref) => {
    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const apiServerUrl = appconfig.API_SERVER_URL;

    const FIRSTNAME = "FirstName";
    const LASTNAME = "LastName";
    const CLIENTALIAS1 = "ClientAlias1";
    const CLIENTALIAS2 = "ClientAlias2";
    const CLIENTALIAS3 = "ClientAlias3";
    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [clientBasicInformationId, setClientBasicInformationId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [dateOfBirthError, setDateOfBirthError] = useState(false);
    const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");
    const [clientAlias1, setClientAlias1] = useState("");
    const [clientAlias1Error, setClientAlias1Error] = useState(false);
    const [clientAlias2, setClientAlias2] = useState("");
    const [clientAlias2Error, setClientAlias2Error] = useState(false);
    const [clientAlias3, setClientAlias3] = useState("");
    const [clientAlias3Error, setClientAlias3Error] = useState(false);
    const [indigenousAncestry, setIndigenousAncestry] = useState("");
    const [showDate, setShowDate] = useState("");

    useImperativeHandle(ref, () => ({
        getClientBasicInfo() {
            if (!firstName) { setFirstNameError(true); }
            if (!lastName) { setLastNameError(true);  }
            if (null == dateOfBirth || "" == dateOfBirth) { setDateOfBirthError(true); }
            return {
                "clientBasicInformationId": clientBasicInformationId,
                "firstNameValidity":(!firstName) ? true : firstNameError,
                "lastNameValidity":(!lastName) ? true : lastNameError,
                "dateOfBirthValidity":(null == dateOfBirth || "" == dateOfBirth) ? true : (dateOfBirthError),
                "clientAlias1Validity": clientAlias1Error,
                "clientAlias2Validity": clientAlias2Error,
                "clientAlias3Validity": clientAlias3Error,
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "dateOfBirth": (null == dateOfBirth || "" == dateOfBirth) ? null : new Date(dateOfBirth),
                "clientAlias1": clientAlias1,
                "clientAlias2": clientAlias2,
                "clientAlias3": clientAlias3,
                "indigenousAncestry": {
                    "IndigenousAncestryId": indigenousAncestry,
                    "IndigenousAncestryCrmValue": 0,
                    "label": "string"
                }
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {


            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ClientFileService/GetClientBasicInformation?clientFileId=' + props.clientFileId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setMainInformation(data);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        };

        if (!props.clientFile && props.clientFileId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        } 
        else if (props.clientFile) {
            setMainInformation(props.clientFile.clientBasicInformation);
        } 
        else {
            setFirstName(props.firstName);
            setMiddleName(props.middleName);
            setLastName(props.lastName);
            setDateOfBirth((null == props.dateOfBirth || "" == props.dateOfBirth) ? "" : Date.parse(props.dateOfBirth));
            setShowDate((null == props.dateOfBirth || "" == props.dateOfBirth) ? "" : new Date(props.dateOfBirth).toLocaleDateString());
        };


    }, [account, instance]);

    function setMainInformation(data) {
        setClientBasicInformationId(data.clientBasicInformationId);
        setFirstName(data.firstName);
        setMiddleName(data.middleName);
        setLastName(data.lastName);
        setDateOfBirth((null == data.dateOfBirth || "" == data.dateOfBirth) ? "" : Date.parse(data.dateOfBirth));
        setShowDate((null == data.dateOfBirth || "" == data.dateOfBirth) ? "" : new Date(data.dateOfBirth).toLocaleDateString());
        setClientAlias1(data.clientAlias1);
        setClientAlias2(data.clientAlias2);
        setClientAlias3(data.clientAlias3);
        if (null == data.indigenousAncestryId || "" == data.indigenousAncestryId) {
            if (null != data.indigenousAncestry && null != data.indigenousAncestry.indigenousAncestryId) {
                setIndigenousAncestry(data.indigenousAncestry.indigenousAncestryId);
            } else if (null != data.indigenousAncestry && null != data.indigenousAncestryCrmValue) {

            } else {
                setIndigenousAncestry("");
            }
        } else {
            setIndigenousAncestry(data.indigenousAncestryId);
        }
    }
    function checkName(event, inputName) {
        if (event.target.value.length == 0) {
            switch (inputName) {
                case FIRSTNAME:
                    setFirstName(EMPTYSTRING);
                    setFirstNameError(true);
                    break;
                case LASTNAME:
                    setLastName(EMPTYSTRING);
                    setLastNameError(true);
                    break;
                default:
                    break;
            }
        }
        else {
            const nameFieldModal = { name: Constants.NAME, value: event.target.value, required: true, maxLength: 50 };
            const status = isValid(nameFieldModal);

            switch (inputName) {
                case FIRSTNAME:
                    setFirstNameError(!status);
                    break;
                case LASTNAME:
                    setLastNameError(!status);
                    break;
                case CLIENTALIAS1:
                    setClientAlias1Error(!status);
                    break;
                case CLIENTALIAS2:
                    setClientAlias2Error(!status);
                    break;
                case CLIENTALIAS3:
                    setClientAlias3Error(!status);
                    break;
                default:
                    break;
            }
        }
    }

    const onFirstNameChanged = (event) => {
        setFirstName(event.target.value);
        checkName(event, FIRSTNAME);
    };
    const onMiddleNameChanged = (event) => {
        setMiddleName(event.target.value);
    };
    const onLastNameChanged = (event) => {
        setLastName(event.target.value);
        checkName(event, LASTNAME);
    };

    // const onDateOfBirthChanged = (date) => {   
    //     setShowDate(date);
    //     if (date != null) {
    //        if((new Date(date)).toString() === "Invalid Date" || new Date(date) > new Date()){
    //         setIsDateOfBirthInvalid(true);
    //        } else {
    //         setDateOfBirth(Date.parse(date));
    //         setDateOfBirthError(false);
    //         setIsDateOfBirthInvalid(false); 
    //        };  
    //     } else {
    //         setDateOfBirth(date);
    //         setDateOfBirthError(true);
    //         setIsDateOfBirthInvalid(false);   
    //     }
    // };

    const onBirthDateChanged = (date) => {
        setDateOfBirth(date);
        setDateOfBirthError(false);
    };

    const today = new Date();
    const maxBirthDate = new Date(today.setDate(today.getDate() - 1));

    const onDateOfBirthChange = (event) => {
        let eventValue = event.target.value;
        let showEventValue= eventValue.replace(/ /g,'').replace(/\-/g,'').replace(/\//g,'');
        let eventValueDate = (new Date(showEventValue)).toString();
        const checkDateValidity = (date) => {
            if((new Date(date)).toString() === "Invalid Date"){
                setDateOfBirthError(true);
                setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy."); 
            } else if (new Date(date) > maxBirthDate) {
                setDateOfBirthError(true);
                setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy.");
            } else {
                setDateOfBirthError(false);
                setDateOfBirth(date);
            }
        };
        if (showEventValue.length === 9) {
            checkDateValidity(eventValueDate);
        } else if (showEventValue.length === 8) {
            let inputArray = [...showEventValue];
            var formattedDate= "";
            formattedDate = inputArray.slice(4,8).join('') + "-" + inputArray.slice(2,4).join('') + "-" + inputArray.slice(0,2).join('');
            let formattedNewDate = new Date(formattedDate);
            let isValidDate = (new Date(formattedNewDate.setDate(formattedNewDate.getDate() +1 ))).toString();
            checkDateValidity(isValidDate);
        } else if (eventValue.length === 0) {
            setDateOfBirthError(false);
        } else {
            setDateOfBirthError(true);
            setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy.");
        }
    };

    const onClientAlias1Changed = (event) => {
        setClientAlias1(event.target.value);
        checkName(event, CLIENTALIAS1);
    };
    const onClientAlias2Changed = (event) => {
        setClientAlias2(event.target.value);
        checkName(event, CLIENTALIAS2);
    };
    const onClientAlias3Changed = (event) => {
        setClientAlias3(event.target.value);
        checkName(event, CLIENTALIAS3);
    };
    const onIndigenousAncestryChanged = (event) => {
        setIndigenousAncestry(event.target.value);
    };

    const indigenousAncestries = props.indigenousAncestries;

    return (
        <>
            <Grid container style={{ padding: "5px 20px 0px 20px", marginBottom: props.isReadOnly ? "1px" : null, backgroundColor: props.isReadOnly ? "#ececec" : null, border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius: "8px", margin: "0px 0px 10px 0px" }}>
                    {!props.fromViewDeatils && <Grid item xs={12} md={12} style={{
                        textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                    }}>Client Basic Information</Grid>}
                    <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                        <TextField label="First Name" placeholder="First Name" required={true}
                            error={firstNameError? true : null}
                            disabled={props.isReadOnly ? true : null}
                            value={firstName}
                            handleChange={onFirstNameChanged} 
                            helperText={firstNameError ? "First Name is Required." : null}/>
                    </Grid>
                    <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                        <TextField label="Middle Name" placeholder="Middle Name" 
                            disabled={props.isReadOnly ? true : null}
                            value={middleName}
                            handleChange={onMiddleNameChanged} />
                    </Grid>
                    <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                        <TextField label="Last Name" placeholder="Last Name" required={true}
                            error={lastNameError ? true : null}
                            disabled={props.isReadOnly ? true : null}
                            value={lastName}
                            handleChange={onLastNameChanged} 
                            helperText={ lastNameError ? "Last Name is Required." : null}/>
                    </Grid>
                    <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                    <div style={{maxWidth: "275px"}}>
                        <CustomDatePicker label="Date of Birth" placeholder="dd-Mmm-yyyy" required={true} maxDate={maxBirthDate}
                            error={dateOfBirthError ? true : null}
                            value={dateOfBirth}
                            handleOnChange={onBirthDateChanged} 
                            onChange={onDateOfBirthChange}
                            helperText={dateOfBirthError ? dateOfBirthErrorMessage : null}/>
                    </div>
                    </Grid>

                {props.isAliasVisible && (
                    <>
                            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px"}}>
                                <TextField label="Client Alias 1" placeholder="Client Alias 1" style={{backgroundColor: "#ececec"}}
                                    disabled
                                    value={clientAlias1}
                                    handleChange={onClientAlias1Changed} />
                            </Grid>
                            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Client Alias 2" placeholder="Client Alias 2" style={{backgroundColor: "#ececec"}}
                                    disabled
                                    value={clientAlias2}
                                    handleChange={onClientAlias2Changed} />
                            </Grid>
                            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Client Alias 3" placeholder="Client Alias 3" style={{backgroundColor: "#ececec"}}
                                    disabled
                                    value={clientAlias3}
                                    handleChange={onClientAlias3Changed} />
                            </Grid>
                            <Grid item xs={12} md={props.isResolveCrmConflicts ? 12 : 4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Indigenous Ancestry" placeholder="Indigenous Ancestry" style={{backgroundColor: "#ececec"}}
                                    select
                                    disabled
                                    value={indigenousAncestry} 
                                    handleChange={onIndigenousAncestryChanged} >
                                    {indigenousAncestries?.map((indigenousAncestry) => (
                                    <MenuItem key={indigenousAncestry.indigenousAncestryId} value={indigenousAncestry.indigenousAncestryId}>
                                        {indigenousAncestry.label}
                                    </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
 
                    </>
                )}
            </Grid>
        </>
    );
})

export default ClientBasicInfo;