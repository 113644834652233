import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Grid, Stack } from '@mui/material';
import {format} from 'date-fns';
import { GetAssignedFileRecords } from "../../../services/dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import StyledButton from '../../../components/elements/StyledButton';
import DataTable from '../../../components/DataTable';
import { IoWarning } from "react-icons/io5";
import StyledSwitch from '../../../components/StyledSwitch';

function TopAssignedFiles(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterByDueDate, setFilterByDueDate] = useState(searchParams.get("topassignedfilesduedate")== "true"? true: false);
    const [showViewAll, setShowViewAll] = useState(true);

    // #region PageLoad
    useEffect(() => {
        window.scrollTo(0, 0);
        setInitialGridColumns();
    }, []);

    useEffect(() => {
        LoadInitialData(filterByDueDate);

    }, [instance]);

    function LoadInitialData(filterByDueDate) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken, filterByDueDate);
                }
            });
        }
    }
    const setInitialGridData = async (token, dueDate) => {

        await GetAssignedFileRecords(token, dueDate, 0, 5).then((rowData) => {
            setInitialGridColumns();
            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        })
    }

    const setInitialGridColumns = () => {

        setGridColumns([{ field: 'idText', width: 100, headerName: 'ID', renderCell: true, editable: false },
        { field: 'requestor', width: 150, headerName: 'Assigned Lawyer', editable: false },
        { field: 'assignedOn', type: 'date', width: 150, headerName: 'Date Assigned', renderDate: true, editable: false },
        { field: 'upcomingCourtDate', type: 'date', width: 150, headerName: 'Next Court Date', renderWarning: true, editable: false },
        { field: 'modifiedOn', type: 'date',width: 150, headerName: 'Last Updated Date', renderDate: true, editable: false },
        { field: 'assignedTo', width: 180, headerName: 'Agency Assigned', editable: false },
        { field: 'hasDisclosureOrdered', width: 220, headerName: 'Disclosure has been Ordered', editable: false },
        { field: 'firstName', width: 180, headerName: 'First Name', editable: false },
        { field: 'lastName', width: 180, headerName: 'Last Name', editable: false },
        { field: 'isOutDated', width: 250, headerName: 'Outdated?', editable: false, hide: true },
        { field: 'clientFileId', width: 120, headerName: 'Client File ID', editable: false, hide: true }]);

    }
    const columns = [];
    function getLinkUrl(params) {
        if (params.value.split("-")[0] == 'I') {
            return "/interaction?id=" + params.value.split("-")[1] + "&topassignedfilesduedate="+filterByDueDate+"&page=topassignedfiles&clientFileId=" + params.row.clientFileId
        }
        else if (params.value.split("-")[0] == 'AR') {
            return "/agencyrequest?agencyrequestid=" + params.value.split("-")[1] + "&topassignedfilesduedate="+filterByDueDate+"&page=topassignedfiles&clientFileId=" + params.row.clientFileId
        }
    }

    gridColumns.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderCell ? <>  <a href={getLinkUrl(params)}>{params.value}</a> </> :
                    (columnHeader.renderWarning ? (((new Date() - new Date(params.row.upcomingCourtDate)) / (1000 * 3600 * 24) > 7) && params.row.isOutDated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} <IoWarning title="Next court date is already passed" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });
    const onFilterChanged = (event) => {

        setFilterByDueDate(event.target.checked);
        LoadInitialData(event.target.checked);

    };
    //#endregion     

    if (canRead("assigned-files-dashboard", null)) { //ToDo - Change this role

        return (
            <Grid container>
                <Grid item xs={12} md={12} style={{ font: "normal normal normal 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }}>
                    <Stack direction="row"> <div div style={{ marginRight: "10px" }}>Due Today: </div> <StyledSwitch style={{ textAlign: "left", font: "normal normal bold 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: " #585454", opacity: "1" }} checked={filterByDueDate} onChange={onFilterChanged} /> </Stack>
                </Grid>
                <DataTable columns={columns} rows={gridRows} totalCount={props.totalCount} />

                {showViewAll &&
                    <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                        <StyledButton variant={"outlined"} color="error" onClick={() => navigate("/assignedfiles?page=topassignedfiles&cardKey=3")} >View All</StyledButton>
                    </Grid>}
            </Grid>

        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Assigned Files
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view this details **
                </Grid>
            </Grid>
        );
    }
}

export default TopAssignedFiles;