import React, { useEffect, useState, useRef } from 'react';
import {Grid} from '@mui/material';
import DataTable from '../components/DataTable';
import {format} from 'date-fns';
import { Button } from "react-bootstrap";

export default function CertificateDataGrid(props) {
    const columnHeaders =   [{field:'number', width: 120, headerName:'Number', editable: false}, 
                            {field: 'status', width: 120, headerName:'Status', editable: false }, 
                            {field: 'lawyerName', width: 150, headerName:'Lawyer Name' , editable: false},
                            {field: 'lawyerMobileNo', width: 180, headerName:'Lawyer Mobile No', editable: false }, 
                            {field: 'serviceDecision', width: 180, headerName:'Service Decision', editable: false },  
                            {field: 'matter', width: 150, headerName:'Matter', editable: false }, 
                            {field: 'areaofLaw', width: 150, headerName:'Area of Law', editable: false },
                            {field: 'matterType', width: 150, headerName:'Matter Type', editable: false }, 
                            {field: 'specialization', width: 150, headerName:'Specialization', editable: false }, 
                            {field: 'serviceLocation', width: 150, headerName:'Service Location', editable: false }, 
                            {field: 'closedDate', type: 'date', width: 150, headerName:'Closed Date', renderDate: true, editable: false }, 
                            {field: 'lawyerEmail', width: 150, headerName:'Lawyer Email', editable: false },
                            {field: 'id', width: 120, headerName: 'Unique Id', editable: false , hide: true} 
                            ];                    
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {   field: columnHeader.field, 
                renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                renderCell: columnHeader.renderDate ? (params) => <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null,
                width: columnHeader.width,
                editable : columnHeader.editable,
                hide : columnHeader.hide
             },
        );
    });
    
    const rows = [];
    if (props.certificates && props.certificates.length > 0) {     
        props.certificates.forEach((certificate) => {
            rows.push(
                {   
                    id: certificate.id,
                    number: certificate.certificateNumber, 
                    status: certificate.status, 
                    lawyerName: certificate.lawyerName, 
                    lawyerMobileNo: certificate.lawyerMobilePhone, 
                    serviceDecision: certificate.serviceDecision, 
                    matter: certificate.matterNumber, 
                    areaofLaw: certificate.areaOfLaw, 
                    matterType: certificate.matterType, 
                    specialization: certificate.specialization, 
                    serviceLocation: certificate.serviceLocation,  
                    closedDate : certificate.closedDate != null ? certificate.closedDate : null,
                    lawyerEmail: certificate.lawyerEmail, 
                 },
            );
        });
    };
   
    return (
            <Grid container style={{ padding: "10px 20px 20px 20px" }}>
                         {!props.hideHeader && <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
                        }}>{props.title}
                           {/* <Button style={{font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "60px"}}
                            size="sm" variant="primary">New Leagl Issue</Button> */}
                        </Grid>}
                    <DataTable columns={columns} rows={rows} dataGridtitle={props.dataGridtitle ? props.dataGridtitle : ""}/>
            </Grid>
     );
   
};
