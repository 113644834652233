import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import { format } from 'date-fns';
import { Button } from "react-bootstrap";
import { useClientGatewayAccessValidation } from "../../../hooks/useClientGatewayAccessValidation";
import { tokenRequest } from "../../../auth/authConfig";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ClientGatewayTable(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [hasAccess] = useClientGatewayAccessValidation();
    const [hasPermissionForClientGateway, setHasPermissionForClientGateway] = useState(false);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();

    useEffect(() => {
        const FetchLookupData = async (token, clientFileId) => {

            setHasPermissionForClientGateway(await hasAccess(token, clientFileId));
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken, props.clientFileId);

                }
            });
        }
    }, []);

    const columnHeaders = [{ field: 'id', width: 180, headerName: 'Client Gateway Form Id', editable: false, renderCell: true, hide: true},
    { field: 'docket', width: 150, headerName: 'Docket', editable: false },
    { field: 'inCustody', width: 150, headerName: 'In Custody', editable: false },
    { field: 'nextCourtDate', type: 'date', width: 150, headerName: 'Next Court Date', renderDate: true, editable: false },
    { field: 'courtLocation', width: 350, headerName: 'Court Location', editable: false },
    { field: 'likelihoodOfImprisonment', width: 200, headerName: 'Likelihood of Imprisonment', editable: false }

    ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: columnHeader.renderDate ? (params) => <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : columnHeader.renderCell ? (params) => (<a href={`/client-gateway?clientgatewayid=${params.value.split("-")[1]}&clientFileId=${props.clientFileId}`}>{params.value}</a>) : null,
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    const rows = [];
    if (props.clientGatewayDetails && props.clientGatewayDetails.length > 0) {
        props.clientGatewayDetails.forEach((clientGatewayDetail) => {
            rows.push(
                {
                    id: clientGatewayDetail.id,
                    docket: clientGatewayDetail.docket,
                    inCustody: clientGatewayDetail.inCustody,
                    nextCourtDate: clientGatewayDetail.nextCourtDate != null ? clientGatewayDetail.nextCourtDate : null,
                    courtLocation: clientGatewayDetail.courtLocation,
                    likelihoodOfImprisonment: clientGatewayDetail.likelihoodOfImprisonment
                },
            );
        });
    };

    const onRowClicked = (item) => {
        navigate("/client-gateway?clientgatewayid=" + item.row.id.split("-")[1] + "&page=clientFile&clientFileId=" + props.clientFileId);
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", backgroundColor: props.isReadOnly ? "#ececec" : null }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
                }}>{props.title}
                    {canCreate("client-gateway", null) && hasPermissionForClientGateway && <Button style={{ font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "40px" }}
                        size="sm" variant={(canCreate("client-gateway", null)) ? "primary" : "secondary"} disabled={(canCreate("client-gateway", null)) ? "" : "disabled"} className="ml-auto" href={'/client-gateway?page=clientFile&clientFileId=' + props.clientFileId}>New Client Gateway</Button>
                    }</Grid>
                <DataTable columns={columns} rows={rows} onRowDoubleClick={onRowClicked}/>
            </Grid>
        );
    }

};
