import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {IoClose}  from "react-icons/io5";
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '80px',
  right: '0px',
  bottom: '20px',
  width: '500px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
};

function StyledDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2, font: 'normal normal normal 18px/22px Franklin Gothic Medium' }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <IoClose />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
};

const TableCellStyled = styled(TableCell)({
    color: '#585858',
    font: 'normal normal normal 14px/16px Franklin Gothic Medium',
});
const columnsHeaders = [{id :'Family Size'}, { id : '1'}, { id : '2'},{ id : '3'},{ id : '4'},{ id : '5'},{ id : '6+'}];

export default function StyledModal({open,title,handleOnClose}) {
      const rows = [
        {name : 'Monthly Income (Last 30 days)', col1 : '$1,805', col2 : '$2,236',  col3 :'$3,182', col4: '$3,439' , col5 : '$3,697' , col6 : '$3,956'},
        {name : 'Annual Income ', col1 : '$21,668', col2 : '$26,827',  col3 :'$38,176', col4: '$41,271' , col5 : '$44,367' , col6 : '$47,462'},
      ];
      function GuidelineTable() {
        return (
          <TableContainer component={Paper} sx={{ width: 650 , marginTop: "15px"}}>
            <Table aria-label="simple table">
              <TableHead >
                <TableRow >
                    {columnsHeaders.map((header) => (
                        <TableCellStyled sx={{ color: '#000000'}}  align="center">{header.id}</TableCellStyled>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } , color: '#000000'}}
                  >
                    <TableCellStyled component="th" scope="row" sx={{ color: '#000000'}} align="center">
                      {row.name}
                    </TableCellStyled>
                    <TableCellStyled>{row.col1}</TableCellStyled>
                    <TableCellStyled>{row.col2}</TableCellStyled>
                    <TableCellStyled>{row.col3}</TableCellStyled>
                    <TableCellStyled>{row.col4}</TableCellStyled>
                    <TableCellStyled>{row.col5}</TableCellStyled>
                    <TableCellStyled>{row.col6}</TableCellStyled>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleOnClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 690}}>
          <StyledDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
          {title}
        </StyledDialogTitle>
        <DialogContent dividers>
          <Typography style={{font: 'normal normal normal 14px/16px Franklin Gothic Medium', letterSpacing: "0.25px", color: '#585858'}}>
          The Financial Eligibility Guidelines are set by the Minister of Justice and Solicitor 
          General who is mandated to deliver legal aid services to Albertans through the Federal 
          Government of Canada. Below are the current guidelines. *
          <GuidelineTable/>
          </Typography>
          </DialogContent>
          <DialogContent dividers>
          <Typography style={{font: 'normal normal normal 14px/16px Franklin Gothic Medium', letterSpacing: "0.25px", color: "#D41F44"}}>
          *AISH recipients are an exception to these income guidelines.
          </Typography>
          </DialogContent>
          <DialogContent dividers>
          <Typography style={{font: 'normal normal normal 14px/16px Franklin Gothic Medium', letterSpacing: "0.25px", color: '#585858'}}>
          In addition to the income guidelines clients must have <span style={{color: "#D41F44"}}>under $120,000</span> of equity in assets.
          </Typography>
        </DialogContent>
        </Box>
      </Modal>
    </div>
  );
}