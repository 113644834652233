import { Button } from "react-bootstrap";
import "./notes.css";
import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from "../hooks/useAuthorization";
import {Grid} from '@mui/material';
import DataTable from '../components/DataTable';
import CustomButton from "../components/Button";
import StyledDialog from '../components/Dialog';
import { useNavigate, useSearchParams } from "react-router-dom";
import {format} from 'date-fns';
import { useMsal, useAccount } from "@azure/msal-react";
import { GetUserDetailsById } from '../services/graph-service'
import { tokenRequest } from "../auth/authConfig";
import _ from 'lodash';

function NoteTable(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const account = useAccount(accounts[0] || {});    
    const [agencyRequests, setAgencyRequests] = useState([]);
    const [graphAPIuser, setGraphAPIuser] = useState([]);

    useEffect(() => {

    var userIDs = [];
    props.notes.forEach(element => {
        userIDs.push(element.createdBy);
        userIDs.push(element.modifiedBy);
    });

    var uniqUserIDs = _.uniqBy(userIDs);    

        if (uniqUserIDs && uniqUserIDs.length > 0){
            const FetchData = (token) => {
                var userDet = [];
                uniqUserIDs.forEach(userID => {
                    const userDetails = GetUserDetailsById(userID, token).then(response => {
                        userDet.push({id : userID, displayName : response.displayName});                          
                        if(userDet.length == uniqUserIDs.length) {
                            setGraphAPIuser(userDet);
                            return;
                        }          
                    })                
                });
            }
        
        
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }
    }
    }, [props.notes]);


    const columnHeaders =   [{field:'id', width: 80, headerName:'ID', renderCell: true, editable: false, hide :true }, 
                            {field: 'noteText', width: 950, headerName:'Note Text', editable: false }, 
                            {field: 'createdOn', width: 150, headerName:'Created On', editable: false, type: 'date', renderDate: true  }, 
                            {field: 'createdBy', width: 250, headerName:'Created By', editable: false }, 
                            {field: 'modifiedOn', width: 150, headerName:'Modified On', editable: false, type: 'date', renderDate: true  }, 
                            {field: 'modifiedBy', width: 250, headerName:'Modified By', editable: false }, 
                            ];                    
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {   field: columnHeader.field, 
                renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                //renderCell: columnHeader.renderCell ? (params) => (<a href={'/note?id=' + params.value.split("-")[1] + '&isDisabled='+(props.isDisabled?props.isDisabled: false)+'&relatedId=' + props.relatedId + '&noteTypeId=' + props.noteTypeId +'&clientFileId='+props.clientFileId+'&page='+props.pageId}>{params.value}</a>) : null,

                
                renderCell: (params) => ((columnHeader.renderCell ? <a href={'/note?id=' + params.value.split("-")[1] + '&isDisabled='+(props.isDisabled?props.isDisabled: false)+'&relatedId=' + props.relatedId + '&noteTypeId=' + props.noteTypeId +'&clientFileId='+props.clientFileId+'&page='+props.pageId}>{params.value}</a>: 
                (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                
                width: columnHeader.width,
                editable : columnHeader.editable,
                hide : props.pageParams ? columnHeader.hide : false,
             },
        );
    });
    
    const rows = [];
    if (props.notes && props.notes.length > 0) {
        props.notes.forEach((note) => {
            var createdBy = "";
            var modifiedBy = "";
            graphAPIuser.forEach(element => {
                if(element.id == note.createdBy) {
                    createdBy = element.displayName;                    
                }
                if(element.id == note.modifiedBy) {
                    modifiedBy = element.displayName;                    
                }
            });

            console.log("createdBy : " + createdBy);
            if (canRead("client-note", note.createdBy.email)) {  
            rows.push(
                { id: `CN-${note.noteId}`, 
                noteText: (((note.noteText != null) && (note.noteText.length > 100)) ? note.noteText.substring(0, 100) : note.noteText), 
                createdOn: note.createdOn, 
                createdBy: createdBy,
                modifiedOn: note.modifiedOn, 
                modifiedBy: modifiedBy,},
            );
        } else {
            return (<table><tbody><tr><td>You are not authorized to read this record.</td></tr></tbody></table>);
        };
        });
    };

    const onNotesRowDoubleClick = (item, event) => {
        navigate('/note?id=' + item.row.id.split("-")[1] + '&relatedId=' + props.relatedId + '&noteTypeId=' + props.noteTypeId +'&clientFileId='+props.clientFileId + props.pageParams);
    };
   
    const onOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };
    const onCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const onConfirmClicked = () => {
        navigate('/note?noteTypeId=' + props.noteTypeId + '&relatedId=' + props.relatedId+'&'+props.queryParams);
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <>
            <StyledDialog title="Saved Changes?" open={openConfirmDialog} onClose={onCloseConfirmDialog}
                        message={"Any unsaved changes will be lost if you try to add a new note without saving your changes. Are you sure you want to proceed?"}
                        onConfirmed={onConfirmClicked} confirmationText="Continue" isCancelRequired={true}/>
            <Grid container style={{ padding: props.hideBorder ? "5px" : "5px 20px 5px 20px", border: props.hideBorder ? "" : "1px solid #7F8284", margin: "10px 0px 0px 0px", borderRadius: "8px" }}>
                       {!props.hideHeader && <><Grid item xs={11} md={11} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                        }}>{props.title}</Grid>
                            <Grid item xs={1} md={1} style={{ textAlign: "end"}}><CustomButton variant="contained" label="New Note" size="small" disabled={ props.isDisabled ? true : ((canCreate("client-note", null)) ? false : true)} onClick={onOpenConfirmDialog}/>
                            {/* <Button style={{font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "65px"}}
                            size="sm" variant={(canCreate("client-note", null)) ? "primary" : "secondary"} disabled={ props.isDisabled ? true : ((canCreate("client-note", null)) ? false : true)} className="ml-auto" href={'/note?noteTypeId=' + props.noteTypeId + '&relatedId=' + props.relatedId+'&'+props.queryParams}>New Note</Button> */}
                        </Grid></>}
                    <DataTable columns={columns} rows={rows} onRowDoubleClick={props.pageParams ? onNotesRowDoubleClick : ()=>{}} dataGridtitle={props.dataGridtitle ? props.dataGridtitle : ""} hideTotalCount={props.hideTotalCount ? true : false}/>
            </Grid>
            </>
        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                        }}>{props.title}
                        </Grid>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1",
                        }}>** Save the new Client File to add new Notes **
                        </Grid>            
            </Grid>
        );
    }
}

export default NoteTable;
