import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/dashboard/";
const MYFILES = "myfiles";
const FILESTOBEASSIGNED = "filestobeassigned";
const FILTER = "/filter";
const COUNT = "counts";
const ASSIGNEDFILES = "assignedfiles";
const SEARCHASSIGNEDFILES = "assignedfiles/filter";
const LSMS = "users/lsms";
const MANAGERS= "users/managers";
const LAWLERSOFLSMS = "lsms/lawyers";


const INDEXPARAM = "index";
const TAKEPARAM = "take";

const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const GETFAILED = 'Error Occurred.';

//#region MyFiles
export async function GetMyFiles(token, dueDate, index, take) {

    const PAGINATION = `?dueDate=${dueDate}&index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+MYFILES+PAGINATION;    

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetCounts(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+COUNT;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {            
            var data =await response.json();             
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function SearchMyFiles(token, searchBy, index, take) {

    const PAGINATION = `?index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+MYFILES+FILTER+PAGINATION;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(searchBy)
        
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}


//#endregion

//#region FilesToBeAssigned
export async function GetFilesToBeAssigned(token,dueDate, index, take) {

    const PAGINATION = `?dueDate=${(dueDate)}&index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+FILESTOBEASSIGNED+PAGINATION;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function SearchFilesToBeAssigned(token, searchBy, index, take) {

    const PAGINATION = `?index=${index}&take=${take}`;

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+FILESTOBEASSIGNED+FILTER+PAGINATION;  
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(searchBy)
        
    }
    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {            
            return response.json();
        } else {
            toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
            //alert(GETFAILED);
        }
    }).catch(error => { toast.error(GETFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    //alert(GETFAILED); 
    console.log(error); });

    return result;
}
export async function SearchAssignedFileRecords(token, searchBy, dueDate, index, records) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const PAGINATION = `?dueDate=${(dueDate)}&index=${index}&take=${records}`;
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + ASSIGNEDFILES+FILTER + PAGINATION;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(searchBy)
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetAssignedFileRecords(token, dueDate, index, records) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const PAGINATION = `?dueDate=${(dueDate=="1"?true:false)}&index=${index}&take=${records}`;
    
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + ASSIGNEDFILES + PAGINATION;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetLSMs(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + LSMS;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetManagers(token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + MANAGERS;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}
//#endregion
