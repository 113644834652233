import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(() => ({
  font: "normal normal 300 14px/16px Franklin Gothic Medium",
  color: "#585454",
  '&:hover':  {cursor: "pointer"}
}));
export default function DataTable({
  columns,
  rows,
  getRowStyled,
  disableSelection,
  onRowClick,
  onRowDoubleClick,
  totalCount,
  dataGridtitle,
  ...props
}) {
  return (
    <Box
      sx={{
        height: rows.length == 0 ? 90 : (rows.length > 100 ? 3711 : rows.length* 36 + 58 ),
        width: "100%",
        font: "normal normal 300 14px/16px Franklin Gothic Medium",
        color: "#585454",
        
        marginBottom: "30px",
        "& .data-grid-row-style-upcoming-court-date": {
          bgcolor: "#d0c190",
        },
        "& .data-grid-row-selected-style": {
          bgcolor: "#EFEFEF",
        },
        "& .data-grid-search-results-crm-row-style": {
          bgcolor: "#EFEFEF",
        }, 
      }}
    >{rows.length >= 0 && <div style={{float: "left",  width:"100%" }}>
    <div style={{margin: "5px 5px 5px ",float: "left", textAlign: "left", fontSize : "16px",  color: "#000000"}}>{dataGridtitle}</div>
    {!props.hideTotalCount && <div style={{margin: "5px 5px 5px ",float: "right", textAlign: "right"}}>Total No. of Items : {totalCount ? totalCount : rows.length}</div>}
    </div>}
      <StyledDataGrid
        density="compact"
        hideFooter={rows.length < 100 ? true : false}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        disableSelectionOnClick={disableSelection}
        experimentalFeatures={{ newEditingApi: true }}
        getRowClassName={getRowStyled}
        onRowClick={onRowClick}
        onRowDoubleClick={onRowDoubleClick}
        sortingOrder={['desc', 'asc']}
        disableColumnSelector 
      />
    </Box>
  );
}
