
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import { useNavigate } from 'react-router-dom';
import {
    NavContainer
} from "../components/styled/Header.styled";
import StyledButton from "../components/elements/StyledButton";
/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleSignIn = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {                
                navigate("/");
            });

        }
    }

    return (
        <>
            <NavContainer>
                <div style={{ marginBottom: "-10px" }}>
                    <StyledButton variant={"outlined"} color="error" onClick={() => handleSignIn("redirect")}>Sign In</StyledButton>
                    <div style={{ fontSize: "12px" }}><a href="https://passwordreset.microsoftonline.com/" target="_blank" style={{ color: "#d41f44" }}>Forgot password?</a></div>
                </div>
            </NavContainer>
        </>
    );
}