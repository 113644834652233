import React from 'react';
import { Button } from "react-bootstrap";
import { useAuthorization } from "../../../hooks/useAuthorization";
import {Grid} from '@mui/material';
import DataTable from '../../../components/DataTable';
import Badge from '@mui/material/Badge';
import { useValidation } from "../../../hooks/useValidation";
import * as Constants from "../../../helper/constants";
import { IoWarning } from "react-icons/io5";
import {format} from 'date-fns';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function InteractionList(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [isValid] = useValidation();
    const navigate = useNavigate();

    const columnHeaders = [{ field: 'id', width: 180, headerName: 'ID', renderCell: true, editable: false, hide: true },
    { field: 'FormStatus', width: 120, headerName: 'Form Status', editable: false },
    { field: 'DocketNumber', width: 150, headerName: 'Docket Number', editable: false },
    { field: 'UpcomingCourtDate', type: 'date', width: 160, headerName: 'Next Court Date', editable: false, renderWarning:true },
    { field: 'AssignedTo', width: 150, headerName: 'Assigned To', editable: false },
    { field: 'CourtLocation', width: 280, headerName: 'Court Location', editable: false },
    { field: 'CourtRoom', width: 100, headerName: 'Court Room', editable: false },
    { field: 'AssesmentOutcome', width: 320, headerName: 'Assesment Outcome', editable: false },
    { field: 'IsActive', width: 120, headerName: 'IsActive', editable: false, hide: true },
    { field: 'IsActionRequired', width: 120, headerName: 'Is Action Required', editable: false, hide: true },
    { field: 'isConflicted', width: 120, headerName: 'Is Conflicted', editable: false, hide: true }
    ];

    const compareDocketDetails = (a, b) => {
        if (Date.parse(a.upcomingCourtDate) < Date.parse(b.upcomingCourtDate)) {
            return -1;
        }
        if (Date.parse(a.upcomingCourtDate) > Date.parse(b.upcomingCourtDate)) {
            return 1;
        }
        return 0;
    }

    const filterFutureDockets = (docket) => {
        var date1 = new Date(docket.upcomingCourtDate);
        var now = new Date();

        return (date1  > now);
    }

    function isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }

    const rows = [];
    if (props.interactions && props.interactions.length > 0) {
        props.interactions.forEach((interaction) => {
            var latestDocket = "";
            var docketNumber = "";
            var courtLocation = "";
            var courtRoom = "";
            
            if (interaction.dockets && interaction.dockets.length > 0) {
                var futureDockets = interaction.dockets.filter(filterFutureDockets);
                var pastDockets = interaction.dockets.filter(docket => !futureDockets.includes(docket));
                // show last docket
                //futureDockets = interaction.dockets;
                    if (null != futureDockets && futureDockets.length > 0) {

                        var sortedDocket = futureDockets.sort(compareDocketDetails)
                        latestDocket = sortedDocket[0];
                        docketNumber = latestDocket?.docketNumber;
                        var upcomingCourtDate = latestDocket?.upcomingCourtDate,
                        courtLocation = latestDocket?.courtLocation ? latestDocket?.courtLocation.label : null;
                        courtRoom = latestDocket?.courtRoom? latestDocket?.courtRoom.label : null;
                    } else if (null != pastDockets && pastDockets.length > 0) {
                        var sortedDocket = pastDockets.sort(compareDocketDetails)
                        latestDocket = sortedDocket[pastDockets.length-1];
                        docketNumber = latestDocket?.docketNumber;
                        var upcomingCourtDate = latestDocket?.upcomingCourtDate,
                        courtLocation = latestDocket?.courtLocation ? latestDocket?.courtLocation.label : null;
                        courtRoom =  latestDocket?.courtRoom? latestDocket?.courtRoom.label : null;
                    }
            }
            if (canRead("interaction", null)) {
                rows.push(
                    {
                        id: `I-${interaction.interactionId}`,
                        FormStatus: interaction.formStatus ? interaction.formStatus.label : null,
                        DocketNumber: docketNumber,
                        UpcomingCourtDate: upcomingCourtDate,
                        AssignedTo: interaction.assignedTo ? JSON.parse(interaction.assignedTo).label : null,
                        CourtLocation: courtLocation,
                        CourtRoom: courtRoom,
                        AssesmentOutcome: interaction.assesmentOutcome ? interaction.assesmentOutcome.label : null,
                        IsActive: interaction.isActive,
                        IsActionRequired: interaction.isActionRequired,
                        isConflicted :  interaction.isInteractionConflicted
                    },
                );
            } else {
                return (<table><tbody><tr><td colSpan={7}>You are not authorized to read this record.</td></tr></tbody></table>);
            };
        });
    };

    const columns = [];

    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderCell ? <> <a href={'/interaction?id=' + params.value.split("-")[1] + '&clientFileId=' + props.clientFileId}>{params.value}</a> {(params.row.IsActive == false ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Inactive** </Badge> : (params.row.isConflicted == true ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null) )}</> : 
                (columnHeader.renderWarning ? (params.row.IsActionRequired) == true ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}<IoWarning title="Next court date is already passed" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}</> : null)),                
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    function getNoteTableRowStyled(params) {
        if (params.row.IsActive == false) { return `data-grid-row-selected-style` } return null;
    };

    const onRowClicked = (item) => {
        navigate('/interaction?id=' + item.row.id.split("-")[1] + '&clientFileId=' + props.clientFileId);
    };

    return (
        <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
        <Grid item xs={12} md={12} style={{
            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
        }}>{props.title}
        </Grid>
            <DataTable columns={columns} rows={rows} onRowDoubleClick={onRowClicked} getRowStyled={getNoteTableRowStyled} />     
        </Grid>
    );
};

