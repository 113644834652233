import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../auth/SignInButton,";

import ProfileContent from "./ProfileContent";


const AuthBlock = () => {

    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            {isAuthenticated ? <ProfileContent /> : <SignInButton />}
        </>
    );
}



export default AuthBlock;
