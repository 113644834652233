import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { RetrieveConflictedInteractions } from "../../../services/interaction-conflict-dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import StyledButton from '../../../components/elements/StyledButton';
import * as Constants from "../../../helper/constants";
import {format} from 'date-fns';
import { IoWarning } from "react-icons/io5";

function TopConflictedInteractions(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [showViewAll, setShowViewAll] = useState(true);

    const crmConflictResolutionPage = Constants.INERACTIONCONFLICTRESOLUTIONPAGEURL + "?page=topinteractionconflicts";

    const navigate = useNavigate();

    // #region PageLoad
    useEffect(() => {
        LoadInitialData();

    }, [instance]);

    function LoadInitialData() {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken);
                }
            });
        }
    }

    const setInitialGridData = async (token) => {

        await RetrieveConflictedInteractions(token, 0, 5).then((rowData) => {
            setInitialGridColumns();
            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        })
    }

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Interaction ID', width: 90, field: 'id', editable: false },
        { headerName: 'Docket number', width: 250, field: 'docketNumber', editable: false },
        { headerName: 'Court location', width: 250, field: 'courtLocation', editable: false },
        { headerName: 'Upcoming court date',type: 'date', width: 150, field: 'upcomingCourtDate', renderDate: true, editable: false, renderWarning: true},
        { headerName: 'Court room', width: 180, field: 'courtRoom', editable: false },
        { headerName: 'Assigned To', width: 180, field: 'assignedTo', editable: false },
        { headerName: 'Outcome Status', width: 180, field: 'outcomeStatus', editable: false },
        { headerName: 'Review Interaction', width: 180, field: 'button', renderButton: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
           
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => ( columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={(event) => {
                        navigate(`${crmConflictResolutionPage}&interactionId=${params.row.id.split("-")[1]}`);}}>Review</StyledButton> : 
                        (columnHeader.renderWarning ? (params.row.isOutdated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} <IoWarning title="Next court date is already passed" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);

    }

    //#endregion  


    if (canRead("interaction-conflict-dashboard", null)) { //ToDo - Change this role
        return (<>

            <DataTable columns={gridColumns} rows={gridRows} totalCount={props.totalCount} />
            {showViewAll && <div align="center" style={{ marginTop: "10px" }}><StyledButton variant={"outlined"} color="error" onClick={() => navigate("/reviewdashboard/interactions")} >View All</StyledButton></div>}
        </>
        );
    } else {
        return (
            <div>You are not authorized to read this record.</div>
        );
    }
}

export default TopConflictedInteractions;