import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import SubHeader from '../../../components/SubHeader';
import StyledDialog from '../../../components/Dialog';
import SelectedDataRow from '../../../components/elements/SelectedDataRow';
import { tokenRequest } from "../../../auth/authConfig";
import { FilterClientAndCRMFiles, ResolveCrmConflictedClientFile } from "../../../services/crm-conflict-dashboard.service";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FetchLookupData, GetClientFileById } from "../../../services/client-file.service";
import Badge from '@mui/material/Badge';
import _ from "lodash";
import { ClearCrmConflict } from "../../../services/client-file.service";
import * as Constants from "../../../helper/constants";
import { toast } from "react-toastify";
import {format} from 'date-fns';

export default function ResolveCrmConflicts() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [crmClientFiles, setCrmClientFiles] = useState([]);
    const [dcpClientFiles, setDcpClientFiles] = useState([]);
    const [clientFileDetails, setClientFileDetails] = useState([]);

    const [selectedCrmClientFile, setSelectedCrmClientFile] = useState(null);
    const [selectedDCPRow, setSelectedDCPRow] = useState(null);
    const [selectedCRMRow, setSelectedCRMRow] = useState(null);
    const [gridColumns, setGridColumns] = useState([]);
    const [crmGridColumns, setCrmGridColumns] = useState([]);

    const [openDCPClientFile, setOpenDCPClientFile] = useState(false);
    const [openCRMClientFile, setOpenCRMClientFile] = useState(false);
    const [openMergeFileDialog, setOpenMergeFileDialog] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstNameQueryParamValue, setFirstNameQueryParamValue] = useState(null);
    const [lastNameQueryParamValue, setLastNameQueryParamValue] = useState(null);
    const [dateOfBirthQueryParamValue, setDateOfBirthQueryParamValue] = useState(null);
    const [idQueryParamValue, setIdQueryParamValue] = useState(searchParams.get("clientfileid"));
    const [unlinkParamValue, setUnlinkParamValue] = useState(searchParams.get("unlink"));

    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();
    
    const [backPage, setBackPage] = useState(searchParams.get("page"));

    useEffect(() => {
        LoadInitialData();

    }, [instance]);
   

    const LoadInitialData = async () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    if (idQueryParamValue) {
                        var data = await GetClientFileById(idQueryParamValue, response.accessToken);

                        if (data) {
                            setFirstNameQueryParamValue(data.clientBasicInformation.firstName);
                            setLastNameQueryParamValue(data.clientBasicInformation.lastName);
                            setDateOfBirthQueryParamValue(data.clientBasicInformation.dateOfBirth);
                        }
                        if (data.isCrmConflicted) {
                            setInitialGridColumns();
                            setCrmInitialGridColumns();
                            const dobDate = new Date(data.clientBasicInformation.dateOfBirth);
                            await FilterClientAndCRMFiles(response.accessToken, 
                                GetSearchBy(data.clientBasicInformation.firstName, data.clientBasicInformation.lastName,
                                dobDate.toLocaleDateString(), data.clientBasicInformation.middleName)).then((rowData) => {
                                    setInitialGridColumns();
                                    setCrmInitialGridColumns();

                                    if (rowData) {
                                        setCrmClientFiles(rowData.crmClientFiles);
                                        setDcpClientFiles(rowData.dcpClientFiles);
                                        setClientFileDetails(rowData.clientFileDetails);   
                                    }
                                })
                            await FetchLookupData(response.accessToken).then((data) => {
                                if (data)
                                    setContactMethods(data.contactMethods);
                                    setIndigenousAncestries(data.indigenousAncestries);
                            })
                        }
                        else {
                            toast.success("This record is not crm conflicted any more.",{position: "top-center",autoClose: 5000,theme: "colored"});
                           
                            navigate();
                        }
                    }
                }
            });
        }
    }



    function GetSearchBy(firstName, lastName, dateOfBirth, middleName) {
        const firstNameSearch = { columnName: "FirstName", searchValue: firstName };
        const lastNameSearch = { columnName: "LastName", searchValue: lastName };
        const middleNameSearch = { columnName: "MiddleName", searchValue: middleName? middleName:'' };
        const dateOfBirthSearch = { columnName: "DateOfBirth", searchValue: dateOfBirth };
        const idSearch = { columnName: "Id", searchValue:  idQueryParamValue};
        return [firstNameSearch, lastNameSearch, dateOfBirthSearch, idSearch, middleNameSearch];
   
      
    }

    const onCancelClicked = () => {
        navigateToPage();
    };

    const handleAutoScroll = () => {
        const element = document.getElementById('scrollByClickedItem1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onDCPRowClick = (item, event) => {
        setSelectedDCPRow(item.row);
        setOpenDCPClientFile(Boolean(event.currentTarget));
        handleAutoScroll();
    };

    const onCRMRowClick = (item, event) => {
        setSelectedCRMRow(item.row);
        const selectedCrmRecord = _.filter(clientFileDetails, { 'clientFileCrmId': item.row.crmId });
        setSelectedCrmClientFile(selectedCrmRecord[0]);
        setOpenCRMClientFile(Boolean(event.currentTarget));

        handleAutoScroll();
    };

    const onMergeFileClickOpen = () => {
        setOpenMergeFileDialog(true);
    };
    const onMergeFileClickClose = () => {
        setOpenMergeFileDialog(false);
    };

    const resolveCrmConflicts = () => {
        if (selectedCRMRow != null & selectedDCPRow != null) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        await ResolveCrmConflictedClientFile(response.accessToken, selectedCRMRow.crmId, selectedDCPRow.id.split("-")[1], 0).then((response) => {

                            if (response) {
                                toast.success("Client File is reviewed Successfully.",{position: "top-center",autoClose: 5000,theme: "colored"});
                               
                                navigateToPage();
                            }
                        });
                    }
                });
            }
        }
    };



    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 200, field: 'id', renderCell: true, editable: false },
        { headerName: 'First Name', width: 150, field: 'firstName', editable: false },
        { headerName: 'Last Name', width: 150, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 150, field: 'dateOfBirth',renderDate: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell:  (params) => (columnHeader.renderCell ?<> {params.value} {params.value.split("-")[1] == idQueryParamValue ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null}</>: 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null )),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }

    const setCrmInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'CRM ID', width: 200, field: 'id', renderCell: true, editable: false },
        { headerName: 'First Name', width: 150, field: 'firstName', editable: false },
        { headerName: 'Last Name', width: 150, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 150, field: 'dateOfBirth',renderDate: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell:  (params) => (columnHeader.renderCell ?<> {params.value} {params.value.split("-")[1] == idQueryParamValue ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null}</>: 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null )),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setCrmGridColumns(columns);
    }

    const [openConfirmMarkAsCrmConflictedDialog, setOpenConfirmMarkAsCrmConflictedDialog] = useState(false);
    const onConfirmMarkAsCrmConflictedClickOpen = () => {
        setOpenConfirmMarkAsCrmConflictedDialog(true);
    };
    const onConfirmMarkAsCrmConflictedClickClose = () => {
        setOpenConfirmMarkAsCrmConflictedDialog(false);
    };

    const [openConfirmMarkAsCrmConflictedUnlinkDialog, setOpenConfirmMarkAsCrmConflictedUnlinkDialog] = useState(false);
    const onConfirmMarkAsCrmConflictedUnlinkClickOpen = () => {
        setOpenConfirmMarkAsCrmConflictedUnlinkDialog(true);
    };
    const onConfirmMarkAsCrmConflictedUnlinkClickClose = () => {
        setOpenConfirmMarkAsCrmConflictedUnlinkDialog(false);
    };


    const onMarkAsCrmUnConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    ClearCrmConflict(idQueryParamValue? parseInt(idQueryParamValue): null,null, response.accessToken).then(() => {
                        navigateToPage();
                    });
                }
            });
        }
    }

    const onMarkAsCrmUnConflictedUnlinkClicked = async (event) => {
       
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        await ResolveCrmConflictedClientFile(response.accessToken, null, parseInt(idQueryParamValue),1).then((response) => {

                            if (response) {
                                toast.success("Client File is reviewed Successfully.",{position: "top-center",autoClose: 5000,theme: "colored"});
                               
                                navigateToPage();
                            }
                        });
                    }
                });
            }
        
    }

    function navigateToPage() {
        switch (backPage) {
            case "crmconflictedclientfiles":
                navigate(Constants.CRMCONFLICTDASHBOARDPAGEURL);
                break;
            case "topcrmconflictedclientfiles":
                navigate(Constants.CRMREVIEWPAGEURL + "?cardKey=2");
                break;
                 
            default: break;
        }
    }

    return (<>
       { (canRead("crm-conflict-dashboard", null)) && <Grid container style={{ padding: "5px 20px 0px 20px", margin: "80px 0px", scrollMarginTop: "80px" }}>
            <SubHeader
                title="Review CRM-Portal Linking"
                menuItemOneText=""
                onMenuItemOneClicked=""
                isMenuItemOneVisible={false}
                isMenuItemOneDisabled={false}
                menuItemTwoText="Mark as Reviewed"
                onMenuItemTwoClicked={onConfirmMarkAsCrmConflictedClickOpen}
                isMenuItemTwoVisible={true}
                menuItemTwoType="CrmUnConflicted"
                menuItemThreeText="Mark as Reviewed & Unlink"
                onMenuItemThreeClicked={onConfirmMarkAsCrmConflictedUnlinkClickOpen}
                isMenuItemThreeVisible={true}
                menuItemThreeType="CrmUnConflicted"
                isMenuItemTwoDisabled={false}
                navItemOneText="Link Files"
                onNavItemOneClicked={onMergeFileClickOpen}
                isNavItemOneVisible={true}
                isNavItemOneDisabled={!(openDCPClientFile && openCRMClientFile)}
                navItemTwoText="Cancel"
                onNavItemTwoClicked={onCancelClicked}
                isNavItemTwoVisible={true}
                isNavItemTwoDisabled={false}
                navItemThreeText=""
                onNavItemThreeClicked=""
                isNavItemThreeVisible={false}
                isNavItemThreeDisabled={false}
                navItemFourText=""
                onNavItemFourClicked=""
                isNavItemFourVisible={false}
                isNavItemFourDisabled={false} />
            <StyledDialog title="Mark as Reviewed" open={openConfirmMarkAsCrmConflictedDialog} onClose={onConfirmMarkAsCrmConflictedClickClose}
                message={"Do you really want to mark the CF-" + idQueryParamValue + " as reviewed?"}
                onConfirmed={onMarkAsCrmUnConflictedClicked} confirmationText="Mark as Reviewed" isCancelRequired={true}/>
            <StyledDialog title="Mark as Reviewed & Unlink Client File" open={openConfirmMarkAsCrmConflictedUnlinkDialog} onClose={onConfirmMarkAsCrmConflictedUnlinkClickClose}
                message={"Do you really want to mark the CF-" + idQueryParamValue + " as reviewed and unlink the client file?"}
                onConfirmed={onMarkAsCrmUnConflictedUnlinkClicked} confirmationText="Mark as Reviewed & Unlink Client File" isCancelRequired={true}/>
            
            <StyledDialog title="Link DC Portal Client File with CRM" open={openMergeFileDialog} onClose={onMergeFileClickClose}
                message={"Do you really want to link DC Portal client file with CRM search results? Please confirm to proceed."}
                onConfirmed={resolveCrmConflicts} confirmationText="Confirm" isCancelRequired={true}/>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}>
                    <Grid item xs={12} md={12} style={{
                        textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                    }}>Portal Client File</Grid>
                    {openDCPClientFile === true ? (<>
                        <SelectedDataRow title={`Portal Client File ${selectedDCPRow?.id} : ${selectedDCPRow?.firstName}  ${selectedDCPRow?.lastName} ${selectedDCPRow?.dateOfBirth} `} clientFileId={selectedDCPRow?.id.split("-")[1]} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} onClose={() => { setOpenDCPClientFile(false) }} />
                    </>) : (<>
                        <DataTable columns={gridColumns} rows={dcpClientFiles} disableSelection={false} onRowClick={onDCPRowClick} />
                    </>)}     </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                }}>CRM Client Files</Grid>
                {openCRMClientFile === true ? (<>
                    <SelectedDataRow title={`CRM Client File ${selectedCRMRow?.id} : ${selectedCRMRow?.firstName}  ${selectedCRMRow?.lastName} ${selectedCRMRow?.dateOfBirth} `} clientFile={selectedCrmClientFile} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} onClose={() => { setOpenCRMClientFile(false) }} />
                </>) : (<>
                    <DataTable columns={crmGridColumns} rows={crmClientFiles} disableSelection={false} onRowClick={onCRMRowClick} />
                </>)}
            </Grid>

        </Grid>}
    </>
    );
};