import "./referral-info.css";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import Option from 'react-select';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import appconfig from '../appConfig'
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import { Grid, MenuItem } from "@mui/material";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import AutoCompleteTextField from "../components/AutoComplete";

const ReferralInfo = forwardRef((props, ref) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const apiServerUrl = appconfig.API_SERVER_URL;

    const EMPTYSTRING = "";

    const [isValid] = useValidation();
    const [referralId, setReferralId] = useState(props.referralId);
    const [clientFileId, setClientFileId] = useState(props.clientFileId);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketNumberError, setDocketNumberError] = useState(false);
    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [showDate, setShowDate] = useState(dayjs(''));
    const [upcomingCourtDateError, setUpcomingCourtDateError] = useState(false);
    const [isUpcomingCourtDateInvalid, setIsUpcomingCourtDateInvalid] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [arryOfInputValues, setArryOfInputValues] = useState([]);
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [dutyCounselType, setDutyCounselType] = useState(0);
    const [dutyCounselTypeError, setDutyCounselTypeError] = useState(false);

    
    useImperativeHandle(ref, () => ({
        getReferralInfo() {
            if (!docketNumber) { setDocketNumberError(true); }
            if (!upcomingCourtDate) { setUpcomingCourtDateError(true);  }
            if (!dutyCounselType) { setDutyCounselTypeError(true); }
            if (!(courtLocation && courtLocation.length > 0)) { setCourtLocationError(true); }
            return {
                "referralId": referralId,
                "clientFile": {
                    "clientFileId": clientFileId
                },
                "docketNumber": docketNumber,
                "upcomingCourtDate": upcomingCourtDate,
                "courtLocation": courtLocation,
                "dutyCounselType": {
                    "dutyCounselTypeId": dutyCounselType.dutyCounselTypeId,
                    "dutyCounselTypeCrmValue": 0,
                    "label": "",
                },
                "docketNumberValidity": (!docketNumber) ? true : docketNumberError,
                "upcomingCourtDateValidity": (!upcomingCourtDate) ? true : (upcomingCourtDateError || isUpcomingCourtDateInvalid),
                "courtLocationValidity":(!(courtLocation && courtLocation.length > 0)) ? true : courtLocationError,
                "dutyCounselTypeValidity": (!dutyCounselType) ? true : dutyCounselTypeError,
            };
        }
    }));

    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            const options = {
                method: "GET",
                headers: headers
            };

            const uri = apiServerUrl + 'ReferralService/GetReferral?id=' + props.referralId;

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setReferralId(data.referralId);
                    setDocketNumber(data.docketNumber);
                    setUpcomingCourtDate(Date.parse(data.upcomingCourtDate));
                    setShowDate(new Date(data.upcomingCourtDate).toLocaleDateString());
                    setDutyCounselType(data.dutyCounselType);
                    setCourtLocation(data.courtLocations);
                    if (data.courtLocations){
                        const fecthInitialCourtLocations = [];
                       data.courtLocations.forEach((courtLocation) => {
                            fecthInitialCourtLocations.push(courtLocation.courtLocation.label);
                        });                     
                        setArryOfInputValues(fecthInitialCourtLocations);
                    }
                    
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        if (props.clientFileId && props.referralId) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);

    const onDocketNumberChanged = (event) => {
        setDocketNumber(event.target.value);
        if (event.target.value.length == 0) {
            setDocketNumber(EMPTYSTRING);
            setDocketNumberError(true);
        }
        else {
            const fieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true, maxLength: 100 };
            const status = isValid(fieldModal);
            setDocketNumberError(!status);
        }
    };

    const onUpcomingCourtDateChanged = (date) => {
        setShowDate(date);
        if (date != null) {
            let today = new Date();
            if((new Date(date)).toString() === "Invalid Date" || (new Date(date) < new Date(today.setDate(today.getDate() -1)))){
                setIsUpcomingCourtDateInvalid(true);
            } else {
                setUpcomingCourtDate(Date.parse(date));           
                setUpcomingCourtDateError(false);
                setIsUpcomingCourtDateInvalid(false);
            }
        } else {
            setUpcomingCourtDate(date);
            setUpcomingCourtDateError(true);
            setIsUpcomingCourtDateInvalid(false);
        }
    };

    const onDutyCounselTypeChanged = (event) => {
        const selectedDutyCounselType = props.dutyCounselTypes.find(
            (object) => object?.label === event.target.value
        );
        setDutyCounselType(selectedDutyCounselType);
        if (selectedDutyCounselType)
            setDutyCounselTypeError(false);
    };   

    const courtLocations = props.courtLocations;

   
    const onCourtLocationsChanged=(event, newInputValues) => {
        setArryOfInputValues(newInputValues);
        const newArrayOfObjects= [];
        newInputValues.forEach( (InputValue) => {
            let seletcedOption = courtLocations.find((option) => option?.label == InputValue)
            newArrayOfObjects.push(seletcedOption);
        })
        setCourtLocation(newArrayOfObjects);
        if (newArrayOfObjects && newArrayOfObjects.length > 0 ){setCourtLocationError(false);} else {setCourtLocationError(true);};
        
    };

    function isInThePast(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today;
    };

    return (<>
    <Grid container style={{ padding: "10px 20px 10px 20px", border: "1px solid #7F8284", margin: "10px 0px 160px 0px", borderRadius: "8px" }}>
        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                        }}>Referral Information</Grid>
                         <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <div style={{maxWidth: "275px"}}>
                            <TextField label="Docket Number(s)" placeholder="Docket Number" required={true}
                                multiline
                                error={docketNumberError ? true : null}
                                value={docketNumber}
                                handleChange={onDocketNumberChanged}
                                helperText={docketNumberError ? Constants.DOCKETNUMBERREQUIREDMESSAGE : null} /></div>
                        </Grid>
                         <Grid item xs={12} md={4} style={{margin: "10px 0px 10px 0px"}}>
                         <div style={{maxWidth: "275px"}}>
                             <CustomDatePicker label="Upcoming Court Date" placeholder="Upcoming Court Date" required={true} disablePast={true}
                                error={isUpcomingCourtDateInvalid ? true : (upcomingCourtDateError ? true : null)}
                                value={showDate} 
                                handleOnChange={onUpcomingCourtDateChanged}
                                helperText={isUpcomingCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (upcomingCourtDateError ? Constants.UPCOMINGCOURTDATEREQUIREDMESSAGE : null)} />
                        </div>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Duty Counsel Type" placeholder="Duty Counsel Type" required={true}
                                select
                                error={dutyCounselTypeError ? true : null}
                                value={dutyCounselType ? dutyCounselType?.label : ""}
                                handleChange={onDutyCounselTypeChanged}
                                helperText={dutyCounselTypeError ? Constants.DUTYCONSELTYPEREQUIREDMESSAGE : null}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                {props.dutyCounselTypes.map((option) => (
                                    <MenuItem key={option.dutyCounselTypeId} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 25px 0px" }}>
                            <AutoCompleteTextField label="Court Location(s)" required={true} options={courtLocations} 
                                    multiple={true}
                                    value={arryOfInputValues} 
                                    onChange={onCourtLocationsChanged} 
                                    error={courtLocationError? true : null}
                                    helperText={courtLocationError ? Constants.COURTLOCATIONREQUIREDMESSAGE :  null}/>
                        </Grid>
    </Grid>
        </>
    );
})

export default ReferralInfo;