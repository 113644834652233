import { Button } from "react-bootstrap";
import "./client-files.css";
import React, { useEffect, useState, useRef } from 'react';
import { useAuthorization } from "../hooks/useAuthorization";

function ClientFileRow(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const clientFile = props.clientFile;
    if (canRead("client-file", null)) {
        return (           
                <tr>
                    <td><a className="clientFileRow" href={'/client-dashboard?id=' + clientFile.clientFileId}>{clientFile.clientBasicInformation ? clientFile.clientBasicInformation.firstName : null}</a></td>
                    <td>{clientFile.clientBasicInformation ? clientFile.clientBasicInformation.lastName : null}</td>
                    <td>{clientFile.clientBasicInformation ? clientFile.clientContactDetails.email : null}</td>
                    <td>{clientFile.clientBasicInformation ? clientFile.clientContactDetails.mobileNumber : null}</td>
                </tr>

        );
    } else {
        return (<tr><td colSpan="4">You are not authorized to read this record.</td></tr>);
    }
}

function ClientFileTable(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const rows = [];

    props.clientFiles.forEach((clientFile) => {
        if (clientFile.clientBasicInformation)
            rows.push(
                <ClientFileRow clientFile={clientFile} />
            );
    });

    return (
        <div className="clientFiles">
            <div>
                <div>
                    <Button variant={(canCreate("client-file", null)) ? "primary" : "secondary"} disabled={(canCreate("client-file", null)) ? "" : "disabled"} className="ml-auto" href="/client-file">New Client File</Button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div >
    );
}

export default ClientFileTable;
