import React, { useState } from "react";
import { SideBarContainer, SideBarItem } from "./styled/SideBar.styled";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { RiBarChart2Fill } from "react-icons/ri";
import { MdSwitchAccount } from "react-icons/md";
import { TbListSearch } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { useAuthorization } from '../hooks/useAuthorization';

export default function SideBar() {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [isDashboardClicked, setIsDashboardClicked] = useState(true);
    const [isMyFilesClicked, setIsMyFilesClicked] = useState(false);
    const [isSearchClientClicked, setIsSearchClientClicked] = useState(false);
    const [isSettingsClicked, setIsSettingsClicked] = useState(false);
    const [isCrmConflictDashboardClicked, setIsCrmConflictDashboardClicked] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    let navigate = useNavigate();

    function handleSelect(dashboard, crmConflictDashboard, searchClient, clientfiles, settings ) {
        setIsDashboardClicked(dashboard);
        setIsCrmConflictDashboardClicked(crmConflictDashboard);
        setIsSearchClientClicked(searchClient);
        setIsMyFilesClicked(clientfiles);
        setIsSettingsClicked(settings);
        
    };
    const handleSideBarClick = (clickedItem) => {
        if (clickedItem === "Dashboard") {
            handleSelect(true, false, false, false, false);
            navigate("/");
        }
        else if (clickedItem === "CrmConflictDashboard") {
            handleSelect(false, true, false,false, false );
            navigate("/reviewdashboard");
        }
        else if (clickedItem === "SearchClient") {
            handleSelect(false, false, true, false, false);
            navigate("/clientfiles/search");
        }
        else if (clickedItem === "ClientFiles") {
            handleSelect(false, false, false, true, false);
            navigate("/clientfiles");
        }
        else if (clickedItem === "Settings") {
            handleSelect(false, false, false, false, true);
        }

    }
    return (
        <>
            {isAuthenticated ?
                <SideBarContainer>
                    <Tooltip title="Dashboards" placement="right" arrow>
                        <SideBarItem onClick={() => handleSideBarClick("Dashboard")} style={{ opacity: isDashboardClicked ? 1 : 0.5 }}>
                            <RiBarChart2Fill size={20} />
                        </SideBarItem>
                    </Tooltip>  
                    {(canRead("crm-conflict-dashboard", null))  &&              
                    <Tooltip title="Review Dashboards" placement="right" arrow>
                        <SideBarItem onClick={() => handleSideBarClick("CrmConflictDashboard")} style={{ opacity: isCrmConflictDashboardClicked ? 1 : 0.5 }}>
                            <PivotTableChartIcon size={20} />
                        </SideBarItem>
                    </Tooltip>}
                    <Tooltip title="Search Clients" placement="right" arrow>
                        <SideBarItem onClick={() => handleSideBarClick("SearchClient")} style={{ opacity: isSearchClientClicked ? 1 : 0.5 }}>
                            <TbListSearch size={20} />
                        </SideBarItem>
                    </Tooltip>
                    {(canRead("crm-conflict-dashboard", null))  &&  
                    <Tooltip title="Client Files" placement="right" arrow>
                        <SideBarItem onClick={() => handleSideBarClick("ClientFiles")} style={{ opacity: isMyFilesClicked ? 1 : 0.5 }}>
                            <MdSwitchAccount size={20} />
                        </SideBarItem>
                    </Tooltip>}
                    <Tooltip title="Settings" placement="right" arrow>
                        <SideBarItem onClick={() => handleSideBarClick("Settings")} style={{ opacity: isSettingsClicked ? 1 : 0.5 }}>
                            <IoSettingsSharp size={20} />
                        </SideBarItem></Tooltip>
                </SideBarContainer>
                : null
            }
        </>
    );
};