import React, { useState } from "react";
import { SubHeaderContainer, TitleContainer, MainTitleContainer, CustomBreadcumContainer, BreadcrumbContainer, StyledSpeedDial, MenuListItem } from "./styled/SubHeader.styled";
import { NavItem, NavContainer } from "./styled/Header.styled";
import { IoNotifications } from "react-icons/io5";
import { IconButton, Badge, Menu, Button, MenuItem } from "@mui/material";
import CustomButton from "./Button";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiMoreFill } from "react-icons/ri";
import { MdErrorOutline } from "react-icons/md";
import { MdOutlineCheckCircle } from "react-icons/md";
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import CustomBreadcrumb from "../feature/custombreadcrumb/custombreadcrumb";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function WarningMenuItem() {
    return (
        <MdErrorOutline style={{ color: "#d41f44", marginRight: "10px" }} size={20} />
    );
};

function DeleteMenuItem() {
    return (
        <RiDeleteBin6Line style={{ color: "#d41f44", marginRight: "10px" }} size={20} />
    );
};

function CrmUnConflictedMenuItem() {
    return (
        <MdOutlineCheckCircle style={{ color: "#d41f44", marginRight: "10px" }} size={20} />
    );
};

export default function SubHeader({
    title,
    breadcrumb,
    menuItemOneText,
    onMenuItemOneClicked,
    isMenuItemOneVisible,
    isMenuItemOneDisabled,
    menuItemTwoText,
    onMenuItemTwoClicked,
    isMenuItemTwoVisible,
    menuItemTwoType,
    isMenuItemTwoDisabled,
    menuItemThreeText,
    onMenuItemThreeClicked,
    isMenuItemThreeVisible,
    menuItemThreeType,
    isMenuItemThreeDisabled,
    navItemOneText,
    onNavItemOneClicked,
    isNavItemOneVisible,
    isNavItemOneDisabled,
    navItemTwoText,
    onNavItemTwoClicked,
    isNavItemTwoVisible,
    isNavItemTwoDisabled,
    navItemThreeText,
    onNavItemThreeClicked,
    isNavItemThreeVisible,
    isNavItemThreeDisabled,
    navItemFourText,
    onNavItemFourClicked,
    isNavItemFourVisible,
    isNavItemFourDisabled,
    isCreateNewVisible,
    newItemOneVisible,
    onNewItemOneClicked,
    newItemOneText,
    newItemTwoVisible,
    onNewItemTwoClicked,
    newItemTwoText,
    newItemThreeVisible,
    onNewItemThreeClicked,
    newItemThreeText,
    newItemFourVisible,
    onNewItemFourClicked,
    newItemFourText,
    newItemFiveVisible,
    onNewItemFiveClicked,
    newItemFiveText,
    isTooltipVisible,
    tooltipTitle,
    ...props
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const handleSpeedDialOpen = () => setOpenSpeedDial(true);
    const handleSpeedDialClose = () => setOpenSpeedDial(false);
    const actions = [
         { icon: <MenuListItem>{newItemOneText}</MenuListItem>, name: newItemOneText,  action : onNewItemOneClicked, isVisible: newItemOneVisible},
         { icon: <MenuListItem>{newItemTwoText}</MenuListItem>, name: newItemTwoText, action : onNewItemTwoClicked, isVisible: newItemTwoVisible},
         { icon: <MenuListItem>{newItemThreeText}</MenuListItem>, name: newItemThreeText, action : onNewItemThreeClicked, isVisible: newItemThreeVisible},
         { icon: <MenuListItem>{newItemFourText}</MenuListItem>, name: newItemFourText, action : onNewItemFourClicked, isVisible: newItemFourVisible},
         { icon: <MenuListItem>{newItemFiveText}</MenuListItem>, name: newItemFiveText, action : onNewItemFiveClicked, isVisible: newItemFiveVisible},
       ];

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#ffffff",
            color: '#000000',
            border: "1px solid black",
            fontSize: "11px",
            maxWidth: 'none',
        },
      });

    return (
        <>
          
        <SubHeaderContainer>
            <CustomBreadcumContainer>
                 <CustomBreadcrumb/>
            </CustomBreadcumContainer>
            <TitleContainer>
                {title}
            </TitleContainer>
            <NavContainer>
            {(isCreateNewVisible == true) && (
                <NavItem>    
                    <StyledSpeedDial
                        ariaLabel="SpeedDial"
                        FabProps={{ size: "small" , sx: { height: "23px", width: "23px", bgcolor: "#d41f44", '&:hover': {bgcolor:"#d41f44",}}}}
                        sx={{ position: 'absolute', top: 5, right: 258 }}
                        icon={<SpeedDialIcon />}
                        onClose={handleSpeedDialClose}
                        onOpen={handleSpeedDialOpen}
                        open={openSpeedDial}
                        direction="down">
                            {actions.map((action) => (
                               action.isVisible && ( <SpeedDialAction
                                    FabProps={{variant:"extended", size: "small" , sx: {bgcolor: "rgba(212,31,68,0.80)", '&:hover': {bgcolor:"#d41f44",}, textTransform: "none"}}}
                                    key={action.name}
                                    icon={action.icon}
                                    onClick={action.action}
                                    />)))}
                    </StyledSpeedDial>
                </NavItem>)}  
                {((isMenuItemOneVisible == true) || (isMenuItemTwoVisible == true)) && (
                    <>
                        <NavItem>
                            <IconButton
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                            >
                                <RiMoreFill style={{ color: "#d41f44" }} size={20} />
                            </IconButton></NavItem>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
                            {(isMenuItemOneVisible == true) && (
                                <MenuItem onClick={onMenuItemOneClicked} disabled={isMenuItemOneDisabled} style={{ font: 'normal normal normal 15px/18px Franklin Gothic Medium' }}>
                                    <DeleteMenuItem />{menuItemOneText}
                                </MenuItem>
                            )}
                            {(isMenuItemTwoVisible == true) && (
                                <MenuItem onClick={onMenuItemTwoClicked} disabled={isMenuItemTwoDisabled} style={{ font: 'normal normal normal 15px/18px Franklin Gothic Medium' }}>
                                    {menuItemTwoType === "Warning" && <WarningMenuItem />}
                                    {menuItemTwoType === "Delete" && <DeleteMenuItem />}
                                    {menuItemTwoType === "CrmUnConflicted" && <CrmUnConflictedMenuItem />}
                                    {menuItemTwoText}
                                </MenuItem>)}
                            {(isMenuItemThreeVisible == true) && (
                                <MenuItem onClick={onMenuItemThreeClicked} disabled={isMenuItemThreeDisabled} style={{ font: 'normal normal normal 15px/18px Franklin Gothic Medium' }}>
                                    {menuItemThreeType === "Warning" && <WarningMenuItem />}
                                    {menuItemThreeType === "Delete" && <DeleteMenuItem />}
                                    {menuItemThreeType === "CrmUnConflicted" && <CrmUnConflictedMenuItem />}
                                    {menuItemThreeText}
                                </MenuItem>)}
                        </Menu> </>)}
                {(isNavItemOneVisible == true) && (isTooltipVisible ? (
                    <CustomTooltip title={tooltipTitle} style={{maxWidth: 'none'}}>
                        <NavItem>
                            <CustomButton variant="contained" label={navItemOneText} size="small" disabled={isNavItemOneDisabled} onClick={onNavItemOneClicked} />
                        </NavItem>
                    </CustomTooltip>) :
                    <NavItem>
                        <CustomButton variant="contained" label={navItemOneText} size="small" disabled={isNavItemOneDisabled} onClick={onNavItemOneClicked} />
                    </NavItem>)}
                {(isNavItemTwoVisible == true) && (
                    <>
                        <NavItem>
                            <CustomButton variant="contained" label={navItemTwoText} size="small" disabled={isNavItemTwoDisabled} onClick={onNavItemTwoClicked} />
                        </NavItem>
                    </>)}
                {(isNavItemThreeVisible == true) && (
                    <>
                        <NavItem>
                            <CustomButton variant="contained" label={navItemThreeText} size="small" disabled={isNavItemThreeDisabled} onClick={onNavItemThreeClicked} />
                        </NavItem>
                    </>)}
                {(isNavItemFourVisible == true) && (
                    <>
                        <NavItem>
                            <CustomButton variant="contained" label={navItemFourText} size="small" disabled={isNavItemFourDisabled} onClick={onNavItemFourClicked} />
                        </NavItem>
                    </>)}
                {(props.isNavItemFiveVisible == true) && (
                    <>
                        <NavItem>
                            <CustomButton variant="contained" label={props.navItemFiveText} size="small" disabled={props.isNavItemFiveDisabled} onClick={props.onNavItemFiveClicked} />
                        </NavItem>
                    </>)}
                {(props.isNavItemSixVisible == true) && (
                    <>
                        <NavItem>
                            <CustomButton variant="contained" label={props.navItemSixText} size="small" disabled={props.isNavItemSixDisabled} onClick={props.onNavItemSixClicked} />
                        </NavItem>
                    </>)}   
            </NavContainer>
        </SubHeaderContainer></>
    );
};