import styled from "styled-components";

export const FooterBar = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px; 
  width: 100%;
  height: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  opacity: 1;
  z-index: 50;
`;
export const FooterBarLeft = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px; 
  width: 100%;
  height: 20px;
  box-shadow: 0px 1px 4px #00000029;
  opacity: 1;
`;

export const FooterBarRight = styled.div`
  position: fixed;
  bottom: 0px;
  left: 50%; 
  width: 100%;
  height: 20px;
  box-shadow: 0px 1px 4px #00000029;
  opacity: 1;
`;