import React, {useState} from 'react';
import { ScreenSideBarContainer, StyledListItemText } from './styled/ScreenSideBar.styled';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

export default function ScreenSideBar({
    screenSideBarList,
    handleToggle,
    screenSideBarKey
    }) {
    return(
        <ScreenSideBarContainer>
        <List>    
            {screenSideBarList.map((text) => (
            <ListItem  key={text} disablePadding>
                <ListItemButton onClick={handleToggle(text)} sx={{ "&:hover": {backgroundColor: "#B6B6AE"}}}>
                    <StyledListItemText sx={{color: screenSideBarKey === text ? "#000000" : "#D41F44"}} 
                        id={text} primary={text} />
                </ListItemButton>
            </ListItem>         
            ))}
        </List>
        </ScreenSideBarContainer>
    );
};