import React, {useState} from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {DateTimePickerTextField, DashboardDateTimePickerTextField} from "./styled/DateTimePicker.styled";

export default function CustomDatePicker({
    label,
    disableFuture,
    disablePast,
    minDate,
    maxDate,
    value,
    handleOnChange,
    onChange,
    required,
    disabled,
    placeholder,
    error,
    isDashBoard,
    helperText,
    onKeyDown,
    style
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
      {isDashBoard ? 
      <DatePicker
      disableFuture={disableFuture}
      disablePast={disablePast}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      label={label}
      openTo="day"
      views={['year','month', 'day']}
      value={value}
      onChange={handleOnChange}
      autoComplete='off'
      inputFormat="DD-MMM-YYYY"
      renderInput={(params) => <DashboardDateTimePickerTextField {...params} required={required} error={error} helperText={helperText} size="small" autoComplete='off' onKeyDown={onKeyDown} style={style}
      inputProps={{
        ...params.inputProps,
        placeholder: "dd-Mmm-yyyy"
      }}/>}
      />
      : <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          label={label}
          openTo="day"
          views={['year','month', 'day']}
          value={value}
          onChange={handleOnChange}
          autoComplete='off'
          inputFormat="DD-MMM-YYYY"
          renderInput={(params) => <DateTimePickerTextField {...params} required={required} error={error} helperText={helperText} size="small" autoComplete='off' onChange={onChange} onKeyDown={onKeyDown} style={style}
          inputProps={{
            ...params.inputProps,
            placeholder: "dd-Mmm-yyyy"
          }}/>}
        />}
      </Stack>
    </LocalizationProvider>
  );
}