import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../../../client-basic-info";
import ClientContactInfo from "../../../client-contact-info";
import ClientFinancialInfo from "../../../client-financial-info";
import Select from 'react-select'; import DateTimePicker from 'react-datetime-picker';
import "./style.css";
import appconfig from '../../../appConfig'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useValidation } from "../../../hooks/useValidation";
import { CreateJPBailOutcome, GetJPBailOutcomeById, UpdateJPBailOutcome, GetJPBailOutcomeMetaData, DeleteJPBailOutcome } from "../../../services/jp-bail-outcome-service";
import * as Constants from "../../../helper/constants";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../../auth/authConfig";
import AttachmentList from '../../attachments/components/attachment-list';
import TextField from '../../../components/TextField';
import { Grid, MenuItem, Checkbox, Button } from "@mui/material";
import SelectorTextField from '../../../components/SelectorTextField';
import CustomDateTimePicker from "../../../components/DateTimePicker";
import CustomDatePicker from "../../../components/DatePicker";
import StyledButton from '../../../components/elements/StyledButton';
import CustomButton from '../../../components/Button';
import SubHeader from '../../../components/SubHeader';
import StyledDialog from '../../../components/Dialog';
import ScreenSideBar from '../../../components/ScreenSideBar';
import StyledSwitch from '../../../components/StyledSwitch';
import { RiInformationFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { GetUserAppRolesByUserId } from "../../../services/graph-service";

function JPBailOutcome() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("No Role");
    const [jpbrole, setJpbRole] = useState("No Role");

    const [isValid] = useValidation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [jpBailOutcomeId, setjpBailOutcomeId] = useState(searchParams.get("id"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [hasAllEditAfterClosedPermission, setHasAllEditAfterClosedPermission] = useState("");
    const [userId, setUserId] = useState("");
    const [jPBailOutcomeCrmId, setJPBailOutcomeCrmId] = useState("");

    const [clientBasicInformationId, setClientBasicInformationId] = useState("");
    const [clientContactInfoId, setClientContactInfoId] = useState("");
    const [clientFinancialInformationId, setClientFinancialInformationId] = useState("");

    const [docketNumber, setDocketNumber] = useState("");
    const [docketNumberError, setDocketNumberError] = useState(false);
    const [docketMaxLengthError, setDocketMaxLengthError] = useState(false);
    const [timeOfShifts, setTimeOfShifts] = useState([]);
    const [timeOfShift, setTimeOfShift] = useState("");
    const [timeOfShiftError, setTimeOfShiftError] = useState(false);
    const [detachments, setDetachments] = useState([]);
    const [detachment, setDetachment] = useState("");
    const [detachmentError, setDetachmentError] = useState(false);
    const [matterDelayedOrExceed24Hrs, setMatterDelayedOrExceed24Hrs] = useState(false);
    const [arrestedOn, setArrestedOn] = useState("");
    const [arrestedOnError, setArrestedOnError] = useState("");
    const [isArrestedOnDateInvalid, setIsArrestedOnDateInvalid] = useState("");
    const [matterReceivedByCBOFromLEA, setMatterReceivedByCBOFromLEA] = useState("");
    const [matterReceivedByCBOFromLEAError, setMatterReceivedByCBOFromLEAError] = useState("");
    const [isMatterReceivedByCBOFromLEAInvalid, setIsMatterReceivedByCBOFromLEAInvalid] = useState("");
    const [crownPostedPositionOn, setCrownPostedPositionOn] = useState("");
    const [crownPostedPositionOnError, setCrownPostedPositionOnError] = useState("");
    const [isCrownPostedPositionOnInvalid, setIsCrownPostedPositionOnInvalid] = useState("");
    const [didCrownPostPosition, setDidCrownPostPosition] = useState(true);
    const [didCrownPostPositionError, setDidCrownPostPositionError] = useState("");
    const [accusedFirstSpokenToOn, setAccusedFirstSpokenToOn] = useState("");
    const [accusedFirstSpokenToOnError, setAccusedFirstSpokenToOnError] = useState("");
    const [isAccusedFirstSpokenToOnInvalid, setIsAccusedFirstSpokenToOnInvalid] = useState("");
    const [wasAccusedSpokenTo, setWasAccusedSpokenTo] = useState(true);
    const [wasAccusedSpokenToError, setWasAccusedSpokenToError] = useState("");
    const [dutyCounselMarkedAsReadyToProceedOn, setDutyCounselMarkedAsReadyToProceedOn] = useState("");
    const [dutyCounselMarkedAsReadyToProceedOnError, setDutyCounselMarkedAsReadyToProceedOnError] = useState("");
    const [isDutyCounselMarkedAsReadyToProceedOnInvalid, setIsDutyCounselMarkedAsReadyToProceedOnInvalid] = useState("");
    const [hearingConductedOn, setHearingConductedOn] = useState(new Date());
    const [hearingConductedOnError, setHearingConductedOnError] = useState("");
    const [isHearingConductedOnInvalid, setIsHearingConductedOnInvalid] = useState("");
    const [wasAHearingReachedOrConducted, setWasAHearingReachedOrConducted] = useState(true);
    const [wasAHearingReachedOrConductedError, setWasAHearingReachedOrConductedError] = useState("");
    const [hearingResults, setHearingResults] = useState([]);
    const [hearingResult, setHearingResult] = useState("");
    const [hearingResultError, setHearingResultError] = useState("");
    const [hearingResultOther, setHearingResultOther] = useState("");
    const [hearingResultOtherError, setHearingResultOtherError] = useState("");
    const [hearingResultOtherMaxLengthError, setHearingResultOtherMaxLengthError] = useState(false);
    const [comments, setComments] = useState("");
    const [commentsMaxLengthError, setCommentsMaxLengthError] = useState(false);
    const [jpBailReasonsForDelay, setJPBailReasonsForDelay] = useState([]);
    const [primaryReasonForDelay, setPrimaryReasonForDelay] = useState("");
    const [primaryReasonForDelayError, setPrimaryReasonForDelayError] = useState("");
    const [primaryReasonForDelayOther, setPrimaryReasonForDelayOther] = useState("");
    const [primaryReasonForDelayOtherError, setPrimaryReasonForDelayOtherError] = useState("");
    const [primaryReasonForDelayOtherMaxLengthError, setPrimaryReasonForDelayOtherMaxLengthError] = useState(false);
    const [secondaryReasonForDelay, setSecondaryReasonForDelay] = useState("");
    const [secondaryReasonForDelayError, setSecondaryReasonForDelayError] = useState("");
    const [secondaryReasonForDelayOther, setSecondaryReasonForDelayOther] = useState("");
    const [secondaryReasonForDelayOtherError, setSecondaryReasonForDelayOtherError] = useState("");
    const [secondaryReasonForDelayOtherMaxLengthError, setSecondaryReasonForDelayOtherMaxLengthError] = useState(false);
    const [agencyRequestId, setAgencyRequestId] = useState("");
    const [saveButtonTitle, setSaveButtonTitle] = useState("Save Draft");
    const [saveCloseButtonTitle, setSaveCloseButtonTitle] = useState("Save Draft & Close");

    const [jpBailOutcomeStatuses, setJPBailOutcomeStatuses] = useState("");

    const [isReadOnlyDetail, setIsReadOnlyDetail] = useState(false);

    const [contactMethods, setContactMethods] = useState([]);
    const SELECTINDEX = "Select..";
    const NAVIGATEURL = "/client-file?id=";
    const apiServerUrl = appconfig.API_SERVER_URL;

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();
    const [jpBailOutcomeStatusId, setJPBailOutcomeStatusId] = useState(1);

    const [canUpdateAfterSubmit, setCanUpdateAfterSubmit] = useState(false);
    const screenSideBarList = ['Client Basic Information', 'Client Contact Information', 'Client Financial Information', 'JP Bail Outcome Information'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [isClickedSave, setIsClickedSave] = useState(false);

    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            if (currentAccount.idTokenClaims.roles) {
                setRole(currentAccount.idTokenClaims.roles[0].toString());
                setCanUpdateAfterSubmit((currentAccount.idTokenClaims.roles[0].toString() == 'DutyCounselTriageLead') ? true : false);
                setSaveButtonTitle((currentAccount.idTokenClaims.roles[0].toString() == 'DutyCounselTriageLead') ? 'Save' : 'Save Draft');
                setSaveCloseButtonTitle((currentAccount.idTokenClaims.roles[0].toString() == 'DutyCounselTriageLead') ? 'Save & Close' : 'Save Draft & Close');
            } else {
                setRole("No Role");
            }
        }
    }, [instance]);

    useEffect(() => {

        const fetchInitialData = async (token) => {

            const metaData = await GetJPBailOutcomeMetaData(token);

            if (metaData) {
                setDetachments(metaData.detachments);
                setTimeOfShifts(metaData.timeOfShifts);
                setHearingResults(metaData.hearingResults);
                setJPBailReasonsForDelay(metaData.jpBailReasonsForDelay);
                setJPBailOutcomeStatuses(metaData.jpBailOutcomeStatuses);
            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
                //alert("An error occurred while loading data.");
            }

            if (jpBailOutcomeId && jpBailOutcomeId > 0) {
                var data = await GetJPBailOutcomeById(jpBailOutcomeId, token);
                setInitialJPBailOutcome(data);
                const bailCreatedUserRole = GetUserAppRolesByUserId(data.createdBy, token).then(response => {
                    setJpbRole(response.role);
                    return;
                })

            }
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }

    }, []);

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    const setInitialJPBailOutcome = (jpBailOutcome) => {
        setJPBailOutcomeCrmId(jpBailOutcome.jPBailOutcomeCrmId);
        setClientBasicInformationId(clientBasicInformationId);
        setClientContactInfoId(clientContactInfoId);
        setClientFinancialInformationId(clientFinancialInformationId);

        setTimeOfShift(jpBailOutcome.timeOfShift);
        setDocketNumber(jpBailOutcome.docketNumber);
        setDetachment(jpBailOutcome.detachment);
        setMatterDelayedOrExceed24Hrs(jpBailOutcome.matterDelayedOrExceed24Hrs);
        setArrestedOn(jpBailOutcome.arrestedOn);
        setMatterReceivedByCBOFromLEA(jpBailOutcome.matterReceivedByCBOFromLEA);
        setDidCrownPostPosition(jpBailOutcome.didCrownPostPosition);
        setCrownPostedPositionOn(jpBailOutcome.crownPostedPositionOn);
        setWasAccusedSpokenTo(jpBailOutcome.wasAccusedSpokenTo);
        setAccusedFirstSpokenToOn(jpBailOutcome.accusedFirstSpokenToOn);
        setDutyCounselMarkedAsReadyToProceedOn(jpBailOutcome.dutyCounselMarkedAsReadyToProceedOn);
        setWasAHearingReachedOrConducted(jpBailOutcome.wasAHearingReachedOrConducted);
        setHearingConductedOn(jpBailOutcome.hearingConductedOn != null ? jpBailOutcome.hearingConductedOn : new Date());
        setHearingResult(jpBailOutcome.hearingResult);
        setHearingResultOther(jpBailOutcome.hearingResultOther);
        setComments(jpBailOutcome.comments);
        setPrimaryReasonForDelay(jpBailOutcome.primaryReasonForDelay);
        setPrimaryReasonForDelayOther(jpBailOutcome.primaryReasonForDelayOther);
        setSecondaryReasonForDelay(jpBailOutcome.secondaryReasonForDelay);
        setSecondaryReasonForDelayOther(jpBailOutcome.secondaryReasonForDelayOther);
        setJPBailOutcomeStatusId(Number(jpBailOutcome.jpBailOutcomeStatusId != null ? jpBailOutcome.jpBailOutcomeStatusId : 1));
    }

    const createJpBailOutcomeObject = (isSubmit) => {
        var now = new Date();
        const jpBailOutcome = {
            "docketNumber": docketNumber,
            "timeOfShiftId": timeOfShift?.timeOfShiftId ? timeOfShift?.timeOfShiftId : null,
            "detachmentId": detachment.detachmentId ? detachment.detachmentId : null,
            "matterDelayedOrExceed24Hrs": matterDelayedOrExceed24Hrs,
            "arrestedOn": arrestedOn ? arrestedOn : null,
            "matterReceivedByCBOFromLEA": matterReceivedByCBOFromLEA ? matterReceivedByCBOFromLEA : null,
            "didCrownPostPosition": didCrownPostPosition,
            "crownPostedPositionOn": crownPostedPositionOn ? crownPostedPositionOn : null,
            "wasAccusedSpokenTo": wasAccusedSpokenTo,
            "accusedFirstSpokenToOn": accusedFirstSpokenToOn ? accusedFirstSpokenToOn : null,
            "dutyCounselMarkedAsReadyToProceedOn": dutyCounselMarkedAsReadyToProceedOn ? dutyCounselMarkedAsReadyToProceedOn : null,
            "wasAHearingReachedOrConducted": wasAHearingReachedOrConducted,
            "hearingConductedOn": hearingConductedOn ? hearingConductedOn : null,
            "hearingResultId": hearingResult?.hearingResultId ? hearingResult?.hearingResultId : null,
            "hearingResultOther": hearingResultOther ? hearingResultOther : null,
            "comments": comments ? comments : null,
            "primaryReasonForDelayId": primaryReasonForDelay?.jpBailReasonForDelayId ? primaryReasonForDelay?.jpBailReasonForDelayId : null,
            "primaryReasonForDelayOther": primaryReasonForDelayOther ? primaryReasonForDelayOther : null,
            "secondaryReasonForDelayId": secondaryReasonForDelay?.jpBailReasonForDelayId ? secondaryReasonForDelay?.jpBailReasonForDelayId : null,
            "secondaryReasonForDelayOther": secondaryReasonForDelayOther ? secondaryReasonForDelayOther : null,
            "jpBailOutcomeStatusId": isSubmit ? 2 : jpBailOutcomeStatusId,
            "clientFileId": clientFileId,
            "createdBy": "System",
            "createdOn": now,
            "modifiedBy": "System",
            "modifiedOn": now
        };
        if ((jpBailOutcomeId != null) && (jpBailOutcomeId > 0)) {
            jpBailOutcome.jpBailOutcomeId = jpBailOutcomeId;
        }
        return jpBailOutcome;
    }

    const onTimeOfShiftsChanged = (event) => {
        const selectedTimeOfShift = timeOfShifts.find(
            (shift) => shift?.label === event.target.value
        );
        setTimeOfShift(selectedTimeOfShift);
        if (selectedTimeOfShift)
            setTimeOfShiftError(false);
    };
    const onDocketNumberChanged = (event) => {
        if (event.target.value.length < 256) {
            setDocketMaxLengthError(false);
            setDocketNumber(event.target.value);
            if (event.target.value.length === 0)
                setDocketNumberError(false);
            else {
                const nameFieldModal = { name: Constants.DOCKETNUMBER, value: event.target.value, required: true };
                setDocketNumberError(!isValid(nameFieldModal));
            }
        } else {
            setDocketMaxLengthError(true);
        }
    }
    const onDetachmentChanged = (event) => {
        const selectedDetachment = detachments.find(
            (object) => object?.label === event.target.value
        );
        setDetachment(selectedDetachment);
        if (selectedDetachment)
            setDetachmentError(false);
    }
    const onMatterDelayedOrExceed24HrsChanged = (event) => {
        setMatterDelayedOrExceed24Hrs(event.target.checked);
        // Handle Validations of other fields
        if (event.target.checked) {
            setArrestedOnError(!isValidArrestedOn(arrestedOn));
            setMatterReceivedByCBOFromLEAError(!isValidMatterReceivedByCBOFromLEA(matterReceivedByCBOFromLEA));
            setCrownPostedPositionOnError(!isValidCrownPostedPositionOn(crownPostedPositionOn));
            setAccusedFirstSpokenToOnError(!isValidAccusedFirstSpokenToOn(accusedFirstSpokenToOn));
            setDutyCounselMarkedAsReadyToProceedOnError(!isValidDutyCounselMarkedAsReadyToProceedOn(dutyCounselMarkedAsReadyToProceedOn));
            setHearingConductedOnError(!isValidHearingConductedOn(hearingConductedOn));
            if (null != hearingResult && "" != hearingResult && hearingResult.label == "Other") {
                setHearingResultOtherError(!isValidHearingResultOther(hearingResultOther))
            } else {
                setHearingResultOtherError(false);
            }

            if (null != primaryReasonForDelay && "" != primaryReasonForDelay && primaryReasonForDelay.label == "Other") {
                setPrimaryReasonForDelayOtherError(!isValidPrimaryReasonForDelayOther(primaryReasonForDelayOther))
            } else {
                setPrimaryReasonForDelayOtherError(false);
            }

            if (null != secondaryReasonForDelay && "" != secondaryReasonForDelay && secondaryReasonForDelay.label == "Other") {
                setSecondaryReasonForDelayOtherError(!isValidSecondaryReasonForDelayOther(secondaryReasonForDelayOther))
            } else {
                setSecondaryReasonForDelayOtherError(false);
            }
        } else {
            // Clear Errors on the Dependent Fields
            setArrestedOnError(false);
            setMatterReceivedByCBOFromLEAError(false);
            setCrownPostedPositionOnError(false);
            setAccusedFirstSpokenToOnError(false);
            setDutyCounselMarkedAsReadyToProceedOnError(false);
            setHearingConductedOnError(false);
            setHearingResultOtherError(false);
            setPrimaryReasonForDelayOtherError(false);
            setSecondaryReasonForDelayOtherError(false);
        }
    }

    const onDateChanged = (date, setDate, setIsDateInvalid, setDateError, checkValidity) => {
        if (date != null) {
            setDate(date);
            if ((new Date(date)).toString() === "Invalid Date" || (new Date(date) > new Date())) {
                setIsDateInvalid(true);
            } else {
                setDateError(!isValidHearingConductedOn(date));
                setIsDateInvalid(false);
            }
        } else {
            setDate("");
            setIsDateInvalid(false);
            setDateError(!checkValidity(date));
        }
    }

    const onArrestedOnChanged = (date) => {
        onDateChanged(date, setArrestedOn, setIsArrestedOnDateInvalid, setArrestedOnError, isValidArrestedOn);
    }

    const onMatterReceivedByCBOFromLEAChanged = (date) => {
        setMatterReceivedByCBOFromLEA(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date" || (new Date(date) > new Date())) {
                setIsMatterReceivedByCBOFromLEAInvalid(true);
            } else {
                setMatterReceivedByCBOFromLEAError(!isValidMatterReceivedByCBOFromLEA(date));
                setIsMatterReceivedByCBOFromLEAInvalid(false);
            }
        } else {
            setMatterReceivedByCBOFromLEAError(!isValidMatterReceivedByCBOFromLEA(date));
            setIsMatterReceivedByCBOFromLEAInvalid(false);
        }
    }

    const onDidCrownPostPositionChanged = (event) => {
        setDidCrownPostPosition(event.target.checked);
        if (event.target.checked) {
            setCrownPostedPositionOnError(!isValidCrownPostedPositionOn(crownPostedPositionOn));
        } else {
            // Clear Errors on the Dependent Fields
            setCrownPostedPositionOnError(false);
        }
    }

    const onCrownPostedPositionOnChanged = (date) => {
        onDateChanged(date, setCrownPostedPositionOn, setIsCrownPostedPositionOnInvalid, setCrownPostedPositionOnError, isValidCrownPostedPositionOn);
    }

    const onWasAccusedSpokenToChanged = (event) => {
        setWasAccusedSpokenTo(event.target.checked);
    }
    const onAccusedFirstSpokenToOnChanged = (date) => {
        onDateChanged(date, setAccusedFirstSpokenToOn, setIsAccusedFirstSpokenToOnInvalid, setAccusedFirstSpokenToOnError, isValidAccusedFirstSpokenToOn);
    }
    const onDutyCounselMarkedAsReadyToProceedOnChanged = (date) => {
        onDateChanged(date, setDutyCounselMarkedAsReadyToProceedOn, setIsDutyCounselMarkedAsReadyToProceedOnInvalid, setDutyCounselMarkedAsReadyToProceedOnError, isValidDutyCounselMarkedAsReadyToProceedOn);
    }
    const onWasAHearingReachedOrConductedChanged = (event) => {
        setWasAHearingReachedOrConducted(event.target.checked);
        if (event.target.checked) {
            setHearingConductedOnError(!isValidHearingConductedOn(hearingConductedOn));
            if (null != hearingResult && "" != hearingResult && hearingResult.label == "Other") {
                setHearingResultOtherError(!isValidHearingResultOther(hearingResultOther))
            } else {
                setHearingResultOtherError(false);
            }
        } else {
            // Clear Errors on the Dependent Fields
            setHearingConductedOnError(false);
            setHearingResultOtherError(false);
        }
    }

    const onHearingConductedOnChanged = (date) => {
        onDateChanged(date, setHearingConductedOn, setIsHearingConductedOnInvalid, setHearingConductedOnError, isValidHearingConductedOn);
    }

    const onHearingResultChanged = (event) => {
        const selectedHearingResult = hearingResults.find(
            (object) => object?.label === event.target.value
        );
        setHearingResult(selectedHearingResult);
        setHearingResultError(!isValidHearingResult(selectedHearingResult));
        if (event.target.value == "Other") {
            setHearingResultOtherError(!isValidHearingResultOther(hearingResultOther))
        } else {
            setHearingResultOtherError(false);
        }
    }
    const onHearingResultOtherChanged = (event) => {
        if (event.target.value.length < 256) {
            setHearingResultOtherMaxLengthError(false);
            setHearingResultOther(event.target.value);
            if (null != hearingResult && "" != hearingResult && hearingResult.label == "Other") {
                setHearingResultOtherError(!isValidHearingResultOther(event.target.value))
            } else {
                setHearingResultOtherError(false);
            }
        } else {
            setHearingResultOtherMaxLengthError(true);
        }
    }
    const onCommentsChanged = (event) => {
        if (event.target.value.length < 1001) {
            setCommentsMaxLengthError(false);
            setComments(event.target.value);
        } else {
            setCommentsMaxLengthError(true);
        }
    }
    const onPrimaryReasonForDelayChanged = (event) => {
        const selectedPrimaryReasonForDelay = jpBailReasonsForDelay.find(
            (object) => object?.label === event.target.value
        );
        setPrimaryReasonForDelay(selectedPrimaryReasonForDelay);
        setPrimaryReasonForDelayError(!isValidPrimaryReasonForDelay(selectedPrimaryReasonForDelay));
        if (event.target.value == "Other") {
            setPrimaryReasonForDelayOtherError(!isValidPrimaryReasonForDelayOther(primaryReasonForDelayOther))
        } else {
            setPrimaryReasonForDelayOtherError(false);
        }
    }
    const onPrimaryReasonForDelayOtherChanged = (event) => {
        if (event.target.value.length < 256) {
            setPrimaryReasonForDelayOtherMaxLengthError(false);
            setPrimaryReasonForDelayOther(event.target.value);
            if (null != primaryReasonForDelay && "" != primaryReasonForDelay && primaryReasonForDelay.label == "Other") {
                setPrimaryReasonForDelayOtherError(!isValidPrimaryReasonForDelayOther(event.target.value))
            } else {
                setPrimaryReasonForDelayOtherError(false);
            }
        } else {
            setPrimaryReasonForDelayOtherMaxLengthError(true);
        }
    }
    const onSecondaryReasonForDelayChanged = (event) => {
        const selectedSecondaryReasonForDelay = jpBailReasonsForDelay.find(
            (object) => object?.label === event.target.value
        );
        setSecondaryReasonForDelay(selectedSecondaryReasonForDelay);
        setSecondaryReasonForDelayError(!isValidSecondaryReasonForDelay(selectedSecondaryReasonForDelay));
        if (event.target.value == "Other") {
            setSecondaryReasonForDelayOtherError(!isValidSecondaryReasonForDelayOther(secondaryReasonForDelayOther))
        } else {
            setSecondaryReasonForDelayOtherError(false);
        }
    }
    const onSecondaryReasonForDelayOtherChanged = (event) => {
        if (event.target.value.length < 256) {
            setSecondaryReasonForDelayOtherMaxLengthError(false);
            setSecondaryReasonForDelayOther(event.target.value);
            if (null != secondaryReasonForDelay && "" != secondaryReasonForDelay && secondaryReasonForDelay.label == "Other") {
                setSecondaryReasonForDelayOtherError(!isValidSecondaryReasonForDelayOther(event.target.value))
            } else {
                setSecondaryReasonForDelayOtherError(false);
            }
        } else {
            setSecondaryReasonForDelayOtherMaxLengthError(true);
        }

    }

    const onCreateARClicked = async (event) => {
        if (canCreate("agency-request", null)) {
            if (jpBailOutcomeId != null) {
                navigate("/agencyrequest?page=jpbailoutcome&jpBailOutcomeId=" + jpBailOutcomeId + "&clientFileId=" + clientFileId);
            } else {
                navigate("/agencyrequest?page=jpbailoutcome&clientFileId=" + clientFileId);
            }
        }
        else {
            toast.error("You don't have permission to add Agency Requests.", { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert("You don't have permission to create Agency Requests.");
        }
    }

    const onSubmitClicked = async (event) => {
        
        const isValidData = validateInformation();
        if (isValidData) {
            setSaveClicked(true);
            setJPBailOutcomeStatusId(2);
            onSaveClicked(event, true, 0);
        }
    }
    const onSubmitCloseClicked = async (event) => {
       
        const isValidData = validateInformation();
        if (isValidData) {
            setSaveClicked(true);
            setJPBailOutcomeStatusId(2);
            onSaveClicked(event, true,1);           
        }
    }

    const onSaveClicked = async (event, isSubmit, option) => {

        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInformation();
            if (isValidData) {
                setSaveClicked(true);
                var jpBailOutcomeDetails = createJpBailOutcomeObject(isSubmit);
                if (jpBailOutcomeId != null && jpBailOutcomeId > 0) {
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async(response) => {
                            if (response) {
                                // var user = GetUserAppRolesByUserId(account.userId, response.accessToken);
                               await UpdateJPBailOutcome(jpBailOutcomeDetails, response.accessToken).then((data) => {
                                    if (option == 1 && data) {
                                        if (searchParams.get("page") == "clientfiledashboard") {
                                            navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
                                        } else {
                                            navigate(NAVIGATEURL + clientFileId);
                                        }
                                    }
                                });
                            }
                        });
                    }
                }

                else {
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async (response) => {
                            if (response) {
                               await CreateJPBailOutcome(jpBailOutcomeDetails, response.accessToken).then((data) => {
                                    if (option == 0 && response) {
                                        navigate(window.location.pathname + window.location.search + `&id=${data}`);
                                        window.location.reload();
                                    }
                                    else if (option == 1 && response) {
                                        if (searchParams.get("page") == "clientfiledashboard") {
                                            navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
                                        } else {
                                            navigate(NAVIGATEURL + clientFileId);
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
        else {
            toast.error("Cannot create an agency request. Agency request needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert("Cannot create an agency request. Agency request needs to have setArrestedOnErroran associated client file.")
        }
    }

    const isValidPrimaryReasonForDelay = (data) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && null != data && "" != data));
    }

    const isValidPrimaryReasonForDelayOther = (data) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && null != data && "" != data));
    }

    const isValidSecondaryReasonForDelay = (data) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && null != data && "" != data));
    }

    const isValidSecondaryReasonForDelayOther = (data) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && null != data && "" != data));
    }

    const isValidHearingResult = (data) => {
        return ((!wasAHearingReachedOrConducted) || (wasAHearingReachedOrConducted && null != data && "" != data));
    }

    const isValidHearingResultOther = (data) => {
        return ((!wasAHearingReachedOrConducted) || (wasAHearingReachedOrConducted && null != data && "" != data));
    }

    const isValidHearingConductedOn = (date) => {
        return ((!wasAHearingReachedOrConducted) || (wasAHearingReachedOrConducted && isValidDate(date)));
    }

    const isValidAccusedFirstSpokenToOn = (date) => {
        return ((!matterDelayedOrExceed24Hrs) || (!wasAccusedSpokenTo) || (matterDelayedOrExceed24Hrs && wasAccusedSpokenTo && isValidDate(date)));
    }

    const isValidDutyCounselMarkedAsReadyToProceedOn = (date) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && isValidDate(date)));
    }

    const isValidCrownPostedPositionOn = (date) => {
        return ((!matterDelayedOrExceed24Hrs) || (!didCrownPostPosition) || (matterDelayedOrExceed24Hrs && didCrownPostPosition && isValidDate(date)));
    }

    const isValidMatterReceivedByCBOFromLEA = (date) => {
        return ((!matterDelayedOrExceed24Hrs)
            || (matterDelayedOrExceed24Hrs && isValidDate(date) && (new Date(date) > new Date(arrestedOn))));
    }

    const isValidArrestedOn = (date) => {
        return ((!matterDelayedOrExceed24Hrs) || (matterDelayedOrExceed24Hrs && isValidDate(date)));
    }

    const isValidDate = (date) => {
        return ("" != date && date?.toString() != 'Invalid Date');
    }
    const validateInformation = () => {
        var status = true;
        if (!docketNumber) { setDocketNumberError(true); status = false; }
        if (docketMaxLengthError || commentsMaxLengthError) { status = false; }
        if (!timeOfShift) { setTimeOfShiftError(true); status = false; }
        if (!detachment) { setDetachmentError(true); status = false; }
        if (!isValidArrestedOn(arrestedOn)) { setArrestedOnError(true); status = false; }
        if (isArrestedOnDateInvalid || isHearingConductedOnInvalid || isCrownPostedPositionOnInvalid || isAccusedFirstSpokenToOnInvalid || isDutyCounselMarkedAsReadyToProceedOnInvalid || isMatterReceivedByCBOFromLEAInvalid) { status = false; }
        if (!isValidMatterReceivedByCBOFromLEA(matterReceivedByCBOFromLEA)) { setMatterReceivedByCBOFromLEAError(true); status = false; }
        if (!isValidCrownPostedPositionOn(crownPostedPositionOn)) { setCrownPostedPositionOnError(true); status = false; }
        if (!isValidAccusedFirstSpokenToOn(accusedFirstSpokenToOn)) { setAccusedFirstSpokenToOnError(true); status = false; }
        if (!isValidDutyCounselMarkedAsReadyToProceedOn(dutyCounselMarkedAsReadyToProceedOn)) { setDutyCounselMarkedAsReadyToProceedOnError(true); status = false; }
        if (!isValidHearingConductedOn(hearingConductedOn)) { setHearingConductedOnError(true); status = false; };
        if (!isValidHearingResult(hearingResult)) { setHearingResultError(true); status = false; }
        if (null != hearingResult && "" != hearingResult && hearingResult.label == "Other") {
            var resultValidHearingResult = !isValidHearingResultOther(hearingResultOther);
            if (resultValidHearingResult) { setHearingResultOtherError(true); status = false; }
            if (hearingResultOtherMaxLengthError) { setHearingResultOtherMaxLengthError(true); status = false; }
        }

        if (!isValidPrimaryReasonForDelay(primaryReasonForDelay)) { setPrimaryReasonForDelayError(true); status = false; }
        if (null != primaryReasonForDelay && "" != primaryReasonForDelay && primaryReasonForDelay.label == "Other") {
            var resultValidPrimaryReasonForDelay = !isValidPrimaryReasonForDelayOther(primaryReasonForDelayOther);
            if (resultValidPrimaryReasonForDelay) { setPrimaryReasonForDelayOtherError(true); status = false; }
        }

        //if (!isValidSecondaryReasonForDelay(secondaryReasonForDelay)) { setSecondaryReasonForDelayError(true); status = false; }
        if (null != secondaryReasonForDelay && "" != secondaryReasonForDelay && secondaryReasonForDelay.label == "Other") {
            var resultValidSecondaryReasonForDelay = !isValidSecondaryReasonForDelayOther(secondaryReasonForDelayOther);
            if (resultValidSecondaryReasonForDelay) { setSecondaryReasonForDelayOtherError(true); status = false; }
        }

        return status;
    }

    const onDeleteClicked = async () => {
        if (jpBailOutcomeId != null && jpBailOutcomeId > 0) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteJPBailOutcome(jpBailOutcomeId, response.accessToken).then(() => {
                            navigate(NAVIGATEURL + clientFileId);
                        });
                    }
                });
            }
        }
        setOpenDeleteDialog(false);
    };

    function getParams() {
        var queryParams = '';
        if (crmId || firstNameFromSearchParams || lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if (crmId != null) {
                queryParams = `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    const onCancelClicked = () => {
        if (searchParams.get("page") == "clientfiledashboard") {
            navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
        } else {
            navigate(NAVIGATEURL + clientFileId);
        }

    };
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const canSave = () => {
        var result = false;
        //console.log("jpbRole : " + jpbrole + " role : " + role);
        if ((null != jpBailOutcomeId) && (jpBailOutcomeId > 0)) {
            if (canUpdateAfterSubmit && (jpBailOutcomeStatusId == 2)) {
                result = true;
            } else {
                // result = canUpdate("jp-bail-outcome", null) && (jpBailOutcomeStatusId != 2);
                result = canUpdate("jp-bail-outcome", null) && (jpBailOutcomeStatusId != 2);
            }
        } else {
            result = canCreate("jp-bail-outcome", null);
        }

        if (!canDelete("jp-bail-outcome", role) && jpbrole != 'No Role' && role != jpbrole) {
            if (!jpbrole.includes('Lawyer') || !role.includes('Lawyer')) {
                result = false;
            }
        }
        return result;
    };

    const canDeleteJPBailOutcome = () => {
        return ((null != jpBailOutcomeId) && (jpBailOutcomeId > 0) && canDelete('jp-bail-outcome', null));
    };

    const canSubmit = () => {
        var result = false;
        result = canCreate("jp-bail-outcome", null) && (jpBailOutcomeStatusId != 2)

        if (!canDelete("jp-bail-outcome", role) && jpbrole != 'No Role') {
            if (!jpbrole.includes('Lawyer') || !role.includes('Lawyer')) {
                result = false;
            }
        }
        return result;
    }

    const canCreateAgencyRequest = () => { return ((null != jpBailOutcomeId) && (jpBailOutcomeId > 0) && canCreate("agency-request", null)); }

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };
    const [saveClicked, setSaveClicked] = useState(false);
    function Save() {       
        onSaveClicked(null, null, 0);
    }

    function SaveAndClose() {        
        onSaveClicked(null, null, 1);
    }
    if (canRead("jp-bail-outcome", null)) {

        return (
            <>
                <Grid container style={{
                    padding: "10px 20px 0px 20px", textAlign: 'left', font: 'normal normal normal 20px/25px Franklin Gothic Medium',
                    letterSpacing: '0px', color: '#000000', opacity: '1', margin: "80px 0px 0px 0px"
                }}>
                    <SubHeader
                        title="JP Bail Outcome"
                        menuItemOneText="Delete JP Bail OutCome"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteJPBailOutcome()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible="false"
                        isMenuItemTwoDisabled={false}

                        navItemOneText={saveButtonTitle}
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={canSave()}
                        isNavItemOneDisabled={false}

                        navItemTwoText={saveCloseButtonTitle}
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={canSave()}
                        isNavItemTwoDisabled={false}

                        navItemThreeText="Submit"
                        onNavItemThreeClicked={onSubmitClicked}
                        isNavItemThreeVisible={canSubmit()}
                        isNavItemThreeDisabled={false}                        

                        navItemFourText="Submit & Close"
                        onNavItemFourClicked={onSubmitCloseClicked}
                        isNavItemFourVisible={canSubmit()}
                        isNavItemFourDisabled={false}

                        navItemFiveText="New Agency Request"
                        onNavItemFiveClicked={onCreateARClicked}
                        isNavItemFiveVisible={canCreateAgencyRequest()}
                        isNavItemFiveDisabled={false} 

                        navItemSixText="Cancel"
                        onNavItemSixClicked={onCancelClicked}
                        isNavItemSixVisible={true}
                        isNavItemSixDisabled={false}
                        
                        />
                    <StyledDialog title="Delete JP Bail Outcome" open={openDeleteDialog} onClose={onDeleteClickClose}
                        message={"Do you really want to delete the JP Bail Outcome : JPBO-" + jpBailOutcomeId + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked} confirmationText="Delete" />
                    {/* <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey}/> */}
                    <Grid item xs={12} md={12}>
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}><ClientBasicInfo isReadOnly={true} clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={false} /></div>
                        <div id="scrollByClickedItem2" style={{ scrollMarginTop: "161px" }}><ClientContactInfo isReadOnly={true} clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} /></div>
                        <div id="scrollByClickedItem3" style={{ scrollMarginTop: "161px" }}><ClientFinancialInfo isCRMOnly={true} clientFileId={clientFileId} ref={clientFinancialInfoComp} /></div>
                    </Grid>
                    <div id="scrollByClickedItem4" style={{ scrollMarginTop: "161px" }}>

                        <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", margin: "10px 0px 60px 0px", borderRadius: "8px", backgroundColor: (canSave()) ? null : "#ececec" }}>
                            <Grid item xs={12} md={12} style={{
                                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                            }}>JP Bail Outcome Information</Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Time of Shift" placeholder="Time of Shift" required={true}
                                    disabled={(canSave()) ? false : true}
                                    select
                                    error={timeOfShiftError ? true : null}
                                    value={timeOfShift ? timeOfShift?.label : ""}
                                    handleChange={onTimeOfShiftsChanged}
                                    helperText={timeOfShiftError ? "Time of Shift is required." : null}>
                                    {timeOfShifts.map((shift) => (
                                        <MenuItem key={shift.timeOfShiftId} value={shift.label}>
                                            {shift.label}
                                        </MenuItem>))}

                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Docket Number" placeholder="Docket Number" required={true}
                                    disabled={(canSave()) ? false : true}
                                    multiline
                                    error={docketMaxLengthError ? true : (docketNumberError ? true : null)}
                                    value={docketNumber}
                                    handleChange={onDocketNumberChanged}
                                    isInfoVisible={true}
                                    infoMessage="Where multiple dockets are applicable, record the dockets with the most serious offences."
                                    helperText={docketMaxLengthError ? "Max. 255 characters are allowed." : (docketNumberError ? "Docket Number is required." : null)} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Detachment" placeholder="Detachment" required={true}
                                    disabled={(canSave()) ? false : true}
                                    select
                                    error={detachmentError ? true : null}
                                    value={detachment ? detachment?.label : ""}
                                    handleChange={onDetachmentChanged}
                                    helperText={detachmentError ? "Detachment is required." : null}
                                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                    {detachments.map((option) => (
                                        <MenuItem key={option.detachmentId} value={option.label}>
                                            {option.label}
                                        </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid container style={{ margin: "10px 0px 0px 0px" }}>
                                <Grid item xs={12} md={12} style={{
                                    textAlign: "left", font: "normal normal bold 14px/25px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #585454", opacity: "1"
                                }}>
                                    Was there Unreasonable Delay or Did the Matter Exceed 24 hours?</Grid>
                                <Grid item xs={12} md={12} style={{ marginBottom: "10px" }} >
                                    <StyledSwitch size="small" disabled={(canSave()) ? false : true}
                                        checked={matterDelayedOrExceed24Hrs}
                                        onChange={onMatterDelayedOrExceed24HrsChanged} /></Grid>
                                {matterDelayedOrExceed24Hrs && (
                                    <>
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <CustomDateTimePicker ampm={false} label="Date and Time of Arrest" placeholder="Date and Time of Arrest" required={true} disableFuture={true}
                                                    disabled={(canSave()) ? false : true}
                                                    error={isArrestedOnDateInvalid ? true : (arrestedOnError ? true : null)}
                                                    value={arrestedOn}
                                                    handleOnChange={onArrestedOnChanged}
                                                    helperText={isArrestedOnDateInvalid ? "Invalid date time format. Format is dd-Mmm-yyyy HH:mm." : (arrestedOnError ? "Date and Time of Arrest is required. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <CustomDateTimePicker isLabelWrapped={true} ampm={false} label="Date and Time of Matter Received by CBO from LEA" placeholder=" Matter Received by CBO from LEA" required={true} disableFuture={true}
                                                    disabled={(canSave()) ? false : true}
                                                    error={isMatterReceivedByCBOFromLEAInvalid ? true : (matterReceivedByCBOFromLEAError ? true : null)}
                                                    value={matterReceivedByCBOFromLEA}
                                                    handleOnChange={onMatterReceivedByCBOFromLEAChanged}
                                                    helperText={isMatterReceivedByCBOFromLEAInvalid ? "Invalid date time format. Format is dd-Mmm-yyyy HH:mm." : (matterReceivedByCBOFromLEAError ? "Date and Time of Matter Received by CBO from LEA is required and cannot be before the Date and Time of Arrest. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                            </div>
                                        </Grid>
                                        <Grid container style={{ margin: "10px 0px 0px 0px" }}>
                                            <Grid item xs={12} md={12} style={{
                                                textAlign: "left", font: "normal normal bold 14px/25px Franklin Gothic Medium",
                                                letterSpacing: "0px", color: " #585454", opacity: "1"
                                            }}>
                                                Did Crown Post Position?</Grid>
                                            <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                                                <StyledSwitch size="small" disabled={(canSave()) ? false : true}
                                                    checked={didCrownPostPosition}
                                                    onChange={onDidCrownPostPositionChanged} /></Grid>
                                            {didCrownPostPosition && (
                                                <>
                                                    <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <div style={{ maxWidth: "275px" }}>
                                                            <CustomDateTimePicker ampm={false} label="Date and Time Crown Posts Position" placeholder="Date and Time Crown Posts Position" required={true} disableFuture={true}
                                                                disabled={(canSave()) ? false : true}
                                                                error={isCrownPostedPositionOnInvalid ? true : (crownPostedPositionOnError ? true : null)}
                                                                value={crownPostedPositionOn}
                                                                handleOnChange={onCrownPostedPositionOnChanged}
                                                                helperText={isCrownPostedPositionOnInvalid ? "Invalid date time format. Format is dd-Mmm-yyyy HH:mm." : (crownPostedPositionOnError ? "Date and Time Crown Posts Position is required. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                                        </div>
                                                    </Grid>
                                                </>)}
                                        </Grid>
                                        <Grid container style={{ margin: "10px 0px 10px 0px" }}>
                                            <Grid item xs={12} md={12} style={{
                                                textAlign: "left", font: "normal normal bold 14px/25px Franklin Gothic Medium",
                                                letterSpacing: "0px", color: " #585454", opacity: "1"
                                            }}>
                                                Was Accused Spoken To?
                                                &nbsp;<RiInformationFill title='"No" should be indicated / chosen only if the accused has not been spoken to by you or any previous LAA Duty Counsel (for example, under the 20+ Hour Protocol). On matters completed after shift change, the "Yes" default should remain in place and the time the accused was first spoken to by prior Duty Counsel must be entered.' size="20px" color="lightblue" className="align-top" />
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                                                <StyledSwitch size="small" disabled={(canSave()) ? false : true}
                                                    checked={wasAccusedSpokenTo}
                                                    onChange={onWasAccusedSpokenToChanged} />
                                            </Grid>
                                            {wasAccusedSpokenTo && (
                                                <>
                                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                        <div style={{ maxWidth: "275px" }}>
                                                            <CustomDateTimePicker isLabelWrapped={true} ampm={false} label="Date and Time Accused First Spoken To" placeholder="Accused First Spoken To" required={true} disableFuture={true}
                                                                disabled={(canSave()) ? false : true}
                                                                error={isAccusedFirstSpokenToOnInvalid ? true : (accusedFirstSpokenToOnError ? true : null)}
                                                                value={accusedFirstSpokenToOn}
                                                                handleOnChange={onAccusedFirstSpokenToOnChanged}
                                                                isInfoVisible={true}
                                                                infoMessage='On matters completed after shift change, the date and time of the first communication with the accused must be recorded. Extra notes or comments regarding subsequent communications with the accused can be entered in the "comments" section.'
                                                                helperText={isAccusedFirstSpokenToOnInvalid ? "Invalid date time format. (Hint: dd-Mmm-yyyy HH:mm)" : (accusedFirstSpokenToOnError ? "Date and Time Accused First Spoken To is required. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                                        </div>
                                                    </Grid>
                                                </>)}
                                        </Grid>
                                    </>)}

                            </Grid>
                            {matterDelayedOrExceed24Hrs && (
                                <>
                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                        <div style={{ maxWidth: "275px" }}>
                                            <CustomDateTimePicker isLabelWrapped={true} ampm={false} label="Date and Time Marked by Duty Counsel as Ready to Proceed" placeholder="Duty Counsel Ready to Proceed On" required={true} disableFuture={true}
                                                disabled={(canSave()) ? false : true}
                                                error={isDutyCounselMarkedAsReadyToProceedOnInvalid ? true : (dutyCounselMarkedAsReadyToProceedOnError ? true : null)}
                                                value={dutyCounselMarkedAsReadyToProceedOn}
                                                handleOnChange={onDutyCounselMarkedAsReadyToProceedOnChanged}
                                                helperText={isDutyCounselMarkedAsReadyToProceedOnInvalid ? "Invalid date time format. (Hint: dd-Mmm-yyyy HH:mm)" : (dutyCounselMarkedAsReadyToProceedOnError ? "Date and Time Marked by Duty Counsel as Ready to Proceed is required. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                        </div>
                                    </Grid>
                                </>)}
                            <Grid container style={{ margin: "10px 0px 10px 0px" }}>
                                <Grid item xs={12} md={12} style={{
                                    textAlign: "left", font: "normal normal bold 14px/25px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #585454", opacity: "1"
                                }}>

                                    Was a Hearing Reached/Conducted? </Grid>
                                <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
                                    <StyledSwitch size="small" disabled={(canSave()) ? false : true}
                                        checked={wasAHearingReachedOrConducted}
                                        onChange={onWasAHearingReachedOrConductedChanged} />
                                </Grid>
                                {wasAHearingReachedOrConducted && (
                                    <>
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <div style={{ maxWidth: "275px" }}>
                                                <CustomDateTimePicker ampm={false} label="Date and Time of Hearing" placeholder="Date and Time of Hearing" required={true} disableFuture={true}
                                                    disabled={(canSave()) ? false : true}
                                                    error={isHearingConductedOnInvalid ? true : (hearingConductedOnError ? true : null)}
                                                    value={hearingConductedOn}
                                                    handleOnChange={onHearingConductedOnChanged}
                                                    helperText={isHearingConductedOnInvalid ? "Invalid date time format. Format is dd-Mmm-yyyy HH:mm." : (hearingConductedOnError ? "Date and Time of Hearing is required. (Hint: dd-Mmm-yyyy HH:mm)" : null)} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                            <SelectorTextField label="Hearing Results" placeholder="Hearing Results" required={true}
                                                disabled={(canSave()) ? false : true}
                                                select
                                                error={hearingResultError ? true : null}
                                                value={hearingResult ? hearingResult?.label : ""}
                                                handleChange={onHearingResultChanged}
                                                helperText={hearingResultError ? "Hearing Result is required." : null}
                                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                                {hearingResults.map((option) => (
                                                    <MenuItem key={option.hearingResultId} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>))}
                                            </SelectorTextField>
                                        </Grid>
                                        {(hearingResult?.hearingResultId == 13) && (
                                            <>
                                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                    <TextField label="Other (Hearing Results)" placeholder="Please specify Hearing Result" required={true} multiline
                                                        disabled={(canSave()) ? false : true}
                                                        error={hearingResultOtherMaxLengthError ? true : (hearingResultOtherError ? true : null)}
                                                        value={hearingResultOther}
                                                        handleChange={onHearingResultOtherChanged}
                                                        helperText={hearingResultOtherMaxLengthError ? "Max. 255 characters are allowed." : (hearingResultOtherError ? "Other (Hearing Results) is required." : null)} />
                                                </Grid>
                                            </>)}
                                    </>)}
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isNote={true} label="Comments" placeholder="Comments" multiline rows={7} sx={{ width: "90%" }}
                                    disabled={(canSave()) ? false : true}
                                    error={commentsMaxLengthError ? true : null}
                                    value={comments}
                                    handleChange={onCommentsChanged}
                                    helperText={commentsMaxLengthError ? "Max. 1000 characters are allowed." : null} />
                            </Grid>
                            {matterDelayedOrExceed24Hrs && (
                                <>
                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                        <SelectorTextField label="Primary Reason for Delay" placeholder="Primary Reason for Delay" required={true}
                                            disabled={(canSave()) ? false : true}
                                            select
                                            error={primaryReasonForDelayError ? true : null}
                                            value={primaryReasonForDelay ? primaryReasonForDelay?.label : ""}
                                            handleChange={onPrimaryReasonForDelayChanged}
                                            helperText={primaryReasonForDelayError ? "Primary Reason for Delay is required." : null}
                                            SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                            {jpBailReasonsForDelay.map((option) => (
                                                <MenuItem key={option.jpBailReasonForDelayId} value={option.label}>
                                                    {option.label}
                                                </MenuItem>))}
                                        </SelectorTextField>
                                    </Grid>
                                    {(primaryReasonForDelay?.jpBailReasonForDelayId == 13) && (
                                        <>
                                            <Grid item xs={12} md={8} style={{ margin: "10px 0px 10px 0px" }}>
                                                <div style={{ maxWidth: "275px" }}>
                                                    <TextField label="Other (Primary Reason for Delay)" placeholder="Please specify Primary Reason for Delay" required={true} multiline
                                                        disabled={(canSave()) ? false : true}
                                                        error={primaryReasonForDelayOtherMaxLengthError ? true : (primaryReasonForDelayOtherError ? true : null)}
                                                        value={primaryReasonForDelayOther}
                                                        handleChange={onPrimaryReasonForDelayOtherChanged}
                                                        helperText={primaryReasonForDelayOtherMaxLengthError ? "Max. 255 characters are allowed." : (primaryReasonForDelayOtherError ? "Other (Primary Reason for Delay) is required." : null)} />
                                                </div>
                                            </Grid>
                                        </>)}
                                    <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                        <SelectorTextField label="Secondary Reason for Delay" placeholder="Secondary Reason for Delay"
                                            disabled={(canSave()) ? false : true}
                                            select
                                            error={secondaryReasonForDelayError ? true : null}
                                            value={secondaryReasonForDelay ? secondaryReasonForDelay?.label : ""}
                                            handleChange={onSecondaryReasonForDelayChanged}
                                            helperText={secondaryReasonForDelayError ? "Secondary Reason for Delay is required." : null}
                                            SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                            {jpBailReasonsForDelay.map((option) => (
                                                <MenuItem key={option.jpBailReasonForDelayId} value={option.label}>
                                                    {option.label}
                                                </MenuItem>))}
                                        </SelectorTextField>
                                    </Grid>
                                    {(secondaryReasonForDelay?.jpBailReasonForDelayId == 13) && (
                                        <>
                                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                                <div style={{ maxWidth: "275px" }}>
                                                    <TextField label="Other (Secondary Reason for Delay)" placeholder="Please specify Secondary Reason for Delay" required={true} multiline
                                                        disabled={(canSave()) ? false : true}
                                                        error={secondaryReasonForDelayOtherMaxLengthError ? true : (secondaryReasonForDelayOtherError ? true : null)}
                                                        value={secondaryReasonForDelayOther}
                                                        handleChange={onSecondaryReasonForDelayOtherChanged}
                                                        helperText={secondaryReasonForDelayOtherMaxLengthError ? "Max. 255 characters are allowed." : (secondaryReasonForDelayOtherError ? "Other (Secondary Reason for Delay) is required." : null)} />
                                                </div>
                                            </Grid>
                                        </>)}
                                </>)}
                            {jpBailOutcomeId != null && jpBailOutcomeId > 0 && (
                                <>
                                    <Grid item xs={12} md={9} style={{ margin: "10px 0px 10px 0px", padding: "5px", border: "1px solid #C4C4C4", borderRadius: "6px" }}>
                                        <AttachmentList isDisabled={(canSave()) ? false : true} containerName="jp-bail-outcomes" recordId={jpBailOutcomeId} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </>

        );
    } else {
        return (
            <div className="agencyRequests">
                <div className="agencyRequest">
                    <div className="sectionTitle">JP Bail Outcome</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default JPBailOutcome;