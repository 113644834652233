export const EMAIL = "EMAIL";
export const PHONENUMBER = "PHONENUMBER";
export const NAME = "NAME";
export const DOCKETNUMBER = "DOCKETNUMBER";
export const NOTE = "NOTE";
export const OTHER = "OTHER";

//#region ValidationMessages

export const DOCKETNUMBERREQUIREDMESSAGE = "Docket Number is required.";
export const UPCOMINGCOURTDATEREQUIREDMESSAGE ="Upcoming Court Date is required.";
export const UPCOMINGCOURTDATEINFUTUREMESSAGE ="Upcoming Court Date must be in the future or a current date.";
export const COURTLOCATIONREQUIREDMESSAGE ="Court Location is required.";
export const COURTROOMREQUIREDMESSAGE ="Court Room is required.";
export const INSTRUCTIONSREQUIREDMESSAGE ="Instructions are required.";
export const REQUESTORREQUIREDMESSAGE = "Requestor is required.";
export const REQUESTORASSIGNEDTONOTSAMEMESSAGE ="Requestor and Assigned To cannot be the same person.";
export const NEXTCOURTDATEREQUIREDMESSAGE = "Next Court Date  is required.";
export const NEXTCOURTDATEINFUTUREMESSAGE ="Next Court Date must be in the future or a current date.";
export const DUTYCONSELTYPEREQUIREDMESSAGE = "Duty Counsel Type is required."

//#endregion

//#region PageUrls

export const SEARCHRESULTSPAGEURL ="/clientfiles/search";
export const CLIENTFILEDASHBOARDPAGEURL ="/client-dashboard";
export const CLIENTFILEPAGEURL = "/client-file";
export const CLIENTFILEDASHBOARDEURL = "/client-dashboard";
export const CRMREVIEWPAGEURL = "/reviewdashboard";

export const DCPCONFLICTEDCLIENTFILESPAGEURL = "/reviewdashboard/dcpclientfiles";
export const CRMCONFLICTDASHBOARDPAGEURL = "/reviewdashboard/crmclientfiles";
export const INERACTIONCCONFLICTEDFILESPAGEURL = "/reviewdashboard/interactions";

export const  DCPCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvedcpconflicts";
export const  CRMCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvecrmconflicts";
export const  INERACTIONCONFLICTRESOLUTIONPAGEURL = "/reviewdashboard/resolvedinteractionconflicts";

export const MYFILESPAGEURL ="/myfiles";
export const FILESTOBEASSIGNEDPAGEURL ="/filestobeassigned";
export const ASSIGNEDFILESPAGEURL ="/assignedfiles";
export const DASHBOARDPAGEURL ="/";
export const JPBAILOUTCOMEPAGEURL = "/jpbailoutcome";
export const CLIENTFILEDASHBOARD = "/client-dashboard";
export const INTERACTIONPAGEURL = "/interaction";

//#endregion
