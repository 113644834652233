import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import "./note.css";
import appconfig from '../appConfig'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthorization } from "../hooks/useAuthorization";
import * as Constants from "../helper/constants";
import { useValidation } from "../hooks/useValidation";
import { tokenRequest } from "../auth/authConfig";
import StyledDialog from '../components/Dialog';
import { Grid, MenuItem, Checkbox, Button } from "@mui/material";
import ScreenSideBar from '../components/ScreenSideBar';
import SubHeader from '../components/SubHeader';
import TextField from '../components/TextField';
import SelectorTextField from '../components/SelectorTextField';
import { toast } from "react-toastify";
import { GetInteractionById } from "../services/interaction-service";

function Note() {

    const CREATESUCCESS = "Note successfully created.";
    const UPDATESUCCESS = "Note successfully updated.";

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isValid] = useValidation();
    const isAuthenticated = useIsAuthenticated();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [username, setUsername] = useState("");
    const [noteTextError, setNoteTextError] = useState(false);
    const [noteMaxLengthError, setNoteMaxLengthError] = useState(false);
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [role, setRole] = useState("");
    const [noteId, setNoteId] = useState((null != searchParams.get("id")) ? Number(searchParams.get("id")) : 0);
    const [relatedId, setRelatedId] = useState((null != searchParams.get("relatedId")) ? Number(searchParams.get("relatedId")) : 0);
    const [clientFileId, setClientFileId] = useState((null != searchParams.get("clientFileId")) ? Number(searchParams.get("clientFileId")) : 0);
    const [noteText, setNoteText] = useState("");
    const [noteType, setNoteType] = useState("");
    const [noteTypeId, setNoteTypeId] = useState((null != searchParams.get("noteTypeId")) ? Number(searchParams.get("noteTypeId")) : 0);
    const [pageId, setPageId] = useState((null != searchParams.get("page")) ? searchParams.get("page") : '');
    const [isDisabled, setIsDisabled] = useState((null != searchParams.get("isDisabled")) ?JSON.parse(searchParams.get("isDisabled")) : false);
    const [createdBy, setCreatedBy] = useState("");
    const [createdByEmail, setCreatedByEmail] = useState("");
    const [createdOn, setCreatedOn] = useState(new Date());
    const [modifiedBy, setModifiedBy] = useState(userLastName + ", " + userFirstName);
    const [modifiedOn, setModifiedOn] = useState(new Date());
    const [noteTypes, setNoteTypes] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const screenSideBarList= ['Note Details'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));
    const [saveClicked, setSaveClicked] = useState(false);

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();        

        if (currentAccount) {
            setUserFirstName(currentAccount.name.split(" ")[0]);
            setUserLastName(currentAccount.name.split(" ")[1]);
            setUsername(currentAccount.username);

            if (currentAccount.idTokenClaims.roles) {
                setRole(currentAccount.idTokenClaims.roles[0].toString());
            } else {
                setRole("No Role");
            }
        }
    }, [instance]);

    useEffect(() => {

        const setRelatedAttributes = async (token) => {
            if (null != noteTypeId && noteTypeId == 3 && null != relatedId && relatedId > 0) {
                var interaction = await GetInteractionById(relatedId, token);
                setIsDisabled(!interaction?.isActive || interaction?.isInteractionConflicted);
            }
        }

        if ((null != noteId) && (noteId > 0)) {

            const FetchData = async (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'GET',
                    headers: headers,
                }

                const uri = apiServerUrl + 'NoteService/GetNote?id=' + noteId;

                fetch(uri, options)
                    .then(async response => {
                        const data = await response.json();

                        // check for error response
                        if (!response.ok) {
                            // get error message from body or default to response statusText
                            const error = (data && data.message) || response.statusText;
                            return Promise.reject(error);
                        }

                        setNoteId(data.noteId);
                        //setClientFileId(data.clientFileId);
                        setNoteText(data.noteText);
                        setCreatedBy(data.createdBy);
                        setNoteType(data.noteType);
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                    });
            }


            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        setRelatedAttributes(response.accessToken);
                        FetchData(response.accessToken);
                    }
                });
            }
        }
    }, [account, instance]);


    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetNoteLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setNoteTypes(data.noteTypes);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);

    const onSaveClicked = (option) => {

        var result = 1;

        if ((null != noteId) && (noteId > 0)) {
            var note = {
                "noteId": noteId,
                "noteText": noteText,
                "noteTypeId": noteType.noteTypeId,
                "relatedRecordId": relatedId.toString()
            };

            const PutData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'PUT',
                    headers: headers,
                    body: JSON.stringify(note)
                }

                const uri = apiServerUrl + 'NoteService/UpdateNote';

                result = fetch(uri, options)
                    .then(response => {
                        if (response.ok) {
                            toast.success(UPDATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
                            if (option == 1 ) {
                                navigate(navigateUrl());
                            }
                            
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => toast.error("Update Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"}));
                        //alert("Update Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        PutData(response.accessToken);
                    }
                });
            }

        } else {
            setSaveClicked(true);
            if (noteText.length == 0) {
                setNoteTextError(true);
                return;
            }
            var note = {
                "noteId": noteId,
                "noteText": noteText,
                "noteTypeId": noteTypeId,
                "relatedRecordId": relatedId.toString()
            };

            const PostData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(note)
                }

                const uri = apiServerUrl + 'NoteService/CreateNote?clientFileId=0';
                result = fetch(uri, options)
                    .then(async response => {
                        if (response.ok) {
                            var data = await response.json();
                            toast.success(CREATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
                            if (option == 0 && response) {
                                navigate(window.location.pathname + window.location.search + `&id=${data}`);
                                window.location.reload();
                            }
                            else if (option == 1 && response) {
                                navigate(navigateUrl());
                            }
                            
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => toast.error("Create Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"}));
                        //alert("Create Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async(response) => {
                    if (response) {
                       await PostData(response.accessToken);
                    }
                });
            }
        }

        return result;

    };

    const onDeleteClicked = () => {

        var result = 1;

        if ((null != noteId) && (noteId > 0)) {

            const DeleteData = (token) => {

                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'DELETE',
                    headers: headers
                }

                result = fetch(apiServerUrl + 'NoteService/DeleteNote?id=' + noteId, options)
                    .then(response => {
                        if (response.ok) {
                            navigate(navigateUrl());
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => toast.error("Delete Note was not succesful.",{position: "top-center",autoClose: 5000,theme: "colored"}));
                        //alert("Delete Note was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken);
                    }
                });
            }
        }
        setOpenDeleteDialog(false);
        return result;
    };

    function getParams() {
        var queryParams = '';
        if (crmId || firstNameFromSearchParams || lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if ( crmId != null ) {
                queryParams = `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    const onCancelClicked = () => {
        navigate(navigateUrl());
    };

    const navigateUrl = () => {
        
        if (noteType.noteTypeId == 4 || noteTypeId == 4 ) {
            return "/agencyrequest?" + `agencyrequestid=${relatedId}&page=${pageId}&clientFileId=${clientFileId}`
        }
        else if ((noteType.noteTypeId == 2 || noteTypeId == 2 ) && (searchParams.get("page") == "clientfiledashboard")) {
            return `/client-dashboard?&id=${relatedId}&page=searchresults${getParams()}`   
        }
        else if (noteType.noteTypeId == 2 || noteTypeId == 2 ) {  
            return `/client-file?id=${relatedId}&page=clientfiledashboard${getParams()}`
        }
        else if (noteType.noteTypeId == 3 || noteTypeId == 3 ) {
            return "/interaction?id=" + relatedId + "&clientFileId=" + clientFileId;
        }
    }
    const canSave = () => {
        var result = false;

        if ((null != noteId) && (noteId > 0)) {
            result = canUpdate("client-note", createdBy);
        } else {
            result = canCreate("client-note", null);
        }

        return result;
    }

    const onNoteTextChanged = (event) => {
        if (noteTypeId == 3){
            if (event.target.value.length < 1001) {
            setNoteMaxLengthError(false);
            setNoteText(event.target.value);
            const fieldModal = { name: Constants.NOTE, value: event.target.value, required: true };
            setNoteTextError(!isValid(fieldModal));
            } else {
                setNoteMaxLengthError(true);
            }
        } else if (event.target.value.length < 2501){
            setNoteMaxLengthError(false);
            setNoteText(event.target.value);
            const fieldModal = { name: Constants.NOTE, value: event.target.value, required: true };
            setNoteTextError(!isValid(fieldModal));
        } else {
            setNoteMaxLengthError(true);
        }
    };

    const onNoteTypeChanged = (event) => {
        const selectedNoteType = noteTypes.find(
            (object) => object?.label === event.target.value
        );
        setNoteType(selectedNoteType);
    };

    const canDeleteNote = () => {
        return ((null != noteId) && (noteId > 0) && canDelete("client-note", null));
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey= `${screenSideBarList?.indexOf(text)+1}`;
       
        const element = document.getElementById('scrollByClickedItem'+ listItemKey.toString());
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' , block: 'start' });
        }
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    function Save() {
        onSaveClicked(0);
    }

    function SaveAndClose() {
        onSaveClicked(1);
    }

    if (canRead("client-note", null)) {
        return (
            <>
            <Grid container style={{
                    padding: "15px 20px 0px 20px", textAlign: 'left', font: 'normal normal normal 20px/25px Franklin Gothic Medium',
                    letterSpacing: '0px', color: '#000000', opacity: '1', margin: "80px 0px 0px 215px"
                }}>
                    <SubHeader
                        title="Note"
                        menuItemOneText="Delete Note"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteNote()}
                        isMenuItemOneDisabled={isDisabled}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible={false}
                        isMenuItemTwoDisabled={false}
                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={canSave()}
                        isNavItemOneDisabled={isDisabled || saveClicked}
                        navItemTwoText='Save & Close'
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={canSave()}
                        isNavItemTwoDisabled={isDisabled || saveClicked}
                        navItemThreeText=''
                        onNavItemThreeClicked=''
                        isNavItemThreeVisible={false}
                        isNavItemThreeDisabled={false}
                        navItemFourText="Cancel"
                        onNavItemFourClicked={onCancelClicked}
                        isNavItemFourVisible={true}
                        isNavItemFourDisabled={false} />
                <StyledDialog title="Delete Note" open={openDeleteDialog} onClose={onDeleteClickClose}
                    message={"Do you really want to delete the Note starting, '" + (((noteText != null) && (noteText.length > 10)) ? noteText.substring(0, 10) : noteText) + "...' ? This process cannot be undone."}
                    onConfirmed={onDeleteClicked} confirmationText="Delete" />
                <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey}/>
                <div id="scrollByClickedItem1" style={{ width: '100%', scrollMarginTop : "175px"}}>
                <Grid container style={{ padding: "10px 20px 10px 20px", border: "1px solid #7F8284", margin: "0px 0px 60px 0px", borderRadius: "8px",backgroundColor: isDisabled == true ? "#ececec" : null  }}>
                <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                        }}>Note Details</Grid>
                <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField isNote={true} label="Note" placeholder="Note" multiline rows={17} sx={{ width: "100%"}}
                                error={noteMaxLengthError ? true : (noteTextError ? true : null)}
                                disabled={isDisabled}
                                value={noteText}
                                handleChange={onNoteTextChanged} 
                                helperText={noteMaxLengthError ? (noteTypeId == 3 ? "Max. 1000 characters are allowed." : "Max. 2500 characters are allowed."): (noteTextError ? "Please include note text to proceed." : null)}/>
                        </Grid>
                        <Grid className="hide" item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Note Type" placeholder="Note Type" 
                                select
                                disabled={true}
                                value={noteType?.label ? noteType?.label : "" }
                                handleChange={onNoteTypeChanged}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                {noteTypes.map((option) => (
                                    <MenuItem key={option.noteTypeId} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>     
                </Grid></div>
                </Grid>
            </>
        );
    } else {
        return (
            <div className="note">
                <div className="noteSection">
                    <div className="sectionTitle">Note</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default Note;