import React from "react";
import { forwardRef, useRef, useReducer, useImperativeHandle, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../client-basic-info";
import ClientContactInfo from "../client-contact-info";
import ClientFinancialInfo from "../client-financial-info";
import NoteTable from "../notes";
import ReferralTable from "../referrals";
import DocketsLegalIssues from "../client-dockets-legal-issues";
import CertificateDataGrid from "../client-certificate";
import CertificateOutcome from '../client-certificate-outcome';
import ServiceDecision from '../client-service-decision';
import AgencyRequestList from "../feature/agency-requests/agency-request-list";
import JPBailOutcomeList from "../feature/jp-bail-outcomes/jp-bail-outcome-list";
import InteractionList from "../feature/interactions/interaction-list";
import ClientGatewayTable from "../feature/client-gateway/client-gateway-details";
import "./client-file.css";
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import { GetAllAgencyRequest } from "../services/agency-request-service";
import { GetAllJPBailOutcomes } from "../services/jp-bail-outcome-service";
import { GetAllInteractions } from "../services/interaction-service";
import { GetAllLegalIssues } from "../services/legal-issues-service";
import { GetAllCertificates } from "../services/certificate-service";
import { GetAllCertificatesOutcomes } from "../services/certificates-outcomes-service";
import { GetAllServiceDecisions } from "../services/service-decision-service";
import { Grid } from "@mui/material";
import SubHeader from '../components/SubHeader';
import StyledDialog from '../components/Dialog';
import ScreenSideBar from "../components/ScreenSideBar";
import { GetClientFileById, MarkClientFileAsCrmConflicted, MarkClientFileAsDcpConflicted, GetClientFileFromCrm, MarkClientFileAsConflicted, GetMergedClientFile, CheckDuplicateClients, UpdateClientFile, CreateClientFile } from "../services/client-file.service";
import Badge from 'react-bootstrap/Badge';
import { SEARCHRESULTSPAGEURL, CRMCONFLICTDASHBOARDPAGEURL, CRMREVIEWPAGEURL, CLIENTFILEDASHBOARDEURL, CLIENTFILEDASHBOARDPAGEURL } from '../helper/constants';
import Backdrop from '../components/elements/Loader';
import { toast } from "react-toastify";
import { GetAllClientGateways } from '../services/client-gateway-service';

function ClientFile() {

    const CREATESUCCESS = "Client File/Details successfully created.";
    const UPDATESUCCESS = "Client File/Details successfully updated.";

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [agencyRequests, setAgencyRequests] = useState([]);
    const [jpBailOutcomes, setJpBailOutcomes] = useState([]);
    const [interactions, setInteractions] = useState([]);
    const [legalIssues, setLegalIssues] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const [certificatesOutcomes, setCertificatesOutcomes] = useState([]);
    const [serviceDecisions, setServiceDecisions] = useState([]);
    const [clientGatewayDetails, setClientGatewayDetails] = useState([]);
    const screenSideBarList = ['Client Information', 'Client Notes', 'Client Interactions', 'CRM History', 'JP Bail Outcomes', 'DC Portal Activity'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [isCrmConflicted, setIsCrmConflicted] = useState(false);
    const [hasDcpDuplicateClientFiles, setHasDcpDuplicateClientFiles] = useState(false);
    const [isLinkedClient, setIsLinkedClient] = useState(false);
    const [isDcpConflicted, setIsDcpConflicted] = useState(false);
    const [isCrmConflictResolved, setIsCrmConflictResolved] = useState(false);
    const [crmData, setCrmData] = useState(null);
    const [clientDetailNotes, setClientDetailNotes] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [middleName, setMiddleName] = useState(searchParams.get("middlename"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [isDataReady, setIsDataReady] = useState(false);
    const [clientFileCrmIdById, setClientFileCrmIdById] = useState("");
    const [saveClicked, setSaveClicked] = useState(false);

    useEffect(() => {

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    var clientFileId = Number(searchParams.get("id"));
                    var clientFileCrmId = searchParams.get("crmId");
                    setCrmData(null);
                    setIsDataReady(false);
                    if ((clientFileCrmId && clientFileCrmId != "") && (clientFileId && clientFileId > 0)) {
                        // Already Linked, Show CRM Data
                        GetAllLegalIssues(clientFileCrmId, response.accessToken).then((data) => {
                            setLegalIssues(data);
                        });
                        GetAllCertificates(clientFileCrmId, response.accessToken).then((data) => {
                            setCertificates(data);
                        });
                        GetAllCertificatesOutcomes(clientFileCrmId, response.accessToken).then((data) => {
                            setCertificatesOutcomes(data);
                        });
                        GetAllServiceDecisions(clientFileCrmId, response.accessToken).then((data) => {
                            setServiceDecisions(data);
                        });
                        GetMergedClientFile(clientFileId, clientFileCrmId, response.accessToken).then(data => {
                            setCrmData(data);
                            setIsCrmConflicted(data.isCrmConflicted);
                            setIsDcpConflicted(data.isDcpConflicted);
                            setIsCrmConflictResolved(false);

                            setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
                            CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                                setHasDcpDuplicateClientFiles(data2);
                                setIsDataReady(true);
                            });
                        });
                        GetAllClientGateways(clientFileId, response.accessToken).then(data => {
                            setClientGatewayDetails(data);
                        });


                    } else if ((clientFileCrmId && clientFileCrmId != "") && (!clientFileId || clientFileId == 0)) {
                        setIsDataReady(true);
                        // CRM Only Record, Not Posible on this page;
                    } else if ((!clientFileCrmId || clientFileCrmId == "") && (clientFileId && clientFileId > 0)) {
                        // DCP Only Record, Show from DCP
                        GetClientFileById(clientFileId, response.accessToken).then(async data => {
                            setIsCrmConflicted(data.isCrmConflicted);
                            setIsDcpConflicted(data.isDcpConflicted);
                            setIsCrmConflictResolved(data.isCrmConflictResolved);
                            if (data?.clientFileCrmId != null) {
                                setClientFileCrmIdById(data?.clientFileCrmId);
                                GetAllLegalIssues(data?.clientFileCrmId, response.accessToken).then((data) => {
                                    setLegalIssues(data);
                                });
                                GetAllCertificates(data?.clientFileCrmId, response.accessToken).then((data) => {
                                    setCertificates(data);
                                });
                                GetAllCertificatesOutcomes(data?.clientFileCrmId, response.accessToken).then((data) => {
                                    setCertificatesOutcomes(data);
                                });
                                GetAllServiceDecisions(data?.clientFileCrmId, response.accessToken).then((data) => {
                                    setServiceDecisions(data);
                                });
                            }
                            setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
                            CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                                setHasDcpDuplicateClientFiles(data2);
                                setIsDataReady(true);
                            });
                            await GetAllClientGateways(clientFileId, response.accessToken).then(data => {
                                setClientGatewayDetails(data);
                            });
                        });
                    } else {
                        setIsDataReady(true);
                    }
                }
            });
        }
    }, []);

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();

    const [openCreateDuplicateClientConfirmDialog, setOpenCreateDuplicateClientConfirmDialog] = useState(false);

    const onCreateDuplicateClientConfirmClickOpen = () => {
        setOpenCreateDuplicateClientConfirmDialog(true);
    };

    const onCreateDuplicateClientConfirmClickClose = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File Aborted
        toast.success("Create Client File aborted.", { position: "top-center", autoClose: 5000, theme: "colored" });
    };

    const onCreateDuplicateClientConfirmClicked = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File
        saveClientDetails(true, true);
    };

    const onSaveClicked = async (option) => {
        if (validateInformation()) {
            var dcpClientId = ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) ?
                Number(searchParams.get("id")) : 0;
            if (dcpClientId == 0)  setSaveClicked(true);
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {

                        var hasDuplicateClients = await CheckDuplicateClients(clientBasicInfoComp.current.getClientBasicInfo().firstName, clientBasicInfoComp.current.getClientBasicInfo().middleName, clientBasicInfoComp.current.getClientBasicInfo().lastName, clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth, dcpClientId, response.accessToken);

                        switch (dcpClientId) {
                            case 0:
                                // Create Client File
                                switch (hasDuplicateClients) {
                                    case true:
                                        // Duplicate records exist
                                        // Get Confirmation
                                        onCreateDuplicateClientConfirmClickOpen();
                                        break;
                                    default:
                                        // No Duplicate records
                                        // Create a Non DCP Conflicted Client File
                                        saveClientDetails(null, true, option);
                                        break;
                                }
                                break;
                            default:
                                // Update Client Details
                                switch (hasDuplicateClients) {
                                    case true:
                                        // Duplicate records exist
                                        // Not allowed to update
                                        toast.error("Duplicate Client Files detected with the same First Name, Last Name and Date of Birth. Please verify form data and try again.", {
                                            position: "top-center", autoClose: 5000, theme: "colored"
                                        });
                                        break;
                                    default:
                                        // No Duplicate records
                                        // Update Non DCP Conflicted Client Details
                                        saveClientDetails(null, false, option);
                                        break;
                                }
                                break;
                        }
                    }
                });
            }
        } else {
            // Incorrect Form Data
            toast.error("Please fill out the details correctly before saving the form.", {
                position: "top-center", autoClose: 5000, theme: "colored"
            });
        }
    }

    const saveClientDetails = async (isDcpConflicted, isCreate, option) => {
        var clientFileId = ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) ?
            Number(searchParams.get("id")) : 0;
        var clientFileCrmId = (null == searchParams.get("crmId") || "" == searchParams.get("crmId")) ? null : searchParams.get("crmId");
        //var isCrmConflicted = null;

        var newClientDetails = {
            "ClientFileId": clientFileId,
            "clientFileCrmId": clientFileCrmId,
            "isCrmConflicted": isCrmConflicted,
            "isDcpConflicted": isDcpConflicted,
            "clientBasicInformation": {
                "clientBasicInformationId": clientBasicInfoComp.current.getClientBasicInfo().clientBasicInformationId,
                "firstName": clientBasicInfoComp.current.getClientBasicInfo().firstName,
                "middleName": clientBasicInfoComp.current.getClientBasicInfo().middleName,
                "lastName": clientBasicInfoComp.current.getClientBasicInfo().lastName,
                "dateOfBirth": clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth,
                "clientAlias1": clientBasicInfoComp.current.getClientBasicInfo().clientAlias1,
                "clientAlias2": clientBasicInfoComp.current.getClientBasicInfo().clientAlias2,
                "clientAlias3": clientBasicInfoComp.current.getClientBasicInfo().clientAlias3,
                "indigenousAncestryId": clientBasicInfoComp.current.getClientBasicInfo().indigenousAncestry?.indigenousAncestryId
            },
            "clientContactDetails": {
                "clientContactInfoId": clientContactInfoComp.current.getClientContactInfo().clientContactInfoId,
                "email": clientContactInfoComp.current.getClientContactInfo().email,
                "phoneNumber": clientContactInfoComp.current.getClientContactInfo().phoneNumber,
                "mobileNumber": clientContactInfoComp.current.getClientContactInfo().mobileNumber,
                "PreferredMethodOfContactId": clientContactInfoComp.current.getClientContactInfo().preferredMethodOfContact?.contactMethodId
            },
            "ClientFinancialInformation": {
                "clientFinancialInformationId": clientFinancialInfoComp.current.getClientFinancialInfo().clientFinancialInformationId,
                "financiallyEligible": clientFinancialInfoComp.current.getClientFinancialInfo().financiallyEligible != null ? Boolean(clientFinancialInfoComp.current.getClientFinancialInfo().financiallyEligible) : null,
                "familySize": clientFinancialInfoComp.current.getClientFinancialInfo().familySize != '' ? clientFinancialInfoComp.current.getClientFinancialInfo().familySize : null,
                "last12MonthsIncome": clientFinancialInfoComp.current.getClientFinancialInfo().last12MonthsIncome != '' ? clientFinancialInfoComp.current.getClientFinancialInfo().last12MonthsIncome : null,
                "last30DaysIncome": clientFinancialInfoComp.current.getClientFinancialInfo().last30DaysIncome != '' ? clientFinancialInfoComp.current.getClientFinancialInfo().last30DaysIncome : null,
                "totalEquityValue": clientFinancialInfoComp.current.getClientFinancialInfo().totalEquityValue != '' ? clientFinancialInfoComp.current.getClientFinancialInfo().totalEquityValue : null,
                "lastAssessmentDate": (null == clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate || "" == clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate) ? null : new Date(clientFinancialInfoComp.current.getClientFinancialInfo().lastAssessmentDate)
            }
        };
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    if (isCreate) {
                        CreateClientFile(newClientDetails, response.accessToken).then((data) => {
                            if (option == 0 && data) {
                                if (window.location.search) {
                                    navigate(window.location.pathname + window.location.search + `&id=${data}`);
                                }
                                else {
                                    navigate(window.location.pathname + window.location.search + `?id=${data}`);
                                }
                                window.location.reload();
                            }
                            else if (option == 1 && data) {
                                navigate("/");
                            }
                        });
                    } else {
                        UpdateClientFile(newClientDetails, response.accessToken).then((data) => {
                            if (option == 1 && response) {
                                navigate("/");
                            }
                        });
                    }
                }
            });
        }
    }

    const onCancelClicked = (event) => {

        switch (backPage) {
            case "searchresults":
                navigate(SEARCHRESULTSPAGEURL + `?${getParams()}`);
                break;
            case "clientfiledashboard":
                navigate(`/client-dashboard?id=${searchParams.get("id")}${getParams()}`);
                break;
            case "crmconflictdashboard":
                navigate(CRMREVIEWPAGEURL);
                break;
            case "crmconflictdashboardclientfiles":
                navigate(CRMCONFLICTDASHBOARDPAGEURL + `?${getParams()}`);
                break;
            case "clientfiledashboard":
                navigate(CLIENTFILEDASHBOARDEURL + "?id=" + searchParams.get("id"));
                break;
            default:
                navigate(CLIENTFILEDASHBOARDEURL + "?id=" + searchParams.get("id"));
                break;
        }
    };

    function getParams() {
        var queryParams = '';
        if (backPage == "clientfiledashboard") {
            queryParams = queryParams + "&page=serachresults";
        } else {
            queryParams = queryParams + "&page=clientfile";
        }
        if (crmId || firstName || middleName || lastName || dateOfBirth) {
            if (crmId != null) {
                queryParams = queryParams + `&crmId=${crmId}`;
            }
            if (firstName) {
                queryParams = `&firstname=${firstName}`;
            }
            if (middleName) {
                queryParams = `&middlename=${middleName}`;
            }
            if (lastName) {
                queryParams = queryParams + `&lastname=${lastName}`;
            }
            if (dateOfBirth) {
                queryParams = queryParams + `&dateofbirth=${dateOfBirth}`;
            }
        }
        return queryParams;
    };

    const validateInformation = () => {
        if (
            clientBasicInfoComp.current.getClientBasicInfo().firstName && !clientBasicInfoComp.current.getClientBasicInfo().firstNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().lastName && !clientBasicInfoComp.current.getClientBasicInfo().lastNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth && !clientBasicInfoComp.current.getClientBasicInfo().dateOfBirthValidity) {
            return true;
        }
        return false;
    }

    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetClientDetailNotes?recordId=' + Number(searchParams.get("id")) + "&noteType=2" + '&onlyLastTwoYears=true';

            fetch(uri, options)
                .then(response => response.json())
                .then(setClientDetailNotes);
        };

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    const [clientReferrals, setClientReferrals] = useState([]);

    useEffect(() => {

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ReferralService/GetClientReferrals?clientFileId=' + Number(searchParams.get("id")) + '&onlyLastTwoYears=true';

            fetch(uri, options)
                .then(response => response.json())
                .then(setClientReferrals);
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchData(response.accessToken);
                }
            });
        }

    }, [account, instance]);

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setContactMethods(data.contactMethods);
                    setIndigenousAncestries(data.indigenousAncestries);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);


    useEffect(() => {
        const fetchInitialData = async (token) => {
            await GetAllAgencyRequest(Number(searchParams.get("id")), token).then((data) => {
                setAgencyRequests(data);
            });
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await fetchInitialData(response.accessToken);
                }
            });
        }


    }, []);

    useEffect(() => {
        const fetchJPBailOutcomeData = async (token) => {
            await GetAllJPBailOutcomes(Number(searchParams.get("id")), token).then((data) => {
                setJpBailOutcomes(data);
            });
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchJPBailOutcomeData(response.accessToken);
                }
            });
        }


    }, [account, instance]);

    useEffect(() => {
        const fetchInteractionData = async (token) => {
            await GetAllInteractions(Number(searchParams.get("id")), token).then((data) => {
                setInteractions(data);
            });
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    await fetchInteractionData(response.accessToken);
                }
            });
        }


    }, [account, instance]);

    const onDeleteClicked = (event) => {

        var result = 1;

        const DeleteData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'DELETE',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/DeleteClientFile?id=' + searchParams.get("id");

            result = fetch(uri, options)
                .then(response => {
                    if (response.ok) {
                        navigate("/");
                    } else {
                        throw new Error('Something went wrong ...');
                    }
                })
                .then()
                .catch(error => toast.error("Delete Client File was not succesful.", { position: "top-center", autoClose: 5000, theme: "colored" }));
            //alert("Delete Client File was not succesful."));
        };


        const thereAreNoSearchParameters = (null != searchParams.get("id"));
        const thereIsAtLeastOneSearchParameter = (Number(searchParams.get("id")) > 0);

        if (thereAreNoSearchParameters && thereIsAtLeastOneSearchParameter) {

            let text;

            const confirmationMessage = "Are you sure you want to delete the Client File and all the details (Notes, Referrals, Etc.) associated with it?";

            // if (window.confirm(confirmationMessage) == true) {

            //     text = "You pressed OK!";

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken);
                    }
                });
            }
            // } else {
            //     text = "You canceled!";
            // }
        }

        return result;
    }

    const canSave = () => {
        var result = false;

        if ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) {
            result = canUpdate("client-file", null);
        } else {
            result = canCreate("client-file", null);
        }

        return result;
    }

    const canDeleteClientFile = () => {
        return ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0) && canDelete('client-file', null));
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);
    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };
    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkClientFileAsConflicted(Number(searchParams.get("id")), response.accessToken).then(() => {
                        navigate("/");
                    });
                }
            });
        }
    }

    const isCreateNewFromSearch = () => {
        var clientFileId = Number(searchParams.get("id"));
        var clientFileCrmId = searchParams.get("crmId");
        return !((clientFileCrmId && clientFileCrmId != "") || (clientFileId && clientFileId > 0));
    }

    const canMarkAsConflicted = () => {
        var result = false;

        if ((canUpdate("client-file", null)) && (Number(searchParams.get("id")) && Number(searchParams.get("id")) > 0)) {
            if (isLinkedClient) {
                result = true;
            }
            else if (hasDcpDuplicateClientFiles) {
                result = true;
            }
        }

        return result;
    }

    function Save() {
       
        onSaveClicked(0);
    }

    function SaveAndClose() {
       
        onSaveClicked(1);
    }

    if (canRead("client-file", null)) {
        if (isDataReady) {
            return (
                <Grid container style={{ padding: "5px 10px 0px 10px", margin: "80px 0px 0px 215px" }}>
                    <SubHeader
                        title="Client File Details"
                        menuItemOneText="Delete Client File"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteClientFile()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText="Mark for Review"
                        onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                        isMenuItemTwoVisible={canMarkAsConflicted()}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}
                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={canSave()}
                        isNavItemOneDisabled={saveClicked}
                        navItemTwoText="Save & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={canSave()}
                        isNavItemTwoDisabled={saveClicked}
                        navItemThreeText="Cancel"
                        onNavItemThreeClicked={onCancelClicked}
                        isNavItemThreeVisible={canSave()}
                        isNavItemThreeDisabled={false}
                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />
                    <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                        message={"Do you really want to mark the CF-" + Number(searchParams.get("id")) + " as conficted?"}
                        onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />
                    <StyledDialog title="Delete Client File"
                        open={openDeleteDialog}
                        onClose={onDeleteClickClose}
                        message={"Do you really want to delete the Client File :" + Number(searchParams.get("id")) + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked}
                        confirmationText="Delete" />

                    <StyledDialog title="Client File Already Exists!"
                        open={openCreateDuplicateClientConfirmDialog}
                        onClose={onCreateDuplicateClientConfirmClickClose}
                        message={"There is already a DC Portal Client File with the same First and Last names and date of birth. Are you sure you want to create a new file for this client?"}
                        onConfirmed={onCreateDuplicateClientConfirmClicked}
                        confirmationText="Create Client" isCancelRequired={true} />

                    <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} />
                    <Grid item xs={12} md={12} style={{ marginBottom: "30px" }}>
                        {(isCrmConflicted || isDcpConflicted) && (<>
                            <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                        </>)}
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", borderRadius: "8px" }}>
                                <Grid item xs={12} md={12} style={{
                                    textAlign: "left", font: "normal normal bold 17px/22px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "10px 0px 0px 20px"
                                }}>Client Information</Grid>
                                <ClientBasicInfo clientFile={crmData} isReadOnly={(crmData?.clientFileCrmId != null && crmData?.clientFileCrmId != "") ? true : (clientFileCrmIdById !== "" ? true : false)} clientFileCrmId={searchParams.get("crmId")} firstName={(isCreateNewFromSearch) ? firstName : ""} middleName={(isCreateNewFromSearch) ? middleName : ""} lastName={(isCreateNewFromSearch) ? lastName : ""} dateOfBirth={(isCreateNewFromSearch) ? dateOfBirth : ""} clientFileId={searchParams.get("id")} ref={clientBasicInfoComp} indigenousAncestries={indigenousAncestries} isAliasVisible={true} fromViewDeatils />
                                <ClientContactInfo clientFile={crmData} clientFileCrmId={searchParams.get("crmId")} clientFileId={searchParams.get("id")} ref={clientContactInfoComp} contactMethods={contactMethods} fromViewDeatils />
                                <ClientFinancialInfo clientFile={crmData} clientFileCrmId={searchParams.get("crmId")} isCRMOnly={true} clientFileId={searchParams.get("id")} ref={clientFinancialInfoComp} fromViewDeatils />
                            </Grid>
                        </div>
                        <div id="scrollByClickedItem2" style={{ scrollMarginTop: "169px" }}><NoteTable title="Client Notes" notes={clientDetailNotes} clientFileId={Number(searchParams.get("id"))} relatedId={Number(searchParams.get("id"))} noteTypeId={2} isDisable={false} pageId={"clientfile"} pageParams={`${getParams()}`} /></div>
                        <div id="scrollByClickedItem3" style={{ scrollMarginTop: "169px" }}><InteractionList title="Client Interactions" interactions={interactions} clientFileId={Number(searchParams.get("id"))} /></div>
                        <div id="scrollByClickedItem4" style={{ scrollMarginTop: "169px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", margin: "10px 0px 10px 0px", backgroundColor: "#ececec", borderRadius: "8px" }}>
                                {/* <DocketsLegalIssues dataGridtitle="Dockets & Legal Issues" hideHeader legalIssues={legalIssues} clientFileId={Number(searchParams.get("id"))} /> */}
                                <CertificateDataGrid dataGridtitle="Certificate" hideHeader certificates={certificates} />
                                {/* <CertificateOutcome dataGridtitle="Certificate Outcome" hideHeader certificatesOutcomes={certificatesOutcomes} /> */}
                                <ServiceDecision dataGridtitle="Service Decision" hideHeader serviceDecisions={serviceDecisions} clientFileId={Number(searchParams.get("id"))} />
                            </Grid>
                        </div>
                        <div id="scrollByClickedItem5" style={{ scrollMarginTop: "169px" }}><JPBailOutcomeList title="JP Bail Outcomes" jpBailOutcomes={jpBailOutcomes} clientFileId={Number(searchParams.get("id"))} /></div>
                        <div id="scrollByClickedItem6" style={{ scrollMarginTop: "169px" }}>
                            <Grid container style={{ border: "1px solid #7F8284", borderRadius: "8px" }}>
                                <ReferralTable title="Client Referrals" fromViewDeatils referrals={clientReferrals} clientFileId={Number(searchParams.get("id"))} />
                                <AgencyRequestList title="Agency Requests" fromViewDeatils agencyRequests={agencyRequests} clientFileId={Number(searchParams.get("id"))} />
                                <ClientGatewayTable title="Client Gateway Details" clientGatewayDetails={clientGatewayDetails} clientFileId={Number(searchParams.get("id"))} />
                            </Grid>

                        </div>

                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Client Details
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view this details **
                </Grid>
            </Grid>
        );
    }
}

export default ClientFile;