import { Button } from "react-bootstrap";
import "./referrals.css";
import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import DataTable from '../components/DataTable';
import { useDateFormat } from "../hooks/useDateFormat";
import { useNavigate, useSearchParams } from "react-router-dom";
import { tokenRequest } from "../auth/authConfig";
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { useClientGatewayAccessValidation } from "../hooks/useClientGatewayAccessValidation";


function ReferralTable(props) {
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [formatDate] = useDateFormat();
    const navigate = useNavigate();
    const [hasAccess] = useClientGatewayAccessValidation();
    const [hasPermissionForClientGateway, setHasPermissionForClientGateway] = useState(false);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(() => {
        const FetchLookupData = async (token, clientFileId) => {

            setHasPermissionForClientGateway(await hasAccess(token, clientFileId));
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken, props.clientFileId);

                }
            });
        }
    }, []);


    const columnHeaders = [{ field: 'id', width: 60, headerName: 'ID', renderCell: true, editable: false, hide: true },
    { field: 'DocketNumber', width: 170, headerName: 'Docket Number', editable: false },
    { field: 'UpcomingCourtDate', width: 180, headerName: 'Upcoming Court Date', editable: false },
    { field: 'DutyCounselType', width: 800, headerName: 'Duty Counsel Type', editable: false },
    ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: columnHeader.renderCell ? (params) => (<a href={'/referral?id=' + params.value.split("-")[1] + '&clientFileId=' + props.clientFileId}>{params.value}</a>) : null,
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });

    const rows = [];
    if (props.referrals && props.referrals.length > 0) {
        props.referrals.forEach((referral) => {
            if (canRead("client-referral", null)) {
                rows.push(
                    {
                        id: `R-${referral.referralId}`,
                        DocketNumber: referral.docketNumber,
                        UpcomingCourtDate: formatDate(referral.upcomingCourtDate),
                        DutyCounselType: referral.dutyCounselType.label,
                    },
                );
            } else {
                return (<table><tbody><tr><td colspan="4">You are not authorized to read this record.</td></tr></tbody></table>);
            };
        });
    };

    const onRowClicked = (item) => {
        navigate('/referral?id=' + item.row.id.split("-")[1] + '&clientFileId=' + props.clientFileId);
    };

    if (props.clientFileId && props.clientFileId > 0) {
        return (
            <Grid container style={{ padding: "10px 20px 10px 20px", border: props.fromViewDeatils ? null : "1px solid #7F8284", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
                }}>{props.title}
                    {canCreate("client-referral", null) && !hasPermissionForClientGateway &&  <Button style={{ font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "40px" }}
                        size="sm" variant={(canCreate("client-referral", null)) ? "primary" : "secondary"} disabled={(canCreate("client-referral", null)) ? "" : "disabled"} className="ml-auto" href={'/referral?clientFileId=' + props.clientFileId}>New Referral</Button>
            }</Grid>
                <DataTable columns={columns} rows={rows} onRowDoubleClick={onRowClicked}/>
            </Grid>
        );
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                        }}>{props.title}
                        </Grid>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1",
                        }}> ** Save the new Client File to add new Referral **
                        </Grid>            
            </Grid>
        );
    }
}

export default ReferralTable;
