import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useState } from 'react';

export default function InfoCard({setKey,title,subtitle,count,cardID, isClicked}) {
    const [titleColor,setTitleColor]= useState( isClicked ? "white" : "black");
    const [textColor,setTextColor]= useState(isClicked ? "white" : "#d41f44");
    const [isMouseOver,setIsMouseOver]= useState(false);
  return (
  <Card onClick={()=>{ setKey(cardID);}} sx={{ width: "24vw", minWidth: "240px", maxWidth: "340px", borderRadius :"6px" , 
  background: isClicked ? "#d41f44" : "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 2px 2px #60606029",  opacity: 1,
  '&:hover':  {cursor: "pointer", backgroundColor : "#d41f44"} }}>
    <Grid container spacing={0} style={{ opacity: "1",padding: "20px 20px 10px 25px",  } } 
    onMouseOver ={()=> {setIsMouseOver(true); setTitleColor("white"); setTextColor("white") ; }}
    onMouseOut ={()=> {setIsMouseOver(false); setTitleColor(isClicked ? "white":"black" ); setTextColor(isClicked ? "white":"#d41f44");}}>
        <Grid item xs={12} md={12} >
            <Typography gutterBottom variant="h5" component="div" style={{ color: (isClicked || isMouseOver) ? titleColor : "black",fontWeight: "bold"}}>
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ color:textColor , marginTop: "-10px"}}>
                {subtitle}
            </Typography>
         </Grid>
         <Grid item xs={12} md={12} style={{
        textAlign: "right",
        font: "normal normal bold 80px/97px Helvetica Neue",
        letterSpacing: "0px",
        color: (isClicked || isMouseOver) ? textColor : "#d41f44" ,
        opacity: "1",
        minHeight: "100px",
        position:" relative",
        margin: "10px 0px 0px 0px"  
        }}>
            {count}           
        </Grid>
    </Grid>     
    </Card>
  );
  
}