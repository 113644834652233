import React from 'react';
import { IoClose } from "react-icons/io5";
import { Grid, DialogTitle, IconButton } from '@mui/material';
import ClientBasicInfo from "../../client-basic-info";
import ClientContactInfo from "../../client-contact-info";
import ClientFinancialInfo from "../../client-financial-info";
import StyledButton from '../../components/elements/StyledButton';

function StyledDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, font: 'normal normal normal 17px/20px Franklin Gothic Medium' , minHeight:"72px"}} {...other}>
      {children}
    </DialogTitle>
  );
};

export default function SelectedDataRow({ onClose, title, clientFileId, clientBasicInfoComp, clientContactInfoComp, clientFinancialInfoComp, contactMethods, indigenousAncestries, clientFile, isResolveCrmConflicts, isFromSearch, actionButtonTitle, actionButtonAction, actionButtonVisible }) {
  return (
    <Grid container sx={{ width: "100%", borderRadius: "8px", border: "2px solid #E8E8E8" }}>
      <Grid item xs={9} md={9}>
        <StyledDialogTitle id="customizedf-title" onClose={onClose}>
          {title}
        </StyledDialogTitle>
      </Grid>
          <Grid item xs={2} md={2} align="right" style={{ paddingTop: "10px" }}>
              {isFromSearch && actionButtonVisible && <StyledButton variant={"outlined"} color="error" onClick={actionButtonAction} >{actionButtonTitle}</StyledButton>}
      </Grid>
      <Grid item xs={1} md={1}  align="right" style={{ padding: "8px 20px 0px 0px" }} >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: "#d32f2f" }}>
          <IoClose />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12} style={{borderBottom: "2px solid #E8E8E8"}}/>
      <ClientBasicInfo clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={true} isResolveCrmConflicts={isResolveCrmConflicts} isReadOnly={true} clientFile={clientFile} indigenousAncestries={indigenousAncestries}/>
      <ClientContactInfo clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} isResolveCrmConflicts={isResolveCrmConflicts} isReadOnly={true} clientFile={clientFile}/>
      <ClientFinancialInfo isCRMOnly={true} clientFileId={clientFileId} ref={clientFinancialInfoComp} isResolveCrmConflicts={isResolveCrmConflicts} clientFile={clientFile}/>
    </Grid> 
  );
};