import React from "react";
import { StyledLoader, Container } from "../styled/Loader.styled";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function BackDrop() {
    return (
      <Backdrop
        sx={{color: "#fff", zIndex: '101' }}
        open={true}
        
      >
        <CircularProgress sx={{ color:"#FFFF" }}/>
      </Backdrop>
    );
  };

  export function Loader() {
    return (
      <Container>
        <StyledLoader />
      </Container>
    );
  };

 