import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../hooks/useValidation";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Grid } from '@mui/material';
import DataTable from '../../components/DataTable';
import { tokenRequest } from "../../auth/authConfig";
import { SearchClients } from "../../services/client-file.service";
import TextField from "../../components/TextField";
import CustomDatePicker from '../../components/DatePicker';
import StyledButton from '../../components/elements/StyledButton';
import StyledDialog from '../../components/Dialog';
import SelectedDataRow from '../../components/elements/SelectedDataRow';
import { FetchLookupData, CreateClientFile, UpdateClientFile, LinkClientFile, ReLinkClientFile, UnLinkClientFile, CheckDuplicateClients, OpenClient } from "../../services/client-file.service";
import {format} from 'date-fns';
import { toast } from "react-toastify";

function SearchClientFiles() {

    const [canRead] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isValid] = useValidation();
    const navigate = useNavigate();

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstName, setFirstName] = useState((searchParams && null != searchParams.get("firstname")) ? searchParams.get("firstname"): "");
    const [middleName, setMiddleName] = useState((searchParams && null != searchParams.get("middlename")) ? searchParams.get("middlename") : "");
    const [lastName, setLastName] = useState((searchParams && null != searchParams.get("lastname")) ? searchParams.get("lastname") : "");
    const [dateOfBirth, setDateOfBirth] = useState(searchParams ? searchParams.get("dateofbirth"): null);
    const [isEmptyResult, setIsEmptyResult] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [queryParams, setQueryParams] = useState("");
    const [selectedSearchResultRow, setSelectedSearchResultRow] = useState(null);
    const [isClientIdOnlyRecord, setIsClientIdOnlyRecord] = useState(false);
    const [isCrmIdOnlyRecord, setIsCrmIdOnlyRecord] = useState(false);
    const [isLinkedRecord, setIsLinkedRecord] = useState(false);
    const [selectedRecordDcpId, setSelectedRecordDcpId] = useState(0);
    const [selectedRecordCrmId, setSelectedRecordCrmId] = useState("");
    const [openPreviewGrid, setOpenPreviewGrid] = useState(false);
    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();
    const [actionButtonTitle, setActionButtonTitle] = useState("Open Client File");
    const [actionButtonVisible, setActionButtonVisible] = useState(true);
    const[dateOfBirthError, setDateOfBirthError] = useState(false);
    const[dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [middleNameError, setMiddleNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        LoadInitialData();
    },[]);

    useEffect(() => {
        LoadInitialData();
    }, [instance]);

    useEffect(() => {
        if (!dateOfBirthError && dateOfBirth != null) setIsEmptyResult(false);
    },[firstName, middleName, lastName, dateOfBirth]);

    const onSetQueryParams = () => { 
        var qParams = "";
        if (firstName || middleName || lastName || dateOfBirth) {
            if (firstName) {
                qParams = `&firstname=${firstName}`;
            }
             if (middleName) {
                 qParams = qParams + `&middlename=${middleName}`;
             }
            if (lastName) {
                qParams = qParams + `&lastname=${lastName}`;
            }
            if (dateOfBirth) {
                qParams = qParams + `&dateofbirth=${dateOfBirth}`;
            }
        }
        setQueryParams(qParams);
    };


    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Portal Id', width: 90, field: 'clientId', editable: false, hide: true },
        { headerName: 'CRM Id', width: 300, field: 'crmClientId', editable: false, hide: true },
        { headerName: 'CRM Id', width: 300, field: 'crmId', editable: false, hide: true },
        { headerName: 'Record Type', width: 120, field: 'recordType', editable: false },
        { headerName: 'First Name', width: 170, field: 'firstName', editable: false },
        { headerName: 'Middle Name', width: 170, field: 'middleName', editable: false },
        { headerName: 'Last Name', width: 170, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 150, field: 'dateOfBirth', renderDate: true, editable: false },
        { headerName: 'Alias 1', width: 170, field: 'clientAlias1', editable: false },
        { headerName: 'Alias 2', width: 170, field: 'clientAlias2', editable: false },
        { headerName: 'Alias 3', width: 170, field: 'clientAlias3', editable: false },
        { headerName: 'Indigenous Ancestry', width: 170, field: 'indigenousAncestry', editable: false, hide: true },
        { headerName: 'Home Phone', width: 150, field: 'homePhoneNumber', editable: false, hide: true },
        { headerName: 'Mobile Phone', width: 150, field: 'mobilePhoneNumber', editable: false, hide: true },
        { headerName: 'Email', width: 250, field: 'email', editable: false },
        { headerName: 'Method of Contact Id', width: 170, field: 'preferredMethodOfContactId', editable: false, hide: true },
        { headerName: 'Indigenous Ancestry Id', width: 170, field: 'indigenousAncestryId', editable: false, hide: true },
        { headerName: 'Unique Id', width: 120, field: 'id', editable: false, hide: true }];

        onSetQueryParams();
        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: columnHeader.renderDate ? (params) => <>{(params.value != null && params.value != "") ? params.value : null}  </> : null,
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                    hide: columnHeader.hide
                },
            );
        });

        setGridColumns(columns);

    }


    function LoadInitialData() {
        if (firstName || middleName || lastName || dateOfBirth) {
            PerformSearchClickDetails();
        }
        else {
            setGridRows([]);
            setInitialGridColumns();
        }
    }

    function getSearchResultsFromCRMRowStyled(params) {
        if (params?.row.recordOrigin == 1) {
            return `data-grid-search-results-crm-row-style`
        }
        return null;
    };


    //#region OnChangeEvents
    const onFirstNameChanged = (event) => { 
        if (event.target.value.length === 1) {setFirstNameError(true);
        } else {
            setFirstNameError(false);
            //setLastNameError(false);
        }
        setFirstName(event.target.value);
        
    };

    const onMiddleNameChanged = (event) => {
        if (event.target.value.length === 1) {
            setMiddleNameError(true);
        } else {
            setMiddleNameError(false);
        }
        setMiddleName(event.target.value);

    };

    const onLastNameChanged = (event) => {
        if (event.target.value.length === 1) {setLastNameError(true);
        } else {
            setLastNameError(false);
            //setFirstNameError(false);
            
        }  
        setLastName(event.target.value); 
    };

    const onBirthDateChanged = (date) => {
        setDateOfBirth(date);
        setDateOfBirthError(false);
    };
    const today = new Date();
    const maxBirthDate = new Date(today.setDate(today.getDate() - 1));

    const onDateOfBirthChange = (event) => {
        let eventValue = event.target.value;
        let showEventValue= eventValue.replace(/ /g,'').replace(/\-/g,'').replace(/\//g,'');
        let eventValueDate = (new Date(showEventValue)).toString();
        const checkDateValidity = (date) => {
            if((new Date(date)).toString() === "Invalid Date"){
                setDateOfBirthError(true);
                setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy."); 
            } else if (new Date(date) > maxBirthDate) {
                setDateOfBirthError(true);
                setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy.");
            } else {
                setDateOfBirthError(false);
                setDateOfBirth(date);
            }
        };
        if (showEventValue.length === 9) {
            checkDateValidity(eventValueDate);
        } else if (showEventValue.length === 8) {
            let inputArray = [...showEventValue];
            var formattedDate= "";
            formattedDate = inputArray.slice(4,8).join('') + "-" + inputArray.slice(2,4).join('') + "-" + inputArray.slice(0,2).join('');
            let formattedNewDate = new Date(formattedDate);
            let isValidDate = (new Date(formattedNewDate.setDate(formattedNewDate.getDate() +1 ))).toString();
            checkDateValidity(isValidDate);
        } else if (eventValue.length === 0) {
            setDateOfBirthError(false);
        } else {
            setDateOfBirthError(true);
            setDateOfBirthErrorMessage("Invalid date format. Date format is dd-Mmm-yyyy.");
        }
    };

    const handleOnRowClick = (params) => { 
        setSelectedSearchResultRow(params.row); 
        setOpenPreviewGrid(true);
        setIsClientIdOnlyRecord(false);
        setIsCrmIdOnlyRecord(false);

        if (params.row?.clientId !== "" && params.row?.crmId === "") {
            setSelectedRecordDcpId(params.row?.clientId);
            setSelectedRecordCrmId("");
            setIsClientIdOnlyRecord(true);
        } else if (params.row?.clientId === "" && params.row?.crmId !== "") {
            setSelectedRecordDcpId(0);
            setSelectedRecordCrmId(params.row?.crmId);
            setIsCrmIdOnlyRecord(true);
        } else if (params.row?.clientId !== "" && params.row?.crmId !== "") {
            setSelectedRecordDcpId(params.row?.clientId);
            setSelectedRecordCrmId(params.row?.crmId);
            setIsLinkedRecord(true);
        };
        handleAutoScroll();
    };

    const handleAutoScroll = () => {
        const element = document.getElementById('scrollByClickedItem1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onCreateClientFileClicked = () => {
        navigate(`/client-file?page=searchresults${queryParams}`);
    };

    const onSearchClicked = async () => {
        setOpenPreviewGrid(false);
        await PerformSearchClickDetails();
    };

    const onEnterKeyPress = (e) => {
        if (e.key === "Enter" && (dateOfBirth != null) && !dateOfBirthError && !firstNameError && !middleNameError  && !lastNameError) {
            e.preventDefault();
            onSearchClicked();
        }
    }

    const getSelectedDcpRecord = (recordDcpId) => {
        return recordDcpId == selectedRecordDcpId;
    }

    const getSelectedCrmRecord = (recordCrmId) => {
        return recordCrmId == selectedRecordCrmId;
    }

    const getContactMethod = (crmId) => {
        var result = 1;
        if ((null != contactMethods) && (!isNaN(crmId))) {
            var contactMethod = contactMethods.find(c => c.contactMethodCrmValue == crmId);
            if (null != contactMethod) {
                result = contactMethod.contactMethodId
            }
        }

        return result
    }

    const getIndigenousAncestry = (crmId) => {
        var result = 1;
        if ((null != indigenousAncestries) && (!isNaN(crmId))) {
            var indigenousAncestry = indigenousAncestries.find(c => c.indigenousAncestryCrmValue == crmId);
            if (null != indigenousAncestry) {
                result = indigenousAncestry.indigenousAncestryId
            }
        }
        return result
    }

    const compareSearchResult = (a, b) => {
        if (Date.parse(a.clientBasicInformation.createdOnInCRM) < Date.parse(b.clientBasicInformation.createdOnInCRM)) {
            return -1;
        }
        if (Date.parse(a.clientBasicInformation.createdOnInCRM) > Date.parse(b.clientBasicInformation.createdOnInCRM)) {
            return 1;
        }
        return 0;
    }

    const getMatchingCrmRecord = (selectedDcpId) => {
        var result = null;
        var dcpOnlyRecord = gridRows.find(r => r.clientFileId == selectedDcpId.replace("CF-", ""));
        if (null != dcpOnlyRecord) {
            var crmRecordsToMatch = gridRows.filter(r => (r.clientFileId == "" || r.clientFileId == null || r.clientFileId == 0))
            if (null != crmRecordsToMatch && crmRecordsToMatch.length > 0) {
                var matchingCrmRecords = crmRecordsToMatch.filter(c => c.clientBasicInformation.firstName == dcpOnlyRecord.clientBasicInformation.firstName && c.clientBasicInformation.middleName == dcpOnlyRecord.clientBasicInformation.middleName 
                    && c.clientBasicInformation.lastName == dcpOnlyRecord.clientBasicInformation.lastName && new Date(c.clientBasicInformation.dateOfBirth).setHours(0, 0, 0, 0) == new Date(dcpOnlyRecord.clientBasicInformation.dateOfBirth).setHours(0, 0, 0, 0));
                if (null != matchingCrmRecords && matchingCrmRecords.length > 0) {
                    if (matchingCrmRecords.length == 1) {
                        result = matchingCrmRecords[0];
                        result.isCrmConflicted = false;
                    } else {
                        // Get Latest CRM Record
                        var sortedMatchingCrmRecords = matchingCrmRecords.sort(compareSearchResult)
                        result = sortedMatchingCrmRecords[(matchingCrmRecords.length - 1)];
                        result.isCrmConflicted = true;
                    }
                }
            }
        }
        return result;
    }

    const onSelectedRecordActionClick = () => {
        var selectedRecord = null;
        var dcpOnlyClientFileId = 0;
        var crmOnlyClientFileCrmId = "";
        var linkedDcpClientFileId = 0;

        if (isClientIdOnlyRecord) {
            selectedRecord = gridRows.find(r => r.clientFileId == selectedRecordDcpId.replace("CF-", ""));
            dcpOnlyClientFileId = selectedRecord.clientFileId;

        } else if (isCrmIdOnlyRecord) {
            selectedRecord = gridRows.find(r => r.clientFileCrmId == selectedRecordCrmId);
            crmOnlyClientFileCrmId = selectedRecord.clientFileCrmId;

        } else if (isLinkedRecord) {
            selectedRecord = gridRows.find(r => r.clientFileCrmId == selectedRecordCrmId);
            linkedDcpClientFileId = selectedRecord.clientFileId;
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    OpenClient(dcpOnlyClientFileId, crmOnlyClientFileCrmId, linkedDcpClientFileId, response.accessToken).then((data) => {
                        if (data) {
                            if (data.checkCreateDuplicateClientFile) {
                                onCreateDuplicateClientConfirmClickOpen();
                            } else {
                                if (null != data.clientFile.clientFileId && "" != data.clientFile.clientFileId && data.clientFile.clientFileId > 0 && null != data.clientFile.clientFileCrmId && "" != data.clientFile.clientFileCrmId) {
                                    navigate(`/client-dashboard?id=${data.clientFile.clientFileId}&crmId=${data.clientFile.clientFileCrmId}&page=searchresults${queryParams}`);
                                }
                                else if ((null != data.clientFile.clientFileId && "" != data.clientFile.clientFileId && data.clientFile.clientFileId > 0) && (null == data.clientFile.clientFileCrmId || "" == data.clientFile.clientFileCrmId)) {
                                    navigate(`/client-dashboard?id=${data.clientFile.clientFileId}&page=searchresults${queryParams}`);
                                }
                                else if (null != data.clientFile.clientFileCrmId && "" != data.clientFile.clientFileCrmId) {
                                    navigate(`/client-dashboard?crmId=${data.clientFile.clientFileCrmId}&page=searchresults${queryParams}`);
                                }
                            }
                        }
                    });
                }
            });
        }
    }

    async function PerformSearchClickDetails() {
        if (firstName || middleName || lastName || dateOfBirth) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        await FetchLookupData(response.accessToken).then((data) => {
                            if (data)
                                setContactMethods(data.contactMethods);
                                setIndigenousAncestries(data.indigenousAncestries);
                        });
                        //Search Value
                        await SearchClients(firstName, middleName, lastName, (dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : ""), response.accessToken).then((data) => {
                            if (data != null && data.length > 0 && data[0] != null) {
                                if (data[0].recordOrigin != null && data[0].recordOrigin == 0) {
                                    onSetQueryParams();
                                    setIsEmptyResult(true);
                                    setGridRows([]);
                                } else {
                                    setIsEmptyResult(false);
                                    setGridRows(data);
                                }
                                setInitialGridColumns();
                            }
                        });
                    }
                });
            }
        }
        else {
            LoadInitialData();
        }
        setIsSearched(true);
    }

    const onCreateDuplicateClientConfirmClicked = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        var crmRecord = gridRows.find(r => r.clientFileCrmId == selectedRecordCrmId);
        var newRecord = {

            "ClientFileId": 0,
            "clientFileCrmId": crmRecord.clientFileCrmId,
            "isCrmConflicted": false,
            "isDcpConflicted": true,
            "clientBasicInformation": {
                "clientBasicInformationId": crmRecord.clientBasicInformation.clientBasicInformationId,
                "firstName": crmRecord.clientBasicInformation.firstName,
                "middleName" : crmRecord.clientBasicInformation.middleName,
                "lastName": crmRecord.clientBasicInformation.lastName,
                "dateOfBirth": crmRecord.clientBasicInformation.dateOfBirth,
                "clientAlias1": crmRecord.clientBasicInformation.clientAlias1,
                "clientAlias2": crmRecord.clientBasicInformation.clientAlias2,
                "clientAlias3": crmRecord.clientBasicInformation.clientAlias3,
                "indigenousAncestryId": getIndigenousAncestry(Number(crmRecord.clientBasicInformation.indigenousAncestry?.indigenousAncestryCrmValue)),
                "createdOnInCRM": crmRecord.clientBasicInformation.createdOnInCRM
            },
            "clientContactDetails": {
                "clientContactInfoId": crmRecord.clientContactDetails.clientContactInfoId,
                "email": crmRecord.clientContactDetails.email,
                "phoneNumber": crmRecord.clientContactDetails.phoneNumber,
                "mobileNumber": crmRecord.clientContactDetails.mobileNumber,
                "PreferredMethodOfContactId": getContactMethod(Number(crmRecord.clientContactDetails.preferredMethodOfContact?.contactMethodCrmValue))
            },
            "ClientFinancialInformation": {
                "clientFinancialInformationId": crmRecord.clientFinancialInformation.clientFinancialInformationId,
                "financiallyEligible": Boolean(crmRecord.clientFinancialInformation.financiallyEligible),
                "familySize": crmRecord.clientFinancialInformation.familySize != '' ? crmRecord.clientFinancialInformation.familySize : null,
                "last12MonthsIncome": crmRecord.clientFinancialInformation.last12MonthsIncome != '' ? crmRecord.clientFinancialInformation.last12MonthsIncome : null,
                "last30DaysIncome": crmRecord.clientFinancialInformation.last30DaysIncome != '' ? crmRecord.clientFinancialInformation.last30DaysIncome : null,
                "totalEquityValue": crmRecord.clientFinancialInformation.totalEquityValue != '' ? crmRecord.clientFinancialInformation.totalEquityValue : null,
                "lastAssessmentDate": new Date(crmRecord.clientFinancialInformation.lastAssessmentDate)
            }
        };

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    CreateClientFile(newRecord, response.accessToken).then((data) => {
                        if (data && Number(data) && Number(data) > 0) {
                            // Navigate to Show the Newly Created Record from CRM Data (Already Linked).
                            navigate(`/client-file?crmId=${selectedRecordCrmId}&id=${Number(data)}&page=searchresults${queryParams}`);
                        }
                    });
                }
            });
        }
    }

    const [openCreateDuplicateClientConfirmDialog, setOpenCreateDuplicateClientConfirmDialog] = useState(false);

    const onCreateDuplicateClientConfirmClickOpen = () => {
        setOpenCreateDuplicateClientConfirmDialog(true);
    };

    const onCreateDuplicateClientConfirmClickClose = () => {
        setOpenCreateDuplicateClientConfirmDialog(false);
        // Create a DCP Conflicted Client File Aborted
        toast.success("Create Client File aborted.", { position: "top-center", autoClose: 5000, theme: "colored" });
    };

    return (
        <Grid container direction="row" alignItems="flex-start" style={{
            padding: "0px 20px 0px 20px",
            background: "#F0F0F0 0% 0% no-repeat padding-box"
        }} >
            <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 32px/39px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 20px 10px"
            }}>Search Client Files</Grid>
            <Grid item xs={12} md={3} style={{ margin: "10px 0px 10px 0px" }}>
            <div style={{maxWidth: "275px"}}>
                <CustomDatePicker label="Date of Birth" placeholder="dd-Mmm-yyyy" maxDate={maxBirthDate} required={true}
                    error={dateOfBirthError ? true : null}
                    value={dateOfBirth}
                    handleOnChange={onBirthDateChanged} 
                    onChange={onDateOfBirthChange}
                    onKeyDown={onEnterKeyPress}
                    helperText={dateOfBirthError ? dateOfBirthErrorMessage : null}/>
            </div>
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "10px 0px 10px 0px" }}>
                <TextField label="First Name" placeholder="First Name"
                    error={firstNameError ? true : null}
                    value={firstName}
                    handleChange={onFirstNameChanged} 
                    onKeyDown={onEnterKeyPress}
                    helperText={firstNameError ? "Minimum two characters are required" : null}/>
            </Grid>
             <Grid item xs={12} md={3} style={{ margin: "10px 0px 10px 0px" }}>
                <TextField label="Middle Name" placeholder="Middle Name"
                    error={middleNameError ? true : null}
                    value={middleName}
                    handleChange={onMiddleNameChanged} 
                    onKeyDown={onEnterKeyPress}
                    helperText={middleNameError ? "Minimum two characters are required" : null}/>
            </Grid> 
            <Grid item xs={12} md={3} style={{ margin: "10px 0px 10px 0px" }}>
                <TextField label="Last Name" placeholder="Last Name"
                    error={lastNameError ? true : null}
                    value={lastName}
                    handleChange={onLastNameChanged} 
                    onKeyDown={onEnterKeyPress}
                    helperText={lastNameError ? "Minimum two characters are required" : null}/>
            </Grid>
            <Grid item xs={12} md={6} style={{ margin: "0px 0px 10px 0px" }}>
                <StyledButton variant={"outlined"} color="error" disabled={(!dateOfBirth || dateOfBirthError || firstNameError || middleNameError || lastNameError) ? true : false} onClick={onSearchClicked}>Search</StyledButton>
            </Grid>
            {(!(!dateOfBirth || dateOfBirthError || firstNameError || middleNameError || lastNameError) && isSearched) &&
                            <Grid item xs={12} md={6} style={{textAlign: "right", margin: "0px 0px 10px 0px", padding: "0px 40px 0px 0px" }}>
                                <StyledButton variant={"outlined"} color="error"  onClick={onCreateClientFileClicked}>Create Client File</StyledButton>
                            </Grid>}

            <StyledDialog title="Create Duplicate Client File"
                open={openCreateDuplicateClientConfirmDialog}
                onClose={onCreateDuplicateClientConfirmClickClose}
                message={"There is already a DC Portal Client File with the same First and Last names and date of birth. Are you sure you want to create a new file for this client?"}
                onConfirmed={onCreateDuplicateClientConfirmClicked}
                confirmationText="Create Client" isCancelRequired={true} />

            <Grid item xs={12} md={12}>
                <div id="scrollByClickedItem1" style={{ scrollMarginTop: "375px" }}>
                    {isSearched && (<>
                        {(isEmptyResult && !dateOfBirthError && dateOfBirth != null) && (<>
                            <Grid item xs={12} md={12}  style={{textAlign: "left", font: "normal normal bold 16px/18px Franklin Gothic Medium",letterSpacing: "0px", margin: "0px 0px 10px 0px" }}>
                                No Client Files found for the search criteria.
                            </Grid>
                        </>)}
                        {openPreviewGrid ?
                            (isClientIdOnlyRecord ? <SelectedDataRow title={`Client File ${selectedSearchResultRow?.clientId} : ${selectedSearchResultRow?.firstName}  ${selectedSearchResultRow?.lastName} ${selectedSearchResultRow?.dateOfBirth} `} isFromSearch={true}
                                actionButtonTitle={actionButtonTitle} actionButtonAction={onSelectedRecordActionClick} actionButtonVisible={actionButtonVisible}
                                clientFileId={selectedSearchResultRow?.clientFileId} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} onClose={() => { setOpenPreviewGrid(false) }} /> :
                                <SelectedDataRow title={`Client File ${selectedSearchResultRow?.clientId} : ${selectedSearchResultRow?.firstName}  ${selectedSearchResultRow?.lastName} ${selectedSearchResultRow?.dateOfBirth} `} clientFileId={selectedSearchResultRow?.clientFileId} isFromSearch={true}
                                    actionButtonTitle={actionButtonTitle} actionButtonAction={onSelectedRecordActionClick} actionButtonVisible={actionButtonVisible} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} 
                                    clientFile={selectedSearchResultRow} onClose={() => { setOpenPreviewGrid(false) }} />)
                            :
                            (!isEmptyResult && gridRows && gridRows.length > 0) && <DataTable columns={gridColumns} rows={gridRows} disableSelection={true} onRowClick={handleOnRowClick} getRowStyled={getSearchResultsFromCRMRowStyled} /> 
                        }
                    </>)}
                </div>
            </Grid>
            <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
        </Grid>
    );

}

export default SearchClientFiles;