import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/graph";
const GETUSERAPPROLE = "/getuserapprole";
const GETUSERDETAILS = "/getuserdetailsbyid";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";


export async function GetUserAppRolesByUserId(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETUSERAPPROLE +"?userID="+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 

            var data =await response.json();
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}


export async function GetUserDetailsById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETUSERDETAILS +"?userID="+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 

            var data =await response.json();
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}