import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { RetrieveConflictedClientFiles } from "../../../services/crm-conflict-dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import StyledButton from '../../../components/elements/StyledButton';
import * as Constants from "../../../helper/constants";
import { format } from 'date-fns';

function TopCrmConflictedClientFiles(props) {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);
    const [showViewAll, setShowViewAll] = useState(true);

    const crmConflictResolutionPage = Constants.CRMCONFLICTRESOLUTIONPAGEURL + "?page=topcrmconflictedclientfiles";

    const navigate = useNavigate();

    // #region PageLoad
    useEffect(() => {
        LoadInitialData();

    }, [instance]);

    function LoadInitialData() {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken);
                }
            });
        }
    }
    function GetSearchBy() {
        const firstNameSearch = { columnName: "FirstName", searchValue: '' };
        const middleNameSearch = { columnName: "MiddleName", searchValue: '' };
        const lastNameSearch = { columnName: "LastName", searchValue: '' };
        const dateOfBirthSearch = { columnName: "DateOfBirth", searchValue: "" };

        return [firstNameSearch, middleNameSearch, lastNameSearch, dateOfBirthSearch];
    }
    const setInitialGridData = async (token) => {

        await RetrieveConflictedClientFiles(token, GetSearchBy(), 0, 5).then((rowData) => {
            setInitialGridColumns();
            if (rowData) {
                setGridRows(rowData);
                if (rowData.length > 0) {
                    setShowViewAll(true);
                }
                else {
                    setShowViewAll(false);
                }
            }
        })
    }

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 90, field: 'id', editable: false },
        { headerName: 'First Name', width: 250, field: 'firstName', editable: false },
        { headerName: 'Middle Name', width: 250, field: 'middleName', editable: false },
        { headerName: 'Last Name', width: 250, field: 'lastName', editable: false },
        { headerName: 'Date of Birth', type: 'date', width: 150, field: 'dateOfBirth', renderDate: true, editable: false },
        { headerName: 'Review Client File', width: 180, field: 'button', renderButton: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell: (params) => ((columnHeader.renderButton ? <StyledButton variant={"outlined"} color="error" onClick={(event) => {
                        navigate(`${crmConflictResolutionPage}&clientfileid=${params.row.id.split("-")[1]}`);
                    }}>Review</StyledButton> :
                        (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> : null))),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }

    //#endregion  


    if (canRead("crm-conflict-dashboard", null)) { //ToDo - Change this role
        return (<>

            <DataTable columns={gridColumns} rows={gridRows} totalCount={props.totalCount} />
            {showViewAll && <div align="center" style={{ marginTop: "10px" }}><StyledButton variant={"outlined"} color="error" onClick={() => navigate("/reviewdashboard/crmclientfiles")} >View All</StyledButton></div>}
        </>
        );
    } else {
        return (
            <div>You are not authorized to read this record.</div>
        );
    }
}

export default TopCrmConflictedClientFiles;