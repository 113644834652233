import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, MenuItem } from "@mui/material";
import SubHeader from '../components/SubHeader';
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import TextField from "../components/TextField";
import CustomDatePicker from "../components/DatePicker";
import SelectorTextField from "../components/SelectorTextField";
import { GetClientFileById, GetClientFileActivity, MarkClientFileAsConflicted, CheckDuplicateClients } from "../services/client-file.service";
import NoteTable from "../notes";
import CustomButton from "../components/Button";
import DataTable from '../components/DataTable';
import Backdrop from '../components/elements/Loader';
import { TextButton } from "../components/Button";
import StyledModal from '../components/Modal';
import { format } from 'date-fns';
import Badge from 'react-bootstrap/Badge';
import StyledDialog from '../components/Dialog';
import { useClientGatewayAccessValidation } from "../hooks/useClientGatewayAccessValidation";

function ClientFileDashboard() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [hasAccess] = useClientGatewayAccessValidation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientFileId, setClientFileId] = useState(searchParams.get("id"));
    const screenSideBarList = ['Client Basic Information', 'Client Notes', 'Client Details'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [indigenousAncestry, setIndigenousAncestry] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [preferredMethodOfContact, setPreferredMethodOfContact] = useState(2);
    const [financiallyEligible, setFinanciallyEligible] = useState('');
    const [familySize, setFamilySize] = useState('');
    const [last12MonthsIncome, setLast12MonthsIncome] = useState('');
    const [last30DaysIncome, setLast30DaysIncome] = useState('');
    const [totalEquityValue, setTotalEquityValue] = useState('');
    const [lastAssessmentDate, setLastAssessmentDate] = useState("");
    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [clientDetailNotes, setClientDetailNotes] = useState([]);
    const [rows, setRows] = useState([]);

    const [isDataReady, setIsDataReady] = useState(false);
    const [hasPermissionForClientGateway, setHasPermissionForClientGateway] = useState(false);   
    const apiServerUrl = appconfig.API_SERVER_URL;
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [middleNameFromSearchParams, setMiddleNameFromSearchParams] = useState(searchParams.get("middlename"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));
    const [openModal, setOpenModal] = useState(false);
    const [isCrmConflicted, setIsCrmConflicted] = useState(false);
    const [isDcpConflicted, setIsDcpConflicted] = useState(false);
    const [hasDcpDuplicateClientFiles, setHasDcpDuplicateClientFiles] = useState(false);
    const [isLinkedClient, setIsLinkedClient] = useState(false);

    useEffect(() => {
        setIsDataReady(false);
        
        const GetNotesDetails = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetClientDetailNotes?recordId=' + Number(searchParams.get("id")) + "&noteType=2" + '&onlyLastTwoYears=true';

            fetch(uri, options)
                .then(response => response.json())
                .then(setClientDetailNotes);
        };

        const FetchLookupData = async (token, clientFileId) => {

            setHasPermissionForClientGateway(await hasAccess(token,clientFileId));

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }
                    setContactMethods(data.contactMethods);
                    setIndigenousAncestries(data.indigenousAncestries);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken, clientFileId);
                    GetClientFileById(clientFileId, response.accessToken).then(data => {
                        setMainInformation(data);
                        CheckDuplicateClients(data.clientBasicInformation.firstName, data.clientBasicInformation.middleName, data.clientBasicInformation.lastName, data.clientBasicInformation.dateOfBirth, data.clientFileId, response.accessToken).then(data2 => {
                            setHasDcpDuplicateClientFiles(data2);
                        });
                    });
                    GetNotesDetails(response.accessToken);
                    setIsDataReady(true);
                }
            });
        }
    }, []);

    useEffect(() => {

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {

                    await GetClientFileActivity(response.accessToken, clientFileId).then(data => {
                        setRows(data);
                    });

                }
            });
        }
    }, []);

    function setMainInformation(data) {
        setFirstName(data.clientBasicInformation.firstName);
        setMiddleName(data.clientBasicInformation.middleName);
        setLastName(data.clientBasicInformation.lastName);
        setDateOfBirth((null == data.clientBasicInformation.dateOfBirth || "" == data.clientBasicInformation.dateOfBirth) ? "" : new Date(data.clientBasicInformation.dateOfBirth).toLocaleDateString());
        setIndigenousAncestry((null == data.clientBasicInformation.indigenousAncestryId || "" == data.clientBasicInformation.indigenousAncestryId) ? "" : data.clientBasicInformation.indigenousAncestryId);
        setEmail(data.clientContactDetails.email);
        setPhoneNumber(data.clientContactDetails.phoneNumber);
        setMobileNumber(data.clientContactDetails.mobileNumber);
        setPreferredMethodOfContact((null == data.clientContactDetails.preferredMethodOfContactId || "" == data.clientContactDetails.preferredMethodOfContactId) ? "" : data.clientContactDetails.preferredMethodOfContactId);
        setFinanciallyEligible(data?.clientFinancialInformation.financiallyEligible != null ? Number(data.clientFinancialInformation.financiallyEligible) : "");
        setFamilySize(data.clientFinancialInformation.familySize);
        setLast12MonthsIncome(data?.clientFinancialInformation.last12MonthsIncome != null ? data.clientFinancialInformation.last12MonthsIncome.toLocaleString("en-US") : "");
        setLast30DaysIncome(data?.clientFinancialInformation.last30DaysIncome != null ? data.clientFinancialInformation.last30DaysIncome.toLocaleString("en-US") : "");
        setTotalEquityValue(data?.clientFinancialInformation.totalEquityValue != null ? data.clientFinancialInformation.totalEquityValue.toLocaleString("en-US") : "");
        setLastAssessmentDate((null == data.clientFinancialInformation.lastAssessmentDate || "" == data.clientFinancialInformation.lastAssessmentDate) ? "" : new Date(data.clientFinancialInformation.lastAssessmentDate).toLocaleDateString());
        setIsCrmConflicted(data.isCrmConflicted);
        setIsDcpConflicted(data.isDcpConflicted);
        setIsLinkedClient((null != data.clientFileCrmId && "" != data.clientFileCrmId))
    }

    const columnHeaders = [{ field: 'id', width: 80, headerName: 'ID', renderCell: true, editable: false, hide: true },
    { field: 'createdDate', type: 'date', width: 120, headerName: 'Created On', editable: false, renderDate: true },
    { field: 'activity', width: 150, headerName: 'Activity', editable: false, renderCell: true },
    { field: 'dockets', width: 180, headerName: 'Dockets', editable: false, renderCell: true },
    { field: 'status', width: 150, headerName: 'Status', editable: false, renderCell: true },
    { field: 'nextCourtDate', type: 'date', width: 150, headerName: 'Next Court Date', editable: false, renderDate: true },
    { field: 'assignedTo', width: 180, headerName: 'Assigned To', editable: false, renderCell: true },
    { field: 'outcome', width: 350, headerName: 'Outcome', editable: false, renderCell: true },
    ];
    const columns = [];
    columnHeaders.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderCell ? <> {params.value} </> :
                    (columnHeader.renderDate && params.value != null ? <>{format(new Date(params.value), 'dd-MMM-yyyy')}</> : null)),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide,
            },
        );
    });



    const onDateOfBirthChanged = (date) => {
        setDateOfBirth(date);
    };
    const onLastAssessmentDateChanged = (date) => {
        setLastAssessmentDate(date);
    };
    const handleModalOpen = () => {
        setOpenModal(true);
    };
    const handleModalClose = () => {
        setOpenModal(false);
    };

    function getParams() {
        var queryParams = "&page=clientfiledashboard";
        if (crmId || firstNameFromSearchParams || middleNameFromSearchParams|| lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if ( crmId != null ) {
                queryParams = queryParams + `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (middleNameFromSearchParams) {
                queryParams = queryParams + `&middlename=${middleNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    const onViewDetailsClicked = () => navigate(`/client-file?id=${clientFileId}${getParams()}`);

    function onRowClicked(item) {
        var pageValue = item.row.id.split('-')[0];
        var itemId = item.row.id.split('-')[1];
        switch (pageValue) {

            case 'AR':
                navigate(`/agencyrequest?agencyrequestid=${itemId}&clientFileId=${clientFileId}` + getParams());
                break;
            case 'JPB':
                navigate(`/jpbailoutcome?id=${itemId}&clientFileId=${clientFileId}` + getParams());
                break;
            case 'REF':
                navigate(`/referral?id=${itemId}&clientFileId=${clientFileId}` + getParams());
                break;
            case 'IN':
                navigate(`/interaction?id=${itemId}&clientFileId=${clientFileId}` + getParams());
                break;
            case 'CG':
                    navigate(`/client-gateway?clientgatewayid=${itemId}&clientFileId=${clientFileId}` + getParams());
                    break;
            default:
                navigate(`/client-file?id=${clientFileId}${getParams()}`);
        }

    }

    const onCancelClicked = () => navigate(`/clientfiles/search?${getParams()}`);

    const createNewNote = () => navigate(`/note?noteTypeId=2&relatedId=${clientFileId}${getParams()}`);

    const createNewAgencyRequest = () => navigate(`/agencyrequest?&clientFileId=${clientFileId}${getParams()}`);

    const createNewClientGateway = () => navigate(`/client-gateway?clientFileId=${clientFileId}${getParams()}`);
    //window.open("https://laa-testportal.powerappsportals.com/client-gateway", "blank");
    const createNewJPBailOutcome = () => navigate(`/jpbailoutcome?clientFileId=${clientFileId}${getParams()}`);

    const createNewReferral = () => navigate(`/referral?clientFileId=${clientFileId}${getParams()}`);

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);
    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };
    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkClientFileAsConflicted(Number(searchParams.get("id")), response.accessToken).then(() => {
                        navigate("/");
                    });
                }
            });
        }
    }

    const canMarkAsConflicted = () => {
        var result = false;

        if ((canUpdate("client-file", null)) && (Number(searchParams.get("id")) && Number(searchParams.get("id")) > 0)) {
            if (isLinkedClient) {
                result = true;
            }
            else if (hasDcpDuplicateClientFiles) {
                result = true;
            }
        }

        return result;
    }


    if (canRead("client-file", null)) {
        if (isDataReady) {
            return (
                <>
                    <Grid container style={{ padding: "10px 20px 0px 10px", margin: "80px 0px 0px 0px" }}>
                        <SubHeader
                            title="Client File"
                            menuItemOneText="Delete Client File"
                            onMenuItemOneClicked={() => { }}
                            isMenuItemOneVisible={false}
                            isMenuItemOneDisabled={false}
                            menuItemTwoText="Mark for Review"
                            onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                            isMenuItemTwoVisible={canMarkAsConflicted()}
                            menuItemTwoType="Warning"
                            isMenuItemTwoDisabled={false}
                            navItemOneText="View Details"
                            onNavItemOneClicked={onViewDetailsClicked}
                            isNavItemOneVisible={true}
                            isNavItemOneDisabled={false}
                            navItemTwoText="Cancel"
                            onNavItemTwoClicked={onCancelClicked}
                            isNavItemTwoVisible={true}
                            isNavItemTwoDisabled={false}
                            isCreateNewVisible={true}
                            newItemOneVisible={canCreate("client-note", null)}
                            newItemOneText="Client Note"
                            onNewItemOneClicked={createNewNote}
                            newItemTwoVisible={canCreate("agency-request", null)}
                            newItemTwoText="Agency Request"
                            onNewItemTwoClicked={createNewAgencyRequest}
                            newItemThreeVisible={canCreate("client-gateway", null) && hasPermissionForClientGateway}
                            newItemThreeText="Client Gateway"
                            onNewItemThreeClicked={createNewClientGateway}
                            newItemFourVisible={canCreate("jp-bail-outcome", null)}
                            newItemFourText="JP Bail Outcome"
                            onNewItemFourClicked={createNewJPBailOutcome}
                            newItemFiveVisible={canCreate("client-referral", null) && !hasPermissionForClientGateway}
                            newItemFiveText="Client Referral"
                            onNewItemFiveClicked={createNewReferral}
                        />
                        <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                            message={"Do you really want to mark the CF-" + Number(searchParams.get("id")) + " as conficted?"}
                            onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />

                    {(isCrmConflicted || isDcpConflicted) && (<>
                        <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                    </>)}
                    <Grid container style={{ backgroundColor: "#ececec", border: "1px solid #7F8284", borderRadius: "8px", margin: "0px" }}>
                        <Grid container style={{ padding: "5px 5px 0px 10px", borderRadius: "8px" }}>
                            <Grid item xs={6} md={6} style={{textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"}}>Client Information</Grid>
                            <Grid item xs={6} md={6}  style={{ textAlign: "right" , paddingRight: "10px"}}><TextButton label="Financial Eligibility Guidelines" size="small" handleOnClick={handleModalOpen} /></Grid>
                                <StyledModal open={openModal} title="Financial Eligibility" handleOnClose={handleModalClose} />
                            <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isDashBoard label="First Name" placeholder="First Name" required={true} disabled value={firstName} />
                            </Grid>
                            <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isDashBoard label="Middle Name" placeholder="Middle Name" required={true} disabled value={middleName} />
                            </Grid>
                            <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isDashBoard label="Last Name" placeholder="Last Name" required={true} disabled value={lastName} />
                            </Grid>
                            <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                <CustomDatePicker isDashBoard label="Date of Birth" placeholder="Date of Birth" required={true} disableFuture={true} disabled value={dateOfBirth} handleChange={onDateOfBirthChanged}/>
                            </Grid>
                            <Grid item xs={12} md={2} style={{margin: "10px 0px 10px 0px"}}>
                                <SelectorTextField isDashBoard label="Indigenous Ancestry" placeholder="Indigenous Ancestry" select disabled value={indigenousAncestry} >
                                    {indigenousAncestries?.map((indigenousAncestry) => (
                                    <MenuItem key={indigenousAncestry.indigenousAncestryId} value={indigenousAncestry.indigenousAncestryId}>
                                        {indigenousAncestry.label}
                                    </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                            <Grid item xs={12} md={2} style={{margin: "10px 0px 10px 0px"}}>
                                <TextField isDashBoard label="Email" placeholder="Email" required={false} disabled value={email} />
                            </Grid>
                            <Grid item xs={12} md={2} style={{margin: "10px 0px 10px 0px"}}>
                                <TextField isDashBoard label="Phone Number" placeholder="Phone Number" required={false} disabled value={phoneNumber}/>
                            </Grid>
                            <Grid item xs={12} md={2} style={{margin: "10px 0px 10px 0px"}}>
                                <TextField isDashBoard label="Mobile Number" placeholder="Mobile Number" required={false} disabled value={mobileNumber}/>
                            </Grid>
                            <Grid item xs={12} md={2} style={{margin: "10px 0px 10px 0px"}}>
                                <SelectorTextField isDashBoard label="Method of Contact" placeholder="Method of Contact" select disabled value={preferredMethodOfContact}>
                                    {contactMethods?.map((contactMethod) => (
                                    <MenuItem key={contactMethod.contactMethodId} value={contactMethod.contactMethodId}>
                                        {contactMethod.label}
                                    </MenuItem>))}
                                </SelectorTextField>
                            </Grid>
                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField isDashBoard label=" Financially Eligible " placeholder=" Financially Eligible " disabled select value={financiallyEligible}  >
                                        <MenuItem key="-1" value=""></MenuItem>
                                        <MenuItem key="1" value={1}>Yes</MenuItem>
                                        <MenuItem key="0" value={0}>No</MenuItem>
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Family Size" placeholder="Family Size" disabled value={familySize} />
                                </Grid>
                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Last 12 Month Income($)" placeholder="Last 12 Months Income" disabled value={last12MonthsIncome} />
                                </Grid>

                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Last 30 Days Income ($)" placeholder="Last 30 Days Income" disabled value={last30DaysIncome} />
                                </Grid>
                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField isDashBoard label="Total Equity Value ($)" placeholder="Total Equity Value" disabled value={totalEquityValue} />
                                </Grid>
                                <Grid item xs={12} md={2} style={{ margin: "10px 0px 10px 0px" }}>
                                    <CustomDatePicker isDashBoard label="Last Assessment Date" placeholder="Last Assessment Date" disableFuture={true} disabled value={lastAssessmentDate} handleOnChange={onLastAssessmentDateChanged} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <NoteTable title="Client Notes" notes={clientDetailNotes} clientFileId={Number(clientFileId)} relatedId={Number(clientFileId)} noteTypeId={2} isDisabled={false} hideHeader dataGridtitle="Client Notes" hideTotalCount={true} pageParams={`${getParams()}`} isDisable={false} pageId={"clientfiledashboard"} />
                        <Grid container style={{ padding: "3px 20px 0px 10px", border: "1px solid #7F8284", margin: "10px 0px 25px 0px", borderRadius: "8px" }}>
                            <DataTable columns={columns} rows={rows} onRowDoubleClick={onRowClicked} dataGridtitle="Recent Activities" hideTotalCount={true}/>
                        </Grid>
                    </Grid>
                </>
            );
        } else {
            return (
                <Backdrop />
            );
        }
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Client File Dashboard
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view this details **
                </Grid>
            </Grid>
        );
    }
}

export default ClientFileDashboard;