export async function GetCourtRooms() {

    return [{ "value": "1", "label": "1" },
    { "value": "100", "label": "100" },
    { "value": "1001", "label": "1001" },
    { "value": "1004", "label": "1004" },
    { "value": "1005", "label": "1005" },
    { "value": "1006", "label": "1006" },
    { "value": "1008", "label": "1008" },
    { "value": "101", "label": "101" },
    { "value": "102", "label": "102" },
    { "value": "103", "label": "103" },
    { "value": "104", "label": "104" },
    { "value": "1040", "label": "1040" },
    { "value": "105", "label": "105" },
    { "value": "110", "label": "110" },
    { "value": "1106", "label": "1106" },
    { "value": "1108", "label": "1108" },
    { "value": "1203", "label": "1203" },
    { "value": "1204", "label": "1204" },
    { "value": "1205", "label": "1205" },
    { "value": "1206", "label": "1206" },
    { "value": "1207", "label": "1207" },
    { "value": "1208", "label": "1208" },
    { "value": "123", "label": "123" },
    { "value": "128", "label": "128" },
    { "value": "1406", "label": "1406" },
    { "value": "1407", "label": "1407" },
    { "value": "1408", "label": "1408" },
    { "value": "1505", "label": "1505" },
    { "value": "1603", "label": "1603" },
    { "value": "1800", "label": "1800" },
    { "value": "2", "label": "2" },
    { "value": "201", "label": "201" },
    { "value": "202", "label": "202" },
    { "value": "203", "label": "203" },
    { "value": "204", "label": "204" },
    { "value": "205", "label": "205" },
    { "value": "265", "label": "265" },
    { "value": "265A", "label": "265A" },
    { "value": "265B", "label": "265B" },
    { "value": "266", "label": "266" },
    { "value": "267", "label": "267" },
    { "value": "268", "label": "268" },
    { "value": "272", "label": "272" },
    { "value": "276", "label": "276" },
    { "value": "3", "label": "3" },
    { "value": "301", "label": "301" },
    { "value": "302", "label": "302" },
    { "value": "303", "label": "303" },
    { "value": "304", "label": "304" },
    { "value": "305", "label": "305" },
    { "value": "306", "label": "306" },
    { "value": "307", "label": "307" },
    { "value": "308", "label": "308" },
    { "value": "313", "label": "313" },
    { "value": "314", "label": "314" },
    { "value": "315", "label": "315" },
    { "value": "316", "label": "316" },
    { "value": "317", "label": "317" },
    { "value": "350", "label": "350" },
    { "value": "353", "label": "353" },
    { "value": "355", "label": "355" },
    { "value": "356", "label": "356" },
    { "value": "357", "label": "357" },
    { "value": "358", "label": "358" },
    { "value": "359", "label": "359" },
    { "value": "395", "label": "395" },
    { "value": "396", "label": "396" },
    { "value": "4", "label": "4" },
    { "value": "441", "label": "441" },
    { "value": "442", "label": "442" },
    { "value": "443", "label": "443" },
    { "value": "444", "label": "444" },
    { "value": "445", "label": "445" },
    { "value": "446", "label": "446" },
    { "value": "447", "label": "447" },
    { "value": "448", "label": "448" },
    { "value": "5", "label": "5" },
    { "value": "505", "label": "505" },
    { "value": "506", "label": "506" },
    { "value": "507", "label": "507" },
    { "value": "508", "label": "508" },
    { "value": "508(1)", "label": "508(1)" },
    { "value": "508(2)", "label": "508(2)" },
    { "value": "525", "label": "525" },
    { "value": "6", "label": "6" },
    { "value": "7", "label": "7" },
    { "value": "9", "label": "9" },
    { "value": "908", "label": "908" },
    { "value": "CCTV", "label": "CCTV" },
    { "value": "CMO", "label": "CMO" },
    { "value": "CourtroomUnknown", "label": "Courtroom Unknown" }]

}

