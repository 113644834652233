import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "storage/";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";


export async function GetAttachments(storageContainerName, recordId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            
            var data = await response.json();;
            if (response.ok) return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function DeleteAttachment(storageContainerName, recordId, filename, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    //This should be reverted back
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId + "/" + filename;    
   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
           
            if (response.ok) {
                return true;
            }
            // check for error response
            else {               
                return false;
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });

}

export async function UploadAttachment(storageContainerName, recordId, file, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId;
    
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);

    const formData = new FormData();
    formData.append("file", file);

    const options = {
        method: 'POST',
        body: formData,
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {            
            if (response.ok) { return true; }
            else { return false; }
        })
        .catch(error => {
            console.error('Error occurred.', error);
            return Promise.reject(error);
        });
}

export async function DownloadAttachments(storageContainerName, recordId, filename, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + storageContainerName + "/" + recordId + "/" + filename;
    
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {             
           
            if (response.ok) {
              return response;
            }
            
            // if (response.ok) return data;
            // // check for error response
            else {
                // get error message from body or default to response statusText
                const error =  response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}
