import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import './main-page.css';
import Header from './header';
import Footer from '../components/Footer';
import SideBar from '../components/SideBar';
import Home from '../home';
import ClientFile from '../client-file';
import ClientFileDashboard from '../client-file-dashboard';
import Note from '../note';
import Referral from '../referral';
import AgencyRequest from '../feature/agency-requests/agency-request';
import MyFiles from '../feature/dashboard/my-files';
import ClientFileList from '../feature/client-files/client-file-list';
import JPBailOutcome from '../feature/jp-bail-outcomes/jp-bail-outcome';
import { Grid } from '@mui/material';
import FilesToBeAssigned from '../feature/dashboard/files-to-be-assigned';
import AssignedFiles from '../feature/dashboard/assigned-files';
import SearchClientFiles from '../feature/search-client-files';
import CrmConflictedClients from '../feature/review-dashboard/crm-conflicted-client-files';
import ConflictDashboard from '../feature/review-dashboard/review-dashboard';
import ResolveCrmConflicts from '../feature/review-dashboard/resolve-crm-conflicts';
import Interaction from '../feature/interactions/interaction';
import ServiceDecisionDetails from '../feature/interactions/service-decision';
import Docket from '../feature/dockets/docket';
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DcpConflictedClientFiles from '../feature/review-dashboard/dcp-conflicted-client-files';
import CrmConflictedClientFiles from '../feature/review-dashboard/crm-conflicted-client-files';
import ResolveDcpConflicts from '../feature/review-dashboard/resolve-dcp-conflicts';
import ConflictedInteractions from '../feature/review-dashboard/conflicted-interactions';
import ResolveInteractionConflicts from '../feature/review-dashboard/resolve-interactions';
import ClientGatewayForm from '../feature/client-gateway/client-gateway';


function App() {

  return (
    <Router>
     <ToastContainer closeOnClick autoClose={5000} transition={Slide}/>
      <Header isCRMDown={false}/>
      <Grid container style={{ margin: "80px 0px 0px 50px", width:"unset"}}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clientfiles" element={<ClientFileList />} />
          <Route path="/client-file" element={<ClientFile />} />
          <Route path="/client-dashboard" element={<ClientFileDashboard />} />
          <Route path="/client-gateway" element={<ClientGatewayForm/>} />
          <Route path="/clientfiles/search" element={<SearchClientFiles />} />
          <Route path="/note" element={<Note />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/agencyrequest" element={<AgencyRequest />} />
          <Route path="/myfiles" element={<MyFiles />} />
          <Route path="/jpbailoutcome" element={<JPBailOutcome />} />
          <Route path="/filestobeassigned" element={<FilesToBeAssigned />} />
          <Route path="/assignedfiles" element={<AssignedFiles />} />
          <Route path="/reviewdashboard" element={<ConflictDashboard />} />
          <Route path="/reviewdashboard/crmclientfiles" element={<CrmConflictedClientFiles />} />
          <Route path="/reviewdashboard/dcpclientfiles" element={<DcpConflictedClientFiles />} />
          <Route path="/reviewdashboard/interactions" element={<ConflictedInteractions />} />
          
          <Route path="/reviewdashboard/resolvecrmconflicts" element={<ResolveCrmConflicts />} />
          <Route path="/reviewdashboard/resolvedcpconflicts" element={<ResolveDcpConflicts />} />
          <Route path="/reviewdashboard/resolvedinteractionconflicts" element={<ResolveInteractionConflicts />} />
          
          <Route path="/interaction" element={<Interaction />} />
          <Route path="/service-decision" element={<ServiceDecisionDetails />} />
          <Route path="/docket" element={<Docket />} />
        </Routes>
      </Grid>
      <SideBar></SideBar>
      <Footer />

    </Router >
  );
}

export default App;
