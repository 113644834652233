import React, { useState } from "react";
import {Stack } from '@mui/material';
import { StyledTextField ,StyledNoteTextField , StyledDashBoardTextField } from "./styled/TextField.styled";
import { RiInformationFill } from "react-icons/ri";

export default function TextField({
  label,
  required,
  placeholder,
  handleChange,
  value,
  disabled,
  error,
  isInfoVisible,
  infoMessage,
  isNote,
  isDashBoard,
  ...props
}) {
  return (
    <Stack direction="row" spacing={0}>
    {isNote ? 
    <StyledNoteTextField
    error={error}
    disabled={disabled}
    placeholder={placeholder}
    required={required}
    label={label}
    value={value}
    onChange={handleChange}
    size="small"
    autoComplete='off'
    {...props}
    />
    :(  isDashBoard ?
      <StyledDashBoardTextField
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      label={label}
      value={value}
      onChange={handleChange}
      size="small"
      autoComplete='off'
      {...props}
    />
     :
    <StyledTextField
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      label={label}
      value={value}
      onChange={handleChange}
      size="small"
      autoComplete='off'
      {...props}
    />)}
   {isInfoVisible && <RiInformationFill title={infoMessage} size="20px" color="lightblue" className="align-top" /> }
    </Stack>
  );
}
