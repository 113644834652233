import styled from "styled-components";
import SpeedDial from '@mui/material/SpeedDial';

export const SubHeaderContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 0px;
  height: 80px;
  width: 100%;
  background: #EFEFEF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  z-index:50;
`;

// export const TitleContainer = styled.div`
//   margin-left: auto;
//   margin-right: auto !important;
//   width: 300px;
//   height: 60px;
//   padding: 25px 0px 0px 30px;
//   color: #000000;
//   font: normal normal normal 21px/24px Franklin Gothic Medium;
//   opacity: 1;
// `;
export const TitleContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 70px;
  width: 300px;
  height: 60px;
  color: #000000;
  font: normal normal normal 21px/24px Franklin Gothic Medium;
  opacity: 1;
`;
export const CustomBreadcumContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 70px;
  width: 600px;
  height: 60px;
  color: #000000;
  font: normal normal normal 21px/24px Franklin Gothic Medium;
  opacity: 1;
`;

export const MainTitleContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 300px;
  height: 60px;
  color: #000000;
  font: normal normal normal 21px/24px Franklin Gothic Medium;
  opacity: 1;
`;

export const BreadcrumbContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 300px;
  height: 60px;
  color: #000000;
  font: normal normal normal 12px/14px Franklin Gothic Medium;
  opacity: 1;
`;

export const StyledSpeedDial = styled(SpeedDial)({
  "& .MuiButtonBase-root": { height: "32px", width: "36px", transition: "none !important" },
  "& .MuiFab-extended": {width: "145px"},
  
});

export const MenuListItem = styled.div`
  color: #ffffff;
  font: normal normal 600 16px/18px Franklin Gothic Medium;
  `;