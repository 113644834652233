import React, {useState} from 'react';
import {ButtonStyled, TextButtonStyled} from './styled/Button.styled';

export default function CustomButton({
    label,
    variant,
    onClick, 
    isInput,
    disabled,
}) {
    return (
        <ButtonStyled variant={variant} disabled={disabled} onClick={onClick} >
            {label}
            {isInput && (<input hidden  multiple type="file" />)}
        </ButtonStyled>
    );
  };

  export function TextButton({label, handleOnClick}){
    return (
        <TextButtonStyled  variant="text" onClick={handleOnClick}>
            {label}
        </TextButtonStyled>
    );
  };