import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {AutoCompleteStyledTextField} from "./styled/AutoCompleteTextField.styled";

export default function AutoCompleteTextField({multiple,label, required, options, value, onChange , helperText, error, disabled}) { 

  return (
    <Autocomplete
      multiple={multiple}
      disabled={disabled}
      value={value}
      onChange={onChange}
      size="small"
      id="auto-complete-textfield"
      options={options.map((option) => option.label)}
      disableCloseOnSelect
      filterSelectedOptions  
      style={{ width: 290 }}
      renderInput={(params) => (
        <AutoCompleteStyledTextField {...params} label={label} required={required} helperText={helperText} error={error} size="small" />
      )}
    />
  );
};