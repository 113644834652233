import { useState, useEffect } from "react";
import {useIsAuthenticated, useMsal } from "@azure/msal-react";
import authorizationMatrix from "../authorization-matrix.json";

export function useAuthorization() {
    const MINE = "Mine";

    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setUsername(currentAccount.homeAccountId?.substring(0,36));
            if (currentAccount.idTokenClaims.roles) {
                setRole(currentAccount.idTokenClaims.roles[0].toString());
            } else {
                setRole("No Role");
            }
        }
    }, [instance]);

    const canCreate = (componentName, owner) => {
        var result = false;

        if (isAuthenticated) {
            var components = authorizationMatrix.filter((auth) => (
                (auth.Component == componentName)
                && ((auth.Create.filter((authRole) => (authRole == role)).length > 0)
                    || ((auth.Create.filter((authRole) => (authRole == MINE)).length > 0) && (owner == username)))
            ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    };

    const canRead = (componentName, owner) => {
        var result = false;

        if (isAuthenticated) {
            var components = authorizationMatrix.filter((auth) => (
                (auth.Component == componentName)
                && ((auth.Read.filter((authRole) => (authRole == role)).length > 0)
                    || ((auth.Read.filter((authRole) => (authRole == MINE)).length > 0) && (owner == username)))
            ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    };

    const canUpdate = (componentName, owner) => {
        var result = false;

        if (isAuthenticated) {
            var components = authorizationMatrix.filter((auth) => (
                (auth.Component == componentName)
                && ((auth.Update.filter((authRole) => (authRole == role)).length > 0)
                    || ((auth.Update.filter((authRole) => (authRole == MINE)).length > 0) && (owner == username)))
            ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    };

    const canDelete = (componentName, owner) => {
        var result = false;

        if (isAuthenticated) {
            var components = authorizationMatrix.filter((auth) => (
                (auth.Component == componentName)
                && ((auth.Delete.filter((authRole) => (authRole == role)).length > 0)
                    || ((auth.Delete.filter((authRole) => (authRole == MINE)).length > 0) && (owner == username)))
            ));

            result = ((null != components) && (components.length > 0));
        }

        return result;
    };

    return [canCreate, canRead, canUpdate, canDelete];
}