import styled from "styled-components";
import Button from "@mui/material/Button";

export const HeaderBar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: #fafafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  z-index:51;
`;



export const ImageContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
  width: 237px;
  height: 40px;
  opacity: 1;
`;

export const NavItem = styled.div`
  padding: 6px 0px 6px 10px;
  color: #848484;
  font: normal normal 600 1vw/1.1vw Franklin Gothic Medium;
  margin-right: 20px;
`;

export const NavContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: fit-content;
  margin: auto;
  height: fit-content;
  text-align: center;
  display: flex;
  
`;

export const UserDetailContainer = styled.div`
  padding: 4px;
  height: fit-content;
  margin-left: 20px;
  margin-right: 20px;
  font: normal normal normal 16px/18px Franklin Gothic Medium;
  color: #7F8284;
  transform: translateY(10%);
`;
export const TitleContainer = styled.div`
margin-left: auto;
margin-right: auto !important;
height: fit-content;
width: 50%;
font: normal normal bold 32px/37px Franklin Gothic Medium;
color: #846444;
opacity: 1;
text-align: center;
transform: translateY(40%);
`;

export const CRMDownBar = styled.div`
position: absolute;
top:60px;
left: 0px;
width: 100%;
height: 20px;
background: #e25928 0% 0% no-repeat padding-box;
opacity: 1;
z-index:51;
font: normal normal bold 15px/17px Franklin Gothic Medium;
color: #d41f44;
text-align: left;
`;
export const CRMDownBarText = styled.div`
margin : 2px 0px 0px 20px;
font: normal normal 500 14px/17px Helvetica Neue;
color: #ffffff;
text-align: left;
`;
export const UserRole = styled.div`
text-align: left;
height: fit-content;
font: normal normal bold 12px/14px Helvetica Neue;
color: #7F8284;
`;