import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { IconButton, Badge, Menu, Button, MenuItem } from "@mui/material";
import { IoNotifications } from "react-icons/io5";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import {
    NavItem,
    NavContainer,
    UserDetailContainer,
    UserRole,
} from "../components/styled/Header.styled";

const ProfileContent = () => {

    const [currentUserDeatils, setCurrentUserDetails] = useState({});
    const { instance } = useMsal();
    const [userRole, setUserRole] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
  let navigate = useNavigate();
  const open = Boolean(anchorEl);

    useEffect(() => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount) {
            setCurrentUserDetails(currentAccount);
            if (currentAccount.idTokenClaims.roles) {                
                setUserRole(currentAccount.idTokenClaims.roles[0].toString());
            } else {
                setUserRole("No Role");
            }            
        }
    }, [instance]);



    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = (logoutType) => {
        if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    };

    const handlePassword = () => {
        window.open("https://passwordreset.microsoftonline.com/" );       
    };

    return (
        <>
            <NavContainer>
                <UserDetailContainer>Welcome {currentUserDeatils.name ? currentUserDeatils.name.split(" ")[0] : "null"}!
                    <UserRole>Your role: {userRole}</UserRole>
                </UserDetailContainer>
                <NavItem style={{paddingTop: '12px'}}>
                    <Badge badgeContent={4} color="error" ><IoNotifications style={{ color: "darkgrey"}} size={20} /></Badge>
                </NavItem>

                <NavItem>
                    <IconButton
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <HiOutlineMenu style={{ color: "#d41f44" }} size={20} />
                    </IconButton></NavItem>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => handlePassword()}><FaUserCircle
                        style={{ color: "#d41f44", marginRight: "20px" }}
                        size={20}
                    />Change Password</MenuItem>
                    <MenuItem onClick={() => handleSignOut("redirect")}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <RiLogoutBoxRLine
                                style={{ color: "#d41f44", marginRight: "20px" }}
                                size={20}
                            />
                            Sign Out
                        </div>
                    </MenuItem>
                </Menu>
            </NavContainer>
        </>

    );
}



export default ProfileContent;
