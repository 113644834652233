import React, { useEffect, useState, useRef } from 'react';
import "./home.css";
import appconfig from '../appConfig'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetCounts } from '../services/dashboard.service'
import { tokenRequest } from "../auth/authConfig";
import TopMyFiles from '../feature/dashboard/top-my-files';
import TopAssignedFiles from '../feature/dashboard/top-assigned-files';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from '../hooks/useAuthorization';
import { Grid } from '@mui/material';
import InfoCard from '../components/Card';
import TopFilesToBeAssigned from '../feature/dashboard/top-files-to-be-assigned';
import { FaSleigh } from 'react-icons/fa';

function Home() {

  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [myFileCount, setMyFileCount] = useState(0);
  const [myFileNavigation, setMyFileNavigation] = useState("/myfiles");
  const [filesToAssign, setFilesToAssign] = useState(0);
  const [assignedFiles, setAssignedFiles] = useState(0);
  const [hasMyFilesPermission, setHasMyFilesPermission] = useState(false);
  const [hasFilesToBeAssignedPermission, setHasFilesToBeAssignedPermission] = useState(false);
  const [hasAssignedFilesPermission, setHasAssignedFilesPermission] = useState(false);
  const [isClickedMyfiles, setIsClickedMyfiles] = useState(false);
  const [isClickedFilestoAssign, setIsClickedFilestoAssign] = useState(false);
  const [isClickedAssignedFiles, setIsClickedAssignedFiles] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardKey, setCardKey] = useState(searchParams.get("cardKey") ? searchParams.get("cardKey") : "1");
  const navigate = useNavigate();
  const apiServerUrl = appconfig.API_SERVER_URL;

  useEffect(() => {

    setInitialPermissions();

  }, [account, instance]);

  useEffect(() => {
    function handleSelect(card1: boolean, card2: boolean, card3: boolean) {
      setIsClickedMyfiles(card1);
      setIsClickedFilestoAssign(card2);
      setIsClickedAssignedFiles(card3);
    };
    if (cardKey == "1") {
      handleSelect(true, false, false);
    } else if (cardKey == "2") {
      handleSelect(false, true, false);
    } else if (cardKey == "3") {
      handleSelect(false, false, true);
    }
  }, [cardKey]);

  function LoadInitialData() {
    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then((response) => {
        if (response) {
          setInitialTopCountData(response.accessToken).then(async () => {
            setInitialBottomData(response.accessToken);
          })
        }
      });
    }
  }

  const setInitialPermissions = () => {
    var userHasPermission = false;
    if (account && account.idTokenClaims && account.idTokenClaims.roles
      && (account.idTokenClaims.roles[0].toString() == "DutyCounselTriageLead"
        || account.idTokenClaims.roles[0].toString() == "LegalServicesManager")) {
      userHasPermission = true;
      setHasFilesToBeAssignedPermission(userHasPermission);
      setHasAssignedFilesPermission(userHasPermission);
      setHasMyFilesPermission(userHasPermission);
      if (!searchParams.get("cardKey"))
        setCardKey("1");
    }
    else if (account && account.idTokenClaims && account.idTokenClaims.roles
      && account.idTokenClaims.roles[0].toString() == "LegalAdministrativeAssisstant") {
      userHasPermission = true;
      setHasAssignedFilesPermission(userHasPermission);
      setHasFilesToBeAssignedPermission(false);
      setHasMyFilesPermission(false);
      if (!searchParams.get("cardKey"))
        setCardKey("3");
    }
    else {
      const lawyers: string[] = appconfig.LAWYER_ROLE_NAMES.split(";");

      lawyers.forEach(roleValue => {
        if (account && account.idTokenClaims && account.idTokenClaims.roles
          && account.idTokenClaims.roles[0].toString() == roleValue) {
          userHasPermission = true;
          setHasMyFilesPermission(userHasPermission);
          setHasAssignedFilesPermission(false);
          setHasFilesToBeAssignedPermission(false);
          if (!searchParams.get("cardKey"))
            setCardKey("1");
        }
      });
    }
    if (userHasPermission) {
      LoadInitialData();
    }
  }

  const setInitialTopCountData = async (token: string) => {

    await GetCounts(token).then((countDetails) => {

      countDetails.forEach((element: any) => {
        if (element.myFilesCount) {
          setMyFileCount(element.myFilesCount);

          if (element.value == 0 && account && account.idTokenClaims && account.idTokenClaims.roles &&
            (account.idTokenClaims.roles[0].toString() == "DutyCounselTriageLead"
              || account.idTokenClaims.roles[0].toString() == "LegalServicesManager")) {
            setHasMyFilesPermission(false);
            setCardKey("2");
          }

        }
        if (element.assignedFilesCount) {
          setAssignedFiles(element.assignedFilesCount)
        }
        if (element.filesToBeAssignedCount) {
          setFilesToAssign(element.filesToBeAssignedCount);
        }

      });

    });

  }

  const setInitialBottomData = async (token: string) => {
    if (hasMyFilesPermission) {
      setCardKey("1");
    }
    else if (hasFilesToBeAssignedPermission) {
      setCardKey("2");
    }
    else if (hasAssignedFilesPermission) {
      setCardKey("3");
    }
  }



  return (
    <>
    <Grid item xs={12} md={12} style={{ minHeight: "100vh",background: "#F0F0F0 0% 0% no-repeat padding-box" }}>
      {isAuthenticated && (
        <Grid container direction="row" alignItems="flex-start" style={{
          padding: "0px 20px 0px 20px"
        }} >
          <Grid item xs={12} md={12} style={{
            textAlign: "left", font: "normal normal bold 32px/39px Franklin Gothic Medium",
            letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 20px 10px"
          }}>Dashboards</Grid>

          {hasMyFilesPermission &&
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <InfoCard setKey={setCardKey} cardID="1" title="My Files" subtitle="" count={myFileCount} isClicked={isClickedMyfiles} />
            </Grid>
          }
          {hasFilesToBeAssignedPermission &&
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <InfoCard setKey={setCardKey} cardID="2" title="Files to Assign" subtitle="" count={filesToAssign} isClicked={isClickedFilestoAssign} />
            </Grid>
          } {hasAssignedFilesPermission &&
            <Grid item xs={12} md={4} style={{ padding: "5px" }}>
              <InfoCard setKey={setCardKey} cardID="3" title="Assigned Files" subtitle="" count={assignedFiles} isClicked={isClickedAssignedFiles} />
            </Grid>
          }
          {hasMyFilesPermission && cardKey === "1" &&
            <>
              <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
              }}>My Files</Grid>
              <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopMyFiles totalCount={myFileCount}></TopMyFiles> </Grid>
            </>}
          {hasFilesToBeAssignedPermission && cardKey === "2" &&
            <>
              <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
              }}>Files to Assign</Grid>
              <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopFilesToBeAssigned totalCount={filesToAssign}></TopFilesToBeAssigned> </Grid>
            </>}
          {hasAssignedFilesPermission && cardKey === "3" &&
            <>
              <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
              }}>Assigned Files</Grid>
              <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopAssignedFiles totalCount={assignedFiles}/> </Grid>
            </>}
        </Grid>)}
        </Grid>
    </>

  )

}

export default Home;