import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientBasicInfo from "../client-basic-info";
import ClientContactInfo from "../client-contact-info";
import ReferralInfo from "../referral-info";
import "./referral.css";
import appconfig from '../appConfig'
import { useAuthorization } from "../hooks/useAuthorization";
import { useAccount, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/authConfig";
import StyledDialog from '../components/Dialog';
import { Grid } from '@mui/material';
import SubHeader from "../components/SubHeader";
import ScreenSideBar from "../components/ScreenSideBar";
import { toast } from "react-toastify";
import { useClientGatewayAccessValidation } from "../hooks/useClientGatewayAccessValidation";

function Referral() {

    const CREATESUCCESS = "Referral successfully created.";
    const UPDATESUCCESS = "Referral successfully updated.";

    const apiServerUrl = appconfig.API_SERVER_URL;

    const [hasAccess] = useClientGatewayAccessValidation();
    const [hasPermissionForClientGateway, setHasPermissionForClientGateway] = useState(false);

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [referralId, setReferralId] = useState(searchParams.get("id"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [courtLocations, setCourtLocations] = useState([]);
    const [dutyCounselTypes, setDutyCounselTypes] = useState([]);
    const [contactMethods, setContactMethods] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openConfirmSubmitDialog, setOpenConfirmSubmitDialog] = useState(false);
    const screenSideBarList = ['Client Basic Information', 'Client Contact Information', 'Referral Information'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));
    const [clickedOption, setClickedOption] = useState("0");
    const [saveClicked, setSaveClicked] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSaveClicked = (event) => {

        var result = 1;
        setOpenConfirmSubmitDialog(false);

        if ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0)) {

            if (validateInformation()) {
                const courtLocations = referralInfoComp.current.getReferralInfo().courtLocation;
                const newCourtLocationsList = new Array();

                courtLocations.forEach(element => {
                    var newCourtLocation = {
                        "referralId": element.referralId>0? element.referralId: 0,
                        "courtLocationId": element.courtLocationId>0 ? element.courtLocationId : parseInt(element.value),
                        "referralCourtLocationId": element.referralCourtLocationId>0? element.referralCourtLocationId:0
                    }
                    newCourtLocationsList.push(newCourtLocation);
                });
                var sampleBasicInfo = clientBasicInfoComp.current.getClientBasicInfo();

                sampleBasicInfo.indigenousAncestry.IndigenousAncestryId = 0;

                var referral = {
                    "referralId": referralInfoComp.current.getReferralInfo().referralId,
                    "referralCrmId": null,
                    "clientBasicInformation": sampleBasicInfo,
                    "clientContactDetails": clientContactInfoComp.current.getClientContactInfo(),
                    "docketNumber": referralInfoComp.current.getReferralInfo().docketNumber,
                    "upcomingCourtDate": new Date(referralInfoComp.current.getReferralInfo().upcomingCourtDate),
                    "courtLocations": newCourtLocationsList,
                    "dutyCounselType": referralInfoComp.current.getReferralInfo().dutyCounselType,
                    "clientFile": referralInfoComp.current.getReferralInfo().clientFile
                };
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then(async (response) => {
                        if (response) {
                            var clientBasicInfoResult =
                                saveClientBasicInfo(
                                    clientBasicInfoComp.current.getClientBasicInfo(),
                                    response.accessToken
                                );

                            var clientContactInfoResult =
                                saveClientContactInfo(
                                    clientContactInfoComp.current.getClientContactInfo(),
                                    response.accessToken
                                );

                            var ReferralInfoResult =
                                await saveReferralInfo(
                                    referral,
                                    response.accessToken
                                );
                        }
                    })
                }

            }
            // else {
            //     toast.error("Please fill out the details correctly before saving the form.",{position: "top-center",autoClose: 5000,theme: "colored"});
            //     //alert("Please fill out the details correctly before saving the form.");
            // }
        }
        else {
            setSaveClicked(true);
            var result = 0;


            if (validateInformation()) {
                const courtLocations = referralInfoComp.current.getReferralInfo().courtLocation;
                const newCourtLocationsList = new Array();

                courtLocations.forEach(element => {
                    var newCourtLocation = {
                        "referralId": 0,
                        "courtLocationId": parseInt(element.value),
                        "referralCourtLocationId": 0
                    }
                    newCourtLocationsList.push(newCourtLocation);
                });
                var sampleBasicInfo = clientBasicInfoComp.current.getClientBasicInfo();

                sampleBasicInfo.indigenousAncestry.IndigenousAncestryId = 0;
                var referral = {
                    "referralId": 0,
                    "referralCrmId": null,
                    "clientBasicInformation": sampleBasicInfo,
                    "clientContactDetails": clientContactInfoComp.current.getClientContactInfo(),
                    "docketNumber": referralInfoComp.current.getReferralInfo().docketNumber,
                    "upcomingCourtDate": new Date(referralInfoComp.current.getReferralInfo().upcomingCourtDate),
                    "courtLocations": newCourtLocationsList,
                    "dutyCounselType": referralInfoComp.current.getReferralInfo().dutyCounselType,
                    "clientFile": referralInfoComp.current.getReferralInfo().clientFile
                };
                const PostData = (token) => {

                    const headers = new Headers();
                    const bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append('Content-Type', 'application/json');

                    const options = {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(referral)

                    }

                    const uri = apiServerUrl + 'ReferralService/CreateReferral';

                    result = fetch(uri, options)
                        .then(async response => {
                            var data = await response.json();
                            if (response.ok) {
                                toast.success(CREATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
                                if (clickedOption == 0) {
                                    navigate(window.location.pathname + window.location.search + `&id=${data}`);
                                    window.location.reload();
                                }
                                else if (clickedOption == 1) {
                                    if (searchParams.get("page") == "clientfiledashboard") {
                                        navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
                                    } else {
                                        navigate("/client-file?id=" + clientFileId);
                                    }
                                }

                            } else {
                                throw new Error('Something went wrong ...');
                            }
                        })
                        .then()
                        .catch((error) => {
                            toast.error("Create Referral was not succesful.", { position: "top-center", autoClose: 5000, theme: "colored" });
                            //alert("Create Referral was not succesful.")
                        });
                };

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then(async (response) => {
                        if (response) {
                           await PostData(response.accessToken);
                        }
                    });
                }
            }
            // else {
            //     toast.error("Please fill out the details correctly before saving the form.",{position: "top-center",autoClose: 5000,theme: "colored"});
            //     //alert("Please fill out the details correctly before saving the form.");
            // }
        }

        return result;
    }

    const validateInformation = () => {

        if (clientBasicInfoComp.current.getClientBasicInfo().firstName && !clientBasicInfoComp.current.getClientBasicInfo().firstNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().lastName && !clientBasicInfoComp.current.getClientBasicInfo().lastNameValidity &&
            clientBasicInfoComp.current.getClientBasicInfo().dateOfBirth && !clientBasicInfoComp.current.getClientBasicInfo().dateOfBirthValidity
            // commented contact information validation as a bug fix for 892 : client contact information (read-only) is not required to add a referral
            // && clientContactInfoComp.current.getClientContactInfo().email && !clientContactInfoComp.current.getClientContactInfo().emailValidity &&
            // clientContactInfoComp.current.getClientContactInfo().mobileNumber && !clientContactInfoComp.current.getClientContactInfo().phoneNumberValidity &&
            // clientContactInfoComp.current.getClientContactInfo().phoneNumber && !clientContactInfoComp.current.getClientContactInfo().mobileNumberValidity
            && referralInfoComp.current.getReferralInfo().docketNumber && !referralInfoComp.current.getReferralInfo().docketNumberValidity
            && referralInfoComp.current.getReferralInfo().upcomingCourtDate && !referralInfoComp.current.getReferralInfo().upcomingCourtDateValidity
            && referralInfoComp.current.getReferralInfo().courtLocation && referralInfoComp.current.getReferralInfo().courtLocation.length > 0 && !referralInfoComp.current.getReferralInfo().courtLocationValidity
            && referralInfoComp.current.getReferralInfo().dutyCounselType.dutyCounselTypeId != 0 && !referralInfoComp.current.getReferralInfo().dutyCounselTypeValidity) {
            return true;
        }
        return false;
    }

    const onValidationClicked = (option) => {
        if (validateInformation()) {
            onConfirmSubmitClickOpen(option);
        }
        else {
            onConfirmSubmitClickClose(option);
            //toast.error("Please fill out the details correctly before saving the form.",{position: "top-center",autoClose: 5000,theme: "colored"});
            // alert("Please fill out the details correctly before saving the form.");
        }
    }

    const saveClientBasicInfo = (clientBasicInformation, token) => {
        // ReadOnly
        return true;
    };

    const saveClientContactInfo = (clientContactInformation, token) => {
        // ReadOnly
        return true;
    };

    const saveReferralInfo = async (referral, token) => {

        const updateReferral = apiServerUrl + 'ReferralService/UpdateReferral';

        var result = 0;

        const headers = new Headers();
        const bearer = "Bearer " + token;
        headers.append("Authorization", bearer);
        headers.append('Content-Type', 'application/json');

        const options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(referral)
        }

        result = await fetch(updateReferral, options)
            .then(response => {
                if (response.ok) {
                    toast.success(UPDATESUCCESS, { position: "top-center", autoClose: 5000, theme: "colored" });
                    if (clickedOption == 1) {
                        if (searchParams.get("page") == "clientfiledashboard") {
                            navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
                        } else {
                            navigate("/client-file?id=" + clientFileId);
                        }
                    }

                } else {
                    toast.error("Update Referral Failed.", { position: "top-center", autoClose: 5000, theme: "colored" });
                    //alert('Update Referral Failed.');
                }
            })
            .then()
            .catch(error => toast.error("Update Referral Failed.", { position: "top-center", autoClose: 5000, theme: "colored" }));
        //alert('Update Referral Failed.'));

        return result;
    };

    const onDeleteClicked = () => {

        var result = 1;

        if ((null != referralId) && (referralId > 0)) {

            const DeleteData = (token) => {
                const headers = new Headers();
                const bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append('Content-Type', 'application/json');

                const options = {
                    method: 'DELETE',
                    headers: headers
                }

                const uri = apiServerUrl + 'ReferralService/DeleteReferral?id=' + referralId;

                result = fetch(uri, options)
                    .then(response => {
                        if (response.ok) {
                            navigate("/client-file?id=" + clientFileId);
                        } else {
                            throw new Error('Something went wrong ...');
                        }
                    })
                    .then()
                    .catch(error => toast.error("Delete Referral was not succesful.", { position: "top-center", autoClose: 5000, theme: "colored" }));
                //alert("Delete Referral was not succesful."));
            };

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteData(response.accessToken);
                    }
                });
            }
        }

        return result;
    };

    useEffect(() => {

        const FetchLookupData = async (token) => {


            setHasPermissionForClientGateway(await hasAccess(token, clientFileId));

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ReferralService/GetReferralLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setCourtLocations(data.courtLocations);
                    setDutyCounselTypes(data.dutyCounselTypes);
                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }


        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    FetchLookupData(response.accessToken);
                }
            });
        }
    }, [account, instance]);


    const canSave = () => {
        var result = false;

        if ((null != referralId) && (referralId > 0)) {
            result = canUpdate("client-referral", null);
        } else {
            result = canCreate("client-referral", null);
        }

        return result;
    };

    const canDeleteReferral = () => {
        return ((null != referralId) && (referralId > 0) && (canDelete("client-referral", null)));
    };

    function getParams() {
        var queryParams = '';
        if (crmId || firstNameFromSearchParams || lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if (crmId != null) {
                queryParams = `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    const onCancelClicked = () => {
        if (searchParams.get("page") == "clientfiledashboard") {
            navigate(`/client-dashboard?&id=${clientFileId}&page=searchresults${getParams()}`);
        } else {
            navigate("/client-file?id=" + clientFileId);
        }
    };

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const referralInfoComp = useRef();

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onConfirmSubmitClickOpen = () => {
        setOpenConfirmSubmitDialog(true);
    };
    const onConfirmSubmitClickClose = () => {
        setOpenConfirmSubmitDialog(false);
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    function Save() {
       
        setClickedOption(0);
        onValidationClicked();
    }

    function SaveAndClose() {
       
        setClickedOption(1);
        onValidationClicked();
    }

    if (canRead("client-referral", null)) {

        return (
            <>
                <Grid container style={{ padding: "0px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                    <StyledDialog title="Delete Referral" open={openDeleteDialog} onClose={onDeleteClickClose}
                        message={"Do you really want to delete the Referral : R-" + referralId + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked} confirmationText="Delete" />
                    <SubHeader
                        title="Referral Details"
                        menuItemOneText="Delete Referral"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteReferral()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText=""
                        onMenuItemTwoClicked=""
                        isMenuItemTwoVisible={false}
                        isMenuItemTwoDisabled={false}

                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={canSave()}
                        isNavItemOneDisabled={saveClicked}

                        navItemTwoText="Save & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={canSave()}
                        isNavItemTwoDisabled={saveClicked}

                        navItemThreeText="Close"
                        onNavItemThreeClicked={onCancelClicked}
                        isNavItemThreeVisible={true}
                        isNavItemThreeDisabled={false}

                        navItemFourText=""
                        onNavItemFourClicked=""
                        isNavItemFourVisible={false}
                        isNavItemFourDisabled={false} />
                    {/* <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} /> */}
                    <Grid item xs={12} md={12} style={{
                        textAlign: "left", font: "normal normal normal 15px/18px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1", marginTop: "10px"
                    }}>
                        {(referralId == null || referralId == 0) && hasPermissionForClientGateway && (
                            <>
                                <div>
                                    This form is to be used when the Client does not have an existing active file with LAA (is a new client). If this is an existing client, please use the Client Gateway Form.
                                    <br />
                                    <a href={searchParams.get("page") == "clientfiledashboard" ? `/client-gateway?clientFileId=${clientFileId}&page=referral&from=clientfiledashboard` : `/client-gateway?clientFileId=${clientFileId}&page=referral`} >Open Client Gateway Form</a>
                                    <br />
                                    Please ensure there is either a phone number or an email for the client so LAA can get in touch with the Client if needed.
                                    <br /><br />
                                </div>
                            </>)}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}><ClientBasicInfo isReadOnly={true} clientFileId={clientFileId} ref={clientBasicInfoComp} isAliasVisible={false} /></div>
                        <div id="scrollByClickedItem2" style={{ scrollMarginTop: "161px" }}><ClientContactInfo isReadOnly={true} clientFileId={clientFileId} ref={clientContactInfoComp} contactMethods={contactMethods} /></div>
                        <div id="scrollByClickedItem3" style={{ scrollMarginTop: "166px" }}><ReferralInfo clientFileId={clientFileId} referralId={referralId} ref={referralInfoComp} dutyCounselTypes={dutyCounselTypes} courtLocations={courtLocations} /></div>
                    </Grid>
                    <StyledDialog title="Client Referral Disclaimer" open={openConfirmSubmitDialog} onClose={onConfirmSubmitClickClose}
                        message={"Submitting this referral indicates that there is a Likelihood of Imprisonment for this Client based on the docket(s)."}
                        onConfirmed={onSaveClicked} confirmationText="Submit" />
                </Grid>
            </>
        );
    } else {
        return (
            <div className="refferal">
                <div className="referralDetails">
                    <div className="sectionTitle">Referral Details</div><br />
                    <div>You are not authorized to read this record.</div>
                </div>
            </div>
        );
    }
}

export default Referral;