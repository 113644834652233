import AuthBlock from "../auth/AuthBlock";
import {
  HeaderBar,
  CRMDownBar,
  CRMDownBarText,
  ImageContainer,
  TitleContainer 
} from "../components/styled/Header.styled";
import {useNavigate } from 'react-router-dom';
import { BsFillExclamationOctagonFill } from "react-icons/bs";

export default function Header(props) {
  const navigate = useNavigate();
  return (
    <HeaderBar>
    <ImageContainer>
      <img src={"../images/logo.png"} alt="logo" height="90%" onClick={() => navigate('/')}/>
    </ImageContainer>
    <TitleContainer>Duty Counsel Portal</TitleContainer>
    <AuthBlock />
    {props.isCRMDown && <><CRMDownBar> <CRMDownBarText><b><BsFillExclamationOctagonFill size={16} style={{paddingBottom : "2px"}}/></b> CRM and some DC Portal functionalities are currently unavailable. You can continue to work and the client information will auto sync once CRM is available.</CRMDownBarText> </CRMDownBar></>}
    </HeaderBar>
  );
}

