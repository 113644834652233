import { useEffect } from "react";
import isEmail from 'validator/lib/isEmail';
import { fieldValidationTypes } from "../helper/fieldValidationTypes";
import validator from 'validator';

export function useValidation() {

    useEffect(() => {
    }, []);

    const isValid = (fieldValidationDetails) => {
        if (!validateGenericDetails(fieldValidationDetails))
            return false;

        switch (fieldValidationDetails.name) {
            case fieldValidationTypes.Email:               
                const isValidEmail = fieldValidationDetails.value ? validator.isEmail(fieldValidationDetails.value, {"ignore_max_length":true}) : false;
                return isValidEmail;
            case fieldValidationTypes.PhoneNumber:
                return (validator.isNumeric(fieldValidationDetails.value));
            case fieldValidationTypes.Name:
                return (validator.isAscii(fieldValidationDetails.value));
            default:
                break;
        }
        return true;
    }
    return [isValid];
}

function validateGenericDetails(fieldValidationDetails) {

    if (fieldValidationDetails.required) {
        if (validator.isEmpty(fieldValidationDetails.value.trim()))
            return false;
    }
    if (fieldValidationDetails.minLength || fieldValidationDetails.maxLength) {
        const minLength = fieldValidationDetails.minLength ? fieldValidationDetails.minLength : 0;
        const maxLength = fieldValidationDetails.maxLength ? fieldValidationDetails.maxLength : undefined;

        if (!validator.isLength(fieldValidationDetails.value, { min: minLength, max: maxLength })) {
            return false;
        }
    }
    if (fieldValidationDetails.json) {
        if (!isStringified(fieldValidationDetails.value)) {
            return false;
        }
    }
    return true;
}

function isStringified(jsonValue) {
    try {
        JSON.parse(jsonValue);
        return true;
    } catch (err) {
        return false;
    }
}