import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { DeleteAttachment, DownloadAttachments } from "../../../services/storage-service";
import { tokenRequest } from "../../../auth/authConfig";
import saveAs from "file-saver";
import { GetAttachments } from "../../../services/storage-service";
import StyledDialog from '../../../components/Dialog';
import { toast } from "react-toastify";

const AttachmentRow = ({ attachment, containerName, recordId, setAttachment , ...props}: any) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    
    const loadFiles = () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }

    const fetchInitialData = async (token: string) => {
        await GetAttachments(containerName, recordId, token).then((data) => {
            setAttachment(data);
        });
    }


    const handleDownloadClick = () => {

        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    var fileDetails: any = await DownloadAttachments(containerName, recordId, attachment.name, response.accessToken);
                    var stream = new Response(fileDetails.body)
                    var blobdetails = await stream.blob();
                    saveAs(blobdetails, attachment.name);
                }
            });
        }
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };


    const onDeleteClicked = async () => {
        //if (confirm("Are you sure you want to delete the attachment?!") == true) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    const status = await DeleteAttachment(containerName, recordId, attachment.name, response.accessToken);
                    if (status) {
                        toast.success("File successfully deleted.",{
                            position: "top-center",
                            autoClose: 5000,
                            theme: "colored"});
                        //alert("File successfully deleted.");
                        loadFiles();
                    }
                    else
                    toast.error("Could not delete file. Please try again.",{
                        position: "top-center",
                        autoClose: 5000,
                        theme: "colored"});
                       // alert("Could not delete file. Please try again.");
                }
            });
        }
       setOpenDeleteDialog(false);
    };

    return (
        <>
            <tr>
                <td>
                    <a href="#attachment-component" onClick={handleDownloadClick} style={{ font: "normal normal normal 15px/17px Franklin Gothic Medium", color: "#478bca" }}>
                        {attachment.name}
                    </a>
                </td>
                <td>
                    <Button className="btn btn-danger" style={{ backgroundColor: "#D41F44", borderColor: "#D41F44" }} disabled={props.isDisabled} onClick={onDeleteClickOpen}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                    </Button>
                </td>
            </tr>
            <tr>
                <td>
                <StyledDialog title="Delete Attachment" open={openDeleteDialog} onClose={onDeleteClickClose}
                        message={"Do you really want to delete the attachment? This process cannot be undone."}
                        onConfirmed={onDeleteClicked} confirmationText="Delete" />
                </td>
            </tr>
        </>
    );
}

export default AttachmentRow;