import { Button } from "react-bootstrap";
import React, { useEffect, useState, useRef } from 'react';
import {Grid} from '@mui/material';
import DataTable from '../../../components/DataTable';
import { useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useValidation } from "../../../hooks/useValidation";
import {format} from 'date-fns';
import * as Constants from "../../../helper/constants";

function DocketTable(props) {
    const [isValid] = useValidation();
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const [searchParams, setSearchParams] = useSearchParams();
    const [interactionId, setInteractionId] = useState(searchParams.get("id"));
    const columnHeaders = [ { field: 'id', width: 100, headerName: 'DocketID', editable: false, hide: true },
        { field: 'DocketNumber', width: 200, headerName: 'Docket Number', renderCell: true, editable: false },
        { field: 'UpcomingCourtDate', type: 'date', width: 200, headerName: 'Upcoming Court Date', renderDate: true, editable: false },
        { field: 'CourtLocation', width: 200, headerName: 'Court Location', editable: false },
        { field: 'CourtRoom', width: 200, headerName: 'Court Room', editable: false } ];

    const columns = [];
    columnHeaders.forEach((columnHeader) => {
            columns.push(
                {   field: columnHeader.field, 
                    renderHeader :() => (<div style={{font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000"}}>{columnHeader.headerName}</div>),
                    renderCell: (params) => (columnHeader.renderCell ? ( <a href={'/docket?id=' + params.row.id + '&recordId=' + interactionId + '&recordType=interaction&clientFileId=' + props.clientFileId}>{params.value}</a> ): 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null )),
                    width: columnHeader.width,
                    editable : columnHeader.editable,
                    hide : columnHeader.hide
                    },
                );
            });
    
    const rows = [];
    if (props.dockets && props.dockets.length > 0) {

        props.dockets.forEach((docket) => {
            rows.push(
                {
                    DocketNumber: docket.docketNumber,
                    UpcomingCourtDate: docket.upcomingCourtDate,
                    CourtLocation: docket.courtLocation ? docket.courtLocation.label : null,
                    CourtRoom: docket.courtRoom ? docket.courtRoom.label : null,
                    id: docket.docketId
                },
            );
        })
    };

    return (
        <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
            <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "25px"
            }}>{props.title}
            <Button style={{ font: "normal normal normal 15px/24px Franklin Gothic Medium", backgroundColor: "#D41F44", borderColor: "#D41F44", position: "absolute", right: "60px" }}
                    size="sm" variant={(canCreate("docket", null)) ? "primary" : "secondary"} disabled={props.isDisabled ? true : ((canCreate("docket", null)) ? false : true)} className="ml-auto" href={'/docket?recordId=' + interactionId + '&recordType=interaction&clientFileId=' + props.clientFileId}>New Docket</Button>

            </Grid>
            <DataTable columns={columns} rows={rows} />
        </Grid>
    );
   
}

export default DocketTable;
