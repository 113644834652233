import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid } from '@mui/material';
import DataTable from '../../../components/DataTable';
import SubHeader from '../../../components/SubHeader';
import StyledDialog from '../../../components/Dialog';
import SelectedDataRow from '../../../components/elements/SelectedDataRow';
import { tokenRequest } from "../../../auth/authConfig";
import { GetMatchingClientFiles, ResolveConflictedClientFile, ClearConflictedDcpClientFile } from "../../../services/dcp-conflict-dashboard.service";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { FetchLookupData, GetClientFileById } from "../../../services/client-file.service";
import Badge from '@mui/material/Badge';
import _ from "lodash";
import * as Constants from "../../../helper/constants";
import { toast } from "react-toastify";
import {format} from 'date-fns';

export default function ResolveDcpConflicts() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    const [unconflictedDcpClientFiles, setUnconflictedDcpClientFiles] = useState([]);
    const [dcpClientFiles, setDcpClientFiles] = useState([]);
    const [clientFileDetails, setClientFileDetails] = useState([]);
    const [selectedUnconflictedDcpClientFile, setSelectedUnconflictedDcpClientFile] = useState(null);
    const [selectedDCPRow, setSelectedDCPRow] = useState(null);
    const [selectedUnconflictedDcpRow, setSelectedUnconflictedDcpRow] = useState(null);
    const [gridColumns, setGridColumns] = useState([]);

    const [openDCPClientFile, setOpenDCPClientFile] = useState(false);
    const [openUnconflictedDcpClientFile, setOpenUnconflictedDcpClientFile] = useState(false);
    const [openMergeFileDialog, setOpenMergeFileDialog] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [firstNameQueryParamValue, setFirstNameQueryParamValue] = useState(null);
    const [lastNameQueryParamValue, setLastNameQueryParamValue] = useState(null);
    const [dateOfBirthQueryParamValue, setDateOfBirthQueryParamValue] = useState(null);
    const [idQueryParamValue, setIdQueryParamValue] = useState(searchParams.get("clientfileid"));

    const [contactMethods, setContactMethods] = useState([]);
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();
    const clientFinancialInfoComp = useRef();

    const unconflictedDcpConflictedClientFilesUrl = "/reviewdashboard/clientfiles";
    const [backPage, setBackPage] = useState(searchParams.get("page"));

    useEffect(() => {
        LoadInitialData();

    }, [instance]);


    const LoadInitialData = async () => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then(async (response) => {
                if (response) {
                    if (idQueryParamValue) {
                        var data = await GetClientFileById(idQueryParamValue, response.accessToken);

                        if (data) {
                            setFirstNameQueryParamValue(data.clientBasicInformation.firstName);
                            setLastNameQueryParamValue(data.clientBasicInformation.lastName);
                            setDateOfBirthQueryParamValue(data.clientBasicInformation.dateOfBirth);
                        }
                        if (data.isDcpConflicted) {
                            setInitialGridColumns();
                            const dobDate = new Date(data.clientBasicInformation.dateOfBirth);
                            await GetMatchingClientFiles(response.accessToken, idQueryParamValue).then((rowData) => {
                                setInitialGridColumns();

                                if (rowData) {
                                    setUnconflictedDcpClientFiles(rowData.unconflictedDcpClientFiles);
                                    setDcpClientFiles(rowData.dcpClientFiles);
                                    setClientFileDetails(rowData.clientFileDetails);
                                }
                            })
                            await FetchLookupData(response.accessToken).then((data) => {
                                if (data)
                                    setContactMethods(data.contactMethods);
                                    setIndigenousAncestries(data.indigenousAncestries);
                            })
                        }
                        else {
                            toast.success("This record is not to be reviewed any more.", { position: "top-center", autoClose: 5000, theme: "colored" });
                           
                            navigate();
                        }
                    }
                }
            });
        }
    }

    const onCancelClicked = () => {
        navigateToPage();
    };

    const handleAutoScroll = () => {
        const element = document.getElementById('scrollByClickedItem1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onDCPRowClick = (item, event) => {
        setSelectedDCPRow(item.row);
        setOpenDCPClientFile(Boolean(event.currentTarget));
        handleAutoScroll();
    };

    const onUnconflictedDcpRowClick = (item, event) => {
        setSelectedUnconflictedDcpRow(item.row);
        const selectedUnconflictedDcpRecord = _.filter(clientFileDetails, { 'clientFileId': parseInt(item.row.id.split("-")[1]) });
        setSelectedUnconflictedDcpClientFile(selectedUnconflictedDcpRecord[0]);
        setOpenUnconflictedDcpClientFile(Boolean(event.currentTarget));

        handleAutoScroll();
    };



    const onMergeFileClickOpen = () => {
        setOpenMergeFileDialog(true);
    };
    const onMergeFileClickClose = () => {
        setOpenMergeFileDialog(false);
    };

    const resolveUnconflictedDcpConflicts = () => {
        if (selectedUnconflictedDcpRow != null & selectedDCPRow != null) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then(async (response) => {
                    if (response) {
                        await ResolveConflictedClientFile(response.accessToken, selectedDCPRow.id.split("-")[1], selectedUnconflictedDcpRow.id.split("-")[1]).then((response) => {

                            if (response) {
                                toast.success("Client File is Reviewed Successfully.", { position: "top-center", autoClose: 5000, theme: "colored" });
                                //alert("UnconflictedDcp Conflict Resolved Successfully.");
                                navigateToPage();
                            }
                            else {
                                toast.error("Error occurred when deleting the client file. Please try again later.", { position: "top-center", autoClose: 5000, theme: "colored" });
                                //alert("UnconflictedDcp Conflict Resolved Successfully.");
                                navigateToPage();
                            }
                        });
                    }
                });
            }
        }
    };

    const setInitialGridColumns = () => {

        const columns = [];

        const columnHeaders = [{ headerName: 'Client ID', width: 175, field: 'id', renderCell: true, editable: false },
        { headerName: 'First Name', width: 250, field: 'firstName', editable: false },
        { headerName: 'Last Name', width: 225, field: 'lastName', editable: false },
        { headerName: 'Date of Birth',type: 'date', width: 110, field: 'dateOfBirth', renderDate: true, editable: false }];

        columnHeaders.forEach((columnHeader) => {
            columns.push(
                {
                    field: columnHeader.field,
                    renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                    renderCell:  (params) => (columnHeader.renderCell ?<> {params.value} {params.value == idQueryParamValue ? <Badge style={{ color: "#d32f2f", marginLeft: "5px" }}>**Needs Review** </Badge> : null}</>: 
                    (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null )),
                    width: columnHeader.width,
                    editable: columnHeader.editable,
                },
            );
        });

        setGridColumns(columns);
    }

    const [openConfirmMarkAsUnconflictedDcpConflictedDialog, setOpenConfirmMarkAsUnconflictedDcpConflictedDialog] = useState(false);
    const onConfirmMarkAsUnconflictedDcpFileClickOpen = () => {
        setOpenConfirmMarkAsUnconflictedDcpConflictedDialog(true);
    };
    const onConfirmMarkAsUnconflictedDcpFileClickClose = () => {
        setOpenConfirmMarkAsUnconflictedDcpConflictedDialog(false);
    };

    const onMarkAsUnconflictedDcpUnConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    ClearConflictedDcpClientFile(response.accessToken, idQueryParamValue ? parseInt(idQueryParamValue) : null).then(() => {
                        navigateToPage();
                    });
                }
            });
        }
    }

    function navigateToPage() {
        switch (backPage) {           
            case "topdcpconflictedclientfiles":
                navigate(Constants.CRMREVIEWPAGEURL + "?cardKey=1");
                break;            
            case "dcpconflictedclientfiles":
                navigate(Constants.DCPCONFLICTEDCLIENTFILESPAGEURL);
                break;

            default: break;
        }
    }

    return (<>
        {(canRead("dcp-conflict-dashboard", null)) && <Grid container style={{ padding: "5px 20px 0px 20px", margin: "80px 0px", scrollMarginTop: "80px" }}>
            <SubHeader
                title="Review Duplicate Portal File"
                menuItemOneText=""
                onMenuItemOneClicked=""
                isMenuItemOneVisible={false}
                isMenuItemOneDisabled={false}
                menuItemTwoText="Mark as Reviewed"
                onMenuItemTwoClicked={onConfirmMarkAsUnconflictedDcpFileClickOpen}
                isMenuItemTwoVisible={true}
                menuItemTwoType="CrmUnConflicted"
                isMenuItemTwoDisabled={false}
                navItemOneText="Link Files"
                onNavItemOneClicked={onMergeFileClickOpen}
                isNavItemOneVisible={true}
                isNavItemOneDisabled={!(openDCPClientFile && openUnconflictedDcpClientFile)}
                navItemTwoText="Cancel"
                onNavItemTwoClicked={onCancelClicked}
                isNavItemTwoVisible={true}
                isNavItemTwoDisabled={false}
                navItemThreeText=""
                onNavItemThreeClicked=""
                isNavItemThreeVisible={false}
                isNavItemThreeDisabled={false}
                navItemFourText=""
                onNavItemFourClicked=""
                isNavItemFourVisible={false}
                isNavItemFourDisabled={false} />

            <StyledDialog title="Mark as Reviewed" open={openConfirmMarkAsUnconflictedDcpConflictedDialog} onClose={onConfirmMarkAsUnconflictedDcpFileClickClose}
                message={"Do you really want to mark the CF-" + idQueryParamValue + " as reviewed?"}
                onConfirmed={onMarkAsUnconflictedDcpUnConflictedClicked} confirmationText="Mark as Reviewed" isCancelRequired={true}/>
           
            <StyledDialog title="Link Portal Client File with a Reviewed DC Portal Client File" open={openMergeFileDialog} onClose={onMergeFileClickClose} 
                message={"Are you sure you want to update the merging for this Client File?"}
                onConfirmed={resolveUnconflictedDcpConflicts} confirmationText="Confirm" isCancelRequired={true}/>
            
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}>
                    <Grid item xs={12} md={12} style={{
                        textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                        letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                    }}>Duplicate Portal Client File</Grid>
                    {openDCPClientFile === true ? (<>
                        <SelectedDataRow title={`Portal Client File ${selectedDCPRow?.id} : ${selectedDCPRow?.firstName}  ${selectedDCPRow?.lastName} ${selectedDCPRow?.dateOfBirth} `} clientFileId={selectedDCPRow?.id.split("-")[1]} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} isResolveUnconflictedDcpConflicts={true} onClose={() => { setOpenDCPClientFile(false) }} />
                    </>) : (<>
                        <DataTable columns={gridColumns} rows={dcpClientFiles} disableSelection={false} onRowClick={onDCPRowClick} />
                    </>)}     </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "5px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "center", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "5px 0px 10px 5px"
                }}>Portal Client Files</Grid>
                {openUnconflictedDcpClientFile === true ? (<>
                    <SelectedDataRow title={`Reviewed DC Portal Client File ${selectedUnconflictedDcpRow?.id} : ${selectedUnconflictedDcpRow?.firstName}  ${selectedUnconflictedDcpRow?.lastName} ${selectedUnconflictedDcpRow?.dateOfBirth} `} clientFile={selectedUnconflictedDcpClientFile} clientBasicInfoComp={clientBasicInfoComp} clientContactInfoComp={clientContactInfoComp} clientFinancialInfoComp={clientFinancialInfoComp} contactMethods={contactMethods} indigenousAncestries={indigenousAncestries} isResolveCrmConflicts={true} isResolveUnconflictedDcpConflicts={true} onClose={() => { setOpenUnconflictedDcpClientFile(false) }} />
                </>) : (<>
                    <DataTable columns={gridColumns} rows={unconflictedDcpClientFiles} disableSelection={false} onRowClick={onUnconflictedDcpRowClick} />
                </>)}
            </Grid>

        </Grid>}
    </>
    );
};