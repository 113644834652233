import React from "react";

import {
  FooterBar,
  FooterBarLeft,
  FooterBarRight
} from "./styled/Footer.styled";

export default function Footer() {
  return (
    <FooterBar >
        <FooterBarLeft><img src={"../images/LAA_Diversity_Stripe_Long.png"} alt="footerImage" width="50%" /></FooterBarLeft>
        <FooterBarRight><img src={"../images/LAA_Diversity_Stripe_Long.png"} alt="footerImage2" width="50%" /></FooterBarRight>
    </FooterBar>
  );
};