import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, MenuItem } from "@mui/material";
import SubHeader from '../../../components/SubHeader';
import appconfig from '../../../appConfig'
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { tokenRequest } from "../../../auth/authConfig";
import TextField from "../../../components/TextField";
import CustomDatePicker from "../../../components/DatePicker";
import SelectorTextField from "../../../components/SelectorTextField";
import StyledSwitch from '../../../components/StyledSwitch';
import StyledDialog from '../../../components/Dialog';
import AutoCompleteTextField from "../../../components/AutoComplete";
import { GetClientGatewayMetaData, CreateClientGatewayRequest, GetClientGatewayById } from "../../../services/client-gateway-service";
import { GetClientFileById } from "../../../services/client-file.service"
import { toast } from "react-toastify";
import _ from "lodash";

function ClientGatewayForm() {

    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientGatewayid, setClientGatewayid] = useState(searchParams.get("clientgatewayid"));
    const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstNameFromSearchParams, setFirstNameFromSearchParams] = useState(searchParams.get("firstname"));
    const [middleNameFromSearchParams, setMiddleNameFromSearchParams] = useState(searchParams.get("middlename"));
    const [lastNameFromSearchParams, setLastNameFromSearchParams] = useState(searchParams.get("lastname"));
    const [dateOfBirthFromSearchParams, setDateOfBirthFromSearchParams] = useState(searchParams.get("dateofbirth"));

    const [readOnly, setReadOnly] = useState(false);
    const [formType, setFormType] = useState("");
    const [formTypes, setFormTypes] = useState([]);
    const [name, setName] = useState("");
    const [counselName, setCounselName] = useState("");
    const [isClientConsented, setIsClientConsented] = useState(false);
    const [isContatcInfoConsented, setIsContatcInfoConsented] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [addressMaxLengthError, setAddressMaxLengthError] = useState(false);
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);
    const [contactNotes, setContactNotes] = useState("");
    const [contactNotesMaxLengthError, setContactNotesMaxLengthError] = useState(false);
    const [havePreferredCounsel, setHavePreferredCounsel] = useState(false);
    const [preferredCounsel, setPreferredCounsel] = useState("");
    const [indigenousAncestry, setIndigenousAncestry] = useState("");
    const [indigenousAncestries, setIndigenousAncestries] = useState([]);
    const [isInCustody, setIsInCustody] = useState(false);
    const [custodyLocation, setCustodyLocation] = useState([]);
    const [custodyLocations, setCustodyLocations] = useState([]);
    const [helpNeededOnAllActiveCharges, setHelpNeededOnAllActiveCharges] = useState(true);
    const yesOrNoSelectionList = [{ selectionItemId: "0", label: "Yes", value: true }, { selectionItemId: "1", label: "No", value: false },];
    const [confirmLOIforCharges, setConfirmLOIforCharges] = useState(false);
    const [isConsentedDCOrdering, setIsConsentedDCOrdering] = useState(false);
    const [nextCourtDate, setNextCourtDate] = useState("");
    const [isNextCourtDateInvalid, setIsNextCourtDateInvalid] = useState(false);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketMaxLengthError, setDocketMaxLengthError] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [arryOfInputValues, setArryOfInputValues] = useState([]);
    const [courtLocations, setCourtLocations] = useState([]);
    const [courtRoom, setCourtRoom] = useState("");
    const [generalComments, setGeneralComments] = useState("");
    const [generalCommentsMaxLengthError, setGeneralCommentsMaxLengthError] = useState(false);
    const [isCurrentCounselConsented, setIsCurrentCounselConsented] = useState(false);
    const [activeCertficateNumber, setActiveCertficateNumber] = useState("");
    const [activeCertficateNumberError, setActiveCertficateNumberError] = useState(false);
    const [activeCertficateNumberMaxLengthError, setActiveCertficateNumberMaxLengthError] = useState(false);
    const [openContactInfoDialog, setOpenContactInfoDialog] = useState(false);
    const [openDCOrderingDialog, setOpenDCOrderingDialog] = useState(false);
    const [openHelpNeededActiveChargesDialog, setOpenHelpNeededActiveChargesDialog] = useState(false);
    const [courtRoomMaxLengthError, setCourtRoomMaxLengthError] = useState(false);
    const [arryOfInputCustodyLocations, setArryOfInputCustodyLocations] = useState([]);
    const [tooltipText, setTooltipText] = useState("Form type is mandatory to submit the form.");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setName(response.account.name);
                    setCounselName(response.account.name);
                    fetchInitialData(response.accessToken);
                }
            });
        }
    }, []);

    const fetchInitialData = async (token) => {

        const clientData = await GetClientFileById(clientFileId, token);

        const metaData = await GetClientGatewayMetaData(token);

        if (metaData) {
            setCourtLocations(metaData.courtLocations);
            setFormTypes(metaData.formTypes);
            
            setCustodyLocations(metaData.custodyLocations);
            setIndigenousAncestries(metaData.isIndeginous);
        }
        else {
            toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
            //alert("An error occurred while loading data.");
        }

        if (clientData) {
            setFirstName(clientData.clientBasicInformation.firstName);
            setLastName(clientData.clientBasicInformation.lastName);
            setDateOfBirth(clientData.clientBasicInformation.dateOfBirth)
            setPhoneNumber(clientData.clientContactDetails.phoneNumber);
            setMobileNumber(clientData.clientContactDetails.mobileNumber);
            setEmail(clientData.clientContactDetails.email);
        }

        if (clientGatewayid && clientGatewayid > 0) {
            setReadOnly(true);
            var data = await GetClientGatewayById(clientGatewayid, token);
            setInitialClientGateway(data, metaData.courtLocations, metaData.custodyLocations,  metaData.formTypes, metaData.isIndeginous);

        }

    }

    useEffect(() => { if (isContatcInfoConsented === false) setOpenContactInfoDialog(true); }, [isContatcInfoConsented]);

    useEffect(() => { if (helpNeededOnAllActiveCharges === false) setOpenHelpNeededActiveChargesDialog(true); }, [helpNeededOnAllActiveCharges]);

    const setInitialClientGateway = (gatewayObject, courtLocationList, custodyLocationList, formTypesList, indigenousAncestriesList) => {

        setName(gatewayObject.laa_DCName)
        setIsCurrentCounselConsented(gatewayObject.laa_CurrentCounselConsent);
        setActiveCertficateNumber(gatewayObject.laa_ActiveCertificateNumber);
        setIsClientConsented(gatewayObject.laa_ClientConsent);
        setIsContatcInfoConsented(gatewayObject.laa_IsClientContactAvailable);
        setAddress(gatewayObject.laa_Address);
        setContactNotes(gatewayObject.laa_ContactComments);
        setHavePreferredCounsel(gatewayObject.laa_HasPreferredCounsel);
        setCity(gatewayObject.laa_City);

        setPreferredCounsel(gatewayObject.laa_PreferredCounsel);
        setIsInCustody(gatewayObject.laa_InCustody);
        setHelpNeededOnAllActiveCharges(gatewayObject.laa_HelpNeededOnAllActiveCharges);
        setConfirmLOIforCharges(gatewayObject.laa_LikelihoodofImprisonment);
        setIsConsentedDCOrdering(gatewayObject.laa_ConsentforDisclosure);
        setNextCourtDate(Date.parse(gatewayObject.laa_NextCourtDate));
        setDocketNumber(gatewayObject.laa_DocketNumber);
        setCourtRoom(gatewayObject.laa_Courtroom);
        setGeneralComments(gatewayObject.laa_GeneralComments);

        if (gatewayObject.laa_FormType) {
            let selectedFormType = _.filter(formTypesList, { 'value': gatewayObject.laa_FormType.toString() });
            setFormType(selectedFormType[0]);
        }      

        if (gatewayObject.laa_CustodyLocation) {
            let selectedCustodyLocation = _.filter(custodyLocationList, { 'value': gatewayObject.laa_CustodyLocation.toString() });
            setCustodyLocation(selectedCustodyLocation[0]);
            setArryOfInputCustodyLocations(selectedCustodyLocation[0]);
        }

        if (gatewayObject.laa_IsIndigenous) {
            let selectedIsIndigenous = _.filter(indigenousAncestriesList, { 'value': gatewayObject.laa_IsIndigenous.toString() });
            setIndigenousAncestry(selectedIsIndigenous[0]);
        }

        if (gatewayObject.laa_CourtLocation) {
            const fecthInitialCourtLocations = [];
            const fecthArrayOfCourtLocations = [];
            var locationArray = gatewayObject.laa_CourtLocation.split(",");
            locationArray.forEach((courtLocation) => {
                let selectedLocation = _.filter(courtLocationList, { 'value': courtLocation.toString() });
                fecthInitialCourtLocations.push(selectedLocation[0]);
                fecthArrayOfCourtLocations.push(selectedLocation[0].label);
            });
            setCourtLocation(fecthInitialCourtLocations);
            setArryOfInputValues(fecthArrayOfCourtLocations);
        }
    }

    //#region onChangeEvents
    const onFormTypeChanged = (event) => {
        const selectedFormType = formTypes.find(
            (object) => object?.label === event.target.value
        );
        setFormType(selectedFormType);

        setTooltipText(selectedFormType.label);

    };
    const onClientConsentChanged = (event) => setIsClientConsented(event.target.checked);
    const onContatcInfoConsentChanged = (event) => setIsContatcInfoConsented(event.target.checked);
    const onAddressChanged = (event) => {
        if (event.target.value.length < 256) {
            setAddressMaxLengthError(false);
            setAddress(event.target.value);
        } else {
            setAddressMaxLengthError(true);
        }
    };
    const onCityChanged = (event) => {        
           setCity(event.target.value);      
    };    
    const onContactNotesChanged = (event) => {
        if (event.target.value.length < 1001) {
            setContactNotesMaxLengthError(false);
            setContactNotes(event.target.value);
        } else {
            setContactNotesMaxLengthError(true);
        }
    };
    const onHavePreferredCounselChanged = (event) => setHavePreferredCounsel(event.target.checked);
    const onPreferredCounselChanged = (event) => setPreferredCounsel(event.target.value);
    const onIndigenousAncestryChanged = (event) => {
        const indigenousAncestryUpdated = indigenousAncestries.find(
            (object) => object?.label === event.target.value
        );
        setIndigenousAncestry(indigenousAncestryUpdated);
    };


    const onInCustodyChanged = (event) => setIsInCustody(event.target.checked);
    const onCustodyLocationChanged = (event, InputValue) => {
        setArryOfInputCustodyLocations(InputValue);
        let seletcedOption = custodyLocations.find((option) => option?.label == InputValue)
        setCustodyLocation(seletcedOption);
    };
    const onHelpNeededOnAllActiveChargesChanged = (event) => setHelpNeededOnAllActiveCharges(event.target.checked);
    const onconfirmLOIforChargesChanged = (event) => setConfirmLOIforCharges(event.target.checked);
    const onConsentedDCOrderingChanged = (event) => setIsConsentedDCOrdering(event.target.checked);
    const onNextCourtDateChanged = (date) => {
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") {
                setIsNextCourtDateInvalid(true);
            } else {
                setNextCourtDate(Date.parse(date));
                //setNextCourtDateError(false);
                setIsNextCourtDateInvalid(false);
            }
        } else {
            setNextCourtDate(date);
            //setNextCourtDateError(true);
            setIsNextCourtDateInvalid(false);
        }

    };
    const onDocketNumberChanged = (event) => {
        if (event.target.value.length < 256) {
            setDocketMaxLengthError(false);
            setDocketNumber(event.target.value);
        } else {
            setDocketMaxLengthError(true);
        }
    };

    const onCourtLocationsChanged = (event, newInputValues) => {
        setArryOfInputValues(newInputValues);
        const newArrayOfObjects = [];
        newInputValues.forEach((InputValue) => {
            let seletcedOption = courtLocations.find((option) => option?.label == InputValue)
            newArrayOfObjects.push(seletcedOption);
        })
        setCourtLocation(newArrayOfObjects);
    };
    const onCourtRoomChanged = (event) => {

        if (event.target.value.length < 256) {
            setCourtRoomMaxLengthError(false);
            setCourtRoom(event.target.value);
        } else {
            setCourtRoom(courtRoom);
        }
    };
    const onGeneralCommentsChanged = (event) => {
        if (event.target.value.length < 5011) {
            setGeneralCommentsMaxLengthError(false);
            setGeneralComments(event.target.value);
        } else {
            setGeneralCommentsMaxLengthError(true);
        }
    };
    const onCurrentCounselConsented = (event) => setIsCurrentCounselConsented(event.target.checked);
    const onActiveCertficateNumberChanged = (event) => {
        if (event.target.value.length < 101) {
            setActiveCertficateNumberMaxLengthError(false);
            setActiveCertficateNumber(event.target.value);
            if (event.target.value.length === 0) { setActiveCertficateNumberError(true); }
            else { setActiveCertficateNumberError(false); }

        } else {
            setActiveCertficateNumberMaxLengthError(true);
        }
    };

    const onContactInfoClose = () => setOpenContactInfoDialog(false);
    const onDCOrderingClose = () => setOpenDCOrderingDialog(false);
    const onHelpNeededActiveChargesClose = () => setOpenHelpNeededActiveChargesDialog(false);

    const canSubmit = () => {
        var status = false;
        if (formType == "") {
            status = true;
        } else if ((formType != "" && formType?.value == "851160003") && (isClientConsented == false || isCurrentCounselConsented == false || helpNeededOnAllActiveCharges == false)) {
            status = true;
        } else if (formType != "" && (formType?.value == "851160000" || formType?.value == "851160001") && (isClientConsented == false || helpNeededOnAllActiveCharges == false)) {
            status = true;
        }

        if (!status) {
            if (readOnly) {
                status = true;
            }
            else {
                status = false;
            }
        }
        return status;
    };

    //#endregion
    const [saveClicked, setSaveClicked] = useState(false);
    function Save() {
       
        onSaveClicked(0);
    }

    function SaveAndClose() {
       
        onSaveClicked(1);
    }

    const validateClientGatewayDetails = () => {
        var status = true;
        if (formType != "" && formType?.value == "851160003" && activeCertficateNumber == "" ) { setActiveCertficateNumberError(true); status = false; }
        return status;
    };

    const onSaveClicked = async (option) => {

        if (clientFileId && clientFileId > 0) {
          
            const isValidData = validateClientGatewayDetails();
            if (isValidData) {
                setSaveClicked(true);
                var requestDetails = createRequestObject();
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then(async (response) => {
                        if (response) {
                            await CreateClientGatewayRequest(requestDetails, response.accessToken).then((clientGatewayNewId) => {
                                if (clientGatewayNewId > 0) {
                                    if (option == 0) {
                                        navigate(`/client-gateway?clientgatewayid=${clientGatewayNewId}&page=${backPage}&clientFileId=${clientFileId}`);
                                        window.location.reload();
                                    }
                                    else {
                                        navigateToPage();
                                    }
                                }

                            });

                        }
                    });
                }
            }
            else
                toast.error("Cannot create a client gateway. Please try again later.", { position: "top-center", autoClose: 5000, theme: "colored" });

        }
        else {
            toast.error("Cannot create a client gateway. Client gateway needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });

        }

    }

    const createRequestObject = () => {

        var courtLocationText = "";
        var count = 0;
        if (courtLocation.length > 0) {
            courtLocation.forEach(element => {
                if (count == 0) {
                    courtLocationText = element.value;
                    count++;
                }
                else {
                    courtLocationText = courtLocationText + ',' + element.value;
                }

            });
        }
        const request = {
            clientGatewayId: 0,
            clientFileId: parseInt(clientFileId),
            laa_FormNumber: 0,
            laa_FormType: formType ? parseInt(formType.value) : "",
            laa_FormSource: 851160000,
            laa_CurrentCounselConsent: isCurrentCounselConsented,
            laa_ActiveCertificateNumber: activeCertficateNumber,
            laa_DCName: '',
            laa_ClientConsent: isClientConsented,
            laa_FirstName: firstName,
            laa_LastName: lastName,
            laa_DateOfBirth: null,
            laa_IsClientContactAvailable: isContatcInfoConsented,
            laa_PhoneNumber: phoneNumber,
            laa_Email: email,
            laa_Address: address,
            laa_City: city,
            laa_ContactComments: contactNotes,
            laa_HasPreferredCounsel: havePreferredCounsel,
            laa_PreferredCounsel: havePreferredCounsel ? preferredCounsel : null,
            laa_IsIndigenous: indigenousAncestry ? parseInt(indigenousAncestry.value) : null,
            laa_InCustody: isInCustody,
            laa_CustodyLocation: isInCustody ? parseInt(custodyLocation.value) : null,
            laa_HelpNeededOnAllActiveCharges: helpNeededOnAllActiveCharges,
            laa_LikelihoodofImprisonment: confirmLOIforCharges,
            laa_ConsentforDisclosure: isConsentedDCOrdering,
            laa_NextCourtDate: nextCourtDate ? new Date(nextCourtDate) : null,
            laa_DocketNumber: docketNumber,
            laa_CourtLocation: courtLocationText ? courtLocationText : null,
            laa_Courtroom: courtRoom,
            laa_GeneralComments: generalComments
        }

        return request;
    }

    function getParams() {
        var queryParams = "";
        if (crmId || firstNameFromSearchParams || middleNameFromSearchParams || lastNameFromSearchParams || dateOfBirthFromSearchParams) {
            if (crmId != null) {
                queryParams = queryParams + `&crmId=${crmId}`;
            }
            if (firstNameFromSearchParams) {
                queryParams = queryParams + `&firstname=${firstNameFromSearchParams}`;
            }
            if (middleNameFromSearchParams) {
                queryParams = queryParams + `&middlename=${middleNameFromSearchParams}`;
            }
            if (lastNameFromSearchParams) {
                queryParams = queryParams + `&lastname=${lastNameFromSearchParams}`;
            }
            if (dateOfBirthFromSearchParams) {
                queryParams = queryParams + `&dateofbirth=${new Date(dateOfBirthFromSearchParams)}`;
            }
        }
        return queryParams;
    };

    function navigateToPage() {
        switch (backPage) {
            case "clientfiledashboard":
                navigate("/client-dashboard?id=" + clientFileId + `&page=searchresults${getParams()}`);
                break;
            case "referral":
                if (searchParams.get("from") == "clientfiledashboard") {
                    navigate("/referral?clientFileId=" + clientFileId + "&page=clientfiledashboard");
                } else {
                    navigate("/referral?clientFileId=" + clientFileId);
                }
                break;
            case "clientFile":
                navigate("/client-file?id=" + clientFileId + `&page=clientfiledashboard${getParams()}`);
                break;
            default: break;
        }
    }
    const onCancelClicked = () => navigateToPage();

    return (
        <Grid container style={{ padding: "10px 20px 0px 10px", margin: "80px 0px 30px 0px" }}>

            {isAuthenticated && (<>
                <SubHeader
                    title="Client Gateway Staff Form"
                    menuItemOneText=""
                    onMenuItemOneClicked={() => { }}
                    isMenuItemOneVisible={false}
                    isMenuItemOneDisabled={false}
                    menuItemTwoText=""
                    onMenuItemTwoClicked=""
                    isMenuItemTwoVisible={false}
                    isMenuItemTwoDisabled={false}
                    isTooltipVisible={canSubmit()}
                    tooltipTitle={tooltipText}
                    navItemOneText="Submit"
                    onNavItemOneClicked={Save}
                    isNavItemOneVisible={true}
                    isNavItemOneDisabled={canSubmit() || saveClicked ? true: false}
                    navItemTwoText="Submit & Close"
                    onNavItemTwoClicked={SaveAndClose}
                    isNavItemTwoVisible={true}
                    isNavItemTwoDisabled={canSubmit() || saveClicked ? true: false}
                    navItemThreeText="Cancel"
                    onNavItemThreeClicked={onCancelClicked}
                    isNavItemThreeVisible={true}
                    isNavItemThreeDisabled={false}
                    navItemFourText=""
                    onNavItemFourClicked={() => { }}
                    isNavItemFourVisible={false}
                    isNavItemFourDisabled={false} />
                <StyledDialog title="Are you sure this client is financially eligible?" open={openContactInfoDialog} onClose={onContactInfoClose}
                    message={"If not, LAA will need to call the client to finish their application or have an email on file to contact the client and alert them to call LAA."}
                    secondMessage={"NOTE: Alternative phone numbers or places we can leave messages for the client are accepted. Ask the client if there is a number LAA can leave messages at 'just in case'."}
                    onConfirmed={onContactInfoClose} confirmationText="Yes" />
                <StyledDialog title="Alert!" open={openDCOrderingDialog} onClose={onDCOrderingClose}
                    message={"As we cannot split active charges, please direct the client to call so they can provide their consent on specific charges."}
                    onConfirmed={onDCOrderingClose} confirmationText="Yes" />
                <StyledDialog title="Alert!" open={openHelpNeededActiveChargesDialog} onClose={onHelpNeededActiveChargesClose}
                    message={"As we cannot split active charges, please direct the client to call so they can provide their consent on specific charges."}
                    onConfirmed={onHelpNeededActiveChargesClose} confirmationText="Yes" />
                <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", borderRadius: "8px" }}>
                    <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                        Please remember that the Client needs to have an existing active file with LAA. If it is a new client, or the client does not know if they have an active file, please have them call our Contact Centre at 1-866-845-3425.
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                        Please ensure you record either a phone number and an email for the client so LAA can get in touch with the Client if needed.
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#ff0000", opacity: "1", marginBottom: "10px" }}>
                        If you experience issues with the form please contact ClientGateway@legalaid.ab.ca
                    </Grid>
                    <Grid container style={{ margin: "0px 20px 20px 20px", padding: "10px 20px 0px 20px", border: "1px solid #7F8284", borderRadius: "8px" }}>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            Please specify: Which form would you be filling today?
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Form Type" placeholder="Form Type" required={true} disabled={readOnly}
                                select
                                value={formType ? formType?.label : ""}
                                handleChange={onFormTypeChanged}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                {formTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                        {(formType == "" || formType?.value == "851160000" || formType?.value == "851160001") && (
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Name" placeholder="Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                    value={name} />
                            </Grid>)}
                        {(formType != "" && formType?.value == "851160003") && (<>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                I confirm that I am making this request with the consent of my existing client as described below.
                                We understand LAA financial and service eligibility rules must still be met before coverage will be provided.
                                Requests for additional coverage of new charges or matters continue to be subject to LAA Policies and Rules including LAA’s Travel Policy.
                                Please ensure you provide the client’s updated contact information (phone/email) in the event that the Client’s eligibility must be confirmed by our Contact Centre Team.<span style={{ color: "#d32f2f" }}>*</span>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "-10px 0px 10px 0px" }}>
                                <StyledSwitch size="small" checked={isCurrentCounselConsented} onChange={onCurrentCounselConsented} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Active Certficate Number" placeholder="Active Certficate Number" required={true} disabled={readOnly}
                                    multiline
                                    error={activeCertficateNumberMaxLengthError ? true : (activeCertficateNumberError ? true : null)}
                                    value={activeCertficateNumber}
                                    handleChange={onActiveCertficateNumberChanged}
                                    helperText={activeCertficateNumberMaxLengthError ? "Max. 100 characters are allowed." : (activeCertficateNumberError ? "Active Certficate Number is required." : null)} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Counsel Name" placeholder="Counsel Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                    value={counselName} />
                            </Grid></>)}
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            I confirm that I have provided the following information to this Client:<br />
                            (1) Legal Aid is not a free service and you will be expected to establish a monthly payment plan if you do not have one set up already.<br />
                            (2) The personal information you provide on this form may be shared within Legal Aid, including the Staff Duty Counsel Team, and with your preferred or appointed Counsel.<br />
                            Does the Client Understand and Consent? <span style={{ color: "#d32f2f" }}>*</span>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                            <StyledSwitch size="small" checked={isClientConsented} onChange={onClientConsentChanged} disabled={readOnly} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="First Name" placeholder="First Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                value={firstName} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="Last Name" placeholder="Last Name" required={true} disabled style={{ backgroundColor: "#ececec" }}
                                value={lastName} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <CustomDatePicker label="Date of Birth" placeholder="Date of Birth" required={true} disabled style={{ width: "275px", backgroundColor: "#ececec" }}
                                value={dateOfBirth} handleOnChange={() => { }} />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            Can you provide the Client's CURRENT contact information below:<br />
                            (Reminder: providing an email or phone number will help ensure that the client's application does not stall due to missing information on their client profile.) <span style={{ color: "#d32f2f" }}>*</span>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                            <StyledSwitch size="small" checked={isContatcInfoConsented} onChange={onContatcInfoConsentChanged} />
                        </Grid>
                        {isContatcInfoConsented && (<>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Phone Number" placeholder="Phone Number" disabled style={{ backgroundColor: "#ececec" }}
                                    value={phoneNumber} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Email" placeholder="Email" disabled style={{ backgroundColor: "#ececec" }}
                                    value={email} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Address" placeholder="Address" multiline disabled={readOnly}
                                    error={addressMaxLengthError ? true : null}
                                    value={address}
                                    handleChange={onAddressChanged}
                                    helperText={addressMaxLengthError ? "Max. 255 characters are allowed." : null} />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="City" placeholder="City" 
                                    value={city}  handleChange={onCityChanged}/>
                                
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField isNote={true} label="Contact Notes" placeholder="Contact Notes" multiline rows={5} sx={{ width: "90%" }}
                                    error={contactNotesMaxLengthError ? true : null}
                                    value={contactNotes} disabled={readOnly}
                                    handleChange={onContactNotesChanged}
                                    helperText={contactNotesMaxLengthError ? "Max. 1000 characters are allowed." : null} />
                            </Grid></>)}
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            I confirm that I have explained to this Client that they can provide me with the names of up to three lawyers to choose as their Preferred Counsel if LAA approves coverage on this matter.<br />
                        </Grid>
                        <Grid item xs={4} md={4} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", margin: "-10px 0px 5px 0px" }}>
                            Does the Client have Preferred Counsel?
                            <StyledSwitch size="small" checked={havePreferredCounsel} onChange={onHavePreferredCounselChanged} disabled={readOnly} />
                        </Grid>
                        {havePreferredCounsel && (
                            <Grid item xs={8} md={8} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Preferred Counsel" placeholder="Preferred Counsel" required={true} disabled={readOnly}
                                    value={preferredCounsel}
                                    handleChange={onPreferredCounselChanged} />
                            </Grid>)}
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1" }}>
                            Does the client self-identify as Indigenous?<br />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal normal 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            (Proper identification allows LAA to better understand client legal need and our ability to provide appropriate services.)
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Indigenous Ancestry" placeholder="Indigenous Ancestry" disabled={readOnly}
                                select
                                value={indigenousAncestry ? indigenousAncestry?.label : ""}
                                handleChange={onIndigenousAncestryChanged} >
                                {indigenousAncestries.map((option) => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                        <Grid item xs={4} md={4} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            In Custody?
                            <StyledSwitch size="small" checked={isInCustody} onChange={onInCustodyChanged} disabled={readOnly} />
                        </Grid>
                        {isInCustody && (<Grid item xs={8} md={8} style={{ margin: "10px 0px 10px 0px" }}>
                            {/* <SelectorTextField label="Custody Location" placeholder="Custody Location" required={true} disabled={readOnly} 
                                select
                                value={custodyLocation ? custodyLocation?.label : ""}
                                handleChange={onCustodyLocationChanged}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>
                                {custodyLocations.map((option) => (
                                    <MenuItem key={option.value} value={option.label}>
                                        {option.label}
                                    </MenuItem>))}
                            </SelectorTextField> */}
                            <AutoCompleteTextField label="Custody Location" required={true}
                                options={custodyLocations}
                                value={arryOfInputCustodyLocations}
                                onChange={onCustodyLocationChanged} />
                        </Grid>)}
                        <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                            Help Needed on All Active Charges?
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                            <StyledSwitch size="small" checked={helpNeededOnAllActiveCharges} onChange={onHelpNeededOnAllActiveChargesChanged} disabled={readOnly} />
                        </Grid>
                        {!(formType != "" && formType?.value == "851160003") && (<>
                            <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                Can you confirm a reasonable Likelihood of Imprisonment for the charges/court locations selected below:
                            </Grid>
                            <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                <StyledSwitch size="small" checked={confirmLOIforCharges} onChange={onconfirmLOIforChargesChanged} disabled={readOnly} />
                            </Grid>
                            {(confirmLOIforCharges == false) && (<>
                                <Grid item xs={12} md={12} style={{ textAlign: "left", font: "normal normal bold 15px/24px Segoe UI", letterSpacing: "0px", color: "#403e3e", opacity: "1", marginBottom: "10px" }}>
                                    If the client's matter needs to be reviewed by the Staff Duty Counsel Team for service eligibility, does the Client consent to DC ordering disclosure on their behalf? <br />
                                    (Reminder: if you cannot obtain consent for disclosure, the DC Triage Lawyer will need to obtain consent which may delay review of their matter for LOI)
                                </Grid>
                                <Grid item xs={12} md={12} style={{ margin: "-10px 0px 5px 0px" }}>
                                    <StyledSwitch size="small" checked={isConsentedDCOrdering} onChange={onConsentedDCOrderingChanged} disabled={readOnly} />
                                </Grid></>)}
                        </>)}
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <CustomDatePicker label="Next Court Date" placeholder="Next Court Date"
                                error={isNextCourtDateInvalid ? true : null} disabled={readOnly}
                                value={nextCourtDate}
                                handleOnChange={onNextCourtDateChanged}
                                helperText={isNextCourtDateInvalid ? "Invalid Date. (Hint: dd-Mmm-yyyy)" : null} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="Docket Number" placeholder="Docket Number" disabled={readOnly}
                                multiline
                                error={docketMaxLengthError ? true : null}
                                value={docketNumber}
                                handleChange={onDocketNumberChanged}
                                helperText={docketMaxLengthError ? "Max. 255 characters are allowed." : null} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <AutoCompleteTextField label="Court Location" disabled={readOnly} multiple={true}
                                options={courtLocations}
                                value={arryOfInputValues}
                                onChange={onCourtLocationsChanged} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="Court Room" placeholder="Court Room" disabled={readOnly}
                                multiline
                                error={courtRoomMaxLengthError ? true : null}
                                value={courtRoom}
                                handleChange={onCourtRoomChanged}
                                helperText={courtRoomMaxLengthError ? "Max. 255 characters are allowed." : null} />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField isNote={true} label="General Comments" placeholder="General Comments" multiline rows={5} sx={{ width: "90%" }} disabled={readOnly}
                                error={generalCommentsMaxLengthError ? true : null}
                                value={generalComments}
                                handleChange={onGeneralCommentsChanged}
                                helperText={generalCommentsMaxLengthError ? "Max. 500 characters are allowed." : null} />
                        </Grid>
                    </Grid>
                </Grid>
            </>)}
        </Grid>
    );
}


export default ClientGatewayForm;