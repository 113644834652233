import React, { useState, useEffect, useRef } from "react";
import { Grid, MenuItem } from "@mui/material";
import dayjs from 'dayjs';
import SubHeader from "../../../components/SubHeader";
import StyledDialog from '../../../components/Dialog';
import ScreenSideBar from "../../../components/ScreenSideBar";
import ClientBasicInfo from "../../../client-basic-info";
import ClientContactInfo from "../../../client-contact-info";
import ClientFinancialInfo from "../../../client-financial-info";
import SelectorTextField from "../../../components/SelectorTextField";
import TextField from "../../../components/TextField";
import CustomDatePicker from "../../../components/DatePicker";
import AttachmentList from "../../attachments/components/attachment-list";
import DocketTable from '../../dockets/docket-list';
import DataTable from "../../../components/DataTable";
import NoteTable from "../../../notes";
import Backdrop from '../../../components/elements/Loader';
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetInteractionMetaData, GetInteractionById, UpdateInteraction, SubmitInteraction, MarkInteractionForReview, DeleteInteraction, UpdateCrmServiceDecisionDutyCounselTriage } from "../../../services/interaction-service";
import { tokenRequest } from "../../../auth/authConfig";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import appconfig from '../../../appConfig';
import Badge from 'react-bootstrap/Badge';
import { useAuthorization } from "../../../hooks/useAuthorization";
import * as Constants from "../../../helper/constants";

export default function Interaction() {
    const isAuthenticated = useIsAuthenticated();
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [interactionId, setInteractionId] = useState(Number(searchParams.get("id")));
    const [clientFileId, setClientFileId] = useState(Number(searchParams.get("clientFileId")));
    const [interactionCrmId, setInteractionCrmId] = useState("");
    const [isActive, setIsActive] = useState("");
    const [isConflicted, setIsConflicted] = useState(false);
    const screenSideBarList = ['Client Basic Information', 'Client Contact Information', 'Client Financial Information', 'Lawyer Information', 'Docket Details', 'Interaction Notes'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [formStatus, setFormStatus] = useState("");
    const [formStatusError, setFormStatusError] = useState("");
    const [assignedTo, setAssignedTo] = useState("");
    const [dutyCounselType, setDutyCounselType] = useState("");
    const [dutyCounselTypeError, setDutyCounselTypeError] = useState("");
    const [adultOrYouth, setAdultOrYouth] = useState("");
    const [adultOrYouthError, setAdultOrYouthError] = useState("");
    const [isDisclosureUploaded, setIsDisclosureUploaded] = useState("");
    const [isDisclosureUploadedError, setIsDisclosureUploadedError] = useState(false);
    const [isDisclosureOrdered, setIsDisclosureOrdered] = useState("");
    const [isDisclosureOrderedError, setIsDisclosureOrderedError] = useState(false);
    const [hasPermissionToOrderDisclosure, setHasPermissionToOrderDisclosure] = useState("");
    const [hasPermissionToOrderDisclosureError, setHasPermissionToOrderDisclosureError] = useState(false);
    const [dateOfFirstAttemptedContact, setDateOfFirstAttemptedContact] = useState("");
    const [isdateOfFirstAttemptedContactInvalid, setIsdateOfFirstAttemptedContactInvalid] = useState(false);
    const [disableDateOfFirstAttemptedContact, setDisableDateOfFirstAttemptedContact] = useState(false);
    const [firstCourtDate, setFirstCourtDate] = useState("");
    const [firstCourtDateError, setFirstCourtDateError] = useState(false);
    const [isFirstCourtDateInvalid, setIsFirstCourtDateInvalid] = useState(false);
    const [disableFirstCourtDate, setDisableFirstCourtDate] = useState("");
    const [isInCustody, setIsInCustody] = useState("");
    const [isInCustodyError, setIsInCustodyError] = useState(false);
    const [financialStatus, setFinancialStatus] = useState("");
    const [serviceDecisionNumber, setServiceDecisionNumber] = useState("");
    const [serviceDecisionNumberError, setServiceDecisionNumberError] = useState("");
    const [assesmentOutcome, setAssesmentOutcome] = useState("");
    const [notes, setNotes] = useState("");
    const [clientInteractionNotes, setClientInteractionNotes] = useState([]);
    const [assesmentOutcomeDate, setAssesmentOutcomeDate] = useState("");
    const [imprisonmentLikelihood, setImprisonmentLikelihood] = useState("");
    const [imprisonmentLikelihoodError, setImprisonmentLikelihoodError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [dockets, setDockets] = useState([]);

    const [formStatuses, setFormStatuses] = useState([]);
    const [dutyCounselTypes, setDutyCounselTypes] = useState([]);
    const [adultOrYouthStatuses, setAdultOrYouthStatuses] = useState([]);
    const [financialStatuses, setFinancialStatuses] = useState([]);
    const [assesmentOutcomes, setAssesmentOutcomes] = useState([]);
    const [imprisonmentLikelihoods, setImprisonmentLikelihoods] = useState([]);
    const yesOrNoSelectionList = [{ selectionItemId: "0", label: "Yes", value: true }, { selectionItemId: "1", label: "No", value: false },];

    const [topmyfilesduedate, setTopmyfilesduedate] = useState(searchParams.get("topmyfilesduedate") == "true" ? true : false);
    const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate") == "true" ? true : false);
    const [topassignedfilesduedate, setTopassignedfilesduedate] = useState(searchParams.get("topassignedfilesduedate") == "true" ? true : false);
    const [myfilesduedate, setMyfilesduedate] = useState(searchParams.get("myfilesduedate") == "true" ? true : false);
    //const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate") == "true"? true: false);
    const [assignedfilesduedate, setAssignedfilesduedate] = useState(searchParams.get("assignedfilesduedate") == "true" ? true : false);
    const [backPage, setBackPage] = useState(searchParams.get("page"));


    const [assignedToList, setAssignedToList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const NAVIGATEURL = "/client-file?id=";
    const apiServerUrl = appconfig.API_SERVER_URL;
    const [contactMethods, setContactMethods] = useState([]);

    const [crmId, setCrmId] = useState(searchParams.get("crmId"));
    const [firstName, setFirstName] = useState(searchParams.get("firstname"));
    const [middleName, setMiddleName] = useState(searchParams.get("middlename"));
    const [lastName, setLastName] = useState(searchParams.get("lastname"));
    const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
    const [pagecount, setPagecount] = useState(searchParams.get("pagecount"));
    const [saveClicked, setSaveClicked] = useState(false);

    useEffect(() => {

        const FetchLookupData = async (token) => {

            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'ClientFileService/GetClientFileLookupData';

            fetch(uri, options)
                .then(async response => {
                    const data = await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response statusText
                        const error = (data && data.message) || response.statusText;
                        return Promise.reject(error);
                    }

                    setContactMethods(data.contactMethods);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        const FetchData = (token) => {
            const headers = new Headers();
            const bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append('Content-Type', 'application/json');

            const options = {
                method: 'GET',
                headers: headers,
            }

            const uri = apiServerUrl + 'NoteService/GetClientDetailNotes?recordId=' + interactionId + "&noteType=3" + '&onlyLastTwoYears=false';

            fetch(uri, options)
                .then(response => response.json())
                .then((response) => {
                    setClientInteractionNotes(response)
                });
        };

        if (interactionId != null && interactionId > 0) {

            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        FetchLookupData(response.accessToken);
                        FetchData(response.accessToken);
                    }
                });
            }
        }

    }, [account, instance, isAuthenticated]);

    useEffect(() => {
        setIsLoading(true);

        const fetchInitialData = async (token) => {

            const metaData = await GetInteractionMetaData(token);

            if (metaData) {
                setFormStatuses(metaData.formStatuses);
                setFormStatus(metaData.formStatuses[0]);
                setDutyCounselTypes(metaData.dutyCounselTypes);
                setAdultOrYouthStatuses(metaData.adultOrYouthStatuses);
                setFinancialStatuses(metaData.financialStatuses);
                setAssesmentOutcomes([...metaData.assesmentOutcomes,{assesmentOutcomeId: 8, assesmentOutcomeCrmValue: null, label: ''}]);
                setImprisonmentLikelihoods(metaData.imprisonmentLikelihoods);
                setAssignedToList(metaData.lawyers);
            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
            };
            if (interactionId && interactionId > 0) {
                var data = await GetInteractionById(interactionId, token);
                setInitialInteraction(data);
                console.log("GetInteractionById", data);
            };
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    fetchInitialData(response.accessToken);
                }
            });
        }

        setIsLoading(false);
    }, [isAuthenticated]);

    const clientBasicInfoComp = useRef();
    const clientContactInfoComp = useRef();

    const setInitialInteraction = (interaction) => {
        setIsActive(interaction.isActive);
        setIsConflicted(interaction.isInteractionConflicted);
        setInteractionCrmId(interaction.interactionCrmId);

        if (interaction.formStatus) { setFormStatus(interaction.formStatus); }

        if (interaction.assignedTo) {
            setAssignedTo(JSON.parse(interaction.assignedTo));
        }
        //setDutyCounselType(interaction.dutyCounselType);
        setAdultOrYouth(interaction.isAdultOrYouth);
        setIsDisclosureUploaded(interaction.isDisclosureUploaded === true ? yesOrNoSelectionList[0] : yesOrNoSelectionList[1]);
        setIsDisclosureOrdered(interaction.isDisclosureOrdered === true ? yesOrNoSelectionList[0] : yesOrNoSelectionList[1]);
        setHasPermissionToOrderDisclosure(interaction.hasPermissionToOrderDisclosure === true ? yesOrNoSelectionList[0] : (interaction.hasPermissionToOrderDisclosure === false ? yesOrNoSelectionList[1] : null));
        setDateOfFirstAttemptedContact(interaction.dateOfFirstAttemptedContact);
        if (interaction.dateOfFirstAttemptedContact != null) setDisableDateOfFirstAttemptedContact(true);
        setFirstCourtDate(interaction.firstCourtDate);
        if (interaction.firstCourtDate != null ) setDisableFirstCourtDate(true);
        setIsInCustody(interaction.isInCustody === true ? yesOrNoSelectionList[0] : (interaction.isInCustody === false ? yesOrNoSelectionList[1] : null));
        setFinancialStatus(interaction.financialStatus);
        setServiceDecisionNumber(interaction.serviceDecisionNumber);
        setAssesmentOutcome(interaction.assesmentOutcome);
        setNotes(interaction.notes);
        setAssesmentOutcomeDate(interaction.assesmentOutcomeDate);
        setImprisonmentLikelihood(interaction.imprisonmentLikelihood);
        setIsSubmitted(interaction.isSubmitted);
        setDockets(interaction.dockets);
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;
        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const validateInteractionDetails = () => {
        var status = true;
        if (!formStatus || formStatus == "") { setFormStatusError(true); status = false; }
        // if (!dutyCounselType || dutyCounselType == "") { setDutyCounselTypeError(true); status = false; }
        // if (!adultOrYouth || adultOrYouth == "") { setAdultOrYouthError(true); status = false; }
        if (!isDisclosureUploaded || isDisclosureUploaded == "") { setIsDisclosureUploadedError(true); status = false; }
        if (!isDisclosureOrdered || isDisclosureOrdered == "") { setIsDisclosureOrderedError(true); status = false; }
        if (!hasPermissionToOrderDisclosure || hasPermissionToOrderDisclosure == "") { setHasPermissionToOrderDisclosureError(true); status = false; }
        if (!firstCourtDate || firstCourtDate == "") { setFirstCourtDateError(true); status = false; }
        // if (!isInCustody || isInCustody == "") { setIsInCustodyError(true); status = false; }
        // if (!serviceDecisionNumber || serviceDecisionNumber == "") { setServiceDecisionNumberError(true); status = false; }
        if ((assesmentOutcome && assesmentOutcome != "") && (!imprisonmentLikelihood || imprisonmentLikelihood == "")) { setImprisonmentLikelihoodError(true); status = false; }
        return status;
    };



    const canSave = () => {
        var result = false;

        if (null != interactionId && interactionId > 0) {
            result = canUpdate("interaction", (null != assignedTo) ? assignedTo.value : null);
        }

        return result;
    };

    const canCreateAgencyRequest = () => { return ((null != interactionId) && (interactionId > 0) && canCreate("agency-request", null)); }

    const onSaveClicked = async (option) => {
        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInteractionDetails();
            if (isValidData) {
                //setSaveClicked(true);
                var interactionDetails = createInteractionObject();
                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            UpdateInteraction(interactionDetails, response.accessToken).then(() => {
                                if (option == 1) {
                                    navigate(NAVIGATEURL + clientFileId);
                                }                               
                            });
                        }
                    });
                }
            }
        }
        else {
            toast.error("Cannot create an interaction. Interaction needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };

    const createInteractionObject = () => {
        var now = new Date();
        const interaction = {
            "createdBy": "System",
            "createdOn": now,
            "modifiedBy": "System",
            "modifiedOn": now,
            "interactionId": interactionId,
            "isActive": true,
            "clientFileId": clientFileId,
            "formStatusId": formStatus ? formStatus?.formStatusId : null,
            "assignedTo": assignedTo ? JSON.stringify(assignedTo) : null,
            "assignedOn": now,
            "interactionCrmId": interactionCrmId ? interactionCrmId : null,
            //"dutyCounselTypeId": dutyCounselType ? dutyCounselType?.dutyCounselTypeId : null,
            "isAdultOrYouthId": adultOrYouth ? adultOrYouth?.adultOrYouthStatusId : null,
            "isDisclosureUploaded": isDisclosureUploaded ? isDisclosureUploaded?.value : null,
            "isDisclosureOrdered": isDisclosureOrdered ? isDisclosureOrdered?.value : null,
            "hasPermissionToOrderDisclosure": hasPermissionToOrderDisclosure ? hasPermissionToOrderDisclosure?.value : null,
            "dateOfFirstAttemptedContact": dateOfFirstAttemptedContact ? new Date(dateOfFirstAttemptedContact) : null,
            "firstCourtDate": firstCourtDate != "" ? new Date(firstCourtDate) : null,
            "isInCustody": isInCustody ? isInCustody?.value : null,
            "financialStatusId": financialStatus ? financialStatus?.financialStatusId : null,
            "serviceDecisionNumber": serviceDecisionNumber ? serviceDecisionNumber : null,
            "assesmentOutcomeId": assesmentOutcome ? assesmentOutcome?.assesmentOutcomeId : null,
            "assesmentOutcomeDate": assesmentOutcomeDate ? assesmentOutcomeDate : null,
            "imprisonmentLikelihoodId": imprisonmentLikelihood ? imprisonmentLikelihood?.imprisonmentLikelihoodId : null,
            "isSubmitted": isSubmitted
        }

        if ((interactionId != null) && (interactionId > 0)) {
            interaction.interactionId = interactionId;
        }
        return interaction;
    }



    const handleSelectChanged = (event, menuItems, setSelectedMenuItem, setSelectedMenuItemError) => {
        const selectedMenuItem = menuItems.find(
            (item) => item?.label === event.target.value
        );
        setSelectedMenuItem(selectedMenuItem);
        if (selectedMenuItem) { setSelectedMenuItemError(false); }
    };

    const onFormStatusChanged = (event) => {
        handleSelectChanged(event, formStatuses, setFormStatus);
    };

    const onAssignedToChanged = (event) => {
        // handleSelectChanged(event,assignedToList,setAssignedTo );
        const selectedMenuItem = assignedToList.find(
            (item) => item?.label === event.target.value
        );
        setAssignedTo(selectedMenuItem);
        if (assesmentOutcome && assesmentOutcome != "" && assesmentOutcome.assesmentOutcomeId != 8) {
            setFormStatus(formStatuses[2])
        } else if (selectedMenuItem && selectedMenuItem != "") {
            setFormStatus(formStatuses[1])
        } else {
            setFormStatus(formStatuses[0])
        }
    };

    // const onDutyCounselTypeChanged = (event) => {
    //     handleSelectChanged(event,dutyCounselTypes,setDutyCounselType );
    // }; 

    const onDisclosureUploadedChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsDisclosureUploaded, setIsDisclosureUploadedError);
    };

    const onDisclosureOrderedChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsDisclosureOrdered, setIsDisclosureOrderedError);
    };

    const onHasPermissionToOrderDisclosureChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setHasPermissionToOrderDisclosure, setHasPermissionToOrderDisclosureError);
    };

    const onDateOfFirstAttemptedContactChanged = (date) => {
        setDateOfFirstAttemptedContact(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") { //|| new Date(date) > new Date()
                setIsdateOfFirstAttemptedContactInvalid(true);
            } else {
                setDateOfFirstAttemptedContact(Date.parse(date));
                setIsdateOfFirstAttemptedContactInvalid(false);
            };
        } else {
            setDateOfFirstAttemptedContact(date);
            setIsdateOfFirstAttemptedContactInvalid(false);
        }
    };
    const onFirstCourtDateChanged = (date) => {
        setFirstCourtDate(date);
        if (date != null) {
            setFirstCourtDateError(false);
            if ((new Date(date)).toString() === "Invalid Date") { // || new Date(date) > new Date()
                setIsFirstCourtDateInvalid(true);
            } else {
                setFirstCourtDate(Date.parse(date));
                setFirstCourtDateError(false);
                setIsFirstCourtDateInvalid(false);
            };
        } else {
            setFirstCourtDate(date);
            setFirstCourtDateError(true);
            setIsFirstCourtDateInvalid(false);
        }
    };

    const onInCustodyChanged = (event) => {
        handleSelectChanged(event, yesOrNoSelectionList, setIsInCustody);
    };

    // const onFinancialStatusChanged = (event) => {
    //     handleSelectChanged(event,yesOrNoSelectionList,setFinancialStatus );
    // };

    const onServiceDecisionNumberChanged = (event) => {
        setServiceDecisionNumber(event.target.value);
    };

    const onNotesChanged = (event) => {
        setNotes(event.target.value);
    };

    const onAssesmentOutcomeChanged = (event) => {
        //handleSelectChanged(event,assesmentOutcomes,setAssesmentOutcome );
        const selectedMenuItem = assesmentOutcomes.find(
            (item) => item?.label === event.target.value
        );
        setAssesmentOutcome(selectedMenuItem);
        if (selectedMenuItem && selectedMenuItem != "" && selectedMenuItem.assesmentOutcomeId != 8) {
            setFormStatus(formStatuses[2]);
            setAssesmentOutcomeDate(new Date());
        } else if (selectedMenuItem && selectedMenuItem.assesmentOutcomeId == 8 && assignedTo && assignedTo != "") {
            setFormStatus(formStatuses[1]);
            setAssesmentOutcomeDate(null);
        } else if (assignedTo && assignedTo != "") {
            setFormStatus(formStatuses[1])
        } 
         else {
            setFormStatus(formStatuses[0])
        }
    };

    // const onAssesmentOutcomeDateChanged = (date) => {
    //     setAssesmentOutcomeDate(dayjs(date));
    // };

    const onLikelihoodOfImprisonmentChanged = (event) => {
        handleSelectChanged(event, imprisonmentLikelihoods, setImprisonmentLikelihood, setImprisonmentLikelihoodError);
    };

    const onCancelClicked = () => {
        navigateToPage();
    };

    const onCreateARClicked = async (event) => {
        if (canCreate("agency-request", null)) {
            if (interactionId != null && interactionId > 0) {
                navigate("/agencyrequest?page=interaction&interactionId=" + interactionId + "&clientFileId=" + clientFileId);
            } else {
                navigate("/agencyrequest?page=interaction&clientFileId=" + clientFileId);
            }
        }
        else {
            toast.error("You don't have permission to add Agency Requests.",{position: "top-center",autoClose: 5000,theme: "colored"});
        }
    }

    function navigateToPage() {
        switch (backPage) {
            case "topmyfiles":
                navigate(Constants.DASHBOARDPAGEURL + "?cardKey=1&topmyfilesduedate=" + topmyfilesduedate);
                break;
            case "topfilestobeassigned":
                navigate(Constants.DASHBOARDPAGEURL + "?cardKey=2&topfilestobeassignedduedate=" + topfilestobeassignedduedate);
                break;
            case "topassignedfiles":
                navigate(Constants.DASHBOARDPAGEURL + "?cardKey=3&topassignedfilesduedate" + topassignedfilesduedate);
                break;

            case "myfiles":
                navigate(Constants.MYFILESPAGEURL + "?cardKey=1&myfilesduedate=" + myfilesduedate);
                break;
            case "filestobeassigned":
                navigate(Constants.FILESTOBEASSIGNEDPAGEURL + "?cardKey=2&topfilestobeassignedduedate=" + topfilestobeassignedduedate);
                break;
            case "assignedfiles":
                navigate(Constants.ASSIGNEDFILESPAGEURL + "?cardKey=3&assignedfilesduedate" + assignedfilesduedate);
                break;
            case "clientfiledashboard":
                navigate(Constants.CLIENTFILEDASHBOARD + "?id=" + clientFileId + `&page=searchresults${getParams()}`);
                break;

            default:
                navigate(NAVIGATEURL + clientFileId);
                break;
        }
    }
    function getParams() {
        var queryParams = '';
        if (crmId || firstName || middleName || lastName || dateOfBirth || pagecount) {
            if (crmId != null) {
                queryParams = `&crmId=${crmId}`;
            }
            if (firstName) {
                queryParams = `&firstname=${firstName}`;
            }
            if (middleName) {
                queryParams = queryParams + `&middlename=${middleName}`;
            }
            if (lastName) {
                queryParams = queryParams + `&lastname=${lastName}`;
            }
            if (dateOfBirth) {
                queryParams = queryParams + `&dateofbirth=${dateOfBirth}`;
            }
            if (pagecount) {
                queryParams = queryParams + `&pagecount=${pagecount}`;
            }
        }
        return queryParams;
    }
    const isSubmitReady = () => {
        return (isActive && canSave() && (formStatus != null && formStatus != "" && formStatus.formStatusId == 3))
    }

    const canDeleteInteraction = () => {
        return ((null != searchParams.get("id")) && (Number(searchParams.get("id")) > 0) && canDelete('interaction', null));
    };

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const onDeleteClicked = (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    DeleteInteraction(Number(searchParams.get("id")), response.accessToken).then(() => {
                        navigate("/");
                    });
                }
            });
        }
    }

    const [openConfirmMarkAsConflictedDialog, setOpenConfirmMarkAsConflictedDialog] = useState(false);

    const onConfirmMarkAsConflictedClickOpen = () => {
        setOpenConfirmMarkAsConflictedDialog(true);
    };

    const onConfirmMarkAsConflictedClickClose = () => {
        setOpenConfirmMarkAsConflictedDialog(false);
    };

    const onMarkAsConflictedClicked = async (event) => {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    MarkInteractionForReview(Number(searchParams.get("id")), response.accessToken).then(() => {
                        navigate("/");
                    });
                }
            });
        }
    }

    function Save() {
        
        onSaveClicked(0);
    }

    function SaveAndClose() {
        
        onSaveClicked(1);
    }
    const onSubmitClicked = async (event) => {
        if (clientFileId && clientFileId > 0) {


            const isValidData = validateInteractionDetails();
            if (isValidData) {
                setIsSubmitted(true);
                var interactionDetails = createInteractionObject();
                interactionDetails.isSubmitted = true;

                if (account) {
                    instance.acquireTokenSilent(
                        tokenRequest
                    ).then((response) => {
                        if (response) {
                            SubmitInteraction(interactionDetails, response.accessToken).then(() => {
                                //navigate(NAVIGATEURL + clientFileId);
                            });
                        }
                    });
                }
            }
        }
        else {
            toast.error("Cannot submit interaction. Interaction needs to have an associated client file.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };

    if (isLoading) {
        return (
            <Backdrop />
        );
    } else {

        if (canRead("interaction", null)) {

            return (
                <Grid container style={{ padding: "10px 20px 0px 20px", margin: "80px 0px 60px 0px" }}>
                    <SubHeader
                        title="Client Interactions"
                        menuItemOneText="Delete Interaction"
                        onMenuItemOneClicked={onDeleteClickOpen}
                        isMenuItemOneVisible={canDeleteInteraction()}
                        isMenuItemOneDisabled={false}
                        menuItemTwoText="Mark for Review"
                        onMenuItemTwoClicked={onConfirmMarkAsConflictedClickOpen}
                        isMenuItemTwoVisible={((canUpdate("interaction", null)) && (Number(searchParams.get("id")) && Number(searchParams.get("id")) > 0) && (!isConflicted))}
                        menuItemTwoType="Warning"
                        isMenuItemTwoDisabled={false}

                        navItemOneText="Save"
                        onNavItemOneClicked={Save}
                        isNavItemOneVisible={true}
                        isNavItemOneDisabled={isActive && canSave() && !isSubmitReady() && !isSubmitted ? (saveClicked): true}                       

                        navItemTwoText="Save & Close"
                        onNavItemTwoClicked={SaveAndClose}
                        isNavItemTwoVisible={true}
                        isNavItemTwoDisabled={isActive && canSave() && !isSubmitReady() && !isSubmitted ? (saveClicked) : true}

                        navItemThreeText="Submit"
                        onNavItemThreeClicked={onSubmitClicked}
                        isNavItemThreeVisible={isSubmitReady()}
                        isNavItemThreeDisabled={isSubmitted}

                        navItemFourText="New Agency Request"
                        onNavItemFourClicked={onCreateARClicked}
                        isNavItemFourVisible={canCreateAgencyRequest()}
                        isNavItemFourDisabled={false} 
                        
                        navItemFiveText="Cancel"
                        onNavItemFiveClicked={onCancelClicked}
                        isNavItemFiveVisible={true}
                        isNavItemFiveDisabled={false}

                        />
                    <StyledDialog title="Mark for Review" open={openConfirmMarkAsConflictedDialog} onClose={onConfirmMarkAsConflictedClickClose}
                        message={"Do you really want to mark the Interaction :" + Number(searchParams.get("id")) + " for review?"}
                        onConfirmed={onMarkAsConflictedClicked} confirmationText="Mark for Review" />
                    <StyledDialog title="Delete Interaction"
                        open={openDeleteDialog}
                        onClose={onDeleteClickClose}
                        message={"Do you really want to delete the Interaction :" + Number(searchParams.get("id")) + " ? This process cannot be undone."}
                        onConfirmed={onDeleteClicked}
                        confirmationText="Delete" />
                    {/* <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} /> */}
                    {!isActive && (<>
                        <Badge bg="danger" style={{ marginBottom: "10px" }}>** Inactive **</Badge>
                    </>)}
                    {isSubmitted && (<>
                        <Badge bg="success" style={{ marginBottom: "10px" }}>** Submitted **</Badge>
                    </>)}
                    {isConflicted && (<>
                        <Badge bg="danger" style={{ marginBottom: "10px" }}>** Needs Review **</Badge>
                    </>)}
                    <Grid item xs={12} md={12} >
                        <div id="scrollByClickedItem1" style={{ scrollMarginTop: "175px" }}><ClientBasicInfo clientFileId={clientFileId} ref={clientBasicInfoComp} isReadOnly={true} /></div>
                        <div id="scrollByClickedItem2" style={{ scrollMarginTop: "161px" }}><ClientContactInfo clientFileId={clientFileId} ref={clientContactInfoComp} isReadOnly={true} contactMethods={contactMethods} /></div>
                        <div id="scrollByClickedItem3" style={{ scrollMarginTop: "161px" }}><ClientFinancialInfo clientFileId={clientFileId} isCRMOnly={true} /></div>
                        <div id="scrollByClickedItem4" style={{ scrollMarginTop: "161px" }}>
                            <Grid container style={{ padding: "10px 20px 0px 20px", border: "1px solid #7F8284", margin: "10px 0px 0px 0px", borderRadius: "8px", backgroundColor: !isActive || isConflicted ? "#ececec" : null }}>
                                <Grid item xs={12} md={12} style={{
                                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                                }}>Lawyer Information</Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Form Status" placeholder="Form Status" required={true} style={{ backgroundColor: "#ececec" }}
                                        select
                                        error={formStatusError ? true : null}
                                        value={formStatus ? formStatus?.label : ""}
                                        disabled={true}
                                        //handleChange={onFormStatusChanged}
                                        helperText={formStatusError ? "Form Status is required." : null}>
                                        {formStatuses.map((status) => (
                                            <MenuItem key={status.formStatusId} value={status.label}>
                                                {status.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Assigned To" placeholder="Assigned to Lawyer"
                                        select
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={assignedTo ? assignedTo?.label : ""}
                                        handleChange={onAssignedToChanged}
                                        SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                        {assignedToList.map((lawyer) => (
                                            <MenuItem key={lawyer.value} value={lawyer.label}>
                                                {lawyer.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                {/*<Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Duty Counsel Type" placeholder="Duty Counsel Type" style={{backgroundColor: "#ececec"}}
                                    select
                                    error={dutyCounselTypeError ? true : null}
                                    disabled={true }
                                    value={dutyCounselType ? dutyCounselType?.label : ""}
                                    handleChange={onDutyCounselTypeChanged}>
                                     helperText={dutyCounselTypeError ? "Duty Counsel Type is required." : null} 
                                    {dutyCounselTypes.map((type) => (
                                        <MenuItem key={type.dutyCounselTypeId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField> 
                            </Grid>*/}
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Adult or Youth?" placeholder="Adult or Youth" style={{ backgroundColor: "#ececec" }}
                                        select
                                        // error={adultOrYouthError ? true : null}
                                        disabled={true}
                                        value={adultOrYouth ? adultOrYouth?.label : ""}>
                                        {/* helperText={adultOrYouthError ? "Please fill the required field." : null} */}
                                        {adultOrYouthStatuses.map((type) => (
                                            <MenuItem key={type.adultOrYouthStatusId} value={type.label}>
                                                {type.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Disclosure Uploaded?" placeholder="Disclosure Uploaded" required={true}
                                        select
                                        error={isDisclosureUploadedError ? true : null}
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={isDisclosureUploaded ? isDisclosureUploaded?.label : ""}
                                        handleChange={onDisclosureUploadedChanged}
                                        helperText={isDisclosureUploadedError ? "Please fill the required field." : null}>
                                        {yesOrNoSelectionList.map((type) => (
                                            <MenuItem key={type.selectionItemId} value={type.label}>
                                                {type.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Disclosure has been ordered?" placeholder="Disclosure has been ordered" required={true}
                                        select
                                        error={isDisclosureOrderedError ? true : null}
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={isDisclosureOrdered ? isDisclosureOrdered?.label : ""}
                                        handleChange={onDisclosureOrderedChanged}
                                        helperText={isDisclosureOrderedError ? "Please fill the required field." : null}>
                                        {yesOrNoSelectionList.map((type) => (
                                            <MenuItem key={type.selectionItemId} value={type.label}>
                                                {type.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Permission to order disclosure?" placeholder="Permission to order disclosure" required={true}
                                        select
                                        error={hasPermissionToOrderDisclosureError ? true : null}
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={hasPermissionToOrderDisclosure ? hasPermissionToOrderDisclosure?.label : ""}
                                        handleChange={onHasPermissionToOrderDisclosureChanged}
                                        helperText={hasPermissionToOrderDisclosureError ? "Please fill the required field." : null}>
                                        {yesOrNoSelectionList.map((type) => (
                                            <MenuItem key={type.selectionItemId} value={type.label}>
                                                {type.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="In-Custody?" placeholder="In-Custody?" style={{ backgroundColor: "#ececec" }} required={true}
                                        select
                                        // error={isInCustodyError ? true : null}
                                        disabled={true}
                                        value={isInCustody ? isInCustody?.label : ""}
                                    //handleChange={onInCustodyChanged}
                                    >
                                        {/* helperText={isInCustodyError ? "Please fill the required field." : null} */}
                                        {yesOrNoSelectionList.map((type) => (
                                            <MenuItem key={type.selectionItemId} value={type.label}>
                                                {type.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                {/* <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                <SelectorTextField label="Financial Status" placeholder="Financial Status" required={true}
                                    select
                                    disabled={!isActive || isConflicted ? true : null }
                                    value={financialStatus ? financialStatus?.label : ""}
                                    >
                                    {financialStatuses.map((type) => (
                                        <MenuItem key={type.financialStatusId} value={type.label}>
                                            {type.label}
                                        </MenuItem>))}
                                </SelectorTextField> 
                            </Grid> */}
                                <Grid item xs={12} md={8} style={{ margin: "10px 0px 10px 0px" }}>
                                    <TextField label="Service Decision Number" placeholder="Service Decision Number" style={{ backgroundColor: "#ececec" }} required={true}
                                        // error={serviceDecisionNumberError ? true : null}
                                        disabled={true}
                                        value={serviceDecisionNumber}
                                        handleChange={onServiceDecisionNumberChanged} />
                                    {/* helperText={serviceDecisionNumberError ? "Service Decision Number is required" : null} */}
                                </Grid>
                                {/* <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px" }}>
                                <TextField label="Notes" placeholder="Notes" 
                                    multiline rows={5} 
                                    disabled={!isActive || isConflicted ? true : null }
                                    value={notes}
                                    handleChange={onNotesChanged}
                                    />
                            </Grid> */}
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <div style={{ maxWidth: "275px" }}>
                                        <CustomDatePicker label="Date of First Attempted Contact" placeholder="Date of First Attempted Contact"
                                            style={{ width: "275px", backgroundColor: (!isActive || isConflicted || disableDateOfFirstAttemptedContact )? "#ececec" : "" }}
                                            error={isdateOfFirstAttemptedContactInvalid ? true : null}
                                            disabled={(!isActive || isConflicted || disableDateOfFirstAttemptedContact )? true : null}
                                            value={dateOfFirstAttemptedContact}
                                            handleOnChange={onDateOfFirstAttemptedContactChanged}
                                            helperText={isdateOfFirstAttemptedContactInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : null} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <div style={{ maxWidth: "275px" }}>
                                        <CustomDatePicker label="First Court Date" placeholder="First Court Date" required={true}
                                        style={{ width: "275px", backgroundColor: (!isActive || isConflicted || disableFirstCourtDate )? "#ececec" : "" }}
                                            error={isFirstCourtDateInvalid ? true : (firstCourtDateError ? true : null)}
                                            disabled={(!isActive || isConflicted || disableFirstCourtDate)? true : null}
                                            value={firstCourtDate}
                                            handleOnChange={onFirstCourtDateChanged}
                                            helperText={isFirstCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : (firstCourtDateError ? "First Court Date is required." : null)} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Assesment Outcome" placeholder="Assesment Outcome"
                                        select
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={assesmentOutcome ? assesmentOutcome?.label : ""}
                                        handleChange={onAssesmentOutcomeChanged}
                                        SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                        {assesmentOutcomes.map((assesmentOutcome) => (
                                            <MenuItem key={assesmentOutcome.assesmentOutcomeId} value={assesmentOutcome.label}>
                                                {assesmentOutcome.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>
                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <CustomDatePicker label="Assesment Outcome Date" placeholder="Assesment Outcome Date" style={{ width: "275px", backgroundColor: "#ececec" }}
                                        disabled={true}
                                        value={assesmentOutcomeDate}
                                        handleOnChange={() => { }} //onAssesmentOutcomeDateChanged
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                                    <SelectorTextField label="Likelihood of Imprisonment" placeholder="Likelihood of Imprisonment" required={(assesmentOutcome && assesmentOutcome != "") ? true : false}
                                        select
                                        error={imprisonmentLikelihoodError ? true : null}
                                        disabled={!isActive || isConflicted ? true : null}
                                        value={imprisonmentLikelihood ? imprisonmentLikelihood?.label : ""}
                                        handleChange={onLikelihoodOfImprisonmentChanged}
                                        helperText={imprisonmentLikelihoodError ? "Likelihood of Imprisonment is required" : null}>
                                        {imprisonmentLikelihoods.map((imprisonmentLikelihood) => (
                                            <MenuItem key={imprisonmentLikelihood.imprisonmentLikelihoodId} value={imprisonmentLikelihood.label}>
                                                {imprisonmentLikelihood.label}
                                            </MenuItem>))}
                                    </SelectorTextField>
                                </Grid>

                                <Grid item xs={12} md={9} style={{ margin: "10px 0px 10px 0px", padding: "5px", border: "1px solid #C4C4C4", borderRadius: "6px" }}>
                                    <AttachmentList containerName="interactions" recordId={interactionId} title="Attachments (Disclosure provided by court, etc)" isDisabled={(!isActive || isConflicted) ? true : false} />
                                </Grid>

                            </Grid>
                        </div>
                        <div id="scrollByClickedItem5" style={{ scrollMarginTop: "161px" }}>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px", backgroundColor: !isActive || isConflicted ? "#ececec" : null }}>
                                <DocketTable title="Docket Details" dockets={dockets} clientFileId={clientFileId} isDisabled={(!isActive || isConflicted) ? true : false} />
                            </Grid>
                        </div>
                        <div id="scrollByClickedItem6" style={{ scrollMarginTop: "161px" }}>
                            <Grid item xs={12} md={12} style={{ margin: "10px 0px 10px 0px", backgroundColor: !isActive || isConflicted ? "#ececec" : null }}>
                                <NoteTable title="Interaction Notes" notes={clientInteractionNotes} clientFileId={clientFileId} relatedId={interactionId} noteTypeId={3} queryParams={"clientFileId=" + clientFileId.toString()} isDisabled={(!isActive || isConflicted) ? true : false} pageId={"interactions"} />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            );

        } else {
            return (
                <div className="agencyRequests">
                    <div className="agencyRequest">
                        <div className="sectionTitle">Client Interaction</div><br />
                        <div>You are not authorized to read this record.</div>
                    </div>
                </div>
            );
        }
    };

};