import React, { useState, useEffect } from "react";
import { Grid, MenuItem } from "@mui/material";
import { tokenRequest } from "../../../auth/authConfig";
import appconfig from '../../../appConfig';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import SelectorTextField from '../../../components/SelectorTextField';
import TextField from "../../../components/TextField";
import CustomDatePicker from '../../../components/DatePicker';
import SubHeader from '../../../components/SubHeader';
import StyledDialog from '../../../components/Dialog';
import ScreenSideBar from '../../../components/ScreenSideBar';
import { CreateDocket, DeleteDocket, GetDocketById, GetDocketMetadata, UpdateDocket } from "../../../services/docket-service";
import { GetInteractionById } from "../../../services/interaction-service";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { toast } from "react-toastify";
import { GetCourtRooms } from "../../../services/court-room-service";
import { useValidation } from "../../../hooks/useValidation";
import * as Constants from "../../../helper/constants";
import _ from "lodash";

export default function Docket() {
    const [isValid] = useValidation();
    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const apiServerUrl = appconfig.API_SERVER_URL;
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [docketId, setDocketId] = useState((null != searchParams.get("id")) ? Number(searchParams.get("id")) : null);
    const [docketType, setDocketType] = useState(searchParams.get("recordType"));
    const [interactionId, setInteractionId] = useState(Number(searchParams.get("recordId")));
    const [clientFileId, setClientFileId] = useState(Number(searchParams.get("clientFileId")));
    const screenSideBarList = ['Docket Details'];
    const [screenSideBarKey, setScreenSideBarKey] = useState(screenSideBarList[0]);
    const [isClickedSave, setIsClickedSave] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const [upcomingCourtDate, setUpcomingCourtDate] = useState("");
    const [isUpcomingCourtDateInvalid, setIsUpcomingCourtDateInvalid] = useState(false);
    const [courtLocation, setCourtLocation] = useState("");
    const [courtLocationError, setCourtLocationError] = useState(false);
    const [courtRoom, setCourtRoom] = useState("");
    const [courtRoomError, setCourtRoomError] = useState(false);
    const [docketNumber, setDocketNumber] = useState("");
    const [docketNumberError, setDocketNumberError] = useState("");

    const [courtLocations, setCourtLocations] = useState([]);
    const [courtRooms, setCourtRooms] = useState([]);
    const [allCourtRoom, setAllCourtRooms] = useState([]);
    const [courtRoomsLocationMapping, setCourtRoomsLocationMapping] = useState([]);

    useEffect(() => {
        const setRelatedAttributes = async (token) => {
            if (null != interactionId && interactionId > 0) {
                var interaction = await GetInteractionById(interactionId, token);
                setIsDisabled(!interaction?.isActive || interaction?.isSubmitted || interaction?.isInteractionConflicted);
            }
        }

        const fetchInitialData = async (token) => {

            const metaData = await GetDocketMetadata(token);
            const courtRooms = await GetCourtRooms();

            if (metaData) {
                setCourtLocations(metaData.courtLocations);
                setAllCourtRooms(metaData.courtRooms);
                setCourtRoomsLocationMapping(metaData.courtRoomAndLocationMapping);

            }
            else {
                toast.error("An error occurred while loading data.", { position: "top-center", autoClose: 5000, theme: "colored" });
            };
            if (docketId && docketId > 0) {
                var data = await GetDocketById(docketId, token);
                setInitialDocketDetails(data, metaData.courtLocations, metaData.courtRooms, metaData.courtRoomAndLocationMappin);
                FindRoomsBasedOnLocation(data.courtLocationId,metaData.courtRoomAndLocationMapping, metaData.courtRooms)
                
                console.log("GetDocketById", data);
            };
        }
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setRelatedAttributes(response.accessToken);
                    fetchInitialData(response.accessToken);
                }
            });
        }

    }, [isAuthenticated]);

    function FindRoomsBasedOnLocation(locationId, courtRoomsLocationMapping, allCourtRoom) {
        setCourtRooms(null);
        let courtRoomsNeedsToAdd = [];
        const selectedCourtRooms = [];
        if (locationId > 0)
            courtRoomsNeedsToAdd = _.filter(courtRoomsLocationMapping, { 'value': locationId.toString() });

            courtRoomsNeedsToAdd.forEach(element => {
            let courtRoom = _.find(allCourtRoom, { 'courtRoomId': parseInt(element.label) });
            if (courtRoom) selectedCourtRooms.push(courtRoom);
        });

        setCourtRooms(selectedCourtRooms);
    }

    const setInitialDocketDetails = (docket, courtLocationList, courtRoomList, courtRoomLocatiionMap) => {
        setUpcomingCourtDate(docket.upcomingCourtDate);
        setCourtLocation(docket.courtLocation.label);
        if (docket.courtLocationId > 0) {
            let selectedCourtLocation = _.filter(courtLocationList, { 'courtLocationId': docket.courtLocationId });
            setCourtLocation(selectedCourtLocation[0]);            
        }
        if (docket.courtRoomId > 0) {
            let selectedCourtRoom = _.filter(courtRoomList, { 'courtRoomId': docket.courtRoomId });
            setCourtRoom(selectedCourtRoom[0]);
        }

        setDocketNumber(docket.docketNumber);
    };

    const validateInformation = () => {
        var status = true;
        if (!courtLocation || courtLocation == "") { setCourtLocationError(true); status = false; }
        if (!courtRoom || courtRoom == "") { setCourtRoomError(true); status = false; }
        if (!docketNumber || docketNumber == "") { setDocketNumberError(true); status = false; }
        return status;
    };

    const onSaveClicked = async (option) => {
        if (clientFileId && clientFileId > 0) {
            const isValidData = validateInformation();
            if (isValidData) {              
                var DocketDetails = createDocketDetailsObject();
                if ((null != docketId) && (docketId > 0)) {
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async (response) => {
                            if (response) {
                               await UpdateDocket(DocketDetails, response.accessToken).then(() => {
                                if (option == 1 && response) {
                                    navigate(navigateUrl());
                                }
                                });
                            }
                        });
                    }
                } else {
                    setIsClickedSave(true);  
                    if (account) {
                        instance.acquireTokenSilent(
                            tokenRequest
                        ).then(async(response) => {
                            if (response) {
                                await CreateDocket(DocketDetails, response.accessToken).then((data) => {
                                    if (option == 0 && data) {
                                        navigate(window.location.pathname + window.location.search + `&id=${data}`);
                                        window.location.reload();
                                    }
                                    else if (option == 1 && data) {
                                        navigate(navigateUrl());
                                    }
                                });
                            }
                        });
                    }
                }
            }
        }
        else {
            toast.error("Cannot create a docket. Docket needs to have an associated interaction.", { position: "top-center", autoClose: 5000, theme: "colored" });
        }
    };

    const createDocketDetailsObject = () => {
        var now = new Date();
        const docketDetails = {
            "docketId": (docketId != null) ? docketId : 0,
            "docketNumber": (docketNumber != null && docketNumber != "") ? docketNumber : "",
            "createdBy": "System",
            "createdOn": now,
            "modifiedBy": "System",
            "modifiedOn": now,
            "interactionId": interactionId,
            "clientFileId": clientFileId,
            "upcomingCourtDate": upcomingCourtDate ? new Date(upcomingCourtDate) : null,
            "courtLocationId": parseInt(courtLocation.courtLocationId),
            "courtRoomId": parseInt(courtRoom.courtRoomId),
        }
        return docketDetails;
    };

    const canSaveDocket = () => {
        var result = false;

        if ((null != docketId) && (docketId > 0)) {
            result = canUpdate("docket", null);
        } else {
            result = canCreate("docket", null);
        }

        return result;
    };

    const onDeleteClicked = async () => {
        if (docketId != null && docketId > 0) {
            if (account) {
                instance.acquireTokenSilent(
                    tokenRequest
                ).then((response) => {
                    if (response) {
                        DeleteDocket(docketId, response.accessToken).then(() => {
                            navigate(navigateUrl());
                        });
                    }
                });
            }
        }
        setOpenDeleteDialog(false);
    };

    const onUpcomingCourtDatetChanged = (date) => {
        setUpcomingCourtDate(date);
        if (date != null) {
            if ((new Date(date)).toString() === "Invalid Date") { // || new Date(date) > new Date()
                setIsUpcomingCourtDateInvalid(true);
            } else {
                setUpcomingCourtDate(Date.parse(date));
                setIsUpcomingCourtDateInvalid(false);
            };
        } else {
            setUpcomingCourtDate(date);
            setIsUpcomingCourtDateInvalid(false);
        }
    };

    const handleSelectChanged = (event, menuItems, setSelectedMenuItem, setSelectedMenuItemError) => {
        const selectedMenuItem = menuItems.find(
            (item) => item?.label === event.target.value
        );
        setSelectedMenuItem(selectedMenuItem);
        if (selectedMenuItem) { setSelectedMenuItemError(false); }
    };

    const onCourtLocationChanged = (event) => {
        const selectedMenuItem = courtLocations.find(
            (item) => item?.label === event.target.value
        );
        setCourtLocation(selectedMenuItem);

        let courtLocationBasedOnId = _.filter(courtLocations, { 'label': event.target.value.toString() });
        FindRoomsBasedOnLocation(courtLocationBasedOnId[0].courtLocationId, courtRoomsLocationMapping, allCourtRoom);
        

        if (selectedMenuItem) { setCourtLocationError(false); }

    };

    const onCourtRoomChanged = (event) => {
        handleSelectChanged(event, courtRooms, setCourtRoom, setCourtRoomError);
    };

    const onDocketNumberChanged = (event) => {
        setDocketNumber(event.target.value);
        if (event.target.value.length > 0) setDocketNumberError(false);
    };

    const onCancelClicked = () => {
        navigate(navigateUrl());
    };

    const navigateUrl = () => {
        if (docketType == "interaction") {
            return "/interaction?id=" + interactionId + "&clientFileId=" + clientFileId;
        }
    };

    const handleToggle = (text) => () => {
        setScreenSideBarKey(text);
        let listItemKey = `${screenSideBarList?.indexOf(text) + 1}`;

        const element = document.getElementById('scrollByClickedItem' + listItemKey.toString());
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onDeleteClickOpen = () => {
        setOpenDeleteDialog(true);
    };
    const onDeleteClickClose = () => {
        setOpenDeleteDialog(false);
    };

    const canDeleteDocket = () => {
        return ((null != docketId) && (Number(docketId) > 0) && canDelete('docket', null));
    };

    function Save() {
       
        onSaveClicked(0);
    }

    function SaveAndClose() {
       
        onSaveClicked(1);
    }

    return (
        <>
            <Grid container style={{
                padding: "15px 20px 0px 20px", textAlign: 'left', font: 'normal normal normal 20px/25px Franklin Gothic Medium',
                letterSpacing: '0px', color: '#000000', opacity: '1', margin: "80px 0px 0px 215px"
            }}>
                <SubHeader
                    title="Docket Details"
                    menuItemOneText="Delete Docket"
                    onMenuItemOneClicked={onDeleteClickOpen}
                    isMenuItemOneVisible={canDeleteDocket()}
                    isMenuItemOneDisabled={isDisabled}
                    menuItemTwoText=""
                    onMenuItemTwoClicked=""
                    isMenuItemTwoVisible={false}
                    isMenuItemTwoDisabled={false}

                    navItemOneText="Save"
                    onNavItemOneClicked={Save}
                    isNavItemOneVisible={true}
                    isNavItemOneDisabled={isDisabled || isClickedSave}

                    navItemTwoText='Save & Close'
                    onNavItemTwoClicked={SaveAndClose}
                    isNavItemTwoVisible={true}
                    isNavItemTwoDisabled={isDisabled || isClickedSave}

                    navItemThreeText=''
                    onNavItemThreeClicked=''
                    isNavItemThreeVisible={false}
                    isNavItemThreeDisabled={false}
                    navItemFourText="Cancel"
                    onNavItemFourClicked={onCancelClicked}
                    isNavItemFourVisible={true}
                    isNavItemFourDisabled={false} />
                <StyledDialog title="Delete Docket" open={openDeleteDialog} onClose={onDeleteClickClose}
                    message={"Do you really want to delete the Docket? This process cannot be undone."}
                    onConfirmed={onDeleteClicked} confirmationText="Delete" />
                <ScreenSideBar screenSideBarList={screenSideBarList} handleToggle={handleToggle} screenSideBarKey={screenSideBarKey} />
                <div id="scrollByClickedItem1" style={{ width: '100%', scrollMarginTop: "175px" }}>
                    <Grid container style={{ padding: "10px 20px 10px 20px", border: "1px solid #7F8284", margin: "0px 0px 60px 0px", borderRadius: "8px", backgroundColor: isDisabled == true ? "#ececec" : null }}>
                        <Grid item xs={12} md={12} style={{
                            textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                            letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "10px"
                        }}>Docket Details</Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <TextField label="Docket Number" placeholder="Docket Number" style={{ backgroundColor: (docketId == null || docketId == "") ? null : "#ececec" }} required={true}
                                error={docketNumberError ? true : null}
                                disabled={(docketId == null || docketId == "") ? false : true}
                                value={docketNumber}
                                handleChange={onDocketNumberChanged}
                                helperText={docketNumberError ? "Docket Number is required" : null} />
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <div style={{ maxWidth: "275px" }}>
                                <CustomDatePicker label="Upcoming Court Date" placeholder="Upcoming Court Date"
                                    error={isUpcomingCourtDateInvalid ? true : null}
                                    disabled={isDisabled ? true : null}
                                    value={upcomingCourtDate}
                                    handleOnChange={onUpcomingCourtDatetChanged}
                                    helperText={isUpcomingCourtDateInvalid ? "Invalid date format. Date format is dd-Mmm-yyyy." : null} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Court Location" placeholder="Court Location" required={true}
                                select
                                error={courtLocationError ? true : null}
                                disabled={isDisabled ? true : null}
                                value={courtLocation ? courtLocation?.label : ""}
                                handleChange={onCourtLocationChanged}
                                helperText={courtLocationError ? "Court Location is required ." : null}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>
                                {courtLocations.map((courtLocation) => (
                                    <MenuItem key={courtLocation.courtLocationId} value={courtLocation.label}>
                                        {courtLocation.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                            <SelectorTextField label="Court Room" placeholder="Court Room" required={true}
                                select
                                error={courtRoomError ? true : null}
                                disabled={isDisabled ? true : null}
                                value={courtRoom ? courtRoom?.label : ""}
                                handleChange={onCourtRoomChanged}
                                helperText={courtRoomError ? "Court Room is required ." : null}
                                SelectProps={{ MenuProps: { style: { maxHeight: 250, }, }, }}>

                                {courtRooms.map((courtRoom) => (
                                    <MenuItem key={courtRoom.courtRoomId} value={courtRoom.label}>
                                        {courtRoom.label}
                                    </MenuItem>))}
                            </SelectorTextField>
                        </Grid>
                    </Grid></div>
            </Grid>
        </>
    );
}