export const general =   [{field:'serviceDecisionsNumber', width: 180, headerName:'Number', editable: false}, 
                            {field: 'areaOfLaw', width: 150, headerName:'Area of Law', editable: false }, 
                            {field: 'matterType', width: 150, headerName:'Matter Type' , editable: false},
                            {field: 'specialization', width: 150, headerName:'Specialization', editable: false }, 
                            {field: 'matterNumber', width: 150, headerName:'Matter Number', editable: false },  
                            {field: 'preferredLawyer', width: 150, headerName:'Preferred Lawyer', editable: false }, 
                            {field: 'isUrgent', width: 150, headerName:'Urgent?', editable: false },
                            {field: 'id', width: 150, headerName:'Service Decision ID', editable: false, hide: true}, 
    ];  

export const certificateAdminstrationDeatils =   [{field:'eligibleForRepresentation', width: 200, headerName:'Eligible for Representation', editable: false}, 
                            {field: 'curentStatus', width: 150, headerName:'Current Status', editable: false }, 
                            {field: 'action', width: 150, headerName:'Action' , editable: false},
                            {field: 'nextCourtAppearanceDate', type: 'date', width: 220, headerName:'Next Court Appearance Date', renderDate: true, editable: false }, 
                            {field: 'decisionReason', width: 150, headerName:'Decision Reason', editable: false },  
                            {field: 'limitedByFinancialEligibility', width: 240, headerName:'Limited By Financialy Eligibility', editable: false }, 
                            {field: 'isTravelAuthorized', width: 150, headerName:'Travel Authorized?', editable: false },
                            {field: 'id', width: 150, headerName:'Docket ID', editable: false, hide: true}, 
    ]; 

export const docketsAndLeaglIssues =   [{field:'docketNumber', width: 150, headerName:'Docket Number', editable: false}, 
                            {field: 'legalIssue', width: 250, headerName:'Legal Issue', editable: false }, 
                            {field: 'isServiceProvided', width: 150, headerName:'Service Provided' , editable: false},
                            {field: 'nextCourtAppearance', type: 'date', width: 220, headerName:'Next Court Appearance Date', renderDate: true, editable: false }, 
                            {field: 'courtLocation', width: 150, headerName:'Court Location', editable: false },  
                            {field: 'courtRoomNumber', width: 150, headerName:'Court Room No', editable: false }, 
                            {field: 'courtEvent', width: 150, headerName:'Court Event', editable: false },
                            {field: 'courtLevel', width: 150, headerName:'Court Level', editable: false },
                            {field: 'highestLevelOfCharge', width: 180, headerName:'Highest Level of Charge', editable: false },
                            {field: 'reasonForNoService', width: 180, headerName:'Reason for No Service', editable: false },
                            {field: 'id', width: 150, headerName:'Docket ID', editable: false, hide: true}, 
    ];  
    
export const relatedParties =   [{field:'partyName', width: 180, headerName:'Party Name', editable: false}, 
                            {field: 'relationship', width: 150, headerName:'Relationship', editable: false }, 
                            {field: 'counsel', width: 150, headerName:'Counsel' , editable: false},
                            {field: 'docketNumber', width: 250, headerName:'Docket Number', editable: false }, 
                            {field: 'id', width: 150, headerName:'Unique Id', editable: false, hide: true },
    ];  

export const dutyCounselTriage =   [{field:'id', width: 220, headerName:'Current DC Triage Status', editable: false}, 
                            {field: 'serviceDecision', width: 150, headerName:'Service Decision', editable: false }, 
                            {field: 'assignedTo', width: 150, headerName:'Assigned To' , editable: false},
    ];  

export const changeofCounsel =   [{field:'currentLawyer', width: 150, headerName:'Current Lawyer', editable: false}, 
                            {field: 'requestedBy', width: 150, headerName:'Requested By', editable: false }, 
                            {field: 'requestDate', type: 'date', width: 150, headerName:'Requested Date', renderDate: true, editable: false},
                            {field: 'reason', width: 150, headerName:'Reason', editable: false }, 
                            {field: 'decision', width: 150, headerName:'Decision', editable: false }, 
                            {field: 'id', width: 150, headerName:'Unique Id', editable: false, hide: true },
    ];  

export const offer =   [{field:'offerNumber', width: 150, headerName:'Offer Number', editable: false}, 
                            {field: 'offerTo', width: 150, headerName:'Offer To', editable: false }, 
                            {field: 'status', width: 150, headerName:'Status' , editable: false},
                            {field: 'issuedDate', type: 'date', width: 150, headerName:'Issued Date', renderDate: true, editable: false }, 
                            {field: 'acceptedOrRejectedDate', type: 'date', width: 220, headerName:'Accepted or Rejected Date', renderDate: true, editable: false }, 
                            {field: 'rejectionReason', width: 150, headerName:'Rejection Reason', editable: false },
                            {field: 'rejectionComments', width: 150, headerName:'Rejection Comments', editable: false },
                            {field: 'expirationDate', type: 'date', width: 150, headerName:'Expiration Date', renderDate: true, editable: false },
                            {field: 'id', width: 150, headerName:'Unique Id', editable: false, hide: true },
    ];  

export const certificate =   [{field:'certificateNumber', width: 150, headerName:'Certificate Number', editable: false}, 
                            {field: 'status', width: 150, headerName:'Status', editable: false }, 
                            {field: 'serviceType', width: 180, headerName:'Service Type' , editable: false},
                            {field: 'lawyerName', width: 150, headerName:'Lawyer Name', editable: false }, 
                            {field: 'issuedDate', type: 'date', width: 150, headerName:'Issued Date', renderDate: true, editable: false },
                            {field: 'id', width: 150, headerName:'Unique Id', editable: false, hide: true }, 
    ];  