import { useEffect } from "react";

export function useDateFormat() {

    useEffect(() => {
    }, []);

    const formatDate = function (date) {
        const months = {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec',
        }

        var d = new Date(date);
        var month = months[d.getMonth()];
        var day = d.getDate();
        var year = d.getFullYear();

        const formatted = `${day}-${month}-${year}`;
        return formatted.toString();
    };

    return [formatDate];
}