import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidation } from "../../../hooks/useValidation";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

import { GetFilesToBeAssigned, SearchFilesToBeAssigned } from "../../../services/dashboard.service";
import { tokenRequest } from "../../../auth/authConfig";
import {format} from 'date-fns';
import DataTable from '../../../components/DataTable';
import TextField from "../../../components/TextField";
import CustomDatePicker from '../../../components/DatePicker';
import StyledButton from '../../../components/elements/StyledButton';
import { IoWarning } from "react-icons/io5";
import SubHeader from '../../../components/SubHeader';
import * as Constants from "../../../helper/constants";
import { Grid, Stack } from '@mui/material';
import StyledSwitch from '../../../components/StyledSwitch';

function FilesToBeAssigned() {


    const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [isValid] = useValidation();
    const navigate = useNavigate();
    const [gridColumns, setGridColumns] = useState([]);
    const [gridRows, setGridRows] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    // const [isReadOnlyDetail, setReadOnlyDetail] = useState("");
    // const [dateOfBirthError, setDateOfBirthError] = useState(false);
    // const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState("");

    //region ShowMore
    // const [maximumPageRecordCount, setMaximumPageRecordCount] = useState(5);
    // const [maximumBackendPageRecordCount, setMaximumBackendPageRecordCount] = useState(10);
    // const [showMore, setShowMore] = useState(false);
    // const [hasSearched, setHasSearched] = useState(false);
    // const [frontendPageIndex, setFrontendPageIndex] = useState(0);
    // const [frontendPageTake, setFrontendPageTake] = useState(maximumPageRecordCount);
    // const [backendPageIndex, setBackendPageIndex] = useState(0);
    // const [backendPageTake, setBackendPageTake] = useState(maximumBackendPageRecordCount);
    // const [onHandRowData, setonHandRowData] = useState([]);
    //#endregion

    const [searchParams, setSearchParams] = useSearchParams();
    const [backPage, setBackPage] = useState(searchParams.get("page"));
    const [filterByDueDate, setFilterByDueDate] = useState(searchParams.get("filestobeassignedduedate") == "true" ? true : false);
    const [topMyFilesFilterByDueDate, setTopMyFilesFilterByDueDate] = useState(searchParams.get("topfilestobeassignedduedate") ? searchParams.get("topfilestobeassignedduedate") : "2");

    // #region PageLoad

    useEffect(() => {
        window.scrollTo(0, 0);
        setInitialGridColumns();
    }, []);

    useEffect(() => {
        LoadInitialData(filterByDueDate);

    }, [instance]);

    function LoadInitialData(dueDate) {
        if (account) {
            instance.acquireTokenSilent(
                tokenRequest
            ).then((response) => {
                if (response) {
                    setInitialGridData(response.accessToken, dueDate);
                }
            });
        }
    }

    const setInitialGridData = async (token, dueDate) => {

        await GetFilesToBeAssigned(token, dueDate, 0, 0).then((rowData) => {
            setInitialGridColumns();

            if (rowData) {
                setGridRows(rowData);
            }
        })
        //setReadOnlyDetails();
    }

    const setInitialGridColumns = () => {

        setGridColumns([{ field: 'idText', width: 100, headerName: 'ID', renderCell: true, editable: false },
        { field: 'requestType', width: 180, headerName: 'Request Type', editable: false },
        { field: 'firstName', width: 150, headerName: 'Client First Name', editable: false },
        { field: 'lastName', width: 150, headerName: 'Client Last Name', editable: false },
        { field: 'dateOfBirth', type: 'date', width: 150, headerName: 'Client Date of Birth',  renderDate: true, editable: false },
        { field: 'modifiedOn', type: 'date', width: 150, headerName: 'File Received Date', renderDate: true, editable: false },
        { field: 'upcomingCourtDate',type: 'date', width: 150, headerName: 'Next Court Date', renderWarning: true, editable: false },
        { field: 'courtLocation', width: 220, headerName: 'Court Location', editable: false },
        { field: 'isOutDated', width: 250, headerName: 'Outdated?', editable: false, hide: true },
        { field: 'clientFileId', width: 120, headerName: 'client File ID', editable: false, hide: true }]);
    }
    const columns = [];
    function getLinkUrl(params) {
        if (params.value.split("-")[0] == 'I') {
            return "/interaction?id=" + params.value.split("-")[1] + "&topfilestobeassignedduedate=" + filterByDueDate + "&page=filestobeassigned&clientFileId=" + params.row.clientFileId
        }
        else if (params.value.split("-")[0] == 'AR') {
            return "/agencyrequest?agencyrequestid=" + params.value.split("-")[1] + "&topfilestobeassignedduedate=" + filterByDueDate + "&page=filestobeassigned&clientFileId=" + params.row.clientFileId
        }
    }

    gridColumns.forEach((columnHeader) => {
        columns.push(
            {
                field: columnHeader.field,
                renderHeader: () => (<div style={{ font: 'normal normal normal 15px/16px Franklin Gothic Medium', color: "#000000" }}>{columnHeader.headerName}</div>),
                renderCell: (params) => (columnHeader.renderCell ? <>  <a href={getLinkUrl(params)}>{params.value}</a> </> :
                    (columnHeader.renderWarning ? (((new Date() - new Date(params.row.upcomingCourtDate)) / (1000 * 3600 * 24) > 7) && params.row.isOutDated == '1') ? <> {params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null} <IoWarning title="Next court date is already passed" size={18} style={{ color: "#d32f2f", marginLeft: "5px" }} /> </> : <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </> :
                     (columnHeader.renderDate ? <>{params.value != null ? format(new Date(params.value), 'dd-MMM-yyyy') : null}  </>: null ))),
                width: columnHeader.width,
                editable: columnHeader.editable,
                hide: columnHeader.hide
            },
        );
    });



    //  const setInitialShowMoreToGrid = async (rowData) => {
    //     setonHandRowData(rowData);
    //     setGridRows(rowData);
    // }

    //#endregion

    //#region ShowMore    
    // const setInitialShowMoreToGrid = async (rowData) => {
    //     setonHandRowData(rowData);
    //     if (rowData && rowData.length > 0) {
    //         if (rowData.length > 0) {
    //             const arrayData = rowData.slice(0, frontendPageTake)
    //             setGridRows(arrayData);
    //         }
    //         if (rowData.length > frontendPageTake) {
    //             setShowMore(true);
    //         }
    //         var indexValue = frontendPageIndex + frontendPageTake;
    //         var pageTakeValue = frontendPageTake + frontendPageTake;
    //         setFrontendPageIndex(indexValue);
    //         setFrontendPageTake(pageTakeValue);

    //     }
    // }

    // const setInitialSesrchShowMoreToGrid = async (rowData) => {
    //     setonHandRowData(rowData);
    //     if (rowData && rowData.length > 0) {
    //         if (rowData.length > 0) {
    //             const arrayData = rowData.slice(0, maximumPageRecordCount)
    //             setGridRows(arrayData);
    //         }
    //         if (rowData.length > maximumPageRecordCount) {
    //             setShowMore(true);
    //         }
    //         var indexValue = maximumPageRecordCount;
    //         var pageTakeValue = maximumPageRecordCount + maximumPageRecordCount;
    //         setFrontendPageIndex(indexValue);
    //         setFrontendPageTake(pageTakeValue);

    //     }
    // }

    // const onShowMoreClicked = () => {
    //     if (onHandRowData && onHandRowData.length > 0) {
    //         //Set frontend data retrival from existing data
    //         var indexValue;
    //         var pageTakeValue;
    //         if (onHandRowData.length > frontendPageIndex) {
    //             const arrayData = onHandRowData.slice(frontendPageIndex, frontendPageTake);
    //             const newGridRows = gridRows.concat(arrayData);
    //             setGridRows(newGridRows);
    //         }


    //         if (frontendPageTake == maximumBackendPageRecordCount) {
    //             indexValue = 0;
    //             pageTakeValue = maximumPageRecordCount;
    //         }
    //         else {
    //             indexValue = frontendPageIndex + frontendPageTake;
    //             pageTakeValue = frontendPageTake + frontendPageTake;
    //         }

    //         setFrontendPageIndex(indexValue);
    //         setFrontendPageTake(pageTakeValue);



    //         //fetch data when the onHandRowData reach the end
    //         if (frontendPageTake == maximumBackendPageRecordCount) {
    //             if (account) {
    //                 instance.acquireTokenSilent(
    //                     tokenRequest
    //                 ).then(async (response) => {
    //                     const backendPageIndexValue = backendPageIndex + 1;
    //                     setBackendPageIndex(backendPageIndexValue);
    //                     setBackendPageTake(backendPageTake);
    //                     if (response) {
    //                         if (hasSearched) {
    //                             const searchBy = GetSearchBy();
    //                             SearchFilesToBeAssigned(response.accessToken, searchBy, backendPageIndexValue, backendPageTake).then((data) => {
    //                                 if (data) {
    //                                     setonHandRowData(data);
    //                                     if (data && data.length == 0) {
    //                                         setShowMore(false);
    //                                     }
    //                                     else {
    //                                         setShowMore(true);
    //                                     }
    //                                 }
    //                             });
    //                         }
    //                         else {
    //                             await GetFilesToBeAssigned(response.accessToken, backendPageIndexValue, backendPageTake).then((rowData) => {
    //                                 setonHandRowData(rowData);
    //                                 if (rowData && rowData.length == 0) {
    //                                     setShowMore(false);
    //                                 }
    //                                 else {
    //                                     setShowMore(true);
    //                                 }
    //                             });
    //                         }
    //                         setReadOnlyDetails();
    //                     }

    //                 });
    //             }
    //         }
    //     }

    // };

    function GetSearchBy() {
        const firstNameSearch = { columnName: "FirstName", searchValue: firstName };
        const lastNameSearch = { columnName: "LastName", searchValue: lastName };
        const dateOfBirthSearch = { columnName: "DateOfBirth", searchValue: dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : "" };

        return [firstNameSearch, lastNameSearch, dateOfBirthSearch];
    }

    // function setInitialPageIndexValues() {

    //     setFrontendPageIndex(0);
    //     setBackendPageIndex(0);
    //     setFrontendPageTake(maximumPageRecordCount);
    //     setBackendPageTake(maximumBackendPageRecordCount);
    // }

    //#endregion

    //#region OnChangeEvents
    // const onFirstNameChanged = (event) => {

    //     setFirstName(event.target.value);
    //     setReadOnlyDetails();
    // };

    // const onLastNameChanged = (event) => {
    //     setLastName(event.target.value);
    //     setReadOnlyDetails();
    // };

    // const onBirthDateChanged = (date) => {
    //     setDateOfBirth(date);
    //     if (date != null) {
    //        if((new Date(date)).toString() === "Invalid Date"){
    //         setDateOfBirthError(true);
    //         setDateOfBirthErrorMessage("Invalid Date of Birth (Hint: dd-Mmm-yyyy)");
    //        } else {
    //         if ( new Date(date) > new Date()) {
    //             setDateOfBirthError(true);
    //             setDateOfBirthErrorMessage("Invalid Date of Birth (Hint: dd-Mmm-yyyy)");
    //         } else {
    //         setDateOfBirthError(false);
    //         setDateOfBirth(Date.parse(date));
    //         setReadOnlyDetails();
    //         }
    //        };  
    //     } 
    // };

    // const setReadOnlyDetails = () => {
    //     if ((firstName || firstName.trim()) || (lastName || lastName.trim() != null) || (dateOfBirth || dateOfBirth.trim() != null))
    //         setReadOnlyDetail(false);
    //     else
    //         setReadOnlyDetail(true);
    // };

    //#endregion

    // const onSearchClicked = () => {

    //     setHasSearched(true);

    //     const searchBy = GetSearchBy();

    //     if (searchBy) {
    //         if (account) {
    //             instance.acquireTokenSilent(
    //                 tokenRequest
    //             ).then((response) => {
    //                 if (response) {
    //                     //Search Value
    //                     SearchFilesToBeAssigned(response.accessToken, searchBy, 0, maximumBackendPageRecordCount).then((data) => {
    //                         if (data) {
    //                             setGridRows(data);
    //                             //#region Showmore
    //                             setInitialPageIndexValues();
    //                             setInitialSesrchShowMoreToGrid(data);
    //                             if (data && data.length == 0) {
    //                                 setShowMore(false);
    //                             }
    //                             else {
    //                                 setShowMore(true);
    //                             }
    //                             //#endregion
    //                         }
    //                     });

    //                 }
    //             });
    //         }
    //     }
    //     else {
    //         LoadInitialData();
    //         //#region Showmore
    //         setHasSearched(false);
    //         //#endregion
    //     }

    // };

    const onCancelClicked = () => {

        navigateToPage();
    };

    function navigateToPage() {
        switch (backPage) {
            case "topfilestobeassigned":
                navigate(Constants.DASHBOARDPAGEURL + "?cardKey=2&topfilestobeassignedduedate=" + topMyFilesFilterByDueDate);
                break;
            default: navigate(Constants.DASHBOARDPAGEURL + "?cardKey=2&topfilestobeassignedduedate=" + topMyFilesFilterByDueDate);
                break;
        }
    }
    const onFilterChanged = (event) => {

        setFilterByDueDate(event.target.checked);
        LoadInitialData(event.target.checked);

    };
    if (canRead("files-to-be-assigned-dashboard", null)) { //ToDo - Change this role

        return (
            <Grid container style={{ padding: "20px 20px 0px 20px", margin: "80px 0px 0px 0px" }}>
                <SubHeader
                    title="Files to Assign"
                    menuItemOneText=""
                    onMenuItemOneClicked={false}
                    isMenuItemOneVisible={false}
                    isMenuItemOneDisabled={false}
                    menuItemTwoText=""
                    onMenuItemTwoClicked=""
                    isMenuItemTwoVisible={false}
                    menuItemTwoType="Warning"
                    isMenuItemTwoDisabled={false}
                    navItemOneText="Cancel"
                    onNavItemOneClicked={onCancelClicked}
                    isNavItemOneVisible={true}
                    isNavItemOneDisabled={false}
                    navItemTwoText=""
                    onNavItemTwoClicked=""
                    isNavItemTwoVisible={false}
                    isNavItemTwoDisabled={false}
                    navItemThreeText=""
                    onNavItemThreeClicked=""
                    isNavItemThreeVisible={false}
                    isNavItemThreeDisabled={false}
                    navItemFourText=""
                    onNavItemFourClicked=""
                    isNavItemFourVisible={false}
                    isNavItemFourDisabled={false} />
                {/* <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                    <TextField label="First Name" placeholder="First Name"
                        value={firstName}
                        handleChange={onFirstNameChanged} />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                    <TextField label="Last Name" placeholder="Last Name"
                        value={lastName}
                        handleChange={onLastNameChanged} />
                </Grid>
                <Grid item xs={12} md={4} style={{ margin: "10px 0px 10px 0px" }}>
                    <CustomDatePicker label="Date of Birth" placeholder="Date of Birth" disableFuture={true}
                        error={dateOfBirthError ? true : null}
                        value={dateOfBirth}
                        handleOnChange={onBirthDateChanged}
                        helperText={dateOfBirthError ? dateOfBirthErrorMessage : null} />
                </Grid>
                <Grid item xs={12} md={12} style={{ margin: "0px 0px 10px 0px" }}>
                    <StyledButton variant={"outlined"} color="error" disabled={dateOfBirthError ? true : false} onClick={onSearchClicked}>Search</StyledButton>
                </Grid> */}
                {/* <SelectorTextField label="Filter By" placeholder="Filter By" required={true}
                    select
                    handleChange={onFilterChanged}
                    value={filterByDueDate ? filterByDueDate : ""}
                    SelectProps={{ MenuProps: { style: { maxHeight: 250, }, } }}>

                    <MenuItem key="1" value="1">
                        Due Date
                    </MenuItem>
                    <MenuItem key="2" value="2">
                        All
                    </MenuItem>

                </SelectorTextField> */}

                <Grid item xs={12} md={12} style={{ font: "normal normal normal 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: "#585454", opacity: "1" }}>
                    <Stack direction="row"> <div div style={{ marginRight: "10px" }}>Due Today: </div> <StyledSwitch style={{ textAlign: "left", font: "normal normal bold 15px/25px Franklin Gothic Medium", letterSpacing: "0px", color: " #585454", opacity: "1" }} checked={filterByDueDate} onChange={onFilterChanged} /> </Stack>
                </Grid>
                <DataTable columns={columns} rows={gridRows} />
                {/* <Grid item xs={12} md={12} style={{ margin: "0px 0px 10px 0px" }}>
                    <div align="center" style={{ marginTop: "10px" }}>  <StyledButton variant={"outlined"} color="error" onClick={onShowMoreClicked} disabled={showMore ? "" : "disabled"}>Show More</StyledButton>
                    </div></Grid> */}
                <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
            </Grid>);
    } else {
        return (
            <Grid container style={{ padding: "10px 20px 20px 20px", border: "1px solid #7F8284", margin: "10px 0px 10px 0px", borderRadius: "8px" }}>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal normal 17px/22px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", marginBottom: "15px"
                }}>Files to Assign
                </Grid>
                <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal 200 15px/17px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1",
                }}>** You are not authorized to view this details **
                </Grid>
            </Grid>
        );
    }
}

export default FilesToBeAssigned;