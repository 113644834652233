import React, { useEffect, useState, useRef } from 'react';
import appconfig from '../../../appConfig'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GetConflictDashboardCounts } from '../../../services/crm-conflict-dashboard.service'
import { tokenRequest } from "../../../auth/authConfig";
import TopCrmConflictedClientFiles from '../top-crm-conflicted-client-files';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from '../../../hooks/useAuthorization';
import { Grid } from '@mui/material';
import InfoCard from '../../../components/Card';
import BackDrop from '../../../components/elements/Loader';
import TopDcpConflictedClientFiles from '../top-dcp-conflicted-client-files';
import TopConflictedInteractions from '../top-conflicted-interactions';



function ConflictDashboard() {

  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  
  const [dcpConflictedClientFileCount, setDcpConflictedClientFileCount] = useState(0);
  const [crmConflictedClientFileCount, setCrmConflictedClientFileCount] = useState(0);
  const [conflictedInteractionsCount, setConflictedInteractionsCount] = useState(0);

  const [isDcpConflictedClientFile, setIsDcpConflictedClientFile] = useState(false);
  const [isCrmConflictedClientFile, setIsCrmConflictedClientFile] = useState(false);
  const [isCrmConflictedInteraction, setIsCrmConflictedInteraction] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardKey, setCardKey] = useState(searchParams.get("cardKey") ? searchParams.get("cardKey") : "1");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      LoadInitialData();
      setIsLoading(false);
    }

  }, [account, instance]);

  useEffect(() => {
    function handleSelect(card1: boolean, card2: boolean, card3: boolean) {
      setIsDcpConflictedClientFile(card1);
      setIsCrmConflictedClientFile(card2);
      setIsCrmConflictedInteraction(card3);
    };
    if (cardKey == "1") {
      handleSelect(true, false, false);
    } else if (cardKey == "2") {
      handleSelect(false, true, false);
    }
    else if (cardKey == "3") {
      handleSelect(false, false, true);
    }
  }, [cardKey]);

  function LoadInitialData() {
    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then((response) => {
        if (response) {
          setInitialTopCountData(response.accessToken).then(async () => {
          
          })
        }
      });
    }
  }

  const setInitialTopCountData = async (token: string) => {

    await GetConflictDashboardCounts(token).then((countDetails) => {
      countDetails.forEach((element: any) => {
        if (element.label == "CrmConflictedClientFileCount") {
          setCrmConflictedClientFileCount(element.value);
        }
        else if (element.label == "ConflictedInteractionsCount") {
          setConflictedInteractionsCount(element.value)
        }
        else if (element.label == "DcpConflictedClientFileCount") {
          setDcpConflictedClientFileCount(element.value)
        }
      });

    });

  }


  if (canRead("crm-conflict-dashboard", null)) {
    return (<>
      {isLoading ? <BackDrop /> :
        <>
          {isAuthenticated && (canRead("crm-conflict-dashboard", null)) && (
            <Grid container direction="row" alignItems="flex-start" style={{
              padding: "0px 20px 0px 20px",
              background: "#F0F0F0 0% 0% no-repeat padding-box"
            }} >
              <Grid item xs={12} md={12} style={{
                textAlign: "left", font: "normal normal bold 32px/39px Franklin Gothic Medium",
                letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 20px 10px"
              }}>Review Dashboards</Grid>

              <Grid item xs={12} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="1" title="Duplicate Portal Files" subtitle="" count={dcpConflictedClientFileCount} isClicked={isDcpConflictedClientFile} />
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="2" title="CRM-Portal Linking" subtitle="" count={crmConflictedClientFileCount} isClicked={isCrmConflictedClientFile} />
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: "5px" }}>
                <InfoCard setKey={setCardKey} cardID="3" title="Interactions" subtitle="" count={conflictedInteractionsCount} isClicked={isCrmConflictedInteraction} />
              </Grid>
              {cardKey === "1" &&
                <>
                  <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
                  }}>Duplicate Portal Files to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopDcpConflictedClientFiles totalCount={dcpConflictedClientFileCount}></TopDcpConflictedClientFiles> </Grid>
                </>}
              {cardKey === "2" &&
                <>
                  <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
                  }}>CRM-Portal Linkings to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopCrmConflictedClientFiles totalCount={crmConflictedClientFileCount}></TopCrmConflictedClientFiles> </Grid>
                </>}
              {cardKey === "3" &&
                <>
                  <Grid item xs={12} md={12} style={{
                    textAlign: "left", font: "normal normal bold 24px/29px Franklin Gothic Medium",
                    letterSpacing: "0px", color: " #000000", opacity: "1", margin: "20px 10px 10px 10px"
                  }}>Interactions to Review</Grid>
                  <Grid item xs={12} md={12} style={{ padding: "10px 5px" }}><TopConflictedInteractions totalCount={conflictedInteractionsCount}></TopConflictedInteractions> </Grid>
                </>}
              <Grid item xs={12} md={12} style={{ minHeight: "90vh" }} />
            </Grid>)
          }

        </>}
    </>);
  } else {
    return (
      <div className="crm-conflicts-dashboard">
        <div className="crmConflictDashboard">
          <div className="sectionTitle">Review Dashboards</div><br />
          <div>You are not authorized to read this record.</div>
        </div>
      </div>
    );
  }


}

export default ConflictDashboard;