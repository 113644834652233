import React, { useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { SelectorStyledTextField, SelectorStyledDashBoardTextField} from "./styled/SelectorTextFiels.styled";

export default function SelectorTextField({
  label,
  required,
  placeholder,
  handleChange,
  value,
  disabled,
  error,
  isDashBoard,
  ...props
}) {
  return (
    <>
    {isDashBoard ?
      <SelectorStyledDashBoardTextField
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      label={label}
      value={value}
      onChange={handleChange}
      InputLabelProps={{ shrink: disabled ? true : false }}
      size="small"
      autoComplete='off'
      {...props}
    /> 
       :
      <SelectorStyledTextField
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      label={label}
      value={value}
      onChange={handleChange}
      InputLabelProps={{ shrink: disabled ? true : false }}
      size="small"
      autoComplete='off'
      {...props}
    /> }
    </>
  );
}
