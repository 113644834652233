import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/serviceDecisions";
const GETFORCLIENTIDREQUESTNAME = "/forclient";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION = "Authorization"
const CONTENTTYPE = "Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";

export async function GetAllServiceDecisions(clientFileCrmId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + GETFORCLIENTIDREQUESTNAME + "?clientFileCrmId=" + clientFileCrmId ;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok)
                return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetServiceDecisionDetailsById(serviceDecisionId, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN, { position: "top-center", autoClose: 5000, theme: "colored" });
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +  "/details?serviceDecisionId=" + serviceDecisionId ;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

    return await fetch(serviceUrl, options)
        .then(async response => {
            var data = await response.json();;
            if (response.ok)
                return data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}
