import appconfig from '../appConfig'
import { toast } from "react-toastify";

const SERVICENAME = "api/dockets";
const CREATEREQUESTNAME = "/create";
const GETMETADATAREQUESTNAME = "/metadata";
const GETFORCINTERACTIONIDREQUESTNAME = "/interaction";
const INVALIDTOKEN = "Cannot proceed. Please login and try again";
const AUTHORIZATION ="Authorization"
const CONTENTTYPE ="Content-Type";
const JSONFORMAT = "application/json";
const BEARER = "Bearer ";
const CREATEFAILED = 'Create Docket Failed.';
const CREATESUCCESS = "Docket successfully created.";
const UPDATESUCCESS ="Docket successfully updated.";
const UPDATEFAILED = 'Update Docket Failed.';
const DELETESUCCESS = "Docket successfully deleted.";
const DELETEFAILED = 'Delete Docket Failed.';

export async function CreateDocket(docket, token) {
    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + CREATEREQUESTNAME;

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(docket)
    }
    

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(CREATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
            return response.json();
        } else {
            toast.error(CREATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
        }
    }).catch(error => { toast.error(CREATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    console.log(error); });

    return result;
}


export async function UpdateDocket(docket, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        return;
    }

    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME + "/" + docket.docketId;  

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(docket)
    }   

    var result = await fetch(serviceUrl, options).then(response => {

        if (response.ok) {
            toast.success(UPDATESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
            return response.json();
        } else {
            toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
        }
    }).catch(error => { toast.error(UPDATEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
    console.log(error); });

    return result;
}

export async function GetDocketById(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function GetDocketMetadata( token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME+GETMETADATAREQUESTNAME;   
    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'GET',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => {             
            var data = await response.json();           
            if(response.ok) return  data;
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
             
        })
        .catch(error => {
            console.error('Error occurred.', error);
        });
}

export async function DeleteDocket(id, token) {

    if (token == null) {
        toast.error(INVALIDTOKEN,{position: "top-center",autoClose: 5000,theme: "colored"});
       // alert(INVALIDTOKEN);
        return;
    }
    const serviceUrl = appconfig.API_SERVER_URL + SERVICENAME +"/"+ id;   

    const headers = new Headers();
    const bearer = BEARER + token;
    headers.append(AUTHORIZATION, bearer);
    headers.append(CONTENTTYPE, JSONFORMAT);

    const options = {
        method: 'DELETE',
        headers: headers
    }

  return await fetch(serviceUrl, options)
        .then(async response => { 
            var data =await response.json(); ;
            if (response.ok) {
                toast.success(DELETESUCCESS,{position: "top-center",autoClose: 5000,theme: "colored"});
                return data;
            }
            // check for error response
            else {
                // get error message from body or default to response statusText
                const error = (data && data.message) || response.statusText;
                toast.error(DELETEFAILED,{position: "top-center",autoClose: 5000,theme: "colored"});
                return Promise.reject(error);
            }
             
        })
      .catch(error => {
            console.error('Error occurred.', error);
        });
}
