import React, { useEffect, useState, useLocation, useHistory } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthorization } from "../../hooks/useAuthorization";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../auth/authConfig";
import { GetClientFileById } from "../../services/client-file.service";
import * as Constants from "../../helper/constants";
import { MdNavigateNext } from "react-icons/md";

function CustomBreadcrumb() {

  const [canCreate, canRead, canUpdate, canDelete] = useAuthorization();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [backPage, setBackPage] = useState(searchParams.get("page"));
  const [clientFileId, setClientFileId] = useState(searchParams.get("clientFileId"));
  const [topMyFilesFilterByDueDate, setTopMyFilesFilterByDueDate] = useState(searchParams.get("topmyfilesduedate") ? searchParams.get("topmyfilesduedate") : "2");
  const [crmId, setCrmId] = useState(searchParams.get("crmId"));
  const [firstName, setFirstName] = useState(searchParams.get("firstname"));
  const [lastName, setLastName] = useState(searchParams.get("lastname"));
  const [dateOfBirth, setDateOfBirth] = useState(searchParams.get("dateofbirth"));
  const [pagecount, setPagecount] = useState(searchParams.get("pagecount"));
  const [topmyfilesduedate, setTopmyfilesduedate] = useState(searchParams.get("topmyfilesduedate"));
  const [topfilestobeassignedduedate, setTopfilestobeassignedduedate] = useState(searchParams.get("topfilestobeassignedduedate"));
  const [topassignedfilesduedate, setTopassignedfilesduedate] = useState(searchParams.get("topassignedfilesduedate"));
  const [myfilesduedate, setMyfilesduedate] = useState(searchParams.get("myfilesduedate"));
  const [filestobeassignedduedate, setFilestobeassignedduedate] = useState(searchParams.get("filestobeassignedduedate"));
  const [assignedfilesduedate, setAssignedfilesduedate] = useState(searchParams.get("assignedfilesduedate"));

  const [clientName, setClientName] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [interactionId, setInteractionId] = useState("");

  const [firstLevel, setFirstLevel] = useState("");
  const [secondLevel, setSecondLevel] = useState("");
  const [thirdLevel, setThirdLevel] = useState("");
  const [fourthLevel, setFourthLevel] = useState("");
  const [fifthLevel, setFifthLevel] = useState("");


  const [searchclienturl, setSearchClientUrl] = useState("");
  const [dashboardurl, setDashboardUrl] = useState("");
  const [reviewdashboardurl, setReviewdashboardUrl] = useState("");

  const [myfilesurl, setMyfilesUrl] = useState("");
  const [filestoassignurl, setFilestoassignUrl] = useState("");
  const [assignedfilesurl, setAssignedfilesUrl] = useState("");

  const [reviewportalclientfilesurl, setReviewportalclientfilesUrl] = useState("");
  const [reviewcrmclientfilesurl, setReviewcrmclientfilesUrl] = useState("");
  const [reviewallinteractionsurl, setReviewallinteractionsUrl] = useState("");

  const [clientfileurl, setClientfileUrl] = useState("");
  const [reviewduplicateportalfileurl, setReviewduplicateportalfileUrl] = useState("");
  const [reviewcrmportallinkingurl, setReviewcrmportallinkingUrl] = useState("");
  const [reviewinteractionsurl, setReviewinteractionsUrl] = useState("");

  const [viewdetailsurl, setViewdetailsurl] = useState("");

  const SEARCHCLIENT = "SearchClient";
  const DASHBOARD = "Dashboard";
  const REVIEWDASHBOARD = "ReviewDashboard";

  const MYFILES = "MyFiles";
  const FILESTOASSIGN = "FilesToAssign";
  const ASSIGNEDFILES = "AssignedFiles";
  const REVIEWPORTALCLIENTFILES = "ReviewPortalClientFiles";
  const REVIEWCRMCLIENTFILES = "ReviewCRMClientFiles";
  const REVIEWALLINTERACTIONS = "ReviewAllInteractions";

  const CLIENTFILE = "ClientFile";
  const REVIEWDUPLICATEPORTALFILE = "ReviewDuplicatePortalFile";
  const REVIEWCRMPORTALLINKING = "ReviewCRM-PortalLinking";
  const REVIEWINTERACTIONS = "ReviewInteractions";

  const AGENCYREQUEST = "AgencyRequest";
  const INTERACTION = "Interaction";
  const RECENTACTIVITIES = "RecentActivities";
  const VIEWDETAILS = "ViewDetails";
  const NOTEDETAILS = "NoteDetails";
  const REFERRALDETAILS = "ReferralDetails";
  const AGENCYREQUESTDETAILS = "AgencyRequestDetails";
  const JPBAILOUTCOMEDETAILS = "JPBailOutcomeDetails";
  const INTERACTIONDETAILS = "InteractionDetails";

  var SEARCHCLIENTURL = "/clientfiles/search";
  var DASHBOARDURL = "/";
  var REVIEWDASHBOARDURL = "/reviewdashboard";

  var MYFILESURL = "/myfiles";
  var FILESTOASSIGNURL = "/filestobeassigned";
  var ASSIGNEDFILESURL = "/assignedfiles";

  var REVIEWPORTALCLIENTFILESURL = "/reviewdashboard/dcpclientfiles";
  var REVIEWCRMCLIENTFILESURL = "/reviewdashboard/crmclientfiles";
  var REVIEWALLINTERACTIONSURL = "/reviewdashboard/interactions";

  var CLIENTFILEURL = "/client-dashboard";
  var REVIEWDUPLICATEPORTALFILEURL = "/reviewdashboard/resolvedcpconflicts";
  var REVIEWCRMPORTALLINKINGURL = "/reviewdashboard/resolvecrmconflicts";
  var REVIEWINTERACTIONSURL = "/reviewdashboard/resolvedinteractionconflicts";


  var RECENTACTIVITIESURL = "/client-dashboard";
  var VIEWDETAILSURL = "/client-file";

  var NOTEDETAILSURL = "/note";
  var REFERRALDETAILSURL = "/referral";
  var JPBAILOUTCOMEDETAILSURL = "/jpbailoutcome";
  var AGENCYREQUESTDETAILSURL = "/agencyrequest";
  var INTERACTIONDETAILSURL = "/interaction";


  useEffect(() => {
    GetClientNameById();
    LoadBreadcrumbByPageDetails();

  }, []);



  function LoadBreadcrumbByPageDetails() {

    var pageLocation = window.location.pathname;
    console.log(firstLevel);
    var first;
    var second;
    var third;
    var fourth;

    //First Level Changes
    if (backPage == "topmyfiles" || backPage == "topfilestobeassigned" || backPage == "topassignedfiles" || backPage == "dashboard"
      || backPage == "assignedfiles" || backPage == "myfiles" || backPage == "filestobeassigned" || backPage == "assignedfiles") {
      setFirstLevel(DASHBOARD);
      first = DASHBOARD;

      switch (backPage) {
        case "topmyfiles":
          setDashboardUrl(Constants.DASHBOARDPAGEURL + "?cardKey=1&topmyfilesduedate=" + topmyfilesduedate);
          break;
        case "topfilestobeassigned":
          setDashboardUrl(Constants.DASHBOARDPAGEURL + "?cardKey=2&topfilestobeassignedduedate=" + topfilestobeassignedduedate);
          break;
        case "topassignedfiles":
          setDashboardUrl(Constants.DASHBOARDPAGEURL + "?cardKey=3&topassignedfilesduedate=" + topassignedfilesduedate);
          break;
        default: setDashboardUrl(Constants.DASHBOARDPAGEURL);
      }
    }

    else if (backPage == "searchclient" || pageLocation == "/reviewdashboard/dcpclientfiles" || pageLocation == "/reviewdashboard/crmclientfiles" || pageLocation == "/reviewdashboard/interactions"
      || pageLocation == "/reviewdashboard/resolvedcpconflicts" || backPage == "topdcpconflictedclientfiles" || backPage == "crmconflictedclientfiles" || backPage == "topinteractionconflicts") {
      setFirstLevel(REVIEWDASHBOARD);
      first = REVIEWDASHBOARD;

      switch (backPage) {
        case "topcrmconflictedclientfiles":
          setReviewdashboardUrl(REVIEWDASHBOARDURL + "?cardKey=2");
          break;
        case "conflictedinteractions":
          setReviewdashboardUrl(REVIEWDASHBOARDURL + "/interactions");
          break;
        case "topinteractionconflicts":
          setReviewdashboardUrl(REVIEWDASHBOARDURL + "?cardKey=3");
          break;
        default: setReviewdashboardUrl(REVIEWDASHBOARDURL);
      }
    }

    else if (pageLocation == "/client-file" || pageLocation == "/clientfiles/search" || pageLocation == "/client-dashboard"
      || pageLocation == "/note" || pageLocation == "/referral" || pageLocation == "/jpbailoutcome" || pageLocation == "/interaction" || pageLocation == "/agencyrequest") {
      setFirstLevel(SEARCHCLIENT);
      first = SEARCHCLIENT;


    }

    //Second Level Changes
    if (pageLocation == "/myfiles" || backPage == "myfiles") {
      setSecondLevel(MYFILES);
      second = MYFILES;

      if (backPage == "myfiles") {
        setMyfilesUrl(Constants.MYFILESPAGEURL + `?page=topmyfiles${getParams()}&myfilesduedate=${myfilesduedate}`);
      }
    }

    if (pageLocation == "/filestobeassigned" || backPage == "filestobeassigned") {
      setSecondLevel(FILESTOASSIGN);
      second = FILESTOASSIGN;

      if (backPage == "filestobeassigned")
        setFilestoassignUrl(Constants.FILESTOBEASSIGNEDPAGEURL + `?page=topfilestobeassigned${getParams()}&filestobeassignedduedate=${filestobeassignedduedate}`);

    }

    if (pageLocation == "/assignedfiles" || backPage == "assignedfiles") {
      setSecondLevel(ASSIGNEDFILES);
      second = ASSIGNEDFILES;
      if (backPage == "assignedfiles") {
        setAssignedfilesUrl(Constants.ASSIGNEDFILESPAGEURL + `?page=topassignedfiles${getParams()}&assignedfilesduedate=${assignedfilesduedate}`);
      }
    }

    if (pageLocation == "/reviewdashboard/dcpclientfiles") {
      setSecondLevel(REVIEWPORTALCLIENTFILES);
      second = REVIEWPORTALCLIENTFILES;
    }

    if (pageLocation == "/reviewdashboard/crmclientfiles" || backPage == "topcrmconflictedclientfiles") {
      setSecondLevel(REVIEWCRMCLIENTFILES);
      second = REVIEWCRMCLIENTFILES;
      switch (backPage) {
        case "crmconflictdashboard":
          setReviewcrmclientfilesUrl(Constants.CRMCONFLICTDASHBOARDPAGEURL);
          break;
        case "crmconflictdashboardclientfiles":
          setReviewcrmclientfilesUrl(Constants.CRMCONFLICTDASHBOARDPAGEURL + `?${getParams()}`);
          break;
        default:
          setReviewcrmclientfilesUrl(Constants.CRMCONFLICTDASHBOARDPAGEURL);
          break;
      }
    }

    if (pageLocation == "/reviewdashboard/interactions") {
      setSecondLevel(REVIEWALLINTERACTIONS);
      second = REVIEWALLINTERACTIONS;
    }

    //Third Level Changes
    if (pageLocation == "/client-file" || backPage == "clientfiledashboard" || pageLocation == "/note" || pageLocation == "/referral" || pageLocation == "/jpbailoutcome" || pageLocation == "/interaction" || pageLocation == "/agencyrequest" || pageLocation == "/client-dashboard") {
      setThirdLevel(CLIENTFILE);
      third = CLIENTFILE;
      if (window.location.pathname == "/client-dashboard") {        
        CLIENTFILEURL = "#";
      }
      else if (window.location.pathname == "/client-file") {        
        CLIENTFILEURL = CLIENTFILEURL + `?id=${searchParams.get("id")}`;
      }
     
      else if (first == DASHBOARD && CLIENTFILEURL == "/client-dashboard")
        CLIENTFILEURL = CLIENTFILEURL + `?page=dashboard&id=${clientFileId}`;
      else if (first == SEARCHCLIENT)
        CLIENTFILEURL = CLIENTFILEURL + `?id=${clientFileId}&page=searchresults${getParams()}`;

      setClientfileUrl(CLIENTFILEURL);
    }

    if (pageLocation == "/reviewdashboard/resolvedcpconflicts") {
      setThirdLevel(REVIEWDUPLICATEPORTALFILE);
    }

    if (pageLocation == "/reviewdashboard/resolvecrmconflicts") {
      setThirdLevel(REVIEWCRMPORTALLINKING);
    }

    if (pageLocation == "/reviewdashboard/resolvedinteractionconflicts") {
      setThirdLevel(REVIEWINTERACTIONS);
    }


    //Fourth Level Changes
    if (pageLocation == "/client-file" || pageLocation == "/note" || pageLocation == "/referral" || pageLocation == "/jpbailoutcome" || pageLocation == "/interaction" || pageLocation == "/agencyrequest") {
      setFourthLevel(VIEWDETAILS);
      if (pageLocation == "/client-file") {
        setViewdetailsurl("#");
      }
      else {
        setViewdetailsurl(Constants.CLIENTFILEPAGEURL + "?id=" + clientFileId);
      }
    }

    if (pageLocation == "/client-dashboard") {
      setFourthLevel(RECENTACTIVITIES);
    }

    //Fifth Level Changes
    if (pageLocation == "/note")
      setFifthLevel(NOTEDETAILS);

    if (pageLocation == "/referral")
      setFifthLevel(REFERRALDETAILS);

    if (pageLocation == "/agencyrequest")
      setFifthLevel(AGENCYREQUESTDETAILS);

    if (pageLocation == "/jpbailoutcome")
      setFifthLevel(JPBAILOUTCOMEDETAILS);

    if (pageLocation == "/interaction")
      setFifthLevel(INTERACTIONDETAILS);
  }

  function handleClick(event) {
  }

  function getParams() {
    var queryParams = '';
    if (crmId || firstName || lastName || dateOfBirth || pagecount) {
      if (crmId != null) {
        queryParams = `&crmId=${crmId}`;
      }
      if (firstName) {
        queryParams = `&firstname=${firstName}`;
      }
      if (lastName) {
        queryParams = queryParams + `&lastname=${lastName}`;
      }
      if (dateOfBirth) {
        queryParams = queryParams + `&dateofbirth=${dateOfBirth}`;
      }
      if (pagecount) {
        queryParams = queryParams + `&pagecount=${pagecount}`;
      }
    }
    return queryParams;
  }


  function GetClientNameById() {
    if (account) {
      instance.acquireTokenSilent(
        tokenRequest
      ).then(async (response) => {
        if (response) {
          if (clientFileId > 0) {
            await GetClientFileById(clientFileId, response.accessToken).then(data => {
              setClientName(data.clientBasicInformation.firstName);
            });
          }

          if (window.location.pathname == "/client-dashboard" || window.location.pathname == "/client-file") {
            var clientId = searchParams.get("id");
            setClientFileId(clientId);
            if (clientId > 0) {
              await GetClientFileById(clientId, response.accessToken).then(data => {
                setClientName(data.clientBasicInformation.firstName);
              });
            }
          }
        }
      });
    }

  }
  return (
    <div align="center">
      <Breadcrumbs separator={<MdNavigateNext size={16} style={{ margin: "0px -10px 0px -8px" }} />} style={{ font: "normal normal normal 12px/14px Franklin Gothic Medium" }} onClick={handleClick}>

        {/* Root Level Links */}
        {firstLevel == SEARCHCLIENT &&
          <Link underline="hover" color="inherit" href={searchclienturl}>
            Search Client
          </Link>}
        {firstLevel == DASHBOARD &&
          <Link underline="hover" color="inherit" href={dashboardurl}>
            Dashboard
          </Link>}
        {firstLevel == REVIEWDASHBOARD &&
          <Link underline="hover" color="inherit" href={reviewdashboardurl}>
            Review Dashboard
          </Link>}

        {/* Second Level Links */}
        {secondLevel == MYFILES &&
          <Link underline="hover" color="inherit" href={myfilesurl}>
            My Files
          </Link>}
        {secondLevel == FILESTOASSIGN &&
          <Link underline="hover" color="inherit" href={filestoassignurl}>
            Files to Assign
          </Link>}
        {secondLevel == ASSIGNEDFILES &&
          <Link underline="hover" color="inherit" href={assignedfilesurl}>
            Assigned Files
          </Link>}
        {secondLevel == REVIEWPORTALCLIENTFILES &&
          <Link underline="hover" color="inherit" href={reviewportalclientfilesurl}>
            Review Portal Client Files
          </Link>}
        {secondLevel == REVIEWCRMCLIENTFILES &&
          <Link underline="hover" color="inherit" href={reviewcrmclientfilesurl}>
            Review CRM Client Files
          </Link>}
        {secondLevel == REVIEWALLINTERACTIONS &&
          <Link underline="hover" color="inherit" >
            Review All Interactions
          </Link>}

        {/* Third Level Links */}
        {thirdLevel == CLIENTFILE &&
          <Link underline="hover" color="inherit" href={clientfileurl}>
            Client File: {clientName}
          </Link>}
        {thirdLevel == REVIEWDUPLICATEPORTALFILE &&
          <Link underline="hover" color="inherit" href={reviewduplicateportalfileurl}>
            Review Duplicate Portal File
          </Link>}
        {thirdLevel == REVIEWCRMPORTALLINKING &&
          <Link underline="hover" color="inherit" href={reviewcrmportallinkingurl}>
            Review CRM-Portal Linking
          </Link>}
        {thirdLevel == REVIEWINTERACTIONS &&
          <Link underline="hover" color="inherit" href={reviewinteractionsurl}>
            Review Interactions
          </Link>}

        {/* Fourth Level Links */}
        {fourthLevel == RECENTACTIVITIES &&
          <Link underline="hover" color="inherit" >
            Recent Activities
          </Link>}
        {fourthLevel == VIEWDETAILS &&
          <Link underline="hover" color="inherit" href={viewdetailsurl}>
            View Details
          </Link>}

        {/* Fifth Level Links */}
        {fifthLevel == NOTEDETAILS &&
          <Link underline="hover" color="inherit" >
            Note
          </Link>}
        {fifthLevel == REFERRALDETAILS &&
          <Link underline="hover" color="inherit" >
            Referral
          </Link>}
        {fifthLevel == AGENCYREQUESTDETAILS &&
          <Link underline="hover" color="inherit">
            Agency Request
          </Link>}
        {fifthLevel == JPBAILOUTCOMEDETAILS &&
          <Link underline="hover" color="inherit" >
            JP Bail Outcome
          </Link>}
        {fifthLevel == INTERACTIONDETAILS &&
          <Link underline="hover" color="inherit" >
            Interaction
          </Link>}

      </Breadcrumbs>

    </div>
  );
}



export default CustomBreadcrumb;